import React, { useEffect, useState } from "react";
import BasePage from "../../components/layouts/BasePage";
import BaseCard from "../../components/BaseCard";
import Col from "react-bootstrap/Col";
import Spinner from "../../components/Spinner";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { fetchAllFilters, trackUserAction } from "../../services/dataService";
import "./index.scss";

const DataGovernance = ({ data }) => {
  return (
    <div>
      {data?.map(el => (
        <section key={el.Category} className="whatsnew">
          <h5>{el.Category}</h5>
          {el.Values.map(e => (
            <div key={e.Title}>
              <span>{e["Sub-Category"]}:&nbsp;</span>
              <a href={e.Url} target="_blank">
                {e.Title}
              </a>
            </div>
          ))}
        </section>
      ))}
    </div>
  );
};
const WhatsNew = () => {
  const [tab, setTab] = useState("governance");
  const [governance, setGovernance] = useState(true);
  const [insights, setInsights] = useState(false);
  const [loading, setLoading] = useState(true);
  const [governanceData, setGovernanceData] = useState([]);
  useEffect(() => {
    fetchAllFilters()
      .then(result => {
        setGovernanceData(
          result.data?.find(e => e.type === "Data Governance")[
            "Data Governance"
          ]
        );
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);
  return (
    <BasePage className="p-4 pt-5">
      <BaseCard className="p-4">
        {!loading ? (
          <Row className="py-4 whatsnew-btn mx-2 mb-4">
            <Col sm={12} md={8} lg={8} className="pr-5">
              <p className="whatsnew-heading my-3">What's New Section</p>
              <Button
                className={`mb-5 ${
                  governance ? "whats-new-btn-active" : "whats-new-btn"
                }`}
                onClick={() => {
                  setTab("governance");
                  setGovernance(true);
                  setInsights(false);
                }}
              >
                Data Governance
              </Button>
              <Button
                disabled
                style={{ cursor: "not-allowed" }}
                className={`ml-2 mb-5 ${
                  insights ? "whats-new-btn-active" : "whats-new-btn"
                }`}
                onClick={() => {
                  setTab("insights");
                  setGovernance(false);
                  setInsights(true);
                }}
              >
                Marketplace Insights
              </Button>
              {tab === "governance" && <DataGovernance data={governanceData} />}
              {tab === "insights" && <div></div>}
            </Col>
            <Col sm={12} md={4} lg={4}>
              <p className="p-4 whatsnew-box">
                For more information about SXM Data Governance, visit our
                <a
                  href="https://sxmwiki.corp.siriusxm.com/display/EDM/EDM+-+Enterprise+Data+Management+-+Public+Home"
                  target="_blank"
                >
                  &nbsp;Enterprise Data Management
                </a>
                &nbsp;confluence page
              </p>
            </Col>
          </Row>
        ) : (
          <Spinner>Loading...</Spinner>
        )}
      </BaseCard>
    </BasePage>
  );
};

export default WhatsNew;
