// import { APP_VARS } from "../../../services/appVars";
const videoThumb = "/images/Notebooks Video Thumbnail.jpg";

export default () => [
  {
    title: "Jupyter Notebook Tutorial",
    src: "https://www.youtube.com/watch?v=HW29067qVWk",
    description:
      "In this course you will learn to use Jupyter Notebook to create and share documents that contain live code, equations, visualizations and markdown text. This can all be run from directly in the browser. It is an essential tool to learn if you are getting started in Data Science, but will also have tons of benefits outside of that field.",
    topics: "",
    duration: "30:20",
    playing: false,
    id: 0,
    thumbnail: "/assets" + videoThumb
  }
];
