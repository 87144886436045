import React, { useCallback, useState } from "react";
import {
  AiOutlineFileExcel,
  AiOutlineFilePdf,
  AiOutlineHtml5
} from "react-icons/ai";

import Spinner from "../../components/Spinner";

import Button from "react-bootstrap/Button";

import TreeView from "../../components/TreeView";
import ReportWidgetContainer from "../../components/ReportWidgetContainer";
import {
  fetchPrompts,
  fetchPromptsCascadeList,
  refreshPrompts,
  trackUserAction,
  pollPrompts
} from "../../services/dataService";
import Modal from "react-bootstrap/Modal";
import { v4 as uuidv4 } from "uuid";

import "../myreports/CognosPromptLoads";
import { useAlertsContext } from "../../context/alerts";

import "./PromptPage.scss";
import datepicker from "js-datepicker";
import { format } from "date-fns";
import { MdRefresh } from "react-icons/md";

const reportTypeIconMap = {
  HTML: <AiOutlineHtml5 />,
  PDF: <AiOutlineFilePdf />,
  spreadsheetML: <AiOutlineFileExcel />
};
const SPINNER = `<div class="spinner-border text-primary" role="status">
<span class="sr-only">Loading...</span>
</div>`;

function ReportTreeView({
  activeMenuItem,
  folders,
  setFolders,
  refreshReports,
  loading
}) {
  const [folderFilterText, setFolderFilterText] = useState("");
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState();
  const [promptsLoading, setPromptsLoading] = useState(false);
  const [prompthtml, setPromptHTML] = useState();

  const handleClose = () => {
    setShow(false);
    setPromptHTML("");
  };
  const handleShow = () => {
    setShow(true);
    setPromptsLoading(true);
  };
  const { addFailAlert, addSuccessAlert, addInfoAlert } = useAlertsContext();

  function lookForFolder(list, item) {
    list.forEach(node => {
      if (node.UUID === item.UUID) {
        node.expanded = !node.expanded;
      }
      if (node.Folder) {
        lookForFolder(node.Folder, item);
      }
    });
  }

  window.populateLOV = (parentLOV, childLOV) => {
    var btnSpinnerElement = document.getElementById(
      `btnspinner${parentLOV.replace(/ /g, "_")}`
    );
    btnSpinnerElement.style.display = "inline-block";
    btnSpinnerElement.innerHTML = SPINNER;

    var dataObj = {
      runType: "run_now",
      actionType: "getCascadeValues",
      reportConversationId: document.getElementById("conversationID").value,
      reportSelectedParameters: window.CognosPromptBuilder.getSelectedLOV(
        parentLOV
      ),
      childParameterName: childLOV
    };

    fetchPromptsCascadeList(dataObj)
      .then(resp => {
        btnSpinnerElement.innerHTML = "";
        btnSpinnerElement.style.display = "none";

        window.CognosPromptBuilder.populateChildLOV(childLOV, resp.data);
      })
      .catch(err => {});
    return;
  };

  const handleExpandTreeClick = item => {
    lookForFolder(folders, item);
    setFolders(p => [...p]);
  };

  const pollPromptsFn = useCallback(
    reportId => {
      pollPrompts({ reportId: reportId })
        .then(res => {
          if (reportId !== window.CognosPromptBuilder.rptStoreID) {
            return;
          }
          setPromptHTML(res.data);
          setPromptsLoading(false);
          setTimeout(function() {
            var inputs = document.forms["frmPrompts"].getElementsByTagName(
              "input"
            );
            for (var i = 0; i < inputs.length; i++) {
              var item = inputs[i];

              if (
                item.getAttribute("ctrlType") === "startdate" ||
                item.getAttribute("ctrlType") === "enddate" ||
                item.getAttribute("ctrlType") === "datetime"
              ) {
                const picker = datepicker("#" + item.id, {
                  formatter: (input, date, instance) => {
                    const value = format(new Date(date), "MMM dd, yyyy");
                    input.value = value;
                  }
                });
                picker.calendarContainer.style.setProperty(
                  "font-size",
                  "1.3rem"
                );
              }
            }
          }, 500);
        })
        .catch(err => {
          if (reportId !== window.CognosPromptBuilder.rptStoreID) {
            return;
          }
          if (err.response.status === 303) {
            return setTimeout(() => pollPromptsFn(reportId));
          } else {
            console.log(err);
            addFailAlert("Something went wrong fetching prompts");
            setPromptsLoading(false);
          }
        });
    },
    [addFailAlert]
  );

  function loadPromptPage(reportID) {
    var dataObj1 = {
      runType: "run_now",
      actionType: "getPromptHTML",
      reportId: reportID,
      promptSession: uuidv4()
    };

    fetchPrompts(dataObj1)
      .then(res => {
        if (reportID !== window.CognosPromptBuilder.rptStoreID) {
          return;
        }
        setPromptHTML(res.data);
        setPromptsLoading(false);
        setTimeout(function() {
          var inputs = document.forms["frmPrompts"].getElementsByTagName(
            "input"
          );
          for (var i = 0; i < inputs.length; i++) {
            var item = inputs[i];

            if (
              item.getAttribute("ctrlType") === "startdate" ||
              item.getAttribute("ctrlType") === "enddate" ||
              item.getAttribute("ctrlType") === "datetime"
            ) {
              const picker = datepicker("#" + item.id, {
                formatter: (input, date, instance) => {
                  const value = format(new Date(date), "MMM dd, yyyy");
                  input.value = value;
                }
              });
              picker.calendarContainer.style.setProperty("font-size", "1.3rem");
            }
          }
        }, 500);
      })
      .catch(err => {
        if (reportID !== window.CognosPromptBuilder.rptStoreID) {
          return;
        }
        if (err.response.status === 303) {
          return pollPromptsFn(window.CognosPromptBuilder.rptStoreID);
        } else {
          console.log(err);
          addFailAlert("Something went wrong fetching prompts");
          setPromptsLoading(false);
        }
      });
  }
  const handlefolderTreeChildClick = (item, key) => {
    window.CognosPromptBuilder.reportName = item.folderName;
    window.CognosPromptBuilder.rptSearchPath = item.searchPath;
    window.CognosPromptBuilder.rptDrillable = item.drill;
    window.CognosPromptBuilder.rptDefaultOutputFormat = item.outputFormat;
    window.CognosPromptBuilder.rptStoreID = item.reportID;
    trackUserAction({
      action: "Open",
      targetType: key,
      targetName: item.folderName,
      targetPath: item.searchPath,
      targetID: item.reportID,
      status: "Success",
      errorDetails: ""
    });
    setTitle(item.folderName);
    handleShow();
    loadPromptPage(item.reportID);
    trackUserAction({
      action: "View",
      targetType: "Prompts",
      targetName: item.folderName,
      targetPath: item.searchPath,
      targetID: item.reportID,
      status: "Success",
      errorDetails: ""
    });

    return;
  };

  const handleSubmitPrompts = () => {
    trackUserAction({
      action: "SubmitNow",
      targetType: window.CognosPromptBuilder.rptDefaultOutputFormat,
      targetName: window.CognosPromptBuilder.reportName,
      targetPath: window.CognosPromptBuilder.rptSearchPath,
      targetID: window.CognosPromptBuilder.rptStoreID,
      status: "Success",
      errorDetails: ""
    });
    window.CognosPromptBuilder.submitPrompts(
      "submitnow",
      "congos",
      addSuccessAlert,
      addFailAlert
    );
    addInfoAlert(
      "Your report submitted to run, you will receive an email when its ready"
    );

    handleClose();
  };

  const reloadPrompts = () => {
    setPromptsLoading(true);
    var refreshPrmptsPayLoad = {
      reportId: window.CognosPromptBuilder.rptStoreID,
      refreshStatus: true
    };
    refreshPrompts(refreshPrmptsPayLoad)
      .then(resp => {
        if (
          refreshPrmptsPayLoad.reportId !==
          window.CognosPromptBuilder.rptStoreID
        ) {
          return;
        }
        trackUserAction({
          action: "Refresh",
          targetType: window.CognosPromptBuilder.rptDefaultOutputFormat,
          targetName: window.CognosPromptBuilder.reportName,
          targetPath: window.CognosPromptBuilder.rptSearchPath,
          targetID: window.CognosPromptBuilder.rptStoreID,
          status: "Success",
          errorDetails: ""
        });
        loadPromptPage(window.CognosPromptBuilder.rptStoreID);
        setPromptsLoading(false);
      })
      .catch(err => {
        if (
          refreshPrmptsPayLoad.reportId !==
          window.CognosPromptBuilder.rptStoreID
        ) {
          return;
        }
        if (err.response.status === 303) {
          return setTimeout(() =>
            pollPromptsFn(window.CognosPromptBuilder.rptStoreID)
          );
        } else {
          setPromptsLoading(false);
        }
      });
  };
  return (
    <ReportWidgetContainer
      headerText="Run Reports"
      value={folderFilterText}
      placeholder="Search reports"
      onRefreshClick={e => {
        e.preventDefault();
        refreshReports(true);
        //e.stopPropagation();
      }}
      onSubmit={e => e.preventDefault()}
      onChange={e => {
        e.preventDefault();
        setFolderFilterText(e.target.value);
      }}
    >
      <Modal
        className="sx-rpt-prmpts"
        show={show}
        onHide={handleClose}
        size="lg"
      >
        <Modal.Header closeButton as="span">
          <Modal.Title>{title} </Modal.Title>

          <MdRefresh
            style={{
              position: "absolute",
              right: 34,
              top: 10,
              cursor: "pointer"
            }}
            size={25}
            onClick={reloadPrompts}
          />
        </Modal.Header>
        <Modal.Body>
          <form name="frmPrompts" validate="true">
            {promptsLoading && <Spinner>Fetching Prompts</Spinner>}
            <div
              style={{ overflowY: "auto", minHeight: 450, maxHeight: 450 }}
              dangerouslySetInnerHTML={{ __html: prompthtml }}
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="row" style={{ marginRight: 0, width: "100%" }}>
            <div
              className="col-sm-3"
              style={{ paddingLeft: 0, fontSize: 12, textAlign: "right" }}
            >
              <label className="control-label" style={{ paddingTop: 5 }}>
                Name your report output{" "}
              </label>
            </div>
            <div className="col-sm-9" style={{ paddingLeft: 24 }}>
              <input
                type="text"
                className="form-control"
                name="reportName"
                id="reportName"
                maxLength="150"
                defaultValue={`${title}_${format(
                  new Date(),
                  "MM_dd_yyyy.HH.mm"
                )}`}
              ></input>
            </div>
          </div>
          <div className="row" style={{ marginRight: 0, width: "100%" }}>
            <div
              className="col-sm-3"
              style={{ paddingLeft: 0, fontSize: 12, textAlign: "right" }}
            >
              <label className="control-label " style={{ paddingTop: 5 }}>
                Select Format
              </label>
            </div>
            <div className="col-md-4" style={{ paddingLeft: 24 }}>
              <select
                id="outputformat"
                className="form-control"
                style={{ width: 200 }}
                defaultValue="spreadsheetML"
              >
                <option value="HTML"> HTML </option>
                <option value="PDF"> PDF </option>
                <option value="spreadsheetML"> Excel </option>
                <option value="CSV"> CSV </option>
              </select>
            </div>
            <div className="col-md-4" style={{ paddingLeft: 0 }}>
              <Button
                variant="secondary"
                onClick={handleClose}
                style={{ marginLeft: 90 }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleSubmitPrompts}
                style={{ marginLeft: 15 }}
              >
                Submit
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <div style={{ maxHeight: "50rem", overflow: "auto" }}>
        {loading && <Spinner>Fetching Reports</Spinner>}
        {!loading && (
          <TreeView
            className="mt-4 ml-4"
            onNodeClick={handleExpandTreeClick}
            renderLabel={item => item.folderName}
            childrenKeyName="Folder"
            nameField="folderName"
            renderChild={item => (
              <div className="d-flex align-items-center justify-content-between">
                <Button
                  onClick={() => handlefolderTreeChildClick(item, "RptFilter")}
                  variant="link"
                >
                  <span className="pr-2 text-primary">
                    {reportTypeIconMap[item.outputFormat]}
                  </span>
                  {item.folderName}
                </Button>
              </div>
            )}
            searchTerm={folderFilterText}
            data={folders}
          />
        )}
      </div>
    </ReportWidgetContainer>
  );
}

export default ReportTreeView;
