import React from "react";
import { AiFillStar } from "react-icons/ai";

export default function DataGovernanceInformation({ totalStars = 4, stars }) {
  return (
    <div>
      {[...Array(totalStars)]
        .fill(<AiFillStar color="#ffdb2a" className="mx-1" />)
        .fill(<AiFillStar color="lightgrey" className="mx-1" />, stars)}
    </div>
  );
}
