import React, { useEffect, useState } from "react";
import classnames from "classnames";
import {
  AiOutlineCaretRight,
  AiOutlineCaretDown,
  AiOutlineFolderOpen
} from "react-icons/ai";

import "./TreeView.scss";

function TreeView({
  className = "",
  data = [],
  onNodeClick,
  renderChild,
  renderLabel,
  searchTerm,
  childrenKeyName,
  nameField,
  ...otherProps
}) {
  function renderItem(item, level = 0) {
    return (
      /*changed item.packageName to use param passed */
      <div key={`${item[nameField]}-${level}`} className="pointer pb-1">
        <div
          style={{ paddingLeft: level * 18 }}
          className={classnames({
            "text-primary font-weight-bold": item[childrenKeyName]
          })}
        >
          {item[childrenKeyName] && (
            <span onClick={() => onNodeClick(item)}>
              {item.expanded && (
                <AiOutlineCaretDown className="sx-tree-view__expanded-icon" />
              )}
              {!item.expanded && (
                <AiOutlineCaretRight className="sx-tree-view__collapsed-icon" />
              )}
              <AiOutlineFolderOpen />
            </span>
          )}{" "}
          <span onClick={() => onNodeClick(item)}>
            {item[childrenKeyName] && renderLabel(item)}
          </span>
          {!item[childrenKeyName] && renderChild(item)}
        </div>
        {item.expanded &&
          item[childrenKeyName] &&
          item[childrenKeyName]
            .filter(j => whiteList[j.UUID])
            .map(i => renderItem(i, level + 1))}
      </div>
    );
  }

  const [whiteList, setWhiteList] = useState({});

  useEffect(() => {
    const whiteList = {};

    function buildWhitelist(list, allowAllChildren) {
      list.forEach(item => {
        if (item[childrenKeyName]) {
          const isFolderMatch =
            item[nameField].toLowerCase().indexOf(searchTerm.toLowerCase()) >
            -1;

          if (isFolderMatch || allowAllChildren) {
            item.UUIDParents.forEach(id => {
              whiteList[id] = true;
            });
            whiteList[item.UUID] = true;
            return buildWhitelist(item[childrenKeyName], true);
          }

          return buildWhitelist(item[childrenKeyName]);
        }
        const isMatch =
          item[nameField].toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;

        if (!isMatch && !allowAllChildren) {
          return;
        }
        item.UUIDParents.forEach(id => {
          whiteList[id] = true;
        });
        whiteList[item.UUID] = true;
      });
    }

    buildWhitelist(data);
    setWhiteList(whiteList);
  }, [data, childrenKeyName, nameField, searchTerm]);

  return (
    <div className={classnames("sx-tree-view", className)} {...otherProps}>
      {data
        .filter(item => whiteList[item.UUID])
        .map(d => {
          return renderItem(d);
        })}
    </div>
  );
}

export default TreeView;
