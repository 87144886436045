import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.scss";
import AlertsProvider from "./context/alerts";
import RecentsProvider from "./context/recents";
import UserPreferencesProvider from "./context/user-preferences";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import history from "./history";
import AuthSessionWrapper from "./pages/app/AuthSessionWrapper";
import App from "./pages/app";
import EnvVarsWrapper from "./pages/app/EnvVarsWrapper";
import ScrollToTop from "./components/ScrollToTop";

ReactDOM.render(
  <EnvVarsWrapper>
    <RecentsProvider>
      <AlertsProvider>
        <UserPreferencesProvider>
          <Router history={history}>
            <ScrollToTop>
              <AuthSessionWrapper
                renderApp={(
                  userName,
                  isSessionExpired,
                  isExecutiveUser,
                  isAdmin
                ) => (
                  <App
                    userName={userName}
                    isSessionExpired={isSessionExpired}
                    isExecutiveUser={isExecutiveUser}
                    isAdmin={isAdmin}
                  />
                )}
              />
            </ScrollToTop>
          </Router>
        </UserPreferencesProvider>
      </AlertsProvider>
    </RecentsProvider>
  </EnvVarsWrapper>,
  document.getElementById("root")
);
