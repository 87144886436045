import React, { useEffect, useState } from "react";
import BasePage from "../../components/layouts/BasePage";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BaseCard from "../../components/BaseCard";
import VideoPlayer from "../../components/VideoPlayer";
import getVideos from "./tableau-content";
import { HelpLink, BottomCap, SelfServiceCard } from "./SelfServiceComponents";

import { trackUserAction } from "../../services/dataService";
import { APP_VARS, S_NOW_LINK } from "../../services/appVars";

import "./SelfServicePages.scss";

const installingTableau = "/ppts/Installing_Tableau.pptx";
const tableauUserGuide = "/pdfs/Tableau_User_Guide.pdf";
const faq = "/docs/Tableau_FAQ.docx";
const tableauImg = "/images/tableau.png";

function GettingStartedTableau(props) {
  const [videoList] = useState(getVideos());
  useEffect(() => {
    trackUserAction({
      action: "View Tableau Analytics",
      targetType: "tile",
      targetName: "Tableau Analytics",
      targetPath: "/gettingstartedwithtableau",
      targetID: "Tableau Ask Data",
      status: "Success",
      errorDetails: ""
    });
  }, []);
  return (
    <BasePage className="SelfServicePages">
      <div className="p-3">
        <BaseCard>
          <div className="d-flex align-items-center py-2 px-3 gray-bottom-border-1">
            <span className="text-primary font-large">Tableau Analytics</span>
            <span className="vertical-bar"></span>
            <span className="text-primary font-medium">
              Your place to find all the tools you need to get up and running
            </span>
            <img
              className="ml-auto"
              src={APP_VARS.REACT_APP_ASSETS_URL + tableauImg}
              alt="Tableau"
            />
          </div>

          <Container fluid>
            <Row>
              <Col sm={12} xl={7}>
                <div className="video-section px-3 pt-3">
                  <VideoPlayer
                    playlistTitle="Training Videos"
                    videoList={videoList}
                  />
                </div>
              </Col>
              <Col sm={12} xl={5} className="right-section">
                <section className="resource-links">
                  <div className="text-primary font-large pb-2">
                    Tableau Learning Resources
                  </div>
                  <HelpLink
                    text="Tableau Starter Kits"
                    url="https://www.tableau.com/learn/starter-kits"
                  />
                  <BottomCap />
                  <HelpLink
                    text="E-Learning"
                    url="https://www.tableau.com/learn/training/elearning"
                  />
                  <BottomCap />
                  <HelpLink
                    text="Tableau Conference Videos"
                    url="https://tc18.tableau.com/watch"
                  />
                  <BottomCap />
                  <HelpLink
                    text="Tableau Community Forum"
                    url="https://community.tableau.com/welcome"
                  />
                </section>
                <section className="resource-links">
                  <div className="text-primary font-large pb-4">
                    Additional Resources
                  </div>
                  <Row>
                    <SelfServiceCard
                      mainText="Tableau User Guide"
                      subText="To learn more, download our useful User Guide"
                      filePath={
                        APP_VARS.REACT_APP_ASSETS_URL + tableauUserGuide
                      }
                    />

                    <SelfServiceCard
                      mainText="Installation Guide"
                      subText="Learn how to install on your desktop"
                      filePath={
                        APP_VARS.REACT_APP_ASSETS_URL + installingTableau
                      }
                    />
                  </Row>
                </section>
                <section className="resource-tiles">
                  <div className="text-primary font-large pb-4">Support</div>
                  <Row>
                    <SelfServiceCard
                      mainText="Get Access"
                      buttonText="Click here"
                      subText="Steps which outline on getting access and use Tableau"
                      href={true}
                      filePath={S_NOW_LINK}
                    />

                    <SelfServiceCard
                      mainText="FAQ"
                      subText="Frequently asked questions related to access, issues etc."
                      filePath={APP_VARS.REACT_APP_ASSETS_URL + faq}
                    />
                  </Row>
                </section>
              </Col>
            </Row>
          </Container>
        </BaseCard>
      </div>
    </BasePage>
  );
}

export default GettingStartedTableau;
