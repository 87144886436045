import React from "react";

import MenuPageTemplate from "../../components/MenuPageTemplate";

import { APP_VARS } from "../../services/appVars";

function CommunityLanding(props) {
  const menuItems = [
    {
      title: "Events",
      subtitle:
        "Access product webinars, upcoming events, customer testimonials and surveys",
      imageUrl:
        APP_VARS.REACT_APP_ASSETS_URL + "/images/Land-page-icons/events.png",
      linkUrl: "/events"
    },
    {
      title: "Forums",
      subtitle: "Read and participate in forums",
      disabled: true,
      imageUrl:
        APP_VARS.REACT_APP_ASSETS_URL + "/images/Land-page-icons/forums.png",
      linkUrl: ""
    },
    {
      title: "EDO Blogs",
      disabled: true,
      subtitle:
        "Tune in to EDO Blogs to follow what's happening in the world of data and analytics",
      imageUrl:
        APP_VARS.REACT_APP_ASSETS_URL + "/images/Land-page-icons/edo-blogs.png",
      linkUrl: ""
    }
  ];
  return (
    <MenuPageTemplate
      menuItems={menuItems}
      mainText="Community"
      subText="Read forums, write blogs and view & register for events"
    />
  );
}

export default CommunityLanding;
