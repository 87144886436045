import React from "react";
import RBToast from "react-bootstrap/Toast";
import "./Toast.scss";
import classnames from "classnames";
import {
  AiOutlineCheckCircle,
  AiOutlineWarning,
  AiOutlineInfoCircle,
  AiOutlineClose
} from "react-icons/ai";

function getHeaderText(type) {
  switch (type) {
    case "success":
      return "Success";
    case "fail":
      return "Error";
    case "info":
      return "Info";
    default:
      return "Success";
  }
}

function getIcon(type) {
  switch (type) {
    case "success":
      return <AiOutlineCheckCircle />;
    case "fail":
      return <AiOutlineWarning />;
    case "info":
      return <AiOutlineInfoCircle />;
    default:
      return <AiOutlineCheckCircle />;
  }
}

function Toast({ show, header = "", message, onClose, type = "success", id }) {
  return (
    <RBToast className={classnames("sx-toast", `sx-${type}`)} show={show}>
      <RBToast.Header>
        {type === "custom" ? (
          <strong className="mr-auto">{header}</strong>
        ) : (
          <strong className="mr-auto">
            {getIcon(type)}
            {getHeaderText(type)}
          </strong>
        )}
        <AiOutlineClose onClick={onClose} className="sx-toast__close pointer" />
      </RBToast.Header>
      {type === "custom" ? (
        <RBToast.Body
          style={{ whiteSpace: "pre-line" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      ) : (
        <RBToast.Body>{message}</RBToast.Body>
      )}
    </RBToast>
  );
}

export default Toast;
