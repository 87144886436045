import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { APP_VARS } from "../../services/appVars";
import { getBannerConfig } from "../../services/dataService";
import "./Banner.scss";
import { DataUniversityContext, USER_STATUS } from "./DataUniversityContext";
import RegisterComponent from "./Register";

export default function DUBanner({ seats }) {
  const { userStatusContext } = useContext(DataUniversityContext);
  const [userStatus] = userStatusContext;
  const [bannerConfig, setBannerConfig] = useState(null);
  const getBannerConfigData = async () => {
    const { data } = await getBannerConfig();
    setBannerConfig(data[0]);
  };
  useEffect(() => {
    getBannerConfigData();
    // eslint-disable-next-line
  }, []);
  return (
    <div className="text-white du-banner-height">
      <div className="p-4">
        <Row className="d-flex align-items-center justify-content-center h-100">
          <Col md={4} className="px-xl-5 px-md-1 pt-xl-3 pt-md-0">
            <div className="p-xl-5 p-md-3">
              <div className="du-logo-wrap">
                <img
                  src={`${APP_VARS.REACT_APP_ASSETS_URL}/images/data-university-landing/data-university-logo-white.svg`}
                  alt="Data University Logo"
                  style={{ height: "8vh" }}
                />
                <span>Beta</span>
              </div>
              <p className="pt-4 pb-2 intro-text">
                {bannerConfig?.Description}
              </p>
            </div>
          </Col>
          <Col md={4} className="px-xl-4 px-md-1 py-xl-3 py-md-0">
            <Row>
              <Col xs={6} md={12}>
                <div className="px-xl-1 px-md-3">
                  <h3 className="font-weight-bold text1 pb-4">
                    Be a Data Champion{" "}
                  </h3>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={4} className="border-right-not-last">
                <div className="m-2 text4 text-center">
                  <img
                    src={
                      APP_VARS.REACT_APP_ASSETS_URL +
                      "/images/data-university-landing/kit-icon.svg"
                    }
                    alt="achievement"
                  />
                </div>
                <div className="m-2 text4 text-center kit-text">
                  {bannerConfig?.Incentive1}
                </div>
              </Col>
              <Col xs={6} md={4} className="border-right-not-last">
                <div className="m-2 text4 text-center">
                  <img
                    src={
                      APP_VARS.REACT_APP_ASSETS_URL +
                      "/images/data-university-landing/achievement-icon.svg"
                    }
                    alt="achievement"
                  />
                </div>
                <div className="m-2 text4 text-center kit-text">
                  {bannerConfig?.Incentive2}
                </div>
              </Col>
              <Col xs={6} md={4} className="border-right-not-last">
                <div className="m-2 text4 text-center">
                  <img
                    src={
                      APP_VARS.REACT_APP_ASSETS_URL +
                      "/images/data-university-landing/access-icon.svg"
                    }
                    alt="achievement"
                  />
                </div>
                <div className="m-2 text4 text-center kit-text">
                  {bannerConfig?.Incentive3}
                </div>
              </Col>
            </Row>
          </Col>

          <Col md={4} className="px-xl-5 px-md-3 py-xl-3 py-md-0">
            <Row>
              <Col xs={3} md={2} className="text-right"></Col>
              <Col xs={9} md={10}>
                <Row>
                  <Col xs={12}>
                    <h1 className="attentionText mb-3">
                      {bannerConfig?.SeatsTitle}
                    </h1>
                    <p>{bannerConfig?.SeatsSubTitle}</p>
                  </Col>
                </Row>
                <Row className="d-flex py-2">
                  <Col>
                    <span className="numberBox mr-3">
                      {("000" + seats).substr(-3, 1)}
                    </span>
                    <span className="numberBox mx-3">
                      {("000" + seats).substr(-2, 1)}
                    </span>
                    <span className="numberBox mx-3">
                      {("000" + seats).substr(-1, 1)}
                    </span>
                    <Row className="justify-content-left mt-4 ml-3">
                      {userStatus === USER_STATUS["PENDING"] ? (
                        <Button size="lg" className="px-3 pending-btn">
                          Pending Approval
                        </Button>
                      ) : (
                        seats > 0 && <RegisterComponent />
                      )}
                    </Row>
                  </Col>

                  {/* <Col xs={12} md={7}>
                    <Row>
                      <Col xs={4}>
                        <h1 className="numberBox">
                          {("000" + seats).substr(-3, 1)}
                        </h1>
                      </Col>
                      <Col xs={4}>
                        <h1 className="numberBox">
                          {("000" + seats).substr(-2, 1)}
                        </h1>
                      </Col>
                      <Col xs={4}>
                        <h1 className="numberBox">
                          {("000" + seats).substr(-1, 1)}
                        </h1>
                      </Col>
                    </Row>
                    <Row>
                      <p className="text3 mx-auto">
                        Seat
                        {seats === 1 ? " " : "s "}
                        Available
                      </p>
                    </Row>

                    <Row className="justify-content-center">
                      {userStatus === USER_STATUS["PENDING"] ? (
                        <Button size="lg" className="px-3 pending-btn">
                          Pending Approval
                        </Button>
                      ) : (
                        seats > 0 && <RegisterComponent />
                      )}
                    </Row>
                  </Col>
                 */}
                </Row>
              </Col>
            </Row>

            {/* <div className="px-xl-4 px-md-3">
              <h3 className="font-weight-bold text1">Be a</h3>
              <h1 className="font-weight-bold mb-0 text2">Data</h1>
              <h1 className="font-weight-bold mb-5 text2">Champion</h1>
            </div>
            <div className="px-xl-4 px-md-3">
              <p className="text3">
                <span className="du-colorText text1">{seats}</span> seat
                {seats.length === 1 ? " " : "s "}
                available
              </p>
            </div> */}
          </Col>
        </Row>
      </div>
    </div>
  );
}
