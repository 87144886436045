import React, { useState } from "react";
import BasePage from "../../components/layouts/BasePage";
import { Tab, Tabs } from "../../components/Tabs";
import CourseManagement from "./CourseManagement";
import StudentManagement from "./StudentManagement";
import BannerManagement from "./BannerManagement";

function DataUniversityManagement(props) {
  const [activeTab, setActiveTab] = useState("students");

  return (
    <BasePage className="DataUniversityManagement">
      <Tabs
        mountOnEnter
        activeKey={activeTab}
        onSelect={k => setActiveTab(k)}
        className="p-0"
      >
        <Tab eventKey="students" title="Student Management">
          <section className="p-1 mt-1">
            <StudentManagement />
          </section>
        </Tab>
        <Tab eventKey="courses" title="Course Management">
          <section className="p-1 mt-3">
            <CourseManagement />
          </section>
        </Tab>
        <Tab eventKey="banner" title="Banner Management">
          <section className="p-1 mt-3">
            <BannerManagement />
          </section>
        </Tab>
      </Tabs>
    </BasePage>
  );
}

export default DataUniversityManagement;
