import React, { createContext, useState } from "react";
export const DataUniversityContext = createContext();

export const DataUniversityProvider = props => {
  const [userStatus, setUserStatus] = useState(USER_STATUS["NEW"]);
  const [user, setUser] = useState({
    studentName: "",
    studentId: "",
    shirtSize: ""
  });
  const [categories, setCategories] = useState([]);
  const [courses, setCourses] = useState([]);
  const [learningPaths, setLearningPaths] = useState([]);
  const [sxmCourses, setSxmCourses] = useState([]);
  const [sxmCourse, setSxmCourse] = useState();
  const [enrollments, setEnrollments] = useState([]);
  const [showSpinner, setShowSpinner] = useState(true);

  return (
    <DataUniversityContext.Provider
      value={{
        userStatusContext: [userStatus, setUserStatus],
        userContext: [user, setUser],
        coursesContext: [courses, setCourses],
        categoriesContext: [categories, setCategories],
        enrollmentsContext: [enrollments, setEnrollments],
        learningPathsContext: [learningPaths, setLearningPaths],
        spinnerContext: [showSpinner, setShowSpinner],
        sxmUniversityContext: [
          sxmCourses,
          setSxmCourses,
          sxmCourse,
          setSxmCourse
        ]
      }}
    >
      {props.children}
    </DataUniversityContext.Provider>
  );
};

export const USER_STATUS = {
  NEW: "New",
  PENDING: "Pending",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  ONBOARDED: "Onboarded",
  REVOKED: "Revoked"
};
