import React from "react";
import { FaLink } from "react-icons/fa";
import BaseCard from "../../components/BaseCard";
import Button from "react-bootstrap/Button";
import IconCard from "../../components/IconCard";
import { APP_VARS } from "../../services/appVars";
import { Row, Col } from "react-bootstrap";

export function VideoTab({ title, text, active, count }) {
  return (
    <div className={active ? `active-indicator` : ``}>
      <Row className={`mx-0 p-4 video-tab ${active ? `active-tab` : ``}`}>
        <Col sm={1}>
          <FaLink size={26} className="help-fa-link" />
        </Col>
        <Col sm={11}>
          <div className="mb-3">
            <span className="font-weight-bold">{title}</span>
            <span>{` (${count} video${count > 1 ? "s" : ""})`}</span>
          </div>
          <span>{text}</span>
        </Col>
      </Row>
    </div>
  );
}

export function HelpLink({ url, text }) {
  return (
    <a
      className="d-flex align-items-center py-4 color-accent pl-2"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaLink style={{ fontSize: 26 }} className="help-fa-link" />
      <div className="font-weight-bold text-primary pl-2">{text}</div>
    </a>
  );
}

export function BottomCap({ fullWidth }) {
  return (
    <div
      className=""
      style={{
        height: 0,
        borderBottom: "1px solid #707070",
        marginLeft: fullWidth ? "0" : "3rem",
        width: fullWidth ? "100%" : "80%",
        opacity: "0.2"
      }}
    ></div>
  );
}

export function SelfServiceCard({
  mainText,
  subText,
  filePath,
  buttonText = "Download",
  disabled = false,
  image,
  href
}) {
  const width = "23.2rem";
  if (disabled) {
    return (
      <IconCard
        variant="disabled"
        style={{ width: width }}
        badgeText="Coming Soon"
        shadow
        className="py-5 px-2 mb-2 mr-4 "
        disabled={true}
        title={mainText}
        subtitle={subText}
        image={
          image && (
            <img src={APP_VARS.REACT_APP_ASSETS_URL + image} alt={mainText} />
          )
        }
      ></IconCard>
    );
  }
  const buttonProps = href ? { target: "_blank" } : { download: true };
  return (
    <BaseCard
      style={{ width: width }}
      className="SelfServiceCard sx-hover-shadow grow py-5 px-2 mb-2 mr-4 d-flex align-items-center justify-content-center"
    >
      <div className="text-center">
        {image && (
          <div className="sx-icon-card__image">
            <img src={APP_VARS.REACT_APP_ASSETS_URL + image} alt={mainText} />
          </div>
        )}
        <div className="font-weight-bold pb-2">{mainText}</div>
        <div className="font-small pb-3">{subText}</div>
        <Button
          as="a"
          className="download-button"
          href={filePath}
          {...buttonProps}
        >
          {buttonText}
        </Button>
      </div>
    </BaseCard>
  );
}
