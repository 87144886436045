import React from "react";
import Spinner from "../../components/Spinner";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BaseCard from "../../components/BaseCard";
import BasePage from "../../components/layouts/BasePage";
import SlimNavbar from "./SlimNavbar";

function AppLoader() {
  return (
    <div>
      <SlimNavbar />
      <BasePage className="p-5">
        <BaseCard>
          <Container fluid className="p-5">
            <Row>
              <Col sm={12} className="text-center">
                <Spinner>Loading...</Spinner>
              </Col>
            </Row>
          </Container>
        </BaseCard>
      </BasePage>
    </div>
  );
}

export default AppLoader;
