import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import BasePage from "../layouts/BasePage";
import BaseCard from "../BaseCard";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

const MENUOPTIONS = [
  "Events",
  "Testimonials",
  "User Management",
  "Group Management",
  "Roles Management",
  "Category Management",
  "Schedules Management",
  "Alerts",
  "Data University",
  "Data Catalog",
  "Configuration"
];
function ProtectedRoute({
  isAdmin,
  children,
  permissionsUpdated,
  ...otherProps
}) {
  // const [isAdmin, setIsAdmin] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   isAdminRole()
  //     .then(res => {
  //       if (res === true) {
  //         setIsAdmin(true);
  //       }
  //       setIsLoading(false);
  //     })
  //     .catch(() => setIsLoading(false));
  // }, []);

  return (
    <>
      {false ? (
        <BasePage className="p-5">
          <BaseCard>
            <Container fluid className="p-5">
              <Row>
                <Col sm={12} className="text-center">
                  LOADING
                </Col>
              </Row>
            </Container>
          </BaseCard>
        </BasePage>
      ) : (
        <>
          {isAdmin ||
          !!permissionsUpdated.find(el => MENUOPTIONS.includes(el.name)) ? (
            <Route {...otherProps}>{children}</Route>
          ) : (
            <BasePage className="p-5">
              <BaseCard>
                <Container fluid className="p-5">
                  <Row>
                    <Col sm={12} className="text-center">
                      NOT AUTHORIZED!
                    </Col>
                  </Row>
                </Container>
              </BaseCard>
            </BasePage>
          )}
        </>
      )}
    </>
  );
}

export default ProtectedRoute;
