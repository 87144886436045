import React, { useCallback, useContext, useEffect } from "react";
import { useLocalStorage } from "beautiful-react-hooks";
import { fetchRecents, submitRecents } from "../services/dataService";

const RecentsContext = React.createContext(null);

const RecentsProvider = props => {
  //   const [loadingRecents, setLoadingRecents] = useState(false);
  const [recents, setRecents] = useLocalStorage("RECENTS", []);

  const loadRecents = useCallback(
    () =>
      fetchRecents().then(res => {
        setRecents(res.data);
      }),
    [setRecents]
  );

  const addRecentDashboard = useCallback(
    db => {
      // Max size 10
      setRecents(recents => {
        const foundDashboard = recents.find(
          item => "" + item.dbId === "" + db.dbId
        );
        if (foundDashboard) {
          // if already exists, move to front of array
          return [
            foundDashboard,
            ...recents.filter(i => "" + i.dbId !== "" + db.dbId)
          ].slice(0, 10);
        }
        return [{ ...db, recentType: "DB" }, ...recents].slice(0, 10);
      });
      submitRecents({ itemID: db.dbId, itemType: "DB" }).then(() =>
        loadRecents()
      );
    },
    [loadRecents, setRecents]
  );

  // Used when a "recent" is added on a different tab
  const listenForChanges = useCallback(() => {
    const storedRecents = localStorage.getItem("RECENTS");
    if (storedRecents && storedRecents !== JSON.stringify(recents)) {
      setRecents(JSON.parse(storedRecents));
    }
  }, [recents, setRecents]);

  useEffect(() => {
    // Listen across tabs for a change to local storage
    window.addEventListener("storage", listenForChanges);

    // Clean up fuction to make sure we don't get multiple instances of the same
    // event listener each time the component re-render
    return () => window.removeEventListener("storage", listenForChanges);
  }, [listenForChanges]);

  return (
    <RecentsContext.Provider
      value={{
        recents,
        loadRecents,
        addRecentDashboard
      }}
    >
      {props.children}
    </RecentsContext.Provider>
  );
};

export const useRecentsContext = () => useContext(RecentsContext);

export default RecentsProvider;
