import { createContextualCan } from "@casl/react";
import React, { createContext, useState } from "react";

import { Ability } from "@casl/ability";
export const AbilityContext = createContext();
export const PermissionsContext = createContext();
export const Can = createContextualCan(AbilityContext.Consumer);

const ability = new Ability();
export const AbilityProvider = props => {
  const [permissions, setPermissions] = useState(props.permissionsUpdated);
  const isAdmin = props.isAdmin;
  return (
    <PermissionsContext.Provider
      value={{ permissionsCtxt: [permissions, setPermissions, isAdmin] }}
    >
      <AbilityContext.Provider value={ability}>
        {props.children}
      </AbilityContext.Provider>
    </PermissionsContext.Provider>
  );
};
