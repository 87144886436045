import { AbilityBuilder } from "@casl/ability";
const MENUOPTIONS = [
  "Events",
  "Testimonials",
  "User Management",
  "Group Management",
  "Roles Management",
  "Category Management",
  "Schedules Management",
  "Alerts",
  "Data University",
  "Data Catalog",
  "Configuration",
  "Content Management",
  "Brand Management"
];
export default function defineRulesFor(permissions = [], isAdmin = false) {
  const { can, rules } = new AbilityBuilder();
  if (isAdmin) {
    MENUOPTIONS.forEach(el => {
      can("Write", el);
    });
  } else {
    permissions.forEach(el => {
      can(
        el.permissions.find(perm => perm.permission === "Write")
          ? "Write"
          : "Read",
        el.name
      );
    });
  }

  return rules;
}

// export default function defineRulesFor(role) {
//   const { can, rules } = new AbilityBuilder();

//   switch (role) {
//     case "admin":
//       can("manage", "all");
//       break;
//     case "alertsrole":
//       can("read", "alerts");
//       break;
//     case "eventsrole":
//       can("read", "events");
//       break;
//     case "viewevents":
//       can("view", "events");
//       break;
//     case "dashboard":
//       can("read", "alerts");
//       can("read", "dashboard1");
//       can("read", "dashboard2");
//       can("read", "dashboard3");
//       break;
//     default:
//       can("read", "all");
//   }

//   return rules;
// }
