import React, { useState } from "react";
import PropTypes from "prop-types";
import Accordion from "react-bootstrap/Accordion";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { Card } from "react-bootstrap";

import "./QuestionAnswerCard.scss";

function QuestionAnswerCard({
  question,
  answer,
  className = "",
  ...otherProps
}) {
  const [expanded, setExpanded] = useState(false);
  return (
    <Accordion>
      <Card className="border-top-0 border-left-0 border-right-0">
        <Card.Header className="p-0 border-bottom-0">
          <Accordion.Toggle
            eventKey="0"
            onClick={() => setExpanded(!expanded)}
            as="div"
          >
            <div className="d-flex align-items-center justify-content-between pointer px-3 py-3">
              <div className="sx-question-answer__item-title">
                <span className=" sx-question-answer__item-abbr">Q.</span>
                {question}
              </div>
              {answer && (
                <div className="sx-question-answer__toggle-button">
                  {expanded ? <AiOutlineMinus /> : <AiOutlinePlus />}
                </div>
              )}
            </div>
          </Accordion.Toggle>
        </Card.Header>
        {answer && (
          <Accordion.Collapse eventKey="0">
            <Card.Body className="py-0">
              <div
                className={`sx-question-answer__item-body py-3 ${
                  expanded ? "active-with-body" : "active-link"
                }`}
              >
                <div dangerouslySetInnerHTML={{ __html: answer }} />
              </div>
            </Card.Body>
          </Accordion.Collapse>
        )}
      </Card>
    </Accordion>
  );
}

QuestionAnswerCard.propTypes = {
  question: PropTypes.any,
  answer: PropTypes.any
};

export default QuestionAnswerCard;
