import { useMediaQuery } from "beautiful-react-hooks";

export const useScreenSize = () => {
  const isSmall = useMediaQuery("(min-width: 576px) and (max-width: 767px)");
  const isMedium = useMediaQuery("(min-width: 768px) and (max-width: 991px)");
  const isLarge = useMediaQuery("(min-width: 992px) and (max-width: 1199px)");
  const isExtraLarge = useMediaQuery(
    "(min-width: 1200px) and (max-width: 1600px)"
  );
  const isXXL = useMediaQuery("(min-width: 1601px)");

  return [isSmall, isMedium, isLarge, isExtraLarge, isXXL];
};
