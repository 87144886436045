import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { DashboardsContext } from "./DashboardsContext";
import "./index.scss";

function LSiderbarLayout() {
  const { categoryContext, dashboardsContext } = useContext(DashboardsContext);
  const [, , activeCategory, , ,] = categoryContext;
  const [
    ,
    ,
    activeDashboard,
    setActiveDashboard,
    filteredDashboards
  ] = dashboardsContext;

  return (
    <div className="p-4">
      {" "}
      <Row className="mx-1">
        <Col lg="3" style={{ paddingRight: "60px" }}>
          <p className="class-yr">{activeCategory?.name}</p>
          {filteredDashboards?.map(el => {
            return (
              <>
                <hr />
                <Button
                  variant="link"
                  className={`shadow-none m-n2 ${activeDashboard?.name ===
                    el.name && " text-muted"}`}
                  onClick={() => setActiveDashboard(el)}
                >
                  {el.name}
                </Button>
              </>
            );
          })}
        </Col>
        <Col lg="9">
          <h3>{activeCategory?.header}</h3>
          <p style={{ color: "grey" }}>
            {activeDashboard?.name},&nbsp;{activeDashboard?.subdomain_name}
          </p>
          <hr />
          <iframe
            src={activeDashboard?.url}
            width="100%"
            height="500px"
            frameBorder="0"
          />
        </Col>
      </Row>
    </div>
  );
}

export default LSiderbarLayout;
