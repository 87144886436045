import React from "react";
import classnames from "classnames";

function Disclaimer({ className = "" }) {
  return (
    <div
      className={classnames(className, "text-secondary pl-2 pb-2 font-small")}
    >
      <span>
        *The categories and hierarchical classifications are being continuously
        refined and broadened through Data Governance program. To know more,
        please contact
      </span>
      <a
        href="mailto:EDM-DataGovernance@siriusxm.com"
        className="font-small ml-1"
      >
        Enterprise Data Management Team
      </a>
    </div>
  );
}

export default Disclaimer;
