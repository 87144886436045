import React from "react";
import "./IconCard.scss";

function IconCard({
  className = "",
  children,
  title,
  secondaryTitle,
  subtitle,
  shadow,
  image,
  disabled,
  badgeText,
  variant,
  ...otherProps
}) {
  const variantClass = variant ? `sx-${variant}` : "sx-primary";

  return (
    <div
      className={`sx-icon-card position-relative d-flex justify-content-center align-items-center ${variantClass} ${className}`}
      {...otherProps}
    >
      {(title === "SXM Analytics Studio" || variant === "disabled") && (
        <div className="sx-icon-card__badge">{badgeText}</div>
      )}
      <div className="text-center">
        <div className="sx-icon-card__image">{image}</div>
        <div className="sx-icon-card__title">{title}</div>
        <div className="sx-icon-card__secondary-title">{secondaryTitle}</div>
        <div className="sx-icon-card__subtitle">{subtitle}</div>
      </div>
    </div>
  );
}

export default IconCard;
