import React from "react";
import classnames from "classnames";
import { S_NOW_LINK } from "../../services/appVars";

function Disclaimer({ className = "" }) {
  return (
    <div
      className={classnames(className, "text-secondary pl-2 pb-2 font-small")}
    >
      *In order to access/view the Insights Marketplace Dashboards, please
      submit a
      <a href={S_NOW_LINK} className="font-small mx-1">
        Service Now ticket
      </a>
      for approval.
    </div>
  );
}

export default Disclaimer;
