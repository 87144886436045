import React, { useContext, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import ViewAs from "../../components/ViewAs";
import { DashboardsContext } from "./DashboardsContext";
import "./index.scss";

function SearchBoxRow() {
  const { filterContext, layoutContext } = useContext(DashboardsContext);
  const [, , filterText, setFilterText] = filterContext;
  const [viewAs, setViewAs] = layoutContext;

  return (
    <Row className="pl-4 mb-2 text-search mr-3">
      <Col lg="4" className="ml-2 text-search-box">
        <InputGroup>
          <Form.Control
            className="text-box"
            type="text"
            placeholder=""
            onChange={e => {
              setFilterText(e.target.value);
            }}
            value={filterText}
          />

          {!filterText ? (
            <AiOutlineSearch className="sx-grid__search-icon text-box-icon" />
          ) : (
            <AiOutlineClose
              className="sx-grid__search-icon text-box-icon"
              onClick={() => {
                setFilterText("");
              }}
            />
          )}
        </InputGroup>
      </Col>

      <Col lg="1" style={{ maxWidth: "30px", marginRight: "35px" }}>
        <div className="d-flex">
          {" "}
          <ViewAs activeKey={viewAs} onClick={setViewAs} />
        </div>
      </Col>
    </Row>
  );
}

export default SearchBoxRow;
