import React, { useEffect, useState } from "react";
import BasePage from "../../components/layouts/BasePage";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BaseCard from "../../components/BaseCard";
import VideoPlayer from "../../components/VideoPlayer";

import { HelpLink, SelfServiceCard } from ".//SelfServiceComponents";
import { trackUserAction } from "../../services/dataService";
import "./JupyterNotebooks.scss";
import { APP_VARS, S_NOW_LINK } from "../../services/appVars";

import AccentButton from "../../components/AccentButton";
import getVideos from "./jupyter-hub-content";
import { AiFillQuestionCircle } from "react-icons/ai";
import JupyterServiceNowInstructions from "./JupyterServiceNowInstructions";

const logo = "/images/Notebooks Logo.png";
const jupyterUserGuide = "/pdfs/Jupyter Hub User Guide_v4.pdf";

const keyFeatures = [
  {
    title: "Team Collaboration",
    feature:
      "Share your Jupyter notebooks privately with project collaborators. All modifications are synchronized in real time, where you can see the cursors of others while they edit the document. You are also notified about the presence of watching collaborators."
  },
  {
    title: "Exploratory Data Analysis",
    feature:
      "Jupyter allows users to view the results of the code in-line without the dependency of other parts of the code."
  },
  {
    title: "Language Independent",
    feature:
      "Jupyter can also be processed by any several languages, and can be converted to any file formats such as Markdown, HTML, PDF, and others."
  },
  {
    title: "Data Visualization",
    feature:
      "Jupyter supports visualizations like graphics and charts and lets the users narrate visualizations, alongside share the code and data sets, enabling others for interactive changes."
  },
  {
    title: "Documenting Code Samples",
    feature:
      "Jupyter makes it easy for users to explain their codes line-by-line with feedback attached all along the way. Even better, with Jupyter, users can add interactivity along with explanations, while the code is fully functional."
  }
];

function JupyterNotebooks(props) {
  const [videoList] = useState(getVideos());
  const [show, setShow] = useState(false);

  const handleCloseModal = () => {
    setShow(false);
  };
  const handleOpenModal = () => {
    setShow(true);
  };

  useEffect(() => {
    trackUserAction({
      action: "View JupyterNotebooks",
      targetType: "tile",
      targetName: "JupyterNotebooks",
      targetPath: "/jupyter-notebooks",
      targetID: "JupyterNotebooks",
      status: "Success",
      errorDetails: ""
    });
  }, []);

  return (
    <BasePage className="JupyterNotebooksPages">
      <div className="p-3">
        <BaseCard>
          <div className="d-flex align-items-center py-2 px-3 gray-bottom-border-1 justify-content-between">
            <div>
              <span className="text-primary font-large">
                Jupyter Notebooks (Beta)
              </span>
            </div>
            <div>
              <div className="d-flex align-items-center">
                <span className="white-space-nowrap">Already a member?</span>
                <a
                  className="mx-2 white-space-nowrap pr-4 border-right pointer"
                  href="jupyterhub"
                >
                  <span>Sign In</span>
                </a>
                <AccentButton
                  onClick={() => window.open(S_NOW_LINK, "_blank")}
                  className="mx-4 sign-in-btn-disabled"
                  disabled
                >
                  SIGN UP
                </AccentButton>
                <div onClick={handleOpenModal} className="pointer mr-4">
                  <AiFillQuestionCircle size={32} color="#a7a9ab" />
                </div>
                <img
                  className="ml-auto py-1"
                  src={APP_VARS.REACT_APP_ASSETS_URL + logo}
                  alt="JupyterNotebooks"
                />
              </div>
              <span className="text-muted pb-2">
                Beta subscription is now closed. Notebooks are still available
                to existing users.
              </span>
            </div>
          </div>
          <Container fluid>
            <Row>
              <Col sm={12} lg={7} xl={7}>
                <div className="video-section px-3 pt-3">
                  <VideoPlayer playlistTitle="Videos" videoList={videoList} />
                </div>
              </Col>
              <Col sm={12} lg={5} xl={5} className="right-section">
                <section className="key-benefits">
                  <div className="text-primary font-large pb-4">
                    Key Benefits
                  </div>
                  {keyFeatures.map((k, index) => (
                    <div key={index}>
                      <div className="font-weight-bold text-primary">
                        {k.title}
                      </div>
                      <div className="pb-3">{k.feature}</div>
                    </div>
                  ))}
                </section>
                <section className="jupyterhub-resources">
                  <div className="resource-links">
                    <div className="text-primary font-large pb-4">
                      Jupyter Resources
                    </div>
                    <HelpLink
                      text="User Guide"
                      url={APP_VARS.REACT_APP_ASSETS_URL + jupyterUserGuide}
                    />
                    <HelpLink
                      text="Jupyter Documentation"
                      url="https://jupyter.org/documentation"
                    />
                    <HelpLink
                      text="Jupyter Blog"
                      url="https://blog.jupyter.org/"
                    />
                    <HelpLink
                      text="Jupyter Contribution Guide"
                      url="https://jupyter.readthedocs.io/en/latest/contributing/content-contributor.html"
                    />
                    <HelpLink
                      text="Jupyter Getting Started Guide"
                      url="https://jupyter.readthedocs.io/en/latest/content-quickstart.html"
                    />
                  </div>
                </section>
                <section className="resource-tiles">
                  <div className="text-primary font-large pb-4">Support</div>
                  <Row>
                    {/* <SelfServiceCard
                      mainText="Get Access"
                      buttonText="Click here"
                      subText="Request access via Service Now"
                      href={true}
                      filePath=S_NOW_LINK
                      image="/img/SelfService-Icons/get access.png"
                    /> */}
                    <SelfServiceCard
                      mainText="FAQ"
                      subText="Frequently asked questions related to access, issues etc."
                      disabled={true}
                      image="/images/SelfService-Icons/FAQ.png"
                    />
                  </Row>
                </section>
              </Col>
            </Row>
          </Container>
        </BaseCard>
        <JupyterServiceNowInstructions
          show={show}
          handleCloseModal={handleCloseModal}
        />
      </div>
    </BasePage>
  );
}

export default JupyterNotebooks;
