import React, { useContext } from "react";
import CategoriesRow from "./CategoriesRow";
import { DashboardsContext } from "./DashboardsContext";
import DashboardsGridView from "./DashboardsGridView";
import DashboardsListView from "./DashboardsListView";
import HeaderRow from "./HeaderRow";
import "./index.scss";
import SearchBoxRow from "./SearchBoxRow";
import Button from "react-bootstrap/Button";
import { trackUserAction } from "../../services/dataService";

function CardsLayout({ onTagClick, favorites, onFavToggle }) {
  const {
    domainsContext,
    layoutContext,
    categoryContext,
    levelsContext,
    subLevelContext,
    dashboardsContext
  } = useContext(DashboardsContext);
  const [, , activeDomain] = domainsContext;
  const [viewAs] = layoutContext;
  const [levels] = levelsContext;
  const [
    allSubLevels,
    setAllSubLevels,
    activeSubLevel,
    setActiveSubLevel,
    filteredSubLevels,
    setFilteredSubLevels
  ] = subLevelContext;
  const [, , , , filteredDashboards] = dashboardsContext;

  return (
    <>
      {/* Categories Row */}
      <CategoriesRow />
      {/* Header Row */}
      {activeDomain?.layout === "ZShape" && <HeaderRow />}
      {/* Search Box Row */}
      <div className="d-flex justify-content-between">
        <div
          className="ml-4 px-2 sublevel-cnt"
          style={{ maxWidth: "60%", minWidth: "20%" }}
        >
          {filteredSubLevels?.map(el => (
            <Button
              key={el.name}
              className={`${
                activeSubLevel?.id === el?.id
                  ? "sublevel-btn-active"
                  : "sublevel-btn"
              } `}
              onClick={event => {
                setActiveSubLevel(el);
                sessionStorage.setItem("activeSublevel", JSON.stringify(el));
                trackUserAction({
                  action: "search",
                  targetType: "executive dashboards",
                  targetName: el.name,
                  targetPath: `/executive-dashboards`,
                  targetID: el.id,
                  status: "Success",
                  errorDetails: ""
                });
              }}
            >
              {el.name}
            </Button>
          ))}
        </div>
        <div style={{ flex: 1 }}>
          {!!filteredDashboards.length && <SearchBoxRow />}
        </div>
      </div>
      {/* Dashboards List View  */}
      {viewAs === "list" && <DashboardsListView />}
      {/* Dashboards Grid View  */}
      {viewAs === "grid" && (
        <DashboardsGridView
          onTagClick={onTagClick}
          favorites={favorites}
          onFavToggle={onFavToggle}
        />
      )}
    </>
  );
}

export default CardsLayout;
