import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { SmartSearchProvider } from "./SmartSearchContext";
import SmartSearchShell from "./SmartSearchShell";

export default function SmartSearchWrapper({
  globalSearchText,
  activeFilters,
  setActiveFilters
}) {
  return (
    <SmartSearchProvider>
      <SmartSearchShell
        globalSearchText={globalSearchText}
        activeFilters={activeFilters}
        setActiveFilters={setActiveFilters}
      />
    </SmartSearchProvider>
  );
}
