import React from "react";
import Toast from "./Toast";
import { navbarHeight } from "../../variables.scss";
import { useAlertsContext } from "../../context/alerts";

function ToastContainer({ toasts = [] }) {
  const { removeAlert } = useAlertsContext();
  return (
    <div
      className="sx-toast-container"
      style={{
        position: "fixed",
        top: `calc(${navbarHeight} + 1rem)`,
        right: 10,
        zIndex: 5
      }}
    >
      {toasts.map(t => (
        <Toast key={t.id} {...t} onClose={() => removeAlert(t.id)} />
      ))}
    </div>
  );
}

export default ToastContainer;
