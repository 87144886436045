import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BasePage from "../../components/layouts/BasePage";
import { fetchLdaxList, fetchLdaxData } from "../../services/dataService";
import groupBy from "lodash/groupBy";
import { Line } from "react-chartjs-2";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import Spinner from "../../components/Spinner";
import "chartjs-adapter-date-fns";
import { APP_VARS } from "../../services/appVars";
import NeoGraph from "./NeoGraph";
import FormCheck from "react-bootstrap/FormCheck";
import Table from "react-bootstrap/Table";
import { isEmpty } from "lodash";
import { AiOutlineTable } from "react-icons/ai";
import {
  getPercentage,
  convertTo_M,
  convertTo_M_B_K
} from "../../services/utils";
import { AiOutlineUnorderedList } from "react-icons/ai";
import {
  IoIosGitNetwork,
  IoGitNetworkSharp,
  IoMdGitNetwork
} from "react-icons/io";
import { BiNetworkChart } from "react-icons/bi";

import "./index.scss";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale
);
ChartJS.defaults.font.family = "Poppins";
ChartJS.defaults.font.size = 16;

const COLOR_CONSTANTS = {
  LDAX: "#005E59",
  PANDORA: "#004dff",
  SIRIUSXM: "black",
  STITCHER: "#F6B500"
};

const GET_OVER_ALL_MATCH = (list = []) => {
  let NUM1 =
    +list.find(e => e?.hasOwnProperty("PANDORA"))?.PANDORA +
    +list.find(e => e?.hasOwnProperty("SIRIUSXM"))?.SIRIUSXM -
    +list.find(e => e?.hasOwnProperty("LDAX"))?.LDAX;
  let NUM2 =
    +list.find(e => e?.hasOwnProperty("PANDORA"))?.PANDORA +
    +list.find(e => e?.hasOwnProperty("SIRIUSXM"))?.SIRIUSXM;
  //return getPercentage(NUM1, NUM2);
  return 26.9;
};

const GET_PANDORA_MATCH = (list = []) => {
  let NUM1 =
    +list.find(e => e?.hasOwnProperty("LDAX"))?.LDAX -
    +list.find(e => e?.hasOwnProperty("PANDORA"))?.PANDORA;
  let NUM2 = +list.find(e => e?.hasOwnProperty("PANDORA"))?.PANDORA;
  //return getPercentage(NUM1, NUM2);
  return 18.9;
};

const GET_SIRIUSXM_MATCH = (list = []) => {
  let NUM1 =
    +list.find(e => e?.hasOwnProperty("LDAX"))?.LDAX -
    +list.find(e => e?.hasOwnProperty("SIRIUSXM"))?.SIRIUSXM;
  let NUM2 = +list.find(e => e?.hasOwnProperty("SIRIUSXM"))?.SIRIUSXM;
  //return getPercentage(NUM1, NUM2);
  return 43.6;
};

const LdaxDashboard = () => {
  const [ldaxList, setLdaxList] = useState([]);
  const [lastUpdated, setLastUpdated] = useState("");
  const [ldaxId, setLdaxId] = useState("");
  const [id, setId] = useState("5000226273098");
  const [userTypes, setUserTypes] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [singleData, setSingleData] = useState(null);
  const [isListView, setIsListView] = useState(true);
  const [isCheckedPid, setIsCheckedPid] = useState(false);
  const [isCheckedLid, setIsCheckedLid] = useState(true);

  useEffect(() => {
    fetchLdaxList()
      .then(res => {
        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ];
        const date = new Date(res.data?.lastUpdateDate);
        setLastUpdated(
          `${
            monthNames[date.getMonth()]
          } ${date.getDate()}, ${date.getFullYear()}`
        );
        const userTypeData = groupBy(res.data?.results, "type");
        const totalCount = [];
        for (let e in userTypeData) {
          const value = userTypeData[e]
            ?.reduce((pre, curr) => pre + curr.count, 0)
            .toString();
          totalCount.push({ [e]: value });
        }

        const legends = Object.keys(userTypeData);
        // if (!legends.find(l => l.toLowerCase() === "stichter")) {
        //   legends.push("STITCHER");
        //   totalCount.push({ STITCHER: 0 });
        // }
        setLdaxList(totalCount);
        setUserTypes(legends);

        const chartData = [];
        for (let ut in userTypeData) {
          const userCount = [];
          for (let ud of userTypeData[ut]) {
            // const tempDay =
            //   ud.createdDate.dayOfMonth.toString().length > 1
            //     ? ud.createdDate.dayOfMonth
            //     : `0${ud.createdDate.dayOfMonth}`;

            // const tempMonth =
            //   ud.createdDate.monthValue.toString().length > 1
            //     ? ud.createdDate.monthValue
            //     : `0${ud.createdDate.monthValue}`;

            userCount.push({
              x: `${ud.createdDate}`,
              y: ud.count,
              s: `${ud.createdDate?.replaceAll("-", "")}`
            });
          }
          userCount.sort((a, b) => a.s - b.s);
          chartData.push(userCount);
        }

        // const sortedDates = chartData.flat().sort((a, b) => a.s - b.s);

        // for (let chrt of chartData) {
        //   if (!chrt.find(el => el.s === sortedDates[0]?.s)) {
        //     chrt.unshift({ x: sortedDates[0].x, y: 0 });
        //   }
        // }

        // const formattedDates = [
        //   ...new Set(
        //     res.data?.results.map(el => {
        //       console.log(
        //         `${el.createdDate.year}-${el.createdDate.monthValue}-${el.createdDate.dayOfMonth}`,
        //         new Date(
        //           `${el.createdDate.monthValue}/${el.createdDate.dayOfMonth}/${el.createdDate.year}`
        //         )
        //       );
        //       const z =
        //         el.createdDate.dayOfMonth.toString().length > 1
        //           ? el.createdDate.dayOfMonth
        //           : `0${el.createdDate.dayOfMonth}`;
        //       return `${el.createdDate.year}-0${el.createdDate.monthValue}-${z}`;
        //     })
        //   )
        // ];
        let formattedData = {
          // labels: formattedDates,
          datasets: [
            ...legends?.map(el => {
              return {
                data: [],
                borderColor: COLOR_CONSTANTS[el],
                backgroundColor: COLOR_CONSTANTS[el],
                tension: 0.1,
                pointRadius: 3
              };
            })
          ]
        };
        formattedData = {
          ...formattedData,
          datasets: formattedData.datasets?.map((el, i) => ({
            ...el,
            data: chartData[i] ? chartData[i] : [],
            label: legends[i] ? legends[i] : []
          }))
        };
        setLineData(formattedData);
        setIsLoading(false);
      })
      .catch(err => setIsLoading(false));
    handleGetData();
  }, []);

  const handleGetData = () => {
    setLoading(true);
    fetchLdaxData(ldaxId)
      .then(res => {
        const data = {
          "LDAX ID": res.data["LDAX ID"],
          "Account ID": res.data["Account ID"],
          Email: res.data.Email,
          Phone: res.data.Phone,
          "Address 1": res.data["Address 1"],
          "Address 2": res.data["Address 2"],
          City: res.data.City,
          State: res.data.State,
          Country: res.data.Country,
          Zip: res.data.Zip,
          Channel: res.data.Channel,
          Content: res.data.Content,
          ...res.data
        };
        setSingleData(data);
        setLoading(false);
      })
      .catch(err => setLoading(false));
  };

  return (
    <BasePage className="p-5">
      {!isLoading ? (
        <Container fluid className="ldax-container">
          <Row className="p-4">
            <Col md={5} lg={5} xl={5}>
              <div className="logo-container">
                <img
                  src={APP_VARS.REACT_APP_ASSETS_URL + "/images/ldax-icon.png"}
                  alt="ldax_logo"
                  width="80px"
                />
                <h3 className="heading">LDAX User Statistics</h3>
              </div>
              <Row style={{ marginLeft: "0" }}>
                {userTypes.map((el, i) => (
                  <Col
                    key={el}
                    md={6}
                    lg={6}
                    xl={6}
                    className="ldax-user"
                    style={{
                      backgroundColor: COLOR_CONSTANTS[el]
                    }}
                  >
                    <div className="user-type">{el} </div>
                    <span className="count-class">
                      {convertTo_M(ldaxList[i][el])}
                    </span>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col md={7} lg={7} xl={7} className="chart-container">
              <div className="last-update">Last updated: {lastUpdated}</div>
              <div>
                {lineData.datasets?.length > 0 &&
                  lineData.datasets[0].data.length > 0 && (
                    <Line
                      options={{
                        scales: {
                          x: {
                            type: "time",
                            time: {
                              unit: "day"
                            },
                            ticks: {
                              display: true,
                              maxTicksLimit: 10
                            },
                            grid: {
                              display: false
                            }
                          },
                          y: {
                            min: -5,
                            afterTickToLabelConversion: scaleInstance => {
                              scaleInstance.ticks.shift();
                              scaleInstance.ticks.pop();
                            },
                            ticks: {
                              display: true,
                              maxTicksLimit: 10,
                              beginAtZero: false,
                              callback: function(value) {
                                return convertTo_M_B_K(value);
                              }
                            },
                            grid: {
                              display: true
                            }
                          }
                        },
                        plugins: {
                          tooltip: {
                            usePointStyle: true
                          },
                          legend: {
                            labels: { boxHeight: 10 }
                          }
                        }
                      }}
                      data={lineData}
                    />
                  )}
              </div>
            </Col>
          </Row>
          <Row style={{ marginLeft: "0" }}>
            {
              <Col md={4} lg={4} xl={4} className="match-box">
                <div className="heading1"> Match Rate </div>
                <div className="heading2">Combined</div>
                <span className="percent-class">
                  {GET_OVER_ALL_MATCH(ldaxList)}%
                </span>
              </Col>
            }
            <Col md={4} lg={4} xl={4} className="match-box py-4">
              <div className="heading1 pb-2"> Match Rate </div>
              <div className="heading2">Pandora</div>
              <span className="percent-class">
                {GET_PANDORA_MATCH(ldaxList)}%
              </span>
            </Col>
            <Col md={4} lg={4} xl={4} className="match-box py-4">
              <div className="heading1 pb-2">Match Rate </div>
              <div className="heading2">Siriusxm</div>
              <span className="percent-class">
                {GET_SIRIUSXM_MATCH(ldaxList)}%
              </span>
            </Col>
          </Row>
          <div
            className="mx-4"
            style={{ borderBottom: "3px solid #eee8f3" }}
          ></div>
          <Row className="p-4">
            <Col md={4} lg={4} xl={4}>
              <InputGroup>
                <Form.Control
                  className="gup-text-box mb-2"
                  type="text"
                  placeholder="Search"
                  onChange={e => setLdaxId(e.target.value)}
                  onKeyUp={e => {
                    if (e.keyCode === 13) handleGetData();
                  }}
                  style={{ padding: "20px 10px 20px 10px", fontSize: "19px" }}
                  value={ldaxId}
                />
                {!ldaxId.length ? (
                  <AiOutlineSearch className="sx-grid__search-icon gup-icon" />
                ) : (
                  <>
                    <AiOutlineClose
                      className="sx-grid__search-icon gup-icon gup-icon-2"
                      onClick={() => {
                        setLdaxId("");
                      }}
                    />
                    <AiOutlineSearch
                      className="sx-grid__search-icon gup-icon"
                      onClick={() => {
                        handleGetData(ldaxId);
                        setId(ldaxId);
                      }}
                    />
                  </>
                )}
              </InputGroup>
            </Col>
            <Col md={4} lg={4} xl={4}>
              {/* <Form as={Row} className="mt-2">
                <Col md={3} lg={3} xl={3}>
                  <Form.Check
                    type="checkbox"
                    id={`default`}
                    label={`LDAX Id`}
                    onChange={() => {
                      setIsCheckedLid(flag => !flag);
                      if (isCheckedPid) setIsCheckedPid(false);
                    }}
                    // name={name}
                    checked={isCheckedLid}
                    // id={`${name}-checkbox`}
                    // label={label}
                    // disabled={disabled}
                  />
                </Col>
                <Col md={3} lg={3} xl={3}>
                  {" "}
                  <FormCheck
                    type="checkbox"
                    id={`default-1`}
                    label={`Party Id`}
                    checked={isCheckedPid}
                    onChange={() => {
                      setIsCheckedPid(flag => !flag);
                      if (isCheckedLid) setIsCheckedLid(false);
                    }}
                  />
                </Col>
              </Form> */}
            </Col>
            <Col md={1} lg={1} xl={1} className="offset-md-3">
              {!isListView ? (
                <AiOutlineTable
                  title="List View"
                  onClick={() => setIsListView(flag => !flag)}
                  className="pointer pl-2 grid-list-icon"
                  style={{
                    fontSize: "4.5rem"
                  }}
                />
              ) : (
                // <img
                //   src={`${APP_VARS.REACT_APP_ASSETS_URL}/images/Icons/network.svg`}
                //   alt="hello"
                //   width="30px"
                // />
                <BiNetworkChart
                  title="List View"
                  onClick={() => setIsListView(flag => !flag)}
                  className="pointer pl-2 grid-list-icon"
                  style={{
                    fontSize: "4.5rem"
                  }}
                />
              )}
            </Col>
          </Row>

          {!isListView ? (
            <NeoGraph id={id} />
          ) : (
            <div className="p-4" style={{ overflowX: "auto" }}>
              {!loading && !isEmpty(singleData) && singleData["LDAX ID"] ? (
                <Table>
                  <tbody>
                    <tr className="primary-tr ldax-header">
                      {Object.keys(singleData).map(el => (
                        <td key={el}>{el}</td>
                      ))}
                    </tr>
                    <tr className="primary-tr ldax-row">
                      {Object.values(singleData).map(el => (
                        <td key={el}>{!isEmpty(el) ? el : "NA"}</td>
                      ))}
                    </tr>
                  </tbody>
                </Table>
              ) : (
                !!ldaxId.length && loading && <Spinner></Spinner>
              )}

              {!loading && !isEmpty(singleData) && !singleData["LDAX ID"] && (
                <div className="text-center mt-3 text-muted">
                  No Records To Display
                </div>
              )}
            </div>
          )}
        </Container>
      ) : (
        <Spinner>Fetching LDAX Data</Spinner>
      )}
    </BasePage>
  );
};

export default LdaxDashboard;
