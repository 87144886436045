import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { AiFillInfoCircle } from "react-icons/ai";
import { FaLock } from "react-icons/fa";
import { MdFavorite } from "react-icons/md";
import { APP_VARS } from "../../services/appVars";
import AccentButton from "../AccentButton";
import BaseCard from "../BaseCard";
import "./DashboardCard.scss";

function DashboardCard({
  imgUrl,
  title,
  footerArea,
  className = "",
  popoverText = "",
  popoverTitle = "",
  onClick = () => {},
  tags = [],
  onTagClick = () => {},
  isFav,
  onFavToggle = () => {},
  authorized = true,
  showFav = true,
  showTitle = true,
  ...otherProps
}) {
  return (
    <BaseCard
      className={`DashboardCard p-2 ${
        authorized ? `pointer` : `not-authorized`
      } ${className}`}
      onClick={e => {
        if (!authorized) {
          return;
        }
        if (
          (e.target &&
            e.target.className &&
            (e.target.className.indexOf("DashboardCard__tag-container") > -1 ||
              e.target.className.indexOf("DashboardCard__tag-icon") > -1)) ||
          e.target.className.indexOf("badge") > -1
        ) {
          return;
        }
        onClick();
      }}
      {...otherProps}
    >
      <div className="d-flex justify-content-between align-items-center">
        {(popoverText || popoverTitle) && (
          <OverlayTrigger
            placement="auto"
            trigger="click"
            rootClose
            overlay={
              <Popover>
                <Popover.Content>
                  <div className="font-medium font-weight-bold color-primary pt-3 px-3">
                    {popoverTitle}
                  </div>
                  <div className="p-3">{popoverText}</div>
                </Popover.Content>
              </Popover>
            }
          >
            {/* e.stopPropagation() is needed to prevent double click events when clicking on the i icon */}
            <AiFillInfoCircle
              onClick={e => e.stopPropagation()}
              className="banner-icon font-medium pointer"
            />
          </OverlayTrigger>
        )}
        <div className="d-flex justify-content-between align-items-center">
          {authorized && showFav && (
            <MdFavorite
              onClick={e => {
                e.stopPropagation();
                onFavToggle(!isFav);
              }}
              className={`DashboardCard__${
                isFav ? `fav-icon` : `grey-icon`
              } font-medium mr-2`}
            />
          )}
          {tags.length > 0 && (
            <div className="DashboardCard__tags pr-1">
              <OverlayTrigger
                placement="auto"
                trigger="click"
                rootClose
                overlay={
                  <Popover>
                    <Popover.Content>
                      <div className="px-3 py-2">
                        {tags.map(t => (
                          <AccentButton
                            key={t}
                            onClick={e => {
                              e.stopPropagation();
                              onTagClick(t);
                            }}
                            className="mr-2 mb-2"
                          >
                            {t}
                          </AccentButton>
                        ))}
                      </div>
                    </Popover.Content>
                  </Popover>
                }
              >
                <div className="position-relative DashboardCard__tag-container pointer">
                  <div className="DashboardCard__badge">{tags.length}</div>
                  <img
                    className="DashboardCard__tag-icon"
                    src={APP_VARS.REACT_APP_ASSETS_URL + "/images/tag-icon.png"}
                    alt="Tag"
                  />
                </div>
              </OverlayTrigger>
            </div>
          )}
        </div>
      </div>
      <div className="DashboardCard__image-container p-3">
        {!authorized && (
          <div className="sx-dashboard-category__auth-modal-backdrop p-3">
            <div className="sx-dashboard-category__auth-modal-content">
              <FaLock size={36} color="#12506f" />
            </div>
          </div>
        )}
        <img src={imgUrl} alt={title} />
      </div>
      {showTitle && (
        <div className="DashboardCard__title-section font-xsmall text-primary py-4 text-align-center">
          {title}
        </div>
      )}
    </BaseCard>
  );
}

export default DashboardCard;
