import React, { useEffect, useState } from "react";
import BasePage from "../../components/layouts/BasePage";
import { AiOutlinePlayCircle } from "react-icons/ai";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactPlayer from "react-player";

import "./Landing.scss";
import { APP_VARS } from "../../services/appVars";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { trackUserAction } from "../../services/dataService";
import FooterSupportCard from "./FooterSupportCard";

function Landing() {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    trackUserAction({
      action: "View",
      targetType: "Page",
      targetName: "Learn-More",
      targetPath: "",
      targetID: "/about",
      status: "Success",
      errorDetails: ""
    });
  }, []);

  return (
    <BasePage className="Landing">
      <section className="section-banner">
        <img
          src={
            APP_VARS.REACT_APP_ASSETS_URL +
            "/images/New-Landing/Banner Image.png"
          }
          alt="cover"
        />
        <div className="banner-text">
          <div className="mb-3">SiriusXM Insights Marketplace</div>
          <div className="header-text">Analyze. Discover.</div>
          <div className="header-text">Learn.</div>
          <div className="subtext">
            Uniting Consumers and Producers of Data and Analytics
          </div>
          <Button
            className="show-btn d-none align-items-center"
            onClick={() => {
              trackUserAction({
                action: "View",
                targetType: "Video",
                targetName: "See it in Action",
                targetPath: "",
                targetID: "/about",
                status: "Success",
                errorDetails: ""
              });
              handleShow();
            }}
          >
            <span className="mx-2">SEE IT IN ACTION</span>
            <AiOutlinePlayCircle size={45} />
          </Button>
          <Modal
            show={show}
            onHide={handleClose}
            centered
            className="modal-w-75"
          >
            <Modal.Header closeButton>
              <Modal.Title>How it works?</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=HW29067qVWk"
                playing
              />
            </Modal.Body>
          </Modal>
        </div>
      </section>
      <section className="section-features ">
        <Container fluid className="px-5 huge-container">
          <Row className="px-5 py-1">
            <Col sm={6} className="p-5">
              <img
                className="img-fluid"
                src={
                  APP_VARS.REACT_APP_ASSETS_URL +
                  "/images/New-Landing/Dashboard Catalog.png"
                }
                alt="images left 1"
              />
            </Col>
            <Col
              sm={6}
              className="p-5 d-flex flex-column justify-content-center"
            >
              <h1 className="font-weight-bold">Dashboard Catalog</h1>
              <h3 className="text-primary mb-4">
                Analytics to Guide Decision Making
              </h3>
              <p className="py-2">
                With Dashboard Catalog, you can quickly access centralized
                dashboards to investigate trends in your data, predict outcomes,
                and discover insights to help produce positive business
                outcomes.
              </p>
              <p className="py-2">
                Our extensive library of curated and user-driven content
                provides Key Performance Indicators (KPIs) and data rich
                visualizations from multiple sources to help you monitor and
                analyze company performance.
              </p>
              <Button
                className="explore-btn"
                onClick={() => {
                  trackUserAction({
                    action: "View",
                    targetType: "Page",
                    targetName: "Dashboard Catalog",
                    targetPath: "/dashboardcatalog/domains",
                    targetID: "/dashboardcatalog/domains",
                    status: "Success",
                    errorDetails: ""
                  });
                  history.push("/dashboardcatalog/domains");
                }}
              >
                EXPLORE
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-features">
        <Container fluid className="px-5 huge-container">
          <Row className="px-5 py-1 flex-row-reverse">
            <Col sm={6} className="p-5">
              <img
                className="img-fluid"
                src={
                  APP_VARS.REACT_APP_ASSETS_URL +
                  "/images/New-Landing/Data Catalog.png"
                }
                alt="images left 1"
              />
            </Col>
            <Col
              sm={6}
              className="p-5 d-flex flex-column justify-content-center"
            >
              <h1 className="font-weight-bold">Data Catalog</h1>
              <h3 className="text-primary mb-4">
                One Stop Shop for Data Assets
              </h3>

              <p className="py-2">
                The Data Catalog is the single source of trusted data for
                SiriusXM where you can search, access, verify and explore
                enterprise data across various sources.
              </p>
              <p className="py-2">
                Data Catalog, powered by Talend, automatically crawls, profiles,
                organizes, links, and enriches your metadata.
              </p>
              <p className="py-2 font-weight-bold m-0">Features include:</p>
              <ul className="px-0">
                <li>
                  Faceted search, data sampling, semantic discovery,
                  categorizing, and auto-profiling
                </li>
                <li>
                  Social curation with data tagging, comments, review,
                  promotion, and certification
                </li>
                <li>Data relationship discovery and certification</li>
              </ul>
              <Button
                className="explore-btn"
                onClick={() => {
                  trackUserAction({
                    action: "View",
                    targetType: "Page",
                    targetName: "Data Catalog",
                    targetPath: "/datacatalog/domains",
                    targetID: "/datacatalog/domains",
                    status: "Success",
                    errorDetails: ""
                  });
                  history.push("/datacatalog/domains");
                }}
              >
                EXPLORE
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-features">
        <Container fluid className="px-5 huge-container">
          <Row className="px-5 py-1">
            <Col sm={6} className="p-5">
              <img
                className="img-fluid"
                src={
                  APP_VARS.REACT_APP_ASSETS_URL +
                  "/images/New-Landing/Data University.png"
                }
                alt="images left 1"
              />
            </Col>
            <Col
              sm={6}
              className="p-5 d-flex flex-column justify-content-center"
            >
              <h1 className="font-weight-bold">Data University</h1>
              <h3 className="text-primary mb-4">Learn at Your Own Pace</h3>
              <p className="py-2">
                Learn how to use self-service enterprise analytics tools such as
                Tableau, DbVisualizer and JupyterHub to take the power of data
                analytics into your own hands.
              </p>
              <p className="py-2">
                With our training resources and videos, you’ll learn valuable
                skills to begin diving into your data to uncover key insights
                and find answers to the hard questions
              </p>
              <Button
                className="explore-btn"
                onClick={() => history.push("/data-university/home")}
              >
                EXPLORE
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-footer">
        <Container fluid className="px-5 ">
          <Row className="p-5">
            <Col sm={3}>
              <div className="py-4 px-2">
                <h1 className="font-weight-bold">
                  Need <br />
                  More Help?
                </h1>
              </div>
            </Col>
            <Col sm={3}>
              <div
                className="p-4 footer-cards pointer"
                onClick={() => history.push("/faqs")}
              >
                <h1 className="font-weight-bold">FAQs</h1>
                <p>
                  Access frequently asked questions about Insights Marketplace
                </p>
              </div>
            </Col>
            <Col sm={6}>
              <FooterSupportCard />
            </Col>
          </Row>
        </Container>
      </section>
    </BasePage>
  );
}

export default Landing;
