import React, { useCallback, useContext, useEffect } from "react";
import { useLocalStorage } from "beautiful-react-hooks";
import {
  submitFavorites,
  fetchFavorites,
  deletePreference
} from "../services/dataService";

const UserPreferencesContext = React.createContext(null);

const UserPreferencesProvider = props => {
  //   const [loadingFavorites, setLoadingFavorites] = useState(false);
  const [favorites, setFavorites] = useLocalStorage("FAVORITES", []);

  const loadPreferences = useCallback(
    () =>
      fetchFavorites().then(res => {
        setFavorites(res.data);
      }),
    [setFavorites]
  );

  const addFavorites = useCallback(
    db => {
      setFavorites(favorites => {
        const foundDashboard = favorites.find(
          item => "" + item.dbId === "" + db.dbId
        );
        if (!foundDashboard) {
          return [{ ...db, favType: "DB" }, ...favorites];
        }
        return [...favorites];
      });
      submitFavorites({ itemID: db.dbId, itemType: "DB" }).then(() =>
        loadPreferences()
      );
    },
    [loadPreferences, setFavorites]
  );

  const removeFavorites = useCallback(
    db => {
      let prefId = null;
      setFavorites(favorites => {
        const foundDashboard = favorites.find(
          item => "" + item.dbId === "" + db.dbId
        );
        if (foundDashboard) {
          prefId = foundDashboard.Pref_ID;
          return [...favorites.filter(i => "" + i.dbId !== "" + db.dbId)];
        }
        return [...favorites];
      });
      if (prefId) {
        deletePreference(prefId).then(() => loadPreferences());
      }
    },
    [loadPreferences, setFavorites]
  );

  // Used when a "favorite" is added on a different tab
  const listenForChanges = useCallback(() => {
    const storedFavorites = localStorage.getItem("FAVORITES");
    if (storedFavorites && storedFavorites !== JSON.stringify(favorites)) {
      setFavorites(JSON.parse(storedFavorites));
    }
  }, [favorites, setFavorites]);

  useEffect(() => {
    // Listen across tabs for a change to local storage
    window.addEventListener("storage", listenForChanges);

    // Clean up fuction to make sure we don't get multiple instances of the same
    // event listener each time the component re-render
    return () => window.removeEventListener("storage", listenForChanges);
  }, [listenForChanges]);

  return (
    <UserPreferencesContext.Provider
      value={{
        favorites,
        loadPreferences,
        addFavorites,
        removeFavorites
      }}
    >
      {props.children}
    </UserPreferencesContext.Provider>
  );
};

export const useUserPreferencesContext = () =>
  useContext(UserPreferencesContext);

export default UserPreferencesProvider;
