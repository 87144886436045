import React, { useContext, useEffect, useState } from "react";
import Select, { components } from "react-select";

import { DashboardsContext } from "./DashboardsContext";
import "./index.scss";
import {
  fetchExecCategories,
  fetchExecDasboards,
  fetchAppVariables,
  trackUserAction
} from "../../services/dataService";
import {
  getValuesByKey,
  getValueOnlyByKey,
  getQuarterQuery
} from "../../services/utils";

function FilterDropdownWidget() {
  const { filterContext, dashboardsContext } = useContext(DashboardsContext);
  const [filterField, setFilterField] = filterContext;
  const [customOptions, setCustomOptions] = useState([]);

  useEffect(() => {
    fetchAppVariables().then(res => {
      let layoutValues = JSON.parse(
        getValueOnlyByKey(res.data, "ui.dashboards.filter")
      );

      const data = [
        ...layoutValues.map(d => {
          let option = {
            label: d.quarter,
            value: d.quarter === "All" ? "" : d.quarter,
            type: "quarter",
            selected: d.selected === "true"
          };

          let options = d.months.map(s => {
            return {
              label: s.month,
              value: s.month,
              type: "month"
            };
          });

          return [option, ...options];
        })
      ];
      const filterArray = data.flat();
      setCustomOptions(filterArray);
      const filter = filterArray.find(el => el.selected);
      filter
        ? setFilterField(filter)
        : setFilterField(
            filterArray.find(val =>
              val.label.toLowerCase().includes(getQuarterQuery())
            )
          );
    });
  }, []);

  const formatOptionLabel = ({ label, type }) => (
    <div
      style={
        type === "month"
          ? { marginLeft: "15px" }
          : {
              marginTop: "5px",
              fontSize: "16px",
              color: "#000",
              fontWeight: "bold"
            }
      }
    >
      {label}
    </div>
  );
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#fff" : "#000"
    })
  };
  return (
    <>
      <div className="ztype-class d-flex mr-3">
        <p className="mt-3 ml-n5">Select Period: </p>
        <Select
          value={filterField}
          styles={customStyles}
          className="select-class"
          options={customOptions}
          onChange={c => {
            setFilterField(c);
          }}
          formatOptionLabel={formatOptionLabel}
        />
      </div>
    </>
  );
}

export default FilterDropdownWidget;
