// import { APP_VARS } from "../../../services/appVars";
const interfaceVideoThumb = "/images/DbVisualizer-Interface_thumb.png";
const gettingStartedVideoThumb = "/images/Setting-Up_thumb.png";

export default () => [
  // {
  //   title: "Setting up DbVisualizer",
  //   src: "https://www.youtube.com/watch?v=rux4Rx4no2M&t=4s",
  //   description:
  //     "This course will show you the basics of DbVisualizer for accessing and analyzing data across multiple databases. You'll learn how to connect to databases, create and execute queries and more.",
  //   topics: "",
  //   duration: "3:01",
  //   playing: false,
  //   id: 0,
  //   thumbnail: "/assets" + gettingStartedVideoThumb
  // },
  {
    title: "How to use parameters in Dbvisualizer and Vertica?",
    src: "https://www.youtube.com/watch?v=4rPLvcZtcgU",
    description:
      "In this course, you will do a deep dive into the DbVisualizer features learn how to use parameters from various databases.",
    topics: "",
    duration: "3:13",
    playing: false,
    id: 1,
    thumbnail: "/assets" + interfaceVideoThumb
  }
];
