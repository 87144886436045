import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BaseCard from "../../components/BaseCard";
import BasePage from "../../components/layouts/BasePage";
import SlimNavbar from "./SlimNavbar";
import { AiFillSlackCircle } from "react-icons/ai";

function AppError() {
  return (
    <div>
      <SlimNavbar />
      <BasePage className="p-5">
        <BaseCard>
          <Container fluid className="p-5">
            <Row>
              <Col sm={12} className="text-center">
                Unable to login!
                <br />
                Please retry after some time or contact
                {
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="contact-us-icon" title="Reach us on Slack">
                      <a href="slack://channel?team=T024FQT4T&id=C0115V44FH9">
                        <AiFillSlackCircle />
                      </a>
                    </div>
                    <span className="dot"></span>
                  </div>
                }
              </Col>
            </Row>
          </Container>
        </BaseCard>
      </BasePage>
    </div>
  );
}

export default AppError;
