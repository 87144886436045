import React, { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";

import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import BaseCard from "../../components/BaseCard";
import Spinner from "../../components/Spinner";
import AssetDescription from "./AssetDescription";
import BasePage from "../../components/layouts/BasePage";

import { FaArrowLeft } from "react-icons/fa";

import {
  fetchAssetDetails,
  fetchSampleData,
  fetchDocumentData
} from "../../services/dataService";
import { useAlertsContext } from "../../context/alerts";

function AssetDescriptionPage(props) {
  const { assetId } = useParams();
  const isRelDocRoute = new URLSearchParams(useLocation().search).get("doc");

  const [asset, setAsset] = useState(null);
  const [elements, setElements] = useState(null);
  const [loading, setLoading] = useState(false);
  const { addFailAlert } = useAlertsContext();
  const [sampleDataLoading, setSampleDataLoading] = useState(false);
  const [sampleData, setSampleData] = useState([]);
  const [documentations, setDocumentations] = useState([]);
  const [docsLoading, setDocsLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    fetchAssetDetails(assetId)
      .then(res => {
        if (res.data && res.data.field) {
          setAsset(res.data.field);
          setElements(
            res.data.assetElementVOList.map(s => ({
              ...s,
              "Direct Identifier": s.Term ? s.Term["Direct Identifier"] : null,
              "Compliance Category": s.Term
                ? s.Term["Compliance Category"]
                : null
            }))
          );
        }
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        addFailAlert("Something went wrong fetching asset information");
        setLoading(false);
      });
    fetchDocumentData(assetId)
      .then(res => {
        setDocsLoading(false);
        if (!!res.data.length) setDocumentations(res.data[0].document);
      })
      .catch(() => {
        setDocsLoading(false);
      });
  }, [assetId, addFailAlert]);

  useEffect(() => {
    if (
      asset &&
      elements &&
      (asset["Data Governance Certification"] === "Gold" ||
        asset["Data Governance Certification"] === "Silver" ||
        asset["Data Governance Certification"] === "Bronze")
    ) {
      setSampleDataLoading(true);
      fetchSampleData({
        platform: asset["Asset Platform"],
        dbName: asset["Database Name"],
        tableName: asset["Name"],
        assetId: asset["Asset ID"],
        columnsList: elements
          .map(e => `${e.Name}:${e["Compliance Category"] || "NA"}`)
          .join(","),
        limit: 100
      })
        .then(response => {
          setSampleData(response);
          setSampleDataLoading(false);
        })
        .catch(err => {
          console.log(err);
          setSampleDataLoading(false);
          if (err.response && err.response.status !== 400)
            addFailAlert("Something went wrong fetching sample data");
        });
    }
  }, [addFailAlert, assetId, asset, elements]);

  return (
    <BasePage className="AssetDescriptionPage">
      <BaseCard>
        <div className="pt-1 pl-2 gray-bottom-border-1">
          <Breadcrumb>
            <Breadcrumb.Item as="div">
              <Button
                className="font-xsmall"
                variant="link"
                onClick={() => history.goBack()}
              >
                <FaArrowLeft /> Back
              </Button>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        {!loading && asset && (
          <AssetDescription
            asset={asset}
            sampleData={sampleData}
            sampleDataLoading={sampleDataLoading}
            elements={elements}
            documentations={documentations}
            isRelDocRoute={isRelDocRoute}
            docsLoading={docsLoading}
            showSampleData={
              asset["Data Governance Certification"] === "Silver" ||
              asset["Data Governance Certification"] === "Bronze" ||
              asset["Data Governance Certification"] === "Gold"
            }
          />
        )}
        {loading && <Spinner>Fetching Asset Information</Spinner>}
        {!loading && !asset && (
          <div className="text-center">
            No information available. Please try refreshing the page.
          </div>
        )}
      </BaseCard>
    </BasePage>
  );
}

export default AssetDescriptionPage;
