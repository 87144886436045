// import { APP_VARS } from "../../../services/appVars";
const excel1 = "/images/Data-University/excel1.png";
const excel2 = "/images/Data-University/excel2.png";
const excel3 = "/images/Data-University/excel3.png";
const excel4 = "/images/Data-University/excel4.png";

export default () => [
  {
    title: "Excel Beginner Tutorial",
    src: "https://www.youtube.com/watch?v=6JnEYGxxd8w",
    description:
      "In this course you will learn the basics of Excel spreadsheets and workbooks.",
    topics:
      "User interface overview, data formatting, simple formulas, inserting rows and columns and more.",
    duration: "1:53:15",
    playing: false,
    id: 0,
    thumbnail: "/assets" + excel1
  },
  {
    title: "Excel Intermediate Tutorial",
    src: "https://www.youtube.com/watch?v=bezV5U0dlbo",
    description:
      "This course shows you the various ways you can transform data in Excel.",
    topics:
      "Removing duplicate data, inserting and using charts, using pivot tables, data validation and more.",
    duration: "1:31:07",
    playing: false,
    id: 1,
    thumbnail: "/assets" + excel2
  },
  {
    title: "Excel Advanced Tutorial",
    src: "https://www.youtube.com/watch?v=sm9rSD8IXgM",
    description:
      "In this course you will learn how to use Excel's powerful data operation features.",
    topics:
      "Creating Named Ranges, IF Functions, VLOOKUPs, Database Functions, Macros and more.",
    duration: "1:33:09",
    playing: false,
    id: 2,
    thumbnail: "/assets" + excel3
  },
  {
    title: "Excel Pivot Tables Tutorial",
    src: "https://www.youtube.com/watch?v=Q1UzraY0yXg",
    description:
      "This course covers the many features and options of Excel Pivot Tables.",
    topics:
      "Setting up pivot tables, charts, using pivot table styles, creating report pages and more.",
    duration: "1:48:59",
    playing: false,
    id: 3,
    thumbnail: "/assets" + excel4
  }
];
