import React, { useContext, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useAlertsContext } from "../../context/alerts";
import { APP_VARS } from "../../services/appVars";
import {
  registerDuUser,
  updateStudent,
  trackUserAction
} from "../../services/dataService";
import { DataUniversityContext, USER_STATUS } from "./DataUniversityContext";
import AccentButton from "../../components/AccentButton";
import "./Register.scss";

export default function RegisterComponent({
  DUClass,
  setModalShow,
  setVisibility
}) {
  const { addFailAlert } = useAlertsContext();
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");

  const { userContext, userStatusContext } = useContext(DataUniversityContext);
  const [userStatus, setUserStatus] = userStatusContext;
  const [user] = userContext;

  const registerDuUserFn = () => {
    registerDuUser()
      .then(res => {
        !!setVisibility && setVisibility(false);
        setShow(true);
        setName(res.data.StudentName);
        trackUserAction({
          action: "Apply",
          targetType: "Students",
          targetName: "Data University",
          targetPath: "/data-university",
          targetID: "",
          status: "Success",
          errorDetails: ""
        });
      })
      .catch(() => addFailAlert("Failed to Register for Data University"));
  };

  const handleClose = () => {
    setUserStatus(USER_STATUS["PENDING"]);
    setShow(false);
    !!setVisibility && setVisibility(true);
    !!setModalShow && setModalShow(false);
    //
  };

  return (
    <>
      {userStatus === USER_STATUS["REJECTED"] ||
      userStatus === USER_STATUS["REVOKED"] ? (
        <Button
          size="lg"
          onClick={async () => {
            const studentDetails = {
              studentName: user.studentName,
              emailId: "",
              status: USER_STATUS["PENDING"],
              comment: "",
              shirtSize: ""
            };
            await updateStudent(user.studentId, studentDetails);
            trackUserAction({
              action: "Re-Apply",
              targetType: "Students",
              targetName: "Data University",
              targetPath: "/data-university",
              targetID: "",
              status: "Success",
              errorDetails: ""
            });
            setShow(true);
            setName(user.studentName);
            !!setVisibility && setVisibility(true);
            !!setModalShow && setModalShow(false);
          }}
          className={!!DUClass ? DUClass : "px-3 apply-btn"}
        >
          Re-Apply Now
        </Button>
      ) : (
        <>
          {APP_VARS.ENABLE_DU ? (
            <Button
              size="lg"
              onClick={() => registerDuUserFn()}
              className={!!DUClass ? DUClass : "px-3 apply-btn"}
            >
              Apply Now
            </Button>
          ) : (
            <AccentButton disabled className="pending-btn p-2">
              Coming Soon
            </AccentButton>
          )}
        </>
      )}
      <Modal
        size="lg"
        centered
        show={show}
        dialogClassName="du-banner-modal"
        onHide={handleClose}
      >
        <Modal.Body>
          <Row>
            <Col md={5}>
              <img
                src={
                  APP_VARS.REACT_APP_ASSETS_URL +
                  "/images/data-university-landing/data-univeristy-logo-modal.png"
                }
                alt="modal background"
                width="100%"
              />
            </Col>
            <Col md={7} className="p-5">
              <div className="py-4">
                <div>
                  <h3 className="font-weight-bolder mb-4">
                    <span className="congrats-blue">Thank You</span>
                    <span className="name-highlight"> {name}!</span>
                  </h3>
                </div>
                <p>
                  We have received your application for SXM Data University.
                  <br />
                  Your access is pending for approval by the University
                  Administrators.
                  <br />
                  You will be notified shortly by email.
                </p>
                <div className="mt-4">
                  <Form>
                    <Button className="start-btn" onClick={handleClose}>
                      OK
                    </Button>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
