import React from "react";
import classnames from "classnames";
import FormCheck from "react-bootstrap/FormCheck";

import "./CheckWithCount.scss";

function CheckWithCount({
  count,
  label,
  checked,
  className = "",
  onChange,
  name,
  disabled,
  ...otherProps
}) {
  return (
    <div
      className={classnames(
        "sx-check-with-count",
        "d-flex",
        "align-items-center",
        "justify-content-between",
        className
      )}
      {...otherProps}
    >
      <FormCheck
        onChange={onChange}
        name={name}
        checked={checked}
        type="checkbox"
        id={`${name}-checkbox`}
        label={label}
        disabled={disabled}
      />
      {count !== null && (
        <div
          className={classnames("sx-check-with-count__count", {
            "bg-primary": count > 0
          })}
        >
          {count}
        </div>
      )}
    </div>
  );
}

export default CheckWithCount;
