import React, { useState, useEffect } from "react";
import axios from "axios";
import { setAppVars } from "../../services/appVars";
import { initApiValues } from "../../services/dataService";

// This component will block the app from rendering until the environment vars are fetched and loaded
function EnvVarsWrapper({ children }) {
  const [loading, setLoading] = useState(true);
  const [vars, setVars] = useState();

  useEffect(() => {
    // Random number for the query string.  This prevents the browser from caching the environment JSON file
    const random = Math.floor(Math.random() * 1000000000000000);

    axios
      .get(`${process.env.PUBLIC_URL}/assets/env-vars.json?v=${random}`)
      .then(res => {
        // Set variables in appVars.js
        setAppVars(res.data);

        // Next, set the API values in the DataService, like BASE, API_URL, COGNITO, etc...
        initApiValues();

        // Set variables here in local state, for app rendering logic only (see render method below)
        setVars(res.data);

        // Turn off loading
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      {!loading && !vars && (
        <div>
          Unable to load Marketplace environment data. Please refresh to try
          again.
        </div>
      )}

      {!loading && vars && children}
    </>
  );
}

export default EnvVarsWrapper;
