import React, { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { AiOutlinePlus, AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import Modal from "react-bootstrap/Modal";
import {
  fetchAlerts,
  createAlert,
  updateAlert,
  deleteAlert,
  fetchDashboardDomainsAndCategories,
  trackUserAction
} from "../../services/dataService";
import Grid from "../../components/Grid";
import AccentButton from "../../components/AccentButton";
import { useAlertsContext } from "../../context/alerts";
import Select from "react-select";
import Spinner from "../../components/Spinner";
import ModalCard from "../../components/ModalCard";
import { AbilityContext, PermissionsContext } from "./AbilityContext";
import { Can } from "./AbilityContext";
import defineRulesFor from "./permissions";

const TYPES = [
  { value: "Data Processing", label: "Data Processing" },
  { value: "Analyst Commentary", label: "Analyst Commentary" }
];

const DBNAMES = [
  "dashboard1",
  "dashboard2",
  "dashboard3",
  "dashboard4",
  "dashboard5"
];

const invalidStyle = {
  placeholder: base => ({
    ...base,
    fontSize: "1em",
    color: "#dc3545",
    fontWeight: 400
  }),
  container: base => ({
    ...base,
    backgroundColor: "#dc3545",
    padding: 0.5
  })
};

function startDatetimeLocal() {
  var date = new Date();
  date = new Date(date.setDate(date.getDate() - 1));
  var ten = function(i) {
      return (i < 10 ? "0" : "") + i;
    },
    YYYY = date.getFullYear(),
    MM = ten(date.getMonth() + 1),
    DD = ten(date.getDate()),
    HH = ten(date.getHours()),
    II = ten(date.getMinutes());
  return YYYY + "-" + MM + "-" + DD + "T" + HH + ":" + II;
}

function endDatetimeLocal(dt) {
  var date = !!dt ? new Date(dt) : new Date();
  date = new Date(date.setDate(date.getDate() + 1));
  var ten = function(i) {
      return (i < 10 ? "0" : "") + i;
    },
    YYYY = date.getFullYear(),
    MM = ten(date.getMonth() + 1),
    DD = ten(date.getDate()),
    HH = ten(date.getHours()),
    II = ten(date.getMinutes());
  return YYYY + "-" + MM + "-" + DD + "T" + HH + ":" + II;
}

function Alerts(props) {
  const [show, setShow] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(true);
  const {
    register,
    handleSubmit,
    errors,
    reset,
    setValue,
    getValues
  } = useForm();
  const [itemForEdit, setItemForEdit] = useState(null);
  const { addSuccessAlert, addFailAlert } = useAlertsContext();
  const [selectedType, setSelectedType] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [invalidType, setInvalidType] = useState(false);
  const [isGeneral, setIsGeneral] = useState(true);
  const [delId, setDelId] = useState(null);
  const [minEndDate, setMinEndDate] = useState(endDatetimeLocal());
  const ability = useContext(AbilityContext);
  const { permissionsCtxt } = useContext(PermissionsContext);
  const [permissions, , isAdmin] = permissionsCtxt;

  useEffect(() => {
    const rules = defineRulesFor(permissions, isAdmin);
    ability.update(rules);
  }, [permissions.length]);

  const handleCloseDelModal = () => {
    setDelId(null);
  };
  const handleShowDelModal = id => {
    setDelId(id);
  };

  useEffect(() => {
    fetchAlerts("false")
      .then(res => {
        setAlerts(res.data);
        setLoading(false);
        trackUserAction({
          action: "List",
          targetType: "Admin",
          targetName: "Alerts",
          targetPath: "/admin/alerts",
          targetID: "",
          status: "Success",
          errorDetails: ""
        });
      })
      .catch(() => setLoading(false));
    fetchDashboardDomainsAndCategories()
      .then(response => {
        setCategories(
          response.Domains.filter(r => r.Type === "Domains").map(i => ({
            value: i.TypeID,
            label: i.NAME
          }))
        );
      })
      .catch(err => {
        console.log(err);
        addFailAlert("Something went wrong fetching categories");
      });
  }, [addFailAlert]);

  const handleCloseModal = () => {
    setItemForEdit(null);
    reset({});
    setSelectedCategories([]);
    setSelectedType("");
    setShow(false);
  };

  const handleNewAlertClick = () => {
    setShow(true);
  };

  const handleSelectType = selectedType => {
    setInvalidType(false);
    setValue("type", selectedType);
    setSelectedType(selectedType);
  };

  const handleSelectCategories = selectedCategories => {
    setIsGeneral(!selectedCategories || selectedCategories?.length < 1);
    setValue("categories", selectedCategories);
    setSelectedCategories(selectedCategories);
  };

  const onSubmit = async data => {
    let skipSubmit = false;
    if (!selectedType) {
      setInvalidType(true);
      skipSubmit = true;
    }

    if (skipSubmit) {
      return;
    }

    const alertID = itemForEdit ? itemForEdit.id : null;
    const isEdit = !!alertID;

    if (isEdit) {
      try {
        const {
          data: [response]
        } = await updateAlert({
          ...data,
          // Format date string according to DB requirement YYYY-MM-DD HH:MM:SS
          startDate: data.startDate.split("T").join(" "),
          endDate: data.endDate.split("T").join(" "),
          id: alertID,
          active: data.active,
          type: selectedType.value,
          categories: isGeneral
            ? "-1"
            : selectedCategories.map(c => c.value).join()
        });
        await trackUserAction({
          action: "Edit",
          targetType: "Admin",
          targetName: "Alerts",
          targetPath: "/admin/alerts",
          targetID: "",
          status: "Success",
          errorDetails: ""
        });
        handleCloseModal();
        addSuccessAlert(`Alert updated`);

        const index = alerts.findIndex(i => i.id === response.id);
        if (index !== undefined) {
          // Insert new updated row into the grid at the correct index
          setAlerts(items =>
            items.map((i, idx) => {
              if (idx === index) {
                return response;
              }
              return i;
            })
          );
        }
      } catch (err) {
        handleCloseModal();
        addFailAlert("Something went wrong");
      }
    } else {
      try {
        const {
          data: [response]
        } = await createAlert({
          ...data,
          // Format date string according to DB requirement YYYY-MM-DD HH:MM:SS
          startDate: data.startDate.split("T").join(" "),
          endDate: data.endDate.split("T").join(" "),
          id: alertID,
          active: true,
          type: selectedType.value,
          categories: isGeneral
            ? "-1"
            : selectedCategories.map(c => c.value).join()
        });
        await trackUserAction({
          action: "Create",
          targetType: "Admin",
          targetName: "Alerts",
          targetPath: "/admin/alerts",
          targetID: "",
          status: "Success",
          errorDetails: ""
        });
        handleCloseModal();
        addSuccessAlert(`Alert created`);
        // Add new row to the end
        setAlerts([...alerts, response]);
      } catch (err) {
        handleCloseModal();
        addFailAlert("Something went wrong");
      }
    }
  };

  const handleDeleteClick = async alertID => {
    try {
      await deleteAlert({ id: alertID });
      await trackUserAction({
        action: "Delete",
        targetType: "Admin",
        targetName: "Alerts",
        targetPath: "/admin/alerts",
        targetID: "",
        status: "Success",
        errorDetails: ""
      });
      addSuccessAlert("Alert deleted");
      setAlerts(items => items.filter(i => i.id !== alertID));
    } catch (err) {
      addFailAlert("Something went wrong");
    }
  };

  const handleEditClick = alert => {
    setItemForEdit(alert);
    setMinEndDate(endDatetimeLocal(alert.startDate.split(" ").join("T")));
    reset({
      ...alert,
      startDate: alert.startDate.split(" ").join("T"),
      endDate: alert.endDate.split(" ").join("T")
    });
    setSelectedCategories(
      alert.categories.map(c => {
        return {
          value: c.categoryid,
          label: c.categoryName
        };
      })
    );
    setIsGeneral(!!alert.categories.length);
    setSelectedType({ value: alert.type, label: alert.type });
    setShow(true);
  };

  return (
    <div className="Alerts">
      <Can I="Write" a="Alerts">
        {() => (
          <Button className="mb-3" onClick={handleNewAlertClick}>
            <AiOutlinePlus /> New Alert
          </Button>
        )}
      </Can>
      <br />
      {/* {DBNAMES.map(db => (
        <Can I="read" a={db} passThrough>
          {allowed => (
            <Button className="mb-3" disabled={!allowed}>
              <AiOutlinePlus /> {db}
            </Button>
          )}
        </Can>
      ))} */}
      {loading ? (
        <Spinner>Fetching Alerts</Spinner>
      ) : (
        <Grid
          width="100%"
          title="Alerts"
          columnDefs={[
            {
              field: "title",
              headerName: "Title",
              sortable: true,
              resizable: true
            },
            {
              field: "startDate",
              headerName: "Start",
              sortable: true,
              resizable: true
            },
            {
              field: "endDate",
              headerName: "End",
              sortable: true,
              resizable: true
            },
            {
              field: "description",
              headerName: "Description",
              sortable: true,
              resizable: true
            },
            {
              field: "url",
              headerName: "Alert URL",
              sortable: true,
              resizable: true
            },
            {
              field: "edit",
              headerName: "",
              resizable: true,
              cellRendererFramework: ({ data, value }) => {
                return (
                  <>
                    <Can I="Write" a="Alerts">
                      {() => (
                        <AccentButton onClick={() => handleEditClick(data)}>
                          <AiOutlineEdit /> Edit
                        </AccentButton>
                      )}
                    </Can>
                    <Can I="Write" a="Alerts">
                      {() => (
                        <AccentButton
                          className="sx-accent-button--danger ml-2"
                          onClick={() => handleShowDelModal(data.id)}
                        >
                          <AiOutlineDelete /> Delete
                        </AccentButton>
                      )}
                    </Can>
                  </>
                );
              }
            }
          ]}
          rowData={alerts}
        />
      )}

      <Modal className="admin-modal" show={show} onHide={handleCloseModal}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {!!itemForEdit ? "Edit" : "Add New"} Alert
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form.Group as={Row} controlId="alerts-title">
              <Form.Label column sm="4">
                Title*
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  name="title"
                  ref={register({
                    required: true,
                    validate: val => val.trim() !== ""
                  })}
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.title}
                  maxLength={250}
                />
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="alerts-description">
              <Form.Label column sm="4">
                Description*
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  as="textarea"
                  name="description"
                  ref={register({
                    required: true,
                    validate: val => val.trim() !== ""
                  })}
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.description}
                  maxLength={4000}
                />
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="alerts-url">
              <Form.Label column sm="4">
                URL
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  name="url"
                  ref={register({
                    setValue: val => val.trim()
                  })}
                  type="text"
                  placeholder=""
                  maxLength={250}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="alerts-start-date">
              <Form.Label column sm="4">
                Start Date/Time*
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  name="startDate"
                  ref={register({
                    required: true,
                    validate: val => val.trim() !== ""
                  })}
                  type="datetime-local"
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
                  defaultValue={startDatetimeLocal()}
                  isInvalid={!!errors.startDate}
                  onChange={e =>
                    setMinEndDate(endDatetimeLocal(getValues("startDate")))
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="alerts-end-date">
              <Form.Label column sm="4">
                End Date/Time*
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  name="endDate"
                  ref={register({
                    required: true,
                    validate: val => val.trim() !== ""
                  })}
                  type="datetime-local"
                  min={minEndDate}
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
                  defaultValue={minEndDate}
                  isInvalid={!!errors.endDate}
                />
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="alerts-type">
              <Form.Label column sm="4">
                Type*
              </Form.Label>
              <Col sm="8">
                <Select
                  value={selectedType}
                  name="type"
                  options={TYPES}
                  onChange={handleSelectType}
                  ref={register({ name: "type" })}
                  isInvalid={invalidType}
                  placeholder={invalidType ? `Required` : `Select Type`}
                  styles={invalidType ? invalidStyle : {}}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="alerts-categories">
              <Form.Label column sm="4">
                Categories
              </Form.Label>
              <Col sm="8">
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  value={selectedCategories}
                  name="categories"
                  options={categories}
                  onChange={handleSelectCategories}
                  ref={register({ name: "categories" })}
                  placeholder={`Select Categories`}
                />
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {isGeneral && (
              <em className="mx-4 font-small">
                This alert is not limited to any category, all users will
                receive this message
              </em>
            )}
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {!!delId && (
        <ModalCard
          body={`Do you still want to delete this ${`Alert`}?`}
          title={`Delete ${`Alert`} Confirmation`}
          show={!!delId}
          noText="Cancel"
          yesText="Ok"
          handleNo={handleCloseDelModal}
          handleYes={() => {
            handleDeleteClick(delId);
            handleCloseDelModal();
          }}
        />
      )}
    </div>
  );
}

export default Alerts;
