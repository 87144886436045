// import { APP_VARS } from "../../../services/appVars";
const videoThumb = "/images/Notebooks Video Thumbnail.jpg";

export default () => [
  {
    title: "Data Science Full Course",
    src:
      "https://www.youtube.com/embed/-ETQ97mXXF0?list=PL9ooVrP1hQOGR57Y4g1LFhn1JXVgn1lkX",
    description:
      "This course will help you learn all about Data Science and how organizations are leveraging it to improve business outcomes.   Topics include data science skill sets and real-life use cases, data life cycle, qualitative and quantitative data analysis, statistics and probability, data sampling, supervised and unsupervised learning, data wrangling, classification, clustering, model development, machine learning, Tensorflow and much much more.",
    topics: "",
    duration: "10:23:56",
    playing: false,
    id: 0,
    thumbnail: "/assets" + videoThumb
  }
];
