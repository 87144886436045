import React, { useContext } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Row from "react-bootstrap/Row";
import { LinkContainer } from "react-router-bootstrap";
import BaseCard from "../../components/BaseCard";
import BasicLayout from "./BasicLayout";
import CardsLayout from "./CardsLayout";
import { DashboardsContext } from "./DashboardsContext";
import DomainsRow from "./DomainsRow";
import FilterDropdownWidget from "./FilterDropdownWidget";
import "./index.scss";
import LSiderbarLayout from "./LSiderbarLayout";

function DashboardResultsView({ onTagClick, favorites, onFavToggle }) {
  const { domainsContext } = useContext(DashboardsContext);
  const [, , activeDomain] = domainsContext;
  return (
    <BaseCard>
      <div className="pt-1 pl-4 pr-5 ml-2">
        <Row>
          <Breadcrumb>
            <LinkContainer to="/">
              <Breadcrumb.Item as="div">Home</Breadcrumb.Item>
            </LinkContainer>
          </Breadcrumb>
        </Row>
      </div>
      <div className="d-flex justify-content-between pt-2 px-4 pb-2 ml-2">
        <span className="title">EXECUTIVE INSIGHTS</span>
        <FilterDropdownWidget />
      </div>
      <DomainsRow />
      {activeDomain?.layout === "LSidebar" ? (
        <LSiderbarLayout />
      ) : activeDomain?.layout === "Basic" ? (
        <BasicLayout />
      ) : (
        <CardsLayout
          onTagClick={onTagClick}
          favorites={favorites}
          onFavToggle={onFavToggle}
        />
      )}
    </BaseCard>
  );
}

export default DashboardResultsView;
