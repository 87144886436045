import { default as React, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getSectionContents } from "../../services/dataService";
import { getUniqueId } from "../../services/utils";
import { DataUniversityContext } from "./DataUniversityContext";
import SxmVideoPlayer from "./SxmVideoPlayer";
import Spinner from "../../components/Spinner";
import BasePage from "../../components/layouts/BasePage";
import BaseCard from "../../components/BaseCard";

export default function SxmCourse() {
  const [sectionsData, setSectionsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { sxmUniversityContext } = useContext(DataUniversityContext);
  const [, , , setSxmCourse] = sxmUniversityContext;

  const location = useLocation();
  useEffect(() => {
    setCourseData();
  }, []);

  const setCourseData = () => {
    const url = location.pathname.split("/");
    const id = url[url.length - 1];
    getSectionContents(id)
      .then(result => {
        const { contentSections, enrollmentId, learningPaths } = result.data;
        const courseData = {
          content_sections: contentSections,

          ...learningPaths[0].courses[0],
          lpName: learningPaths[0].name
        };
        let dataSXM = courseData;
        for (const sect of dataSXM.content_sections) {
          for (const content of sect.course_contents) {
            content.isComplete = content.contentProgress?.completed;
            content.progress = parseInt(content.contentProgress?.progress);
            content.contentProgressId = content.contentProgress?.id;
            content.enrollId = enrollmentId;
            content.cId = id;
            content.contentId = content.id ? content.id : null;
          }
        }

        setSxmCourse(dataSXM);
        setLoading(false);
        const courseSections = courseData.content_sections.map(el => {
          return {
            id: getUniqueId(),
            contents: el.course_contents,
            name: el.Name
          };
        });
        // console.log(courseSections);
        // for (const section of courseSections) {
        //   for (const content of section.contents) {
        //     content.isComplete = content.contentProgress?.completed;
        //     content.progress = content.contentProgress?.progress;
        //     content.contentProgressId = content.contentProgress?.id;
        //     content.contentId = content.id;
        //     content.enrollId = enrollmentId;
        //     content.cId = id;
        //   }
        // }

        setSectionsData(courseSections);
      })
      .catch();
  };
  return !loading ? (
    <SxmVideoPlayer courseTitle={""} coursePaths={[]} sections={sectionsData} />
  ) : (
    <BasePage className="p-5">
      <BaseCard className="p-5">
        <Spinner>Loading...</Spinner>
      </BaseCard>
    </BasePage>
  );
}
