import React from "react";
import classnames from "classnames";
import Button from "react-bootstrap/Button";
import "./AccentButton.scss";

function AccentButton({
  className = "",
  onClick = () => {},
  children,
  ...otherProps
}) {
  return (
    <Button
      onClick={onClick}
      className={classnames("sx-accent-button", className)}
      {...otherProps}
    >
      {children}
    </Button>
  );
}

export default AccentButton;
