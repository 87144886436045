import PropTypes from "prop-types";
import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import BaseCard from "../BaseCard";
import "./ExpandableMenu.scss";

function ExpandableMenu({
  title,
  items = [],
  className = "",
  defaultActiveKey,
  onItemClick = () => {},
  activeKey,
  activeIcon = <AiOutlineMinus />,
  inActiveIcon = <AiOutlinePlus />,
  ...otherProps
}) {
  return (
    <BaseCard {...otherProps} className={`sx-expandable-menu ${className}`}>
      {title && <div className="sx-expandable-menu__title py-2">{title}</div>}
      <Accordion activeKey={activeKey} defaultActiveKey={defaultActiveKey}>
        <ul className="sx-expandable-menu__items-container">
          {items.map(item => {
            const isActive = item.id === activeKey;
            let activeClassName;
            if (item.body && isActive) {
              activeClassName = "active-with-body";
            }
            if (!item.body && isActive) {
              activeClassName = "active-link";
            }

            return (
              <div key={item.id}>
                <Accordion.Toggle
                  eventKey={item.id}
                  onClick={() => onItemClick(item.id, item)}
                  as="div"
                >
                  <li
                    className={`sx-expandable-menu__item py-3 text-primary ${activeClassName}`}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="sx-expandable-menu__item-title">
                        {item.name}
                      </div>
                      {item.body && (
                        <div className="sx-expandable-menu__toggle-button">
                          {isActive ? activeIcon : inActiveIcon}
                        </div>
                      )}
                    </div>
                  </li>
                </Accordion.Toggle>
                {item.body && (
                  <Accordion.Collapse eventKey={item.id}>
                    <div
                      className={`sx-expandable-menu__item-body py-3 ${activeClassName}`}
                    >
                      {item.body}
                    </div>
                  </Accordion.Collapse>
                )}
              </div>
            );
          })}
        </ul>
      </Accordion>
    </BaseCard>
  );
}

ExpandableMenu.propTypes = {
  activeKey: PropTypes.any,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      body: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    })
  ).isRequired,
  className: PropTypes.string,
  defaultActiveKey: PropTypes.any,
  onItemClick: PropTypes.func
};

export default ExpandableMenu;
