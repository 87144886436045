import React from "react";
import { Row } from "react-bootstrap";

export default function DataRetentionInfo() {
  return (
    <Row>
      <b>Data Retention Category</b>
      <div className="pl-3">
        <div className="p-2">
          <b>Short-Term:</b> Hot Retention Period = 1-3 years (Months Range
          1-36). Example: payment card based on Expiration Date. We recommend
          Hot Retention period for 1 year, followed by 5 years of Cold
          Retention.
        </div>
        <div className="p-2">
          <b>Mid-Term:</b> Hot Retention Period = 3-6 years (Months Range
          37-72). Example: CV – 4 years of retention of subscription history
          after last active date. We recommend Hot Retention period of 4 years,
          followed by another 2 years of Cold Retention.
        </div>
        <div className="p-2">
          <b>Long-Term:</b> Hot Retention Period = &gt;6 years (Months Range
          73+). Example: Finance data needs to be retained for 7 years to meet
          IRS audit requirements. We recommend Hot Retention period for 7 years,
          followed by another 2 years of Cold Retention.
        </div>
        <div className="p-2">
          <b>Perpetual:</b> Hot Retention never ends. Always Hot.
        </div>
      </div>
      <div className="p-2">
        <b>Hot Retention Period:</b> The storage enables fast access to read and
        write for business-critical data, which need to be accessed quickly and
        frequently by users or processes.
      </div>
      <div className="p-2">
        <b>Cold Retention Period:</b> The storage has slower read and write
        speed for inactive data and is less expensive than Hot storage. It is
        suitable for data that does not require fast access or is accessed less
        frequently by users. Access to data stored in Cold Storage could be the
        same, or different from the process on Hot storage, which, therefore,
        needs to be designed and implemented.
      </div>
      <div className="p-2">
        <b>Deep Cold Storage:</b> This storage is the least expensive option. It
        is used to store data that is rarely accessed and allows for a much
        longer retention period.
      </div>{" "}
      <div className="p-2">
        <b>Retention Policy Field:</b> This is the field which allocates the
        data into the various retention categories (Hot, Cold, Deep Cold). For
        example it may be the load date of a transaction, the sale date of a
        vehicle (event date), the subscription start or end date.
      </div>
      <div className="p-2">
        <a
          href="https://sxmwiki.corp.siriusxm.com/display/EDM/Data+Retention+Standards"
          target="_blank"
          rel="noreferrer noopener"
        >
          View more information link
        </a>
      </div>
    </Row>
  );
}
