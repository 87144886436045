import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { useScreenSize } from "../../hooks/customHooks";
import Button from "react-bootstrap/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import BaseCard from "../../components/BaseCard";
import CategoryCard from "../../components/CategoryCard";
import AssetCard from "../../components/AssetCard";
import Spinner from "../../components/Spinner";
import Slider from "../../components/Slider";
import { getAssetColor } from "../../services/assetColorMap";
import "./Categories.scss";
import { useAlertsContext } from "../../context/alerts";

import {
  trackUserAction,
  fetchCategories,
  fetchAssets
} from "../../services/dataService";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DgcLegendData from "./DgcLegendData";

function Categories({
  activeFilters,
  setActiveFilters,
  setCounts,
  filterAllData
}) {
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [assets, setAssets] = useState([]);
  const [assetsLoading, setAssetsLoading] = useState(false);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const { addFailAlert } = useAlertsContext();

  const [carouselWidth, setCarouselWidth] = useState();

  const [isSmall, isMedium, isLarge, isExtraLarge, isXXL] = useScreenSize();

  useEffect(() => {
    if (isSmall || isMedium) {
      setCarouselWidth(1);
    }
    if (isLarge) {
      setCarouselWidth(2);
    }
    if (isExtraLarge) {
      setCarouselWidth(3);
    }
    if (isXXL) {
      setCarouselWidth(4);
    }
  }, [isSmall, isMedium, isLarge, isExtraLarge, isXXL]);

  const { value: businessDomain = "" } =
    activeFilters.find(i => i.type === "Data Domain") || {};

  const { value: activeCategory = "" } =
    activeFilters.find(i => i.type === "Data Category") || {};

  const refreshCategories = useCallback(
    businessDomain => {
      setCategoriesLoading(true);
      fetchCategories(businessDomain)
        .then(response => {
          setCategories(
            (response.data.categoryList || []).filter(c => !!c.name)
          );
          setCategoriesLoading(false);
        })
        .catch(err => {
          console.log(err);
          setCategoriesLoading(false);
          addFailAlert("Something went wrong fetching categories");
        });
    },
    [addFailAlert]
  );

  const refreshAssets = useCallback(
    (businessDomain, activeCategory) => {
      setAssetsLoading(true);
      fetchAssets({
        where: [
          { key: "Data Domain", value: [businessDomain] },
          { key: "Data Category", value: [activeCategory] }
        ]
      })
        .then(response => {
          const fieldList = response.data.fieldList;
          setAssets(fieldList || []);
          setCounts(response.data.fieldCounts || []);
          setAssetsLoading(false);
        })
        .catch(err => {
          console.log(err);
          setAssetsLoading(false);
          addFailAlert("Something went wrong fetching assets");
        });
    },
    [setCounts, addFailAlert]
  );

  useEffect(() => {
    refreshCategories(businessDomain);
  }, [businessDomain, refreshCategories]);

  useEffect(() => {
    if (businessDomain && activeCategory) {
      refreshAssets(businessDomain, activeCategory);
    }
  }, [businessDomain, activeCategory, refreshAssets]);

  useEffect(() => {
    if (categories && categories.length > 0 && !activeCategory) {
      setActiveFilters(af => [
        ...af,
        { type: "Data Category", value: categories[0].name }
      ]);
    }
  }, [activeCategory, setActiveFilters, categories]);

  useEffect(() => {
    setSelectedDomain(
      filterAllData.find(e => e["Data Domain"] === businessDomain)
    );
  }, [businessDomain, filterAllData]);

  return (
    <BaseCard>
      <div className="pt-1 pl-2 categories-cnt">
        <Breadcrumb>
          <LinkContainer to="/">
            <Breadcrumb.Item as="div">Home</Breadcrumb.Item>
          </LinkContainer>
          <LinkContainer to="/datacatalog/domains">
            <Breadcrumb.Item as="div">Data Catalog</Breadcrumb.Item>
          </LinkContainer>
          <Breadcrumb.Item active>{businessDomain}</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="d-flex align-items-center p-4">
        <span className="text-primary font-medium">
          DATA CATALOG - {businessDomain}
        </span>
      </div>
      {!!selectedDomain?.helplink && (
        <div className="d-flex align-items-center pl-4 pb-3 pt-3 doc-links-cnt">
          <span className="text-muted" style={{ fontSize: "14px" }}>
            Documentation:
          </span>{" "}
          {selectedDomain?.helplink?.map((e, i) => (
            <>
              {!!i ? <span className="text-muted">|</span> : <></>}
              <a href={e.url} className="mx-2" target="_blank">
                {e.label}
              </a>
            </>
          ))}
        </div>
      )}
      <div>
        {categoriesLoading && <Spinner>Fetching Categories</Spinner>}
        {!categoriesLoading && categories.length === 0 && (
          <div className="text-center">No categories available.</div>
        )}
        {!categoriesLoading && categories.length !== 0 && (
          <div className="p-4 Categories__section-categories d-flex flex-wrap">
            {categories.map((c, index) => (
              <div key={c.name}>
                <CategoryCard
                  title={c.name}
                  className="pointer mr-3 mb-2"
                  isActive={c.name === activeCategory}
                  authorized={true}
                  popoverTitle={c.name}
                  popoverText={c.descriptions}
                  onClick={() => {
                    setActiveFilters(af => {
                      const filtered = af.filter(
                        f => f.type !== "Data Category"
                      );
                      return [
                        ...filtered,
                        { type: "Data Category", value: c.name }
                      ];
                    });
                  }}
                />
              </div>
            ))}
          </div>
        )}

        {activeCategory && (
          <div className="Categories__assets-section">
            {assetsLoading && <Spinner>Fetching Assets</Spinner>}
            {!assetsLoading && assets.length > 0 && (
              <div className="py-5 px-5 position-relative">
                <div
                  className="d-flex align-items-center position-absolute"
                  style={{ width: "calc(100% - 8.7rem)" }}
                >
                  <span className="text-primary font-weight-bold font-medium">
                    {activeCategory}
                  </span>
                  <span className="text-secondary font-medium pl-2">
                    ({assets.length})
                  </span>
                  <Button
                    className="ml-auto"
                    style={{ zIndex: 2 }}
                    onClick={() => {
                      trackUserAction({
                        action: "Search",
                        targetType: "assets",
                        targetName: `${activeCategory}`,
                        targetPath: `/datacatalog/search`,
                        targetID: "",
                        status: "Success",
                        errorDetails: ""
                      });
                      history.push(`/datacatalog/search`);
                    }}
                    variant="link"
                  >
                    View All
                  </Button>
                </div>
                <Slider
                  infinite={false}
                  slidesToShow={Math.min(assets.length, carouselWidth)}
                  slidesToScroll={Math.min(assets.length, carouselWidth)}
                >
                  {assets.map(a => (
                    <div key={a["Asset ID"]}>
                      <AssetCard
                        onClick={() => {
                          trackUserAction({
                            action: "View",
                            targetType: "asset",
                            targetName: a["Name"],
                            targetPath: `/assetdescription/${a["Asset ID"]}`,
                            targetID: a["Asset ID"],
                            status: "Success",
                            errorDetails: ""
                          });
                          history.push(`/assetdescription/${a["Asset ID"]}`);
                        }}
                        style={{
                          maxWidth: "40rem",
                          margin: "8.8rem 1rem 1rem"
                        }}
                        onButtonClick={({ name, value }) => {
                          const filterAlreadyExists = activeFilters.find(
                            i => i.type === name && i.value === value
                          );
                          if (!filterAlreadyExists) {
                            setActiveFilters(i => [
                              ...i,
                              { type: name, value }
                            ]);
                          }
                          trackUserAction({
                            action: "Search Data Catalog",
                            targetType: "filter",
                            targetName: name,
                            targetPath: `/datacatalog/search`,
                            targetID: name,
                            status: "Success",
                            errorDetails: ""
                          });
                          history.push(`/datacatalog/search`);
                        }}
                        onTagClick={tag => {
                          const filterAlreadyExists = activeFilters.find(
                            i => i.type === "Tags" && i.value === tag
                          );
                          if (!filterAlreadyExists) {
                            setActiveFilters(i => [
                              ...i,
                              { type: "Tags", value: tag }
                            ]);
                          }
                          trackUserAction({
                            action: "Search Data Catalog",
                            targetType: "tag",
                            targetName: tag,
                            targetPath: `/datacatalog/search`,
                            targetID: tag,
                            status: "Success",
                            errorDetails: ""
                          });
                          history.push(`/datacatalog/search`);
                        }}
                        description={a["Business Description"]}
                        tags={a["Tags"] || []}
                        className="pointer grow"
                        title={a["Business Name"]}
                        brandColor={getAssetColor(a["Brand"])}
                        brandText={a["Brand"]}
                        dataGovernanceScore={
                          a["Data Governance Certification"] || "Pending"
                        }
                        dgcValues={a["DGC Metrics"]}
                        tableValues={[
                          {
                            name: "Refresh Frequency",
                            value: a["Refresh Frequency"]
                          },
                          {
                            name: "Security Classification",
                            value: a["Security Classification"]
                          },
                          {
                            name: "Data Category",
                            value: a["Data Category"]
                          },
                          {
                            name: "Asset Platform",
                            value: a["Asset Platform"]
                          },
                          {
                            name: "Data Domain",
                            value: a["Data Domain"]
                          }
                        ]}
                      />
                    </div>
                  ))}
                </Slider>
                <Row>
                  <Col sm={6}>
                    <DgcLegendData />
                  </Col>
                </Row>
              </div>
            )}
            {!assetsLoading && assets.length === 0 && (
              <div className="text-center">
                No assets available for this category.
              </div>
            )}
          </div>
        )}
      </div>
    </BaseCard>
  );
}

export default Categories;
