import React, { useContext } from "react";
import Select from "react-select";
import { trackUserAction } from "../../services/dataService";
import { DashboardsContext } from "./DashboardsContext";
import "./index.scss";

function ZShapeLayoutWidget() {
  const { domainsContext, categoryContext, dashboardsContext } = useContext(
    DashboardsContext
  );
  const [, , activeDomain] = domainsContext;
  const [
    ,
    ,
    activeCategory,
    setActiveCategory,
    filteredCategories
  ] = categoryContext;
  const [allDashboards, , , , , setFilteredDashboards] = dashboardsContext;

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      color: "#434343",
      minHeight: "35px",
      height: "35px",
      fontSize: "16px"
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      color: "#434343",
      height: "35px",
      fontSize: "16px"
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      fontSize: "16px"
    }),

    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "35px",
      color: "#434343"
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "16px"
    }),
    menu: provided => ({
      ...provided,
      zIndex: 6,
      color: "#000"
    })
  };
  return (
    <>
      {activeDomain?.layout === "ZShape" && (
        <div className="ztype-class d-flex">
          <Select
            styles={customStyles}
            className="select-class"
            value={{ label: activeCategory?.name, value: activeCategory?.name }}
            name="href"
            options={filteredCategories.map(e => {
              return { ...e, label: e.name, value: e.name };
            })}
            onChange={c => {
              setActiveCategory(c);
              setFilteredDashboards(
                allDashboards.filter(e => e.subdomain_id === c?.id)
              );
              trackUserAction({
                action: "search",
                targetType: "executive dashboards",
                targetName: c.name,
                targetPath: `/executive-dashboards`,
                targetID: c.id,
                status: "Success",
                errorDetails: ""
              });
            }}
          />
        </div>
      )}
    </>
  );
}

export default ZShapeLayoutWidget;
