import React, { useContext, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { APP_VARS } from "../../services/appVars";
import { updateStudent } from "../../services/dataService";
import { DataUniversityContext, USER_STATUS } from "./DataUniversityContext";
import Select from "../../components/Select";
import "./WelcomeModal.scss";
import { SSO_Login } from "./StudentHome";

const ShirtSizes = [
  { value: "Small" },
  { value: "Medium" },
  { value: "Large" },
  { value: "XL" },
  { value: "XXL" }
];

export default function WelcomeModal({ show, setShowStartModal }) {
  const { userContext, userStatusContext } = useContext(DataUniversityContext);

  const [, setUserStatus] = userStatusContext;
  const [user] = userContext;
  const [shirtSize, setShirtSize] = useState("Small");
  const continueLearning = async () => {
    const studentDetails = {
      studentName: user.studentName,
      emailId: "",
      status: USER_STATUS["ONBOARDED"],
      comment: "",
      shirtSize: user.shirtSize
    };
    await updateStudent(user.studentId, studentDetails);
    setUserStatus(USER_STATUS["ONBOARDED"]);
    setShowStartModal(true);
  };
  const submitWelcome = async () => {
    const studentDetails = {
      studentName: user.studentName,
      emailId: "",
      status: USER_STATUS["ONBOARDED"],
      comment: "",
      shirtSize: shirtSize
    };
    await updateStudent(user.studentId, studentDetails);
    setUserStatus(USER_STATUS["ONBOARDED"]);
    setShowStartModal(true);
  };
  return (
    <Modal
      size="lg"
      centered
      show={show}
      onHide={() => {
        setUserStatus(USER_STATUS["ONBOARDED"]);
        setShowStartModal(true);
      }}
      dialogClassName="welcome-modal"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body>
        <Row>
          <Col md={5}>
            <img
              src={
                APP_VARS.REACT_APP_ASSETS_URL +
                "/images/data-university-landing/data-univeristy-logo-modal.png"
              }
              alt="modal background"
              width="100%"
            />
          </Col>
          <Col md={7} className="p-5">
            <div className="p-4">
              {" "}
              <div>
                <h3 className={"font-weight-bolder mb-4"}>
                  <span className="congrats-blue">Congratulations</span>
                  <span className="name-highlight"> {user.studentName}!</span>
                </h3>
              </div>
              {!user.shirtSize ? (
                <>
                  <p>
                    Thank you for registering! And since you are one of our
                    first students, you will be receiving a welcome kit with
                    gifts from the SiriusXM store.
                  </p>
                  <p>Please let us know your T-shirt size.</p>
                  <div className="mt-4">
                    <Form>
                      <Row>
                        <Col sm={1} className="mt-2">
                          <b>Size</b>
                        </Col>
                        <Col sm={5}>
                          <Select
                            options={ShirtSizes}
                            className="select-welcome"
                            onChange={e => setShirtSize(e.target.value)}
                          />
                        </Col>
                        <Col sm={6}>
                          <Button
                            className="welcome-btn"
                            onClick={submitWelcome}
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </>
              ) : (
                <>
                  <p>
                    Welcome back! All your previous progress and achievements
                    have been restored. You can continue where you left off.
                  </p>
                  <div className="mt-4">
                    <Button
                      className="welcome-btn-alt"
                      onClick={continueLearning}
                    >
                      Continue Learning
                    </Button>
                  </div>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
