import React from "react";

import "./BaseCard.scss";

function BaseCard({ children, className = "", ...otherProps }) {
  return (
    <div {...otherProps} className={`sx-base-card ${className}`}>
      {children}
    </div>
  );
}

export default BaseCard;
