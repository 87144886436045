import React, { useContext } from "react";
import { LinkContainer } from "react-router-bootstrap";
import PaginationTemplate from "../../components/PaginationTemplate";
import { SmartSearchContext } from "./SmartSearchContext";

function MetricsList() {
  const {
    metricTabContext,
    pageContext,
    dataDocumentationContext
  } = useContext(SmartSearchContext);
  const [, , metricTabData] = metricTabContext;
  const [pageData, setPageData] = pageContext;
  const [dataDocumentation] = dataDocumentationContext;
  return (
    <>
      {metricTabData?.length ? (
        <>
          {metricTabData?.map((el, index) => (
            <MetricComponent
              key={index}
              el={el}
              docsData={dataDocumentation.find(
                dc => dc["Data Domain"] === el["domain"]
              )}
            />
          ))}
        </>
      ) : (
        <div className="text-center mb-2">No results found.</div>
      )}

      <PaginationTemplate
        setCurrentPage={val => {
          setPageData(j => ({
            ...j,
            metricTab: { ...j.metricTab, currentPage: val }
          }));
        }}
        numberOfPages={pageData?.metricTab?.numberOfPages}
        currentPage={pageData?.metricTab?.currentPage}
      />
    </>
  );
}
export function MetricComponent({ el, docsData }) {
  return (
    <>
      {/* eslint-disable-next-line */}
      <LinkContainer
        to={{
          pathname: `/metricviewer/${el["objectId"]}`,
          data: "smart-search"
        }}
        className="search-primary font-weight-bold"
      >
        {/* eslint-disable-next-line */}
        <a href="javascript:void(0);">{el.businessName}</a>
      </LinkContainer>
      <br />
      <small>{el["description"]}</small>
      <br />
      <div>
        <small style={{ color: "#333333" }}>
          <>
            <b>Type: </b>
            <span style={{ color: "#333333" }}>Metric</span>
            <span className="mx-2" style={{ color: "rgb(227 217 217)" }}>
              |
            </span>
          </>
          {el["domain"] && (
            <>
              <b>Domain: </b>
              <span style={{ color: "#333333" }}>{el["domain"]}</span>
              <span className="mx-2" style={{ color: "rgb(227 217 217)" }}>
                |
              </span>
            </>
          )}
          {el["assetOwner"] && (
            <>
              <b>EDO Contact : </b>
              <span style={{ color: "#333333" }}>{el["assetOwner"]}</span>
              <span className="mx-2" style={{ color: "rgb(227 217 217)" }}>
                |
              </span>
            </>
          )}
        </small>
      </div>
      {!!docsData?.helplink && (
        <div className="d-flex align-items-center pb-3 pt-1">
          <small style={{ color: "#333333" }}>
            <b>Documentation: </b>
            {docsData?.helplink?.map((e, i) => (
              <>
                {!!i ? (
                  <span style={{ color: "rgb(227 217 217)" }}>|</span>
                ) : (
                  <></>
                )}
                <a href={e.url} className="mx-2" target="_blank">
                  {e.label}
                </a>
              </>
            ))}
          </small>
        </div>
      )}
      <br />
    </>
  );
}
export default MetricsList;
