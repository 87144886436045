import React, { useContext, useEffect, useState, useRef } from "react";
import { Card, ProgressBar, Row } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { AiFillPlayCircle } from "react-icons/ai";
import { FaWindowClose } from "react-icons/fa";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import Slider from "react-slick";
import BaseCard from "../../components/BaseCard";
import Spinner from "../../components/Spinner";
import { useAlertsContext } from "../../context/alerts";
import { useLocation } from "react-router-dom";

import {
  createCorseraEnrollments,
  createEnrollments,
  getEnrollments,
  getNewCourseCategories,
  getCourseraMembershipStatus,
  trackUserAction
} from "../../services/dataService";
import { APP_VARS } from "../../services/appVars";
import { DataUniversityContext } from "./DataUniversityContext";
import { SSO_Login } from "./StudentHome";

const convertToHrsMins = (duration = "", small = false) => {
  let hours = duration
    ?.split(",")[0]
    ?.split("hrs")[0]
    .trim();
  // totalSeconds %= 3600;
  let minutes = duration
    ?.split(",")[1]
    ?.split("ms")[0]
    .trim();
  if (hours || minutes) {
    return small
      ? `${hours < 9 ? `0${hours}` : hours}h ${
          minutes < 9 ? `0${minutes}` : minutes
        }m`
      : `${hours < 9 ? `0${hours}` : hours}hr ${
          minutes < 9 ? `0${minutes}` : minutes
        }mins`;
  }
};
export default function CourseLibrary() {
  const [courseCategories, setCourseCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [catIndex, setCatIndex] = useState(0);
  const [lpId, setLpId] = useState();
  const { spinnerContext } = useContext(DataUniversityContext);
  const [showSpinner] = spinnerContext;
  const location = useLocation();

  const currentPathName = `${location.pathname}${location.search || ""}`;

  useEffect(() => {
    getNewCourseCategories().then(cc => {
      let categoriesSorted = cc?.data.sort((a, b) => a.SortOrder - b.SortOrder);
      setCourseCategories(categoriesSorted);
      setSelectedCategory(categoriesSorted[0]);

      let catIdFromUrl = currentPathName
        ?.split("?")
        .find(el => el.includes("categoryId"))
        ?.split("=")[1];

      let lpIdFromUrl = currentPathName
        ?.split("?")
        .find(el => el.includes("learningPathId"))
        ?.split("=")[1];

      if (catIdFromUrl) {
        setCatIndex(
          categoriesSorted.map(el => el.id).indexOf(parseInt(catIdFromUrl))
        );
        // if (lpIdFromUrl) {
        //   setLpId(lpIdFromUrl);
        // }
      }
    });
  }, [currentPathName]);

  return (
    <BaseCard className="course-library shadow">
      <Card.Header as="h4" className="p-3 mt-2 ml-3">
        Course Library
      </Card.Header>
      <Card.Body className="course-library-div">
        <CourseCategories
          courseCategories={courseCategories}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          catIndex={catIndex}
        />

        {showSpinner ? (
          <Spinner />
        ) : (
          <>
            <Card.Header as="h4" className="p-2 mt-3 py-4">
              <span className="ml-5">
                {selectedCategory?.name}{" "}
                <small className="text-muted">
                  ({selectedCategory?.learning_paths.length} Learning Path
                  {selectedCategory?.learning_paths.length > 1 && "s"})
                </small>
              </span>
            </Card.Header>
            {selectedCategory?.learning_paths
              ?.sort((a, b) => a.SortOrder - b.SortOrder)
              .map(lp => (
                <LearningPath lp={lp} />
              ))}
          </>
        )}
      </Card.Body>
    </BaseCard>
  );
}

function CourseCategories({
  courseCategories,
  selectedCategory,
  setSelectedCategory,
  catIndex
}) {
  let settings = {
    centerMode: false,
    slidesToShow: 4,
    swipeToSlide: true,
    infinite: false,
    dots: false,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 3.8,
          slidesToScroll: 3.8,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3.2,
          slidesToScroll: 3.2,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2.8,
          slidesToScroll: 2.8,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 2.2,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.8,
          slidesToScroll: 1.8,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1.2,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false
        }
      }
    ],
    nextArrow: <MdKeyboardArrowRight />,
    prevArrow: <MdKeyboardArrowLeft />
  };
  const categorySlider = useRef();
  useEffect(() => {
    setTimeout(() => {
      categorySlider.current.slickGoTo(catIndex);
      setSelectedCategory(courseCategories[catIndex]);
    }, 2000);
  }, [catIndex, courseCategories, setSelectedCategory]);
  return (
    <div className="categories-div mb-n2 px-3">
      <Slider {...settings} ref={categorySlider}>
        {courseCategories.map(cc => (
          <div
            className={`category-div ${
              cc?.name === selectedCategory?.name ? "selected" : ""
            }`}
            onClick={() => {
              setSelectedCategory(cc);
              window.history.replaceState(
                {},
                document.title,
                "/data-university/home"
              );
            }}
          >
            {/* // eslint-disable-next-line */}
            <img
              src={cc?.Thumbnail[0]?.url}
              alt={"" + cc?.name}
              className={` mx-auto ${
                cc?.name.includes("Information Technology")
                  ? "category-icon-img-info"
                  : "category-icon-img"
              }`}
            />
            <h5 className={`ml-2 mr-2 mt-2 category-text-font`}>{cc?.name}</h5>
          </div>
        ))}
      </Slider>
    </div>
  );
}

function LearningPath({ lp }) {
  const [learningPath, setLearningPath] = useState(null);
  const {
    enrollmentsContext,
    coursesContext,
    learningPathsContext
  } = useContext(DataUniversityContext);
  const [enrollments, setEnrollments] = enrollmentsContext;
  const [courses] = coursesContext;
  const [learningPaths] = learningPathsContext;
  const { addFailAlert } = useAlertsContext();

  const clickCourse = (src, id, courseId, vendor = "") => {
    if (vendor === "SiriusXM") {
      createEnrollments(id)
        .then(() => {
          getEnrollments().then(res => {
            setEnrollments(res.data.filter(l => l.course !== null));
          });
          window.open(`/data-university/sxm-course/${id}`, "_blank");
          trackUserAction({
            action: "Enroll",
            targetType: "Course",
            targetName: "Data University",
            targetPath: "/data-university",
            targetID: "",
            status: "Success",
            errorDetails: ""
          });
        })
        .catch(err => {
          err?.response?.data?.errors[0]?.message
            .toLowerCase()
            .includes("Course already enrolled".toLowerCase())
            ? window.open(`/data-university/sxm-course/${id}`, "_blank")
            : addFailAlert("Something went wrong");
        });
    } else {
      createCorseraEnrollments({
        contentId: courseId
      })
        .then(res => {
          createEnrollments(id)
            .then(() => {
              getEnrollments().then(res => {
                setEnrollments(res.data.filter(l => l.course !== null));
              });
              window.open(
                APP_VARS.COURSERA_LEARNING_PAGE.replace("{source}", src),
                "_blank"
              );
              trackUserAction({
                action: "Enroll",
                targetType: "Course",
                targetName: "Data University",
                targetPath: "/data-university",
                targetID: "",
                status: "Success",
                errorDetails: ""
              });
            })
            .catch(err => {
              err?.response?.data?.errors[0]?.message
                .toLowerCase()
                .includes("Course already enrolled".toLowerCase())
                ? window.open(
                    APP_VARS.COURSERA_LEARNING_PAGE.replace("{source}", src),
                    "_blank"
                  )
                : addFailAlert("Something went wrong");
            });
        })
        .catch(err => addFailAlert(`"Something went wrong from Coursera"`));
    }
  };

  let settings = {
    dots: false,
    centerMode: false,
    slidesToShow: 3.2,
    swipeToSlide: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2.7,
          slidesToScroll: 2.7,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 2.2,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1.7,
          slidesToScroll: 1.7,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.4,
          slidesToScroll: 1.4,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1.2,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false
        }
      }
    ],
    nextArrow: <MdKeyboardArrowRight />,
    prevArrow: <MdKeyboardArrowLeft />
  };
  useEffect(() => {
    setLearningPath(learningPaths.find(el => el.id === lp.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lp, courses, enrollments, learningPaths]);
  return (
    <>
      {learningPath && (
        <>
          <Row className="p-3 px-5 mt-3">
            <h5 className="ml-4 text-muted">{learningPath.name}</h5>
            {"  "}
            <small className="mt-1 ml-2 text-primary">
              {!!learningPath?.courses.filter(c => !!c.MakeAvailableInDU)
                ?.length ? (
                <>
                  (
                  {
                    learningPath?.courses.filter(c => !!c.MakeAvailableInDU)
                      ?.length
                  }{" "}
                  Course
                  {learningPath?.courses.filter(c => !!c.MakeAvailableInDU)
                    ?.length > 1 && "s"}
                  )
                </>
              ) : (
                <>No courses available.</>
              )}
            </small>
          </Row>
          <div className="px-3 mt-n2">
            <Slider {...settings}>
              {learningPath?.courses
                ?.filter(c => !!c.MakeAvailableInDU)
                ?.sort((a, b) => a.SortOrder - b.SortOrder)
                ?.map((cc, index) => (
                  <SliderItem
                    cc={cc}
                    clickCourse={clickCourse}
                    lp={learningPath}
                    enrollments={enrollments}
                  />
                ))}
            </Slider>
          </div>
        </>
      )}
    </>
  );
}

function SliderItem({ cc, clickCourse, lp, enrollments }) {
  const [enrolledCourse, setEnrolledCourse] = useState(null);
  useEffect(() => {
    let eCourse = enrollments?.find(e => e.course.CourseId === cc?.CourseId);
    setEnrolledCourse(eCourse);
  }, [enrollments, enrolledCourse, cc.id, cc.CourseId, lp]);
  return (
    <div className="course-div mx-2">
      <div
        className="course-thumbnail"
        onClick={() =>
          clickCourse(cc?.src, cc?.id, cc?.CourseId, cc?.University)
        }
      >
        <img
          src={cc?.thumbnail?.url || cc?.ThumbnailUrl}
          alt={cc?.thumbnail?.url || cc?.ThumbnailUrl}
          className={enrolledCourse?.ProgressPct === 100 ? "complete-img" : ""}
        />

        <div className="play-icon-container">
          <AiFillPlayCircle
            color="white"
            className="play-icon mt-n3 ml-n3"
            size={50}
          />
        </div>

        {!!enrolledCourse?.ProgressPct && (
          <ProgressBar
            variant="success"
            now={enrolledCourse?.ProgressPct}
            bsPrefix={enrolledCourse?.ProgressPct === 100 ? "complete" : ""}
          />
        )}
      </div>
      <div className="courseDetails">
        <p className="mt-4 course-title">{cc?.title}</p>
        <p className="mt-2 course-skillpoints">
          <b>Skill Points:</b>{" "}
          {enrolledCourse ? (
            <>
              {!enrolledCourse?.Completed &&
                `${enrolledCourse?.ProgressPoint || "0"} / `}
              {enrolledCourse?.course?.SkillPoint}
            </>
          ) : (
            <>{cc?.SkillPoint}</>
          )}
        </p>
        <small className="mt-2 course-description">
          <OverlayTrigger
            trigger="click"
            placement="left"
            rootClose
            overlay={
              <Card className="course-details-card">
                <Card.Img
                  variant="top"
                  src={cc?.thumbnail?.url || cc?.ThumbnailUrl}
                  className="course-details-img"
                />
                <div className="play-icon-container">
                  <AiFillPlayCircle
                    color="white"
                    className="play-icon mt-n3 ml-n3"
                    size={50}
                  />
                </div>
                <div
                  className="close-container"
                  onClick={() => document.body.click()}
                >
                  <FaWindowClose />
                </div>
                {cc?.Duration && (
                  <div className="duration-container p-2">
                    {convertToHrsMins(cc?.Duration, true)}
                  </div>
                )}
                <Card.Body className="p-4">
                  <Card.Title>{cc?.title}</Card.Title>
                  <Card.Text>
                    {/*   eslint-disable-next-line */}
                    <p className="mt-2 learning-path-text">{lp.name}</p>
                    <p style={{ fontSize: "1.6rem" }}>
                      <div className="details-row mt-2">
                        {cc?.DifficultyLevel && (
                          <span> {cc?.DifficultyLevel}</span>
                        )}
                        {cc?.Duration && (
                          <span>{convertToHrsMins(cc?.Duration)}</span>
                        )}
                        {cc?.SkillPoint && (
                          <span> Skill Points: {cc?.SkillPoint}</span>
                        )}
                      </div>
                    </p>

                    <div className="mt-2 description-container">
                      <p className="" style={{ fontSize: "1.4rem" }}>
                        {cc?.description}
                      </p>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            }
          >
            {/*   eslint-disable-next-line */}
            <p
              className="view-details-text"
              onClick={e => {
                e.stopPropagation();
              }}
            >
              View Details
            </p>
          </OverlayTrigger>
        </small>
      </div>
    </div>
  );
}
