import React, { useContext, useEffect } from "react";
import { Button } from "react-bootstrap";
import { trackUserAction } from "../../services/dataService";
import { DashboardsContext } from "./DashboardsContext";
import "./index.scss";

function CategoriesRow() {
  const {
    domainsContext,
    categoryContext,
    dashboardsContext,
    subLevelContext
  } = useContext(DashboardsContext);
  const [, , activeDomain] = domainsContext;
  const [
    ,
    ,
    activeCategory,
    setActiveCategory,
    filteredCategories
  ] = categoryContext;

  const [
    allSubLevels,
    setAllSubLevels,
    activeSubLevel,
    setActiveSubLevel,
    filteredSubLevels,
    setFilteredSubLevels
  ] = subLevelContext;
  useEffect(() => {
    const actSublevel = JSON.parse(sessionStorage.getItem("activeSublevel"));
    if (actSublevel) {
      setActiveSubLevel(actSublevel);
    } else {
      setActiveSubLevel(
        allSubLevels.find(i => i.parentid === activeCategory?.id)
      );
    }
  }, [activeCategory]);
  return (
    <>
      {!!filteredCategories.length && activeDomain?.layout !== "ZShape" && (
        <div className="pl-4 px-2 py-3 mb-3 categories-container">
          <div className="d-flex ctg-btn">
            {filteredCategories.length > 0 &&
              filteredCategories.map((c, i) => (
                <div key={i}>
                  <Button
                    className={`btn btn-primary mr-2 ${
                      activeCategory?.id === c?.id
                        ? " category-btn-active"
                        : " category-btn"
                    } ${!i && "ml-2"}`}
                    onClick={event => {
                      setActiveCategory(c);
                      sessionStorage.setItem(
                        "activeCategory",
                        JSON.stringify(c)
                      );
                      sessionStorage.setItem("activeSublevel", null);
                      trackUserAction({
                        action: "search",
                        targetType: "executive dashboards",
                        targetName: c.name,
                        targetPath: `/executive-dashboards`,
                        targetID: c.id,
                        status: "Success",
                        errorDetails: ""
                      });
                    }}
                  >
                    {c.name}
                  </Button>
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
}

export default CategoriesRow;
