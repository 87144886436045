import React, { useEffect, useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import BaseCard from "../../components/BaseCard";
import Spinner from "../../components/Spinner";
import { useAlertsContext } from "../../context/alerts";
import { addBannerConfig, getBannerConfig } from "../../services/dataService";
import { AbilityContext, PermissionsContext } from "./AbilityContext";
import defineRulesFor from "./permissions";
import { Can } from "./AbilityContext";
import "./Events.scss";

function BannerManagement(props) {
  const [itemForEdit, setItemForEdit] = useState(null);
  const { addSuccessAlert, addFailAlert } = useAlertsContext();
  const { register, handleSubmit, errors, reset } = useForm();
  const [loading, setLoading] = useState(true);

  const ability = useContext(AbilityContext);
  const { permissionsCtxt } = useContext(PermissionsContext);
  const [permissions, , isAdmin] = permissionsCtxt;

  useEffect(() => {
    const rules = defineRulesFor(permissions, isAdmin);
    ability.update(rules);
  }, [permissions.length]);

  const getBannerConfigData = async () => {
    const { data } = await getBannerConfig();
    reset({
      ...data[0]
    });
    setItemForEdit(data[0]);
    setLoading(false);
  };
  useEffect(() => {
    getBannerConfigData();
    // eslint-disable-next-line
  }, []);

  const onSubmit = async data => {
    try {
      await addBannerConfig({
        ...itemForEdit,
        ...data,
        TotalSeats: +data.TotalSeats
      });
      addSuccessAlert(
        `Banner Configuration ${!!itemForEdit ? "updated" : "created"}`
      );
    } catch (err) {
      addFailAlert("Something went wrong");
    }
  };

  return (
    <div className="Events">
      {loading ? (
        <Spinner>Fetching Banner Configuration</Spinner>
      ) : (
        <BaseCard className=" p-4">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group as={Row} controlId="banner-description">
              <Form.Label column sm="1">
                Description*
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  as="textarea"
                  name="Description"
                  ref={register({
                    required: true,
                    validate: val => val.trim() !== ""
                  })}
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.Description}
                  maxLength={250}
                />
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="banner-title">
              <Form.Label column sm="1">
                Incentive 1*
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  name="Incentive1"
                  ref={register({
                    required: true,
                    validate: val => val.trim() !== ""
                  })}
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.Incentive1}
                  maxLength={250}
                />
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="banner-title">
              <Form.Label column sm="1">
                Incentive 2*
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  name="Incentive2"
                  ref={register({
                    required: true,
                    validate: val => val.trim() !== ""
                  })}
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.Incentive2}
                  maxLength={250}
                />
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="banner-title">
              <Form.Label column sm="1">
                Incentive 3*
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  name="Incentive3"
                  ref={register({
                    required: true,
                    validate: val => val.trim() !== ""
                  })}
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.Incentive3}
                  maxLength={250}
                />
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="banner-title">
              <Form.Label column sm="1">
                Seats Title*
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  name="SeatsTitle"
                  ref={register({
                    required: true,
                    validate: val => val.trim() !== ""
                  })}
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.SeatsTitle}
                  maxLength={250}
                />
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="banner-title">
              <Form.Label column sm="1">
                Seats Sub-Title*
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  name="SeatsSubTitle"
                  ref={register({
                    required: false
                    // validate: val => val.trim() !== ""
                  })}
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.SeatsSubTitle}
                  maxLength={250}
                />
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="banner-title">
              <Form.Label column sm="1">
                Total Seats*
              </Form.Label>
              <Col sm="11">
                <Form.Control
                  name="TotalSeats"
                  ref={register({
                    required: true,
                    validate: val => val.trim() !== ""
                  })}
                  type="number"
                  placeholder=""
                  isInvalid={!!errors.TotalSeats}
                  max={999}
                />
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Modal.Footer>
              <Can I="Write" a="Data University" passThrough>
                {allowed => (
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={!allowed}
                    style={{ cursor: !allowed ? "not-allowed" : "pointer" }}
                  >
                    Submit
                  </Button>
                )}
              </Can>
            </Modal.Footer>
          </Form>
        </BaseCard>
      )}
    </div>
  );
}

export default BannerManagement;
