import React from "react";
import { useHistory } from "react-router-dom";

import IMAGE from "./landing-image.jpeg";
import BasePage from "../../components/layouts/BasePage";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BaseCard from "../../components/BaseCard";
import IconCard from "../../components/IconCard";

import classnames from "classnames";

import "./MenuPageTemplate.scss";

function MenuPageTemplate({ menuItems = [], mainText, subText }) {
  const history = useHistory();

  return (
    <BasePage className="MenuPageTemplate">
      <div className="p-3">
        <BaseCard>
          <section className="section-banner">
            <img src={IMAGE} alt="cover" />
            <div className="text-center banner-text">
              <div className="header-text">{mainText}</div>
              <div className="subtext">{subText}</div>
            </div>
          </section>

          <section
            className={classnames(
              "section-middle",
              `length-${menuItems.length}`
            )}
          >
            <Container fluid>
              <Row
                className={classnames({ "px-closer": menuItems.length <= 2 })}
              >
                {menuItems.map(item => (
                  <Col key={item.title} sm={12 / menuItems.length}>
                    <IconCard
                      onClick={() => {
                        if (item.linkUrl) {
                          history.push(item.linkUrl);
                        }
                        if (item.href) {
                          window.open(item.href, "_blank");
                        }
                      }}
                      className={classnames("mb-5 px-3 m-auto", {
                        "grow pointer": !item.disabled
                      })}
                      variant={item.disabled ? "disabled" : "secondary"}
                      badgeText="Coming Soon"
                      shadow
                      disabled={item.disabled}
                      title={item.title}
                      secondaryTitle={item.secondaryTitle}
                      subtitle={item.subtitle}
                      image={<img src={item.imageUrl} alt={item.title} />}
                    ></IconCard>
                  </Col>
                ))}
              </Row>
            </Container>
          </section>
        </BaseCard>
      </div>
    </BasePage>
  );
}

export default MenuPageTemplate;
