import React, { useState } from "react";
import { Tab, Tabs } from "../../components/Tabs";
import "./admin-styles.scss";
import Groups from "./Groups";
import GroupsCustom from "./GroupsCustom";

function GroupManagement(props) {
  const [activeTab, setActiveTab] = useState("customgroups");

  return (
    <div className="GroupManagement">
      {/* <Tabs
        mountOnEnter
        activeKey={activeTab}
        onSelect={k => setActiveTab(k)}
        className="p-0"
      > */}
      {/* <Tab eventKey="groups" title={"Groups"} className="p-4">
          <Groups />
        </Tab> */}
      {/* <Tab eventKey="customgroups" title={"Groups"} className="p-4"> */}
      <GroupsCustom />
      {/* </Tab> */}
      {/* </Tabs> */}
    </div>
  );
}

export default GroupManagement;
