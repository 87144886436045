import React from "react";
import { APP_VARS } from "../../services/appVars";

import MenuPageTemplate from "../../components/MenuPageTemplate";

function PlaygroundLanding(props) {
  const menuItems = [
    {
      title: "Ask Data",
      secondaryTitle: "Powered by Tableau",
      subtitle:
        "Interact with your data by typing a question and getting a response right in Tableau. See Ask Data in action using Call Miner and Call Center metrics. ",
      imageUrl:
        APP_VARS.REACT_APP_ASSETS_URL +
        "/images/reporting-icons/Tableau Ask Data.svg",
      linkUrl: "/tableauaskdata"
    },
    {
      title: "Tableau Explain Data",
      secondaryTitle: "Powered by Tableau",
      disabled: true,
      subtitle:
        'Get explanations for the "why" behind a data point with a single click.',
      imageUrl:
        APP_VARS.REACT_APP_ASSETS_URL +
        "/images/reporting-icons/Tableau Explain Data.png",
      linkUrl: ""
    }
  ];
  return (
    <MenuPageTemplate
      menuItems={menuItems}
      mainText="Playground"
      subText="Explore and play with enterprise data and try out new industry leading analytics tools and features"
    />
  );
}

export default PlaygroundLanding;
