import React, { useState, useContext, useEffect } from "react";
import { Tab, Tabs } from "../../components/Tabs";
import BasePage from "../../components/layouts/BasePage";
import ContentManagement from "./ContentManagement";
import CategoryManagement from "./CategoryManagement";
import BrandManagement from "./BrandManagement";
import { PermissionsContext } from "./AbilityContext";
import "./DashboardManagement.scss";

function DashboardManagement(props) {
  const [activeTab, setActiveTab] = useState("Category Management");
  const { permissionsCtxt } = useContext(PermissionsContext);
  const [permissions, , isAdmin] = permissionsCtxt;

  const showCategoryManagementTab = isAdmin
    ? true
    : !!permissions.find(el => el.name === "Category Management");
  const showContentManagementTab = isAdmin
    ? true
    : !!permissions.find(el => el.name === "Content Management");
  const showBrandManagementTab = isAdmin
    ? true
    : !!permissions.find(el => el.name === "Brand Management");

  useEffect(() => {
    const tabs = permissions.filter(el =>
      [
        "Category Management",
        "Content Management",
        "Brand Management"
      ].includes(el.name)
    );
    if (!isAdmin && tabs.length > 0) setActiveTab(tabs[0].name);
  }, [permissions, permissions.length]);

  return (
    <BasePage className="DashboardManagement">
      <Tabs
        mountOnEnter
        activeKey={activeTab}
        onSelect={k => setActiveTab(k)}
        className="p-0"
      >
        {showCategoryManagementTab && (
          <Tab eventKey="Category Management" title="Categories">
            <section className="p-1 mt-3">
              <CategoryManagement />
            </section>
          </Tab>
        )}
        {showContentManagementTab && (
          <Tab eventKey="Content Management" title="Content">
            <section className="p-1 mt-3">
              <ContentManagement />
            </section>
          </Tab>
        )}
        {showBrandManagementTab && (
          <Tab eventKey="Brand Management" title="Brands">
            <section className="p-1 mt-3">
              <BrandManagement />
            </section>
          </Tab>
        )}
      </Tabs>
    </BasePage>
  );
}

export default DashboardManagement;
