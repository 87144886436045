import React, { useEffect, useState, useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useRouteMatch, Switch, Redirect, Route } from "react-router-dom";

import BasePage from "../../components/layouts/BasePage";
import DataOperations from "./DataOperations";
import NavLinkList from "../../components/NavLinkList/NavLinkList";
import Testimonials from "./Testimonials";
import Events from "./Events";
import Alerts from "./Alerts";

import GroupManagement from "./GroupManagement";
import RolesManagement from "./RolesManagement";
import DashboardManagement from "./DashboardManagement";
import SchedulesManagement from "./SchedulesManagement";
import AppVariables from "./AppVariables";
import { APP_VARS } from "../../services/appVars";
import "./admin-styles.scss";
import DataUniversityManagement from "./DataUniversityManagement";
import { fetchPermissions } from "../../services/dataService";
import { PermissionsContext } from "./AbilityContext";
import isEmpty from "lodash/isEmpty";
import UserManagementCust from "./UserManagementCust";
import { sortByName } from "../../services/utils";

const eventsImg = "/images/EVENTS.png";
const testimonialsImg = "/images/TESTIMONIALS.png";
const usersImg = "/images/USERS.png";
const dataOperationsImg = "/images/Icons_SXM/Data Catalog.png";

function AdminShell() {
  const { permissionsCtxt } = useContext(PermissionsContext);
  const [permissions, setPermissions, isAdmin] = permissionsCtxt;
  const [loading, setLoading] = useState(true);
  // const rules = defineRulesFor("permissions");
  // ability.update(rules);
  const { path } = useRouteMatch();

  const adminDataFixed = [
    {
      title: "Events",
      imageUrl: APP_VARS.REACT_APP_ASSETS_URL + eventsImg,
      linkUrl: "/admin/events"
    },
    {
      title: "Testimonials",
      imageUrl: APP_VARS.REACT_APP_ASSETS_URL + testimonialsImg,
      linkUrl: "/admin/testimonials"
    },
    {
      title: "Group Management",
      imageUrl: APP_VARS.REACT_APP_ASSETS_URL + usersImg,
      linkUrl: "/admin/groups"
    },
    {
      title: "User Management",
      imageUrl: APP_VARS.REACT_APP_ASSETS_URL + usersImg,
      linkUrl: "/admin/users"
    },
    {
      title: "Roles Management",
      imageUrl: APP_VARS.REACT_APP_ASSETS_URL + usersImg,
      linkUrl: "/admin/roles"
    },
    {
      title: "Category Management",
      imageUrl: APP_VARS.REACT_APP_ASSETS_URL + eventsImg,
      linkUrl: "/admin/dashboards"
    },
    {
      title: "Schedules Management",
      imageUrl: APP_VARS.REACT_APP_ASSETS_URL + usersImg,
      linkUrl: "/admin/schedules"
    },
    {
      title: "Alerts",
      imageUrl: APP_VARS.REACT_APP_ASSETS_URL + eventsImg,
      linkUrl: "/admin/alerts"
    },
    {
      title: "Data University",
      imageUrl: APP_VARS.REACT_APP_ASSETS_URL + eventsImg,
      linkUrl: "/admin/data-university"
    },
    {
      title: "Data Catalog",
      subtitle:
        "Setup data assets for enabling search, access and discover of data assets throughout the Enterprise",
      imageUrl: APP_VARS.REACT_APP_ASSETS_URL + dataOperationsImg,
      variant: "primary",
      linkUrl: "/admin/dataoperations"
    },
    {
      title: "Configuration",
      imageUrl: APP_VARS.REACT_APP_ASSETS_URL + dataOperationsImg,
      linkUrl: "/admin/app-variables"
    }
  ];

  useEffect(() => {
    fetchPermissions()
      .then(result => {
        if (!isEmpty(result.data?.admin)) setPermissions(result.data.admin);
        setLoading(false);
      })
      .catch();
  }, []);
  const Routes = [
    { name: "Events", route: `${path}/events`, component: <Events /> },
    {
      name: "Testimonials",
      route: `${path}/testimonials`,
      component: <Testimonials />
    },
    {
      name: "Group Management",
      route: `${path}/groups`,
      component: <GroupManagement />
    },
    {
      name: "User Management",
      route: `${path}/users`,
      component: <UserManagementCust />
    },
    {
      name: "Roles Management",
      route: `${path}/roles`,
      component: <RolesManagement />
    },
    {
      name: "Category Management",
      route: `${path}/dashboards`,
      component: <DashboardManagement />
    },
    {
      name: "Schedules Management",
      route: `${path}/schedules`,
      component: <SchedulesManagement />
    },
    {
      name: "Alerts",
      route: `${path}/alerts`,
      component: <Alerts />
    },
    {
      name: "Data University",
      route: `${path}/data-university`,
      component: <DataUniversityManagement />
    },
    {
      name: "Data Catalog",
      route: `${path}/dataoperations`,
      component: <DataOperations />
    },
    {
      name: "Configuration",
      route: `${path}/app-variables`,
      component: <AppVariables />
    }
  ];

  const categoryLink = permissions?.find(el =>
    ["Category Management", "Content Management", "Brand Management"].includes(
      el.name
    )
  );

  const adminData = adminDataFixed.filter(
    el => el?.title === permissions?.find(elm => elm.name === el.title)?.name
  );
  if (
    !!permissions?.length &&
    categoryLink &&
    !adminData.find(el => el.title === "Category Management")
  ) {
    adminData.push(adminDataFixed[5]);
  }

  let permittedRoutes = !!permissions.length
    ? Routes.filter(
        el => el?.name === permissions.find(elm => elm.name === el.name)?.name
      )
    : [];
  let unPermittedRoutes = !!permissions.length
    ? Routes.filter(
        el => el?.name !== permissions.find(elm => elm.name === el.name)?.name
      )
    : [];

  if (
    !!permissions.length &&
    categoryLink &&
    !permittedRoutes.find(el => el.name === "Category Management")
  ) {
    permittedRoutes.push(Routes[5]);
    unPermittedRoutes = unPermittedRoutes.filter(
      el => el.name !== "Category Management"
    );
  }
  if (isAdmin) {
    permittedRoutes = [...Routes];
    unPermittedRoutes = [];
  }

  return (
    <BasePage>
      <Container fluid>
        <Row>
          <Col md={4} lg={3} xl={2} sm={12} className="p-0">
            <NavLinkList
              headerText="Settings"
              Dashboard
              Management
              links={
                isAdmin
                  ? sortByName(adminDataFixed, "title")
                  : sortByName(adminData, "title")
              }
            />
          </Col>
          <Col md={8} lg={9} xl={10} sm={12} className="p-4">
            <Switch>
              {/* If landing on "/admin" then redirect to "/admin/events" */}
              {permittedRoutes.slice(0, 1).map(el => (
                <Redirect exact path={path} to={el.route} />
              ))}
              {permittedRoutes.map(el => (
                <Route exact path={el.route} key={el.name}>
                  {el.component}
                </Route>
              ))}
              {unPermittedRoutes.map(el => (
                <Redirect exact path={el.route} to="/" />
              ))}
              {/* <Route path={`*`} to={<Home />} /> */}
              {!loading && !permittedRoutes.length && (
                <Redirect path="/admin" to="/" />
              )}
              {/* <Redirect exact path={path} to={`${path}/events`} /> */}
            </Switch>
          </Col>
        </Row>
      </Container>
    </BasePage>
  );
}

export default AdminShell;
