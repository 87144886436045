import React from "react";
import { Col, Row } from "react-bootstrap";

import "./FooterSupportCard.scss";

export default function FooterSupportCard() {
  return (
    <div className="p-4 footer-cards">
      <h1 className="font-weight-bold">Contact Us</h1>
      <Row>
        <Col sm={5}>
          Slack:{" "}
          <a
            href="slack://channel?team=T024FQT4T&id=C0115V44FH9"
            className="text-white"
          >
            #marketplace-prod-support
          </a>
        </Col>
        <Col sm={7}>
          <a
            href="mailto:insightsmarketplace@siriusxm.com"
            className="text-white"
          >
            Email: insightsmarketplace@siriusxm.com
          </a>
        </Col>
      </Row>
    </div>
  );
}
