import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import BaseCard from "../../components/BaseCard";
import BasePage from "../../components/layouts/BasePage";
import Spinner from "../../components/Spinner";
import { activateDUUser } from "../../services/dataService";

export default function ActivateDU() {
  const history = useHistory();
  useEffect(() => {
    activateDUUser().then(() => history.push("/data-university/home"));
  }, [history]);
  return (
    <BasePage className="p-5">
      <BaseCard>
        <Container fluid className="p-5">
          <Row>
            <Col sm={12} className="text-center">
              <Spinner>ACTIVATING</Spinner>
            </Col>
          </Row>
        </Container>
      </BaseCard>
    </BasePage>
  );
}
