import React, { useEffect, useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlinePlus,
  AiOutlineUsergroupAdd
} from "react-icons/ai";
import Select from "react-select";
import AccentButton from "../../components/AccentButton";
import Grid from "../../components/Grid";
import ModalCard from "../../components/ModalCard";
import Spinner from "../../components/Spinner";
import { useAlertsContext } from "../../context/alerts";
import {
  addCustomGroup,
  deleteCustomGroup,
  fetchCustomGroups,
  fetchCustomUsers,
  trackUserAction,
  updateCustomGroup
} from "../../services/dataService";
import { AbilityContext, PermissionsContext } from "./AbilityContext";
import { Can } from "./AbilityContext";
import defineRulesFor from "./permissions";

function GroupsCustom(props) {
  const [showUsers, setShowUsers] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState({});
  const [customGroups, setCustomGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [itemForEdit, setItemForEdit] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [delId, setDelId] = useState(null);
  const { register, handleSubmit, reset, errors } = useForm();
  const { addSuccessAlert, addFailAlert } = useAlertsContext();
  const [warningFlag, setWarningFlag] = useState(false);

  const ability = useContext(AbilityContext);
  const { permissionsCtxt } = useContext(PermissionsContext);
  const [permissions, , isAdmin] = permissionsCtxt;

  useEffect(() => {
    const rules = defineRulesFor(permissions, isAdmin);
    ability.update(rules);
  }, [permissions.length]);

  useEffect(() => {
    fetchCustomGroups()
      .then(result => {
        setCustomGroups(result.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });

    fetchCustomUsers().then(res => {
      setUsers(
        res.data
          .filter(user => user.status === "A" && user.type !== "E")
          .map(u => {
            return {
              ...u,
              value: u.id,
              label: `${u.name} (${u.ntid})`
            };
          })
      );
    });
  }, []);

  const handleCloseUsersModalCustom = () => {
    setSelectedGroup(null);
    setShowUsers(false);
  };

  const handleViewUsersCustom = group => {
    setSelectedGroup(group);
    setShowUsers(true);
  };

  const handleCloseModal = () => {
    setItemForEdit(null);
    reset({});
    setShow(false);
    setSelectedUsers([]);
    setWarningFlag(false);
  };

  const handleEditClick = group => {
    setItemForEdit(group);
    reset(group);
    fetchCustomGroups()
      .then(result => {
        setShow(true);
        setCustomGroups(result.data);
        setSelectedUsers(
          result.data
            .find(el => el?.id === group?.id)
            ?.users.map(e => ({ value: e.id, label: e.name }))
        );
      })
      .catch(() => {});
  };

  const onSubmit = async data => {
    const itemID = itemForEdit ? itemForEdit.id : null;
    const isEdit = !!itemID;
    if (!selectedUsers?.length) {
      setWarningFlag(true);
      return;
    }

    try {
      if (isEdit) {
        const {
          data: [response]
        } = await updateCustomGroup({
          id: itemForEdit.id,
          name: data.name,
          description: data.desc,
          users: selectedUsers.map(el => el.value).join(",")
        });

        setCustomGroups(prev =>
          prev.map(el => {
            if (el.id === itemForEdit.id) return response;
            else return el;
          })
        );
      } else {
        const {
          data: [response]
        } = await addCustomGroup({
          name: data.name,
          description: data.desc,
          users: selectedUsers.map(el => el.value).join(",")
        });
        setCustomGroups(prev => [...prev, response]);
      }
      handleCloseModal();
      addSuccessAlert(`Custom Group ${isEdit ? "updated" : "created"}`);
      await trackUserAction({
        action: isEdit ? "Edit" : "Create",
        targetType: "Admin",
        targetName: "Custom Groups",
        targetPath: "/admin/groups",
        targetID: "",
        status: "Success",
        errorDetails: ""
      });
    } catch (err) {
      handleCloseModal();
      addFailAlert("Something went wrong");
    }
  };

  const handleDeleteClick = async () => {
    try {
      const response = await deleteCustomGroup(delId);
      if (response) {
        setCustomGroups(prev => prev.filter(el => el.id !== delId));
      }
      setDelId(null);
      addSuccessAlert(`Custom Group deleted`);
      await trackUserAction({
        action: "Delete",
        targetType: "Admin",
        targetName: "Custom Groups",
        targetPath: "/admin/groups",
        targetID: "",
        status: "Success",
        errorDetails: ""
      });
    } catch {
      addFailAlert("Something went wrong");
    }
  };

  return (
    <>
      <Can I="Write" a="Group Management">
        {() => (
          <Button
            className="mb-3"
            onClick={() => setShow(true)}
            disabled={loading}
          >
            <AiOutlinePlus /> New Group
          </Button>
        )}
      </Can>
      <Modal className="admin-modal" show={show} onHide={handleCloseModal}>
        <Form onSubmit={handleSubmit(onSubmit)} className="p-3">
          <Modal.Header closeButton>
            <Modal.Title>
              {!!itemForEdit ? "Edit" : "Create"} Custom Group
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="px-3">
              <Form.Group as={Row} controlId="custromGroups-Name">
                <Form.Label column sm="4">
                  Group Name*
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    name="name"
                    ref={register({
                      required: true,
                      validate: val => val.trim() !== ""
                    })}
                    type="text"
                    placeholder=""
                    maxLength={250}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {!!errors.name && "Required"}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="custromGroups-Desc">
                <Form.Label column sm="4">
                  Description
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    name="desc"
                    ref={register({})}
                    type="text"
                    placeholder=""
                    maxLength={1000}
                  />
                </Col>
              </Form.Group>
            </div>

            <div className="px-3 pb-3">
              <span>Users*</span>
              <Select
                isMulti
                value={selectedUsers}
                closeMenuOnSelect={false}
                name="users"
                options={users}
                onChange={selectedUsers => {
                  setWarningFlag(false);
                  setSelectedUsers(selectedUsers);
                }}
              />
              {warningFlag && (
                <span
                  className="mt-1"
                  style={{
                    color: "#dc3545",
                    display: "inline-block",
                    fontSize: "80%"
                  }}
                >
                  Select at least one User
                </span>
              )}
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {!!delId && (
        <ModalCard
          body={`Do you still want to delete this Custom Group?`}
          title={`Delete Custom Group Confirmation`}
          show={!!delId}
          noText="Cancel"
          yesText="Ok"
          handleNo={() => setDelId(null)}
          handleYes={() => {
            handleDeleteClick();
            setDelId(null);
          }}
        />
      )}
      {!loading ? (
        <Grid
          width="100%"
          title="Custom Groups"
          showExcel={true}
          suppressRowTransform={true}
          columnDefs={[
            {
              field: "id",
              headerName: "Group ID",
              sortable: true,
              resizable: true
            },
            {
              field: "name",
              headerName: "Name",
              sortable: true,
              resizable: true
            },
            {
              field: "desc",
              headerName: "Description",
              sortable: true,
              resizable: true
            },
            {
              field: "view",
              headerName: "",
              resizable: true,
              cellRendererFramework: ({ data, value }) => {
                return (
                  <>
                    <AccentButton onClick={() => handleViewUsersCustom(data)}>
                      <AiOutlineUsergroupAdd />
                      View Users
                    </AccentButton>
                  </>
                );
              }
            },
            {
              field: "view",
              headerName: "",
              resizable: true,
              cellRendererFramework: ({ data, value }) => {
                return (
                  <>
                    <Can I="Write" a="Group Management">
                      {() => (
                        <AccentButton onClick={() => handleEditClick(data)}>
                          <AiOutlineEdit /> Edit
                        </AccentButton>
                      )}
                    </Can>
                  </>
                );
              }
            },
            {
              field: "view",
              headerName: "",
              resizable: true,
              cellRendererFramework: ({ data, value }) => {
                return (
                  <>
                    <Can I="Write" a="Group Management">
                      {() => (
                        <AccentButton onClick={() => setDelId(data.id)}>
                          <AiOutlineDelete /> Delete
                        </AccentButton>
                      )}
                    </Can>
                  </>
                );
              }
            }
          ]}
          rowData={customGroups}
        />
      ) : (
        <Spinner>Loading Custom Groups</Spinner>
      )}
      <Modal
        className="admin-modal"
        show={showUsers}
        onHide={handleCloseUsersModalCustom}
      >
        <Modal.Header closeButton>
          <Modal.Title>Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Grid
            width="100%"
            height="375px"
            animateRows
            showExcel={true}
            className="p-3"
            columnDefs={[
              {
                field: "id",
                headerName: "User Id",
                sortable: true,
                resizable: true
              },
              {
                field: "name",
                headerName: "Name",
                sortable: true,
                resizable: true
              },
              {
                field: "dept",
                headerName: "Department",
                sortable: true,
                resizable: true
              },
              {
                field: "ntid",
                headerName: "User NTID",
                sortable: true,
                resizable: true
              }
            ]}
            rowData={
              customGroups.find(el => el?.id === selectedGroup?.id)
                ? customGroups?.find(el => el?.id === selectedGroup?.id).users
                : []
            }
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default GroupsCustom;
