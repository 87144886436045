import React, { useState, useEffect } from "react";
import { LinkContainer } from "react-router-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import BaseCard from "../../components/BaseCard";
import QuestionAnswerCard from "../../components/QuestionAnswerCard";
import "./FAQ.scss";
import groupBy from "lodash/groupBy";
import { fetchMarketPlaceFAQs } from "../../services/dataService";
import Spinner from "../../components/Spinner";
import BasePage from "../../components/layouts/BasePage";

function FAQ() {
  const [loading, setLoading] = useState(false);
  const [groupedFAQs, setGroupedFAQs] = useState([]);
  useEffect(() => {
    setLoading(true);
    fetchMarketPlaceFAQs().then(res => {
      setGroupedFAQs(groupBy(res.data, "Group"));
      setLoading(false);
    });
  }, []);
  return (
    <>
      {!loading ? (
        <BasePage className="Home p-4">
          <BaseCard className="FAQ px-5">
            <div className="pt-1 pl-2 gray-bottom-border-1">
              <Breadcrumb>
                <LinkContainer to="/">
                  <Breadcrumb.Item as="div">Home</Breadcrumb.Item>
                </LinkContainer>
                <Breadcrumb.Item active>
                  Frequently Asked Questions
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="p-3">
              {Object.keys(groupedFAQs).map(group => (
                <>
                  <h4 className="mt-4 pb-3 text-primary gray-bottom-border-1">
                    {group}
                  </h4>
                  {groupedFAQs[group].map((data, index) => {
                    return (
                      <div key={index}>
                        <QuestionAnswerCard
                          question={data.Question}
                          answer={data.Answer}
                        />
                      </div>
                    );
                  })}
                </>
              ))}
            </div>
          </BaseCard>
        </BasePage>
      ) : (
        <BasePage className="Home p-4">
          <BaseCard className="p-5">
            <Spinner>Loading...</Spinner>
          </BaseCard>
        </BasePage>
      )}
    </>
  );
}

export default FAQ;
