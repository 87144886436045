import { useInterval, useLocalStorage } from "beautiful-react-hooks";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Col from "react-bootstrap/Col";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import Row from "react-bootstrap/Row";
import { BiHelpCircle } from "react-icons/bi";
import { BsFillBellFill } from "react-icons/bs";
import { MdFavorite, MdHistory } from "react-icons/md";
import { LinkContainer } from "react-router-bootstrap";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch
} from "react-router-dom";
import ExecutiveProtectedRoute from "../../components/ExecutiveProtectedRoute";
import Navbar from "../../components/Navigation";
import Footer from "../../components/Navigation/Footer";
import NavLinkList from "../../components/NavLinkList/NavLinkList";
import ProtectedRoute from "../../components/ProtectedRoute";
import SelectSearchForm from "../../components/SelectSearchForm";
import ToastContainer from "../../components/Toast/ToastContainer";
import { useAlertsContext } from "../../context/alerts";
import { useRecentsContext } from "../../context/recents";
import { useUserPreferencesContext } from "../../context/user-preferences";
import { APP_VARS } from "../../services/appVars";
import { launchReportInNewWindow } from "../../services/cognosService";
import {
  fetchAlerts,
  trackUserAction,
  trackUserLogin,
  fetchPermissions,
  fetchUserInfo
} from "../../services/dataService";
import { getUrlWithNoSpace } from "../../services/utils";
import variables from "../../variables.scss";
import AdminShell from "../admin/AdminShell";
import AssetDescriptionPage from "../asset-description/AssetDescriptionPage";
import CommunityLanding from "../community/CommunityLanding";
import DashboardCatalogShell from "../dashboard-catalog/DashboardCatalogShell";
import MyReports from "../dashboard-catalog/MyReports";
import ReportAndDashboardLanding from "../dashboard-catalog/ReportAndDashboardLanding";
import DataCatalogShell from "../data-catalog/DataCatalogShell";
import DataCatalogLanding from "../data-catalog/Landing";
import DataDocumentation from "../data-documentation";
import DataScienceNotebooks from "../data-science-notebooks/DataScienceNotebooks";
import DataUniversityShell from "../data-university/DataUniversityShell";
import DashboardsWrapper from "../executive-dashboards/DashboardsWrapper";
import FAQ from "../faq/FAQ";
import Home, { getFeatureData } from "../home/Home";
import Landing from "../landing/Landing";
import LdaxDashboard from "../ldax/LdaxDashboard";
import Events from "../marketplace-events/Events";
import MetricViewer from "../metric-viewer/MetricViewer";
import PlaygroundLanding from "../playground/PlaygroundLanding";
import TableauAskData from "../playground/TableauAskData";
import ReportViewer from "../report-viewer/ReportViewer";
import DbVisualizer from "../self-service-visualization/DbVisualizer";
import GettingStartedTableau from "../self-service-visualization/GettingStartedTableau";
import JupyterHubViewer from "../self-service-visualization/JupyterHubViewer";
import JupyterNotebooks from "../self-service-visualization/JupyterNotebooks";
import SelfServiceVisualization from "../self-service-visualization/SelfServiceVisualization";
import SmartSearchWrapper from "../smart-search/SmartSearchWrapper";
import Licenses from "../Licenses";
import WhatsNew from "../whats-new";
import isEmpty from "lodash/isEmpty";
import "./App.scss";
// import DataUniversityShellSxm from "../data-university-sxm/DataUniversityShell";
// import VideoPlayer from "../../pages/video-player";

const adminImg = "/images/ADMIN.png";
const ldaxImg = "/images/Land-page-icons/community.png";

const sidebarWidth = "28.4rem";

const ALERTS_POLL_INTERVAL = 10 * 60 * 1000;

export const getAdminLinks = () => [
  {
    title: "About Insights Marketplace",
    disabled: false,
    imageUrl: APP_VARS.REACT_APP_ASSETS_URL + adminImg,
    linkUrl: "/about"
  },
  {
    title: "Frequently Asked Questions",
    disabled: false,
    imageUrl: APP_VARS.REACT_APP_ASSETS_URL + adminImg,
    linkUrl: "/faqs"
  }
];

function App({ userName, isSessionExpired, isExecutiveUser, isAdmin }) {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const {
    alerts,
    activeAlerts,
    syncActiveAlerts,
    addCustomAlert,
    markAsInactive,
    addFailAlert
  } = useAlertsContext();
  const { favorites, loadPreferences } = useUserPreferencesContext();
  const { recents, loadRecents } = useRecentsContext();
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const [popoverContent, setPopoverContent] = useState("");
  const [popoverType, setPopoverType] = useState(null);
  // const [isAdmin, setIsAdmin] = useState(false);

  const [animate, setAnimate] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const location = useLocation();

  const animateFn = useCallback(() => {
    if (activeAlerts.some(aa => aa.Menu === "General" && aa.show === true)) {
      setAnimate(true);
      setTimeout(function() {
        animateFn();
      }, 5000);
    }
  }, [activeAlerts]);

  useEffect(() => {
    animateFn();
  }, [animateFn]);

  useEffect(() => {
    fetchPermissions()
      .then(result => {
        if (!isEmpty(result.data?.admin)) setPermissions(result.data.admin);
      })
      .catch();
  }, []);

  const [globalSearchText, setGlobalSearchText] = useLocalStorage(
    "GLOBAL_SEARCH_TEXT",
    ""
  );
  const [globalSearchType, setGlobalSearchType] = useLocalStorage(
    "GLOBAL_SEARCH_TYPE",
    ""
  );

  const [activeDashboardFilters, setActiveDashboardFilters] = useLocalStorage(
    "DASHBOARD_CATALOG_ACTIVE_FILTERS",
    []
  );

  const [activeCatalogFilters, setActiveCatalogFilters] = useLocalStorage(
    "DATA_CATALOG_ACTIVE_FILTERS",
    []
  );

  const [
    activeSmartSearchFilters,
    setActiveSmartSearchFilters
  ] = useLocalStorage("SMART_SEARCH_ACTIVE_FILTERS", []);

  const [
    activeExecutiveDashboardsFilters,
    setActiveExecutiveDashboardsFilters
  ] = useLocalStorage("EXECUTIVE_DASHBOARDS_ACTIVE_FILTERS", []);

  // Default the search box to the last search text if found in local storage
  const [searchText, setSearchText] = useState(globalSearchText);
  // Default the search box to the last search text if found in local storage
  const [searchType, setSearchType] = useState(globalSearchType || "data");

  const handleClick = (event, type) => {
    setTimeout(() => setShow(true));
    setTarget(event.target);
    setPopoverType(type);
  };

  const renderPopoverContent = useCallback(
    type => {
      if (type === "fav")
        setPopoverContent(
          favorites.length > 0 ? (
            <>
              <h5 className="px-2 py-1 m-0">Favorites</h5>
              {favorites.map(a => (
                <div
                  key={a.dbId}
                  onClick={() => {
                    launchReportInNewWindow(getUrlWithNoSpace(a.name), a.dbId);
                  }}
                  className="d-flex align-items-center p-4 gray-bottom-border-1 pointer"
                >
                  <div>
                    <img
                      style={{ width: "150px" }}
                      src={`${APP_VARS.REACT_APP_ASSETS_URL}/${(
                        a.thumbnail || ""
                      ).replace("assets/", "")}`}
                      alt={a.name}
                    />
                  </div>
                  <div className="pl-2">
                    <h6 className="font-weight-bold ">{a.name}</h6>
                    <span className="text-primary">
                      {a.DomainName || "Digital Product"}
                    </span>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="p-4">
              <strong className="m-auto">No Favorites Selected</strong>
            </div>
          )
        );
      if (type === "recents")
        setPopoverContent(
          recents.length > 0 ? (
            <>
              <h5 className="px-2 py-1 m-0">Recents</h5>
              {recents.map(a => (
                <div
                  key={a.dbId}
                  onClick={() => {
                    launchReportInNewWindow(getUrlWithNoSpace(a.name), a.dbId);
                  }}
                  className="d-flex align-items-center p-4 gray-bottom-border-1 pointer"
                >
                  <div>
                    <img
                      style={{ width: "150px" }}
                      src={`${APP_VARS.REACT_APP_ASSETS_URL}/${(
                        a.thumbnail || ""
                      ).replace("assets/", "")}`}
                      alt={a.name}
                    />
                  </div>
                  <div className="pl-2">
                    <h6 className="font-weight-bold ">{a.name}</h6>
                    <span className="text-primary">
                      {a.DomainName || "Digital Product"}
                    </span>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="p-4">
              <strong className="m-auto">No Recent Activity</strong>
            </div>
          )
        );
      if (type === "alerts")
        setPopoverContent(
          activeAlerts.length > 0 ? (
            <>
              <h5 className="px-2 py-1 m-0">Alerts</h5>
              {activeAlerts.map(a => (
                <div key={a.MapID} className="px-2 py-4 gray-bottom-border-1">
                  <h6>
                    <strong className="mr-auto">{a.title}</strong>
                  </h6>
                  <div
                    className="mb-2"
                    style={{ whiteSpace: "pre-line" }}
                    dangerouslySetInnerHTML={{ __html: a.description }}
                  />
                  <span className="text-primary">{a.Menu}</span>
                </div>
              ))}
            </>
          ) : (
            <div className="p-4">
              <strong className="m-auto">No Active Alerts</strong>
            </div>
          )
        );

      if (type === "help")
        setPopoverContent(
          <>
            <p className="help-heading">Help</p>
            <div className="p-1">
              <Row>
                <Col>
                  <a
                    href="https://sxmwiki.corp.siriusxm.com/display/EDM/Enterprise+Glossary "
                    style={{ color: "black" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="help-box"
                      onClick={() => document.body.click()}
                    >
                      <img
                        src={`${APP_VARS.REACT_APP_ASSETS_URL}/images/Icons-help/Glossary.svg`}
                        alt="Glossary"
                        height="29px"
                      />
                      <p className="help-text">Enterprise Glossary</p>
                    </div>
                  </a>
                </Col>
                <Col>
                  <LinkContainer to="/faqs">
                    <div
                      className="help-box pointer"
                      onClick={() => document.body.click()}
                    >
                      <img
                        src={`${APP_VARS.REACT_APP_ASSETS_URL}/images/Icons-help/FAQs.svg`}
                        alt="FAQs"
                        height="22px"
                      />
                      <p className="help-text">FAQs</p>
                    </div>
                  </LinkContainer>
                </Col>
              </Row>

              <Row>
                <Col>
                  <LinkContainer to="/data-documentation">
                    <div
                      className="help-box pointer"
                      onClick={() => document.body.click()}
                    >
                      <img
                        src={`${APP_VARS.REACT_APP_ASSETS_URL}/images/Icons-help/DataDoc.svg`}
                        alt="FAQs"
                        height="25px"
                      />
                      <p className="help-text">Data Documentation</p>
                    </div>
                  </LinkContainer>
                </Col>
                <Col>
                  <LinkContainer to="/whats-new">
                    <div
                      className="help-box pointer"
                      onClick={() => document.body.click()}
                    >
                      <img
                        src={`${APP_VARS.REACT_APP_ASSETS_URL}/images/Icons-help/WhatNew.svg`}
                        alt="FAQs"
                        height="29px"
                      />
                      <p className="help-text">What's New</p>
                    </div>
                  </LinkContainer>
                </Col>
              </Row>
            </div>
          </>
        );
    },
    [activeAlerts, favorites, recents]
  );
  useEffect(() => {
    renderPopoverContent(popoverType);
  }, [renderPopoverContent, popoverType, recents, favorites]);

  const loadAlerts = useCallback(() => {
    fetchAlerts("true").then(res => {
      syncActiveAlerts(res.data);
    });
  }, [syncActiveAlerts]);

  const [, clearInterval] = useInterval(() => {
    loadAlerts();
  }, ALERTS_POLL_INTERVAL);

  useEffect(() => {
    if (isSessionExpired) {
      clearInterval();
    }
  }, [clearInterval, isSessionExpired]);

  const history = useHistory();

  const { path } = useRouteMatch();
  // run once on load
  useEffect(() => {
    loadAlerts();
    loadPreferences();
    loadRecents();
    trackUserLogin({ status: "success" });
  }, [loadPreferences, loadRecents, loadAlerts]);

  const MENUOPTIONS = [
    "Events",
    "Testimonials",
    "User Management",
    "Group Management",
    "Roles Management",
    "Category Management",
    "Schedules Management",
    "Alerts",
    "Data University",
    "Data Catalog",
    "Configuration"
  ];

  const sidebarLinks =
    isAdmin || !!permissions.find(el => MENUOPTIONS.includes(el.name))
      ? [
          ...getFeatureData(),
          {
            title: "Admin",
            disabled: false,
            imageUrl: APP_VARS.REACT_APP_ASSETS_URL + adminImg,
            linkUrl: "/admin"
          },
          {
            title: "LDAX Dashboard",
            disabled: false,
            imageUrl: APP_VARS.REACT_APP_ASSETS_URL + ldaxImg,
            linkUrl: "/ldax-dashboard"
          },
          ...getAdminLinks()
        ]
      : [...getFeatureData(), ...getAdminLinks()];

  return (
    <div>
      <div
        style={{
          marginLeft: sidebarIsOpen ? sidebarWidth : 0,
          transition: "margin-left 0.2s ease"
        }}
      >
        <ToastContainer toasts={alerts} />
        <Navbar className="sx-navbar-body">
          <div className="pointer pl-4">
            <img
              className="pointer pl-4"
              onClick={() => setSidebarIsOpen(i => !i)}
              src={
                APP_VARS.REACT_APP_ASSETS_URL + "/images/Icons/hamburger.png"
              }
              alt="hamburger"
            />
            <img
              onClick={() => {
                history.push("/");
                trackUserAction({
                  action: "View Homepage",
                  targetType: "logo", //"Dashboard",
                  targetName: "Home", //"OutboundSalesAnalysis",
                  targetPath: "", //location.pathname,
                  targetID: "/",
                  status: "Success",
                  errorDetails: ""
                });
                history.push("/");
              }}
              className="pointer px-4"
              style={{ height: "2.7rem" }}
              src={APP_VARS.REACT_APP_ASSETS_URL + "/images/logo.png"}
              alt="SXM Brand Logo"
            />
            {location.pathname.includes("/data-university") && (
              <>
                <span
                  style={{ borderLeft: "1px solid white", padding: "1rem 0" }}
                ></span>
                <img
                  onClick={() => {
                    trackUserAction({
                      action: "View Data University",
                      targetType: "logo", //"Dashboard",
                      targetName: "Data University", //"OutboundSalesAnalysis",
                      targetPath: "", //location.pathname,
                      targetID: "/data-university",
                      status: "Success",
                      errorDetails: ""
                    });
                    history.push("/data-university");
                  }}
                  className="pointer px-4"
                  src={
                    APP_VARS.REACT_APP_ASSETS_URL +
                    "/images/data-university-landing/data-university-logo.svg"
                  }
                  alt="Data University Logo"
                />
                <span
                  style={{
                    color: "#fff",
                    fontSize: "1.3rem",
                    left: "-21px",
                    position: "relative"
                  }}
                >
                  Beta
                </span>
              </>
            )}
          </div>
          <div className="pr-4 d-flex align-items-center" ref={ref}>
            <div className="pr-3">
              <SelectSearchForm
                value={searchText}
                onSubmit={() => {
                  if (!searchText) return;
                  setGlobalSearchText(searchText);
                  setGlobalSearchType("dashboard");
                  // setGlobalActiveFilters([]);
                  searchType === "dashboard"
                    ? setActiveDashboardFilters([])
                    : setActiveCatalogFilters([]);
                  trackUserAction({
                    action: `Search dashboardcatalog`,
                    targetType: "search-box",
                    targetName: searchText,
                    targetPath: `/smartsearch`,
                    targetID: "",
                    status: "Success",
                    errorDetails: ""
                  });
                  history.push(`${path}smartsearch/results`);
                }}
                onChange={e => {
                  e.preventDefault();
                  setSearchText(e.target.value);
                }}
                setSearchType={setSearchType}
                searchType={searchType}
                placeholder="Search"
                maxLength={500}
              />
            </div>

            <div className="pr-3">
              <span className="text-white">Welcome,</span>{" "}
              <span style={{ color: variables.accent2 }} className="pr-2">
                {userName}
              </span>
              <span className="p-2 banner-icon">
                <MdFavorite
                  className={`font-medium pointer`}
                  onClick={e => handleClick(e, "fav")}
                />
              </span>
              <span className="p-2 banner-icon">
                <MdHistory
                  className={`font-medium pointer`}
                  onClick={e => handleClick(e, "recents")}
                />
              </span>
              <span className="p-2 banner-icon">
                {activeAlerts.length ? (
                  <img
                    src={
                      APP_VARS.REACT_APP_ASSETS_URL + "/images/alert active.png"
                    }
                    alt="activeAlerts"
                    width="20px"
                    className={`font-medium pointer ${
                      animate ? `headShake` : ``
                    }`}
                    onClick={e => {
                      const generalAlerts = activeAlerts.filter(
                        item => item.Menu === "General" && item.show === true
                      );
                      if (!!generalAlerts.length) {
                        generalAlerts.forEach(ga => {
                          addCustomAlert(ga.title, ga.description);
                          markAsInactive(ga.id + ga.Menu);
                        });
                        setAnimate(false);
                        return;
                      }
                      handleClick(e, "alerts");
                    }}
                  />
                ) : (
                  <BsFillBellFill
                    className={`font-medium pointer`}
                    onClick={e => handleClick(e, "alerts")}
                  />
                )}
              </span>
              <span className="p-2 banner-icon">
                <BiHelpCircle
                  className={`font-medium pointer`}
                  onClick={e => handleClick(e, "help")}
                />
              </span>
            </div>

            {/* <AssetManagement /> */}
            <Overlay
              show={show}
              target={target}
              placement="bottom"
              container={ref.current}
              containerPadding={60}
              rootClose={true}
              onHide={() => setShow(false)}
            >
              <Popover id="popover-contained" className="banner-popover">
                <Popover.Content
                  className={
                    (popoverType === "fav" && favorites.length > 3) ||
                    (popoverType === "recents" && recents.length > 3) ||
                    (popoverType === "alerts" && activeAlerts.length > 3)
                      ? "banner-popover-overflow"
                      : ""
                  }
                >
                  {popoverContent}
                </Popover.Content>
              </Popover>
            </Overlay>
          </div>
        </Navbar>
        <Switch>
          <Route path="/dashboardcatalog">
            <DashboardCatalogShell
              globalSearchText={globalSearchText}
              setGlobalSearchText={setGlobalSearchText}
              activeFilters={activeDashboardFilters}
              setActiveFilters={setActiveDashboardFilters}
              isAdmin={isAdmin}
            />
          </Route>

          <Route path="/myreports">
            <MyReports />
          </Route>
          {/* <Route path="/video-player">
            <VideoPlayer />
          </Route> */}
          <Route path="/licenses">
            <Licenses />
          </Route>
          <Route path="/whats-new">
            <WhatsNew />
          </Route>
          <Route path="/datacatalog">
            <DataCatalogShell
              globalSearchText={globalSearchText}
              setGlobalSearchText={setGlobalSearchText}
              activeFilters={activeCatalogFilters}
              setActiveFilters={setActiveCatalogFilters}
            />
          </Route>

          <ExecutiveProtectedRoute path="/executive-dashboards">
            <DashboardsWrapper
              globalSearchText={globalSearchText}
              setGlobalSearchText={setGlobalSearchText}
              activeFilters={activeExecutiveDashboardsFilters}
              setActiveFilters={setActiveExecutiveDashboardsFilters}
              isAdmin={isAdmin}
            />
          </ExecutiveProtectedRoute>

          <Route path="/smartsearch">
            <SmartSearchWrapper
              globalSearchText={globalSearchText}
              setGlobalSearchText={setGlobalSearchText}
              activeFilters={activeSmartSearchFilters}
              setActiveFilters={setActiveSmartSearchFilters}
            />
          </Route>

          <Route path="/datalanding">
            <DataCatalogLanding />
          </Route>

          <Route path="/data-university">
            <DataUniversityShell />
          </Route>

          <Route path="/playgroundlanding">
            <PlaygroundLanding />
          </Route>

          <Route path="/communitylanding">
            <CommunityLanding />
          </Route>

          <Route path="/tableauaskdata">
            <TableauAskData />
          </Route>

          <Route path="/jupyter-notebooks">
            <JupyterNotebooks />
          </Route>

          <Route exact path="/assetdescription/:assetId">
            <AssetDescriptionPage />
          </Route>

          <Route path="/reportinganddashboard">
            <ReportAndDashboardLanding />
          </Route>

          <Route exact path="/selfservice">
            <SelfServiceVisualization />
          </Route>

          <Route exact path="/gettingstartedwithtableau">
            <GettingStartedTableau />
          </Route>

          <Route exact path="/dbvisualizer">
            <DbVisualizer />
          </Route>

          <Route exact path="/selfservice/aatools">
            <DataScienceNotebooks />
          </Route>

          <Route exact path="/jupyterhub">
            <JupyterHubViewer />
          </Route>

          <Route exact path="/reportviewer/:name/:dbid">
            <ReportViewer setActiveFilters={setActiveDashboardFilters} />
          </Route>

          <Route exact path="/reportviewer/:dbid">
            <ReportViewer setActiveFilters={setActiveDashboardFilters} />
          </Route>

          <Route exact path="/metricviewer/:metricId">
            <MetricViewer />
          </Route>

          <Route exact path="/events">
            <Events />
          </Route>

          <Route exact path="/">
            <Home isExecutiveUser={isExecutiveUser} />
          </Route>

          <Route exact path="/about">
            <Landing />
          </Route>

          <Route exact path="/faqs">
            <FAQ />
          </Route>

          <Route exact path="/data-documentation">
            <DataDocumentation />
          </Route>

          <ProtectedRoute
            path="/admin"
            permissionsUpdated={permissions}
            isAdmin={isAdmin}
          >
            <AdminShell isAdmin={isAdmin} permissionsUpdated={permissions} />
          </ProtectedRoute>

          <Route exact path="/ldax-dashboard">
            <LdaxDashboard />
          </Route>

          {/* To handle old bookmarks */}
          <Route exact path="/app">
            <Redirect to="/" />
          </Route>

          {/* Route Not Found */}
          <Redirect from="*" to="/" />
        </Switch>
      </div>
      <div
        style={{
          width: sidebarWidth,
          height: "100%",
          position: "fixed",
          top: 0,
          left: 0,
          transform: sidebarIsOpen ? "translateX(0)" : "translateX(-100%)",
          transition: "all 0.2s ease"
        }}
      >
        <NavLinkList
          onItemClick={() => setSidebarIsOpen(false)}
          headerText="Main Menu"
          links={sidebarLinks}
        />
      </div>
      <Footer />
    </div>
  );
}

export default App;
