import React, { useState } from "react";
import { CloseButton, Col, Row } from "react-bootstrap";
import { GoAlert } from "react-icons/go";

import "./ToggleDisclaimer.scss";

export default function ToggleDisclaimer() {
  const [show, setShow] = useState(true);

  return (
    <div className="ToggleDisclaimer mx-3 py-2 row d-flex align-items-end flex-column">
      <Row className="bg-light rounded border py-1 flex-direction-row">
        <Col
          sm={1}
          className={`text-center align-self-center ${
            show ? `p-0 disclaimer-icon` : ``
          }`}
        >
          {!show ? (
            <GoAlert
              color="#2aaeeb"
              size={26}
              className="pointer"
              onClick={e => {
                e.stopPropagation();
                setShow(true);
              }}
            />
          ) : (
            <GoAlert color="#2aaeeb" size={26} />
          )}
        </Col>
        {show && (
          <Col sm={11} className="pl-0">
            <p className="m-0" style={{ fontSize: "1rem" }}>
              Information available in the Insight Marketplace is confidential
              and proprietary and for internal use only by SiriusXM, Pandora or
              their affiliates. Please do not share this information with or
              provide access to this information to any third party without
              express authorization.
            </p>
          </Col>
        )}
        {show && (
          <div className="disclaimer-close">
            <CloseButton
              onClick={e => {
                e.stopPropagation();
                setShow(false);
              }}
            ></CloseButton>
          </div>
        )}
      </Row>
    </div>
  );
}
