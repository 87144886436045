import { useState, useEffect, useCallback, useRef } from "react";
import { useThrottledFn } from "beautiful-react-hooks";

const EVENTS = [
  "mousemove",
  "keydown",
  "wheel",
  "DOMMouseScroll",
  "mousewheel",
  "mousedown",
  "touchstart",
  "touchmove",
  "MSPointerDown",
  "MSPointerMove",
  "visibilitychange"
];

const DEFAULT_SESSION_TIME_SECONDS = 60 * 30; // 30 minutes
const DEFAULT_WARNING_TIME_SECONDS = 60 * 1; // one minute
const DEFAULT_THROTTLE_MS = 500; // half a second

function useSessionManager({
  sessionTimeSeconds = DEFAULT_SESSION_TIME_SECONDS,
  warningTimeSeconds = DEFAULT_WARNING_TIME_SECONDS,
  throttleMs = DEFAULT_THROTTLE_MS,
  actionFn = () => {}
}) {
  const [timeLeft, setTimeLeft] = useState(sessionTimeSeconds);
  const [isWarning, setIsWarning] = useState(false);
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  // IMPORTANT
  // Need useRef to keep the same instance of actionFn
  const throttledUserAction = useRef(
    useThrottledFn(actionFn, throttleMs, { leading: true, trailing: false })
  );

  // Initialize an interval for decrementing counter every second
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(t => t - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // Checks the state of `timeLeft` and sets flags accordingly
  useEffect(() => {
    if (timeLeft <= 0) {
      setIsSessionExpired(true);
      return;
    }

    if (timeLeft < warningTimeSeconds) {
      setIsWarning(true);
      return;
    }
  }, [isSessionExpired, timeLeft, warningTimeSeconds]);

  const reset = useCallback(() => {
    setIsWarning(false);
    setTimeLeft(sessionTimeSeconds);
    throttledUserAction.current();
  }, [sessionTimeSeconds]);

  // Event handler for user activity.  Reset timer, and callback function can be executed
  const handleUserAction = useCallback(() => {
    if (!isSessionExpired && !isWarning) {
      setTimeLeft(sessionTimeSeconds);

      // Callback function
      throttledUserAction.current();
    }
  }, [sessionTimeSeconds, isSessionExpired, isWarning]);

  useEffect(() => {
    EVENTS.forEach(e => window.addEventListener(e, handleUserAction));

    return () =>
      EVENTS.forEach(e => window.removeEventListener(e, handleUserAction));
  }, [handleUserAction, throttledUserAction]);

  return {
    timeLeft,
    isWarning,
    isSessionExpired,
    reset
  };
}

export default useSessionManager;
