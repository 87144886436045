import React, { useContext, useEffect } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import { getLearningPaths } from "../../services/dataService";
import Activate from "./Activate";
import DataUniversity from "./DataUniversity";
import {
  DataUniversityContext,
  DataUniversityProvider
} from "./DataUniversityContext";
import ProtectedRoute from "./ProtectedRoute";
import StudentHome from "./StudentHome";
import SxmCourse from "./SxmCourse";

export default function DataUniversityShell() {
  return (
    <div>
      <DataUniversityProvider>
        <DataUniversityWrapper />
      </DataUniversityProvider>
    </div>
  );
}

function DataUniversityWrapper() {
  const { path } = useRouteMatch();
  const { sxmUniversityContext } = useContext(DataUniversityContext);
  const [sxmCourses, setSxmCourses] = sxmUniversityContext;
  useEffect(() => {
    getLearningPaths("?university=SiriusXM").then(res => {
      setSxmCourses(res.data);
    });
  }, []);
  return (
    <Switch>
      {/* If landing on "/data-university" then redirect to "/data-university/home" */}

      <Redirect exact path={path} to={`${path}/home`} />

      <Route exact path={`${path}/register`}>
        <DataUniversity />
      </Route>

      <ProtectedRoute exact path={`${path}/activate`}>
        <Activate />
      </ProtectedRoute>

      <ProtectedRoute exact path={`${path}/home`}>
        <StudentHome />
      </ProtectedRoute>
      <Route exact path={`${path}/sxm-course/:id`}>
        <SxmCourse />
      </Route>

      {/* Route Not Found */}
      <Redirect from="*" to={`${path}/home`} />
    </Switch>
  );
}
