import React from "react";
import NavLinkItem from "./NavLinkItem";

import "./NavLinkList.scss";

function NavLinkList({
  children,
  className = "",
  headerText = "",
  links = [],
  renderItem,
  onItemClick = () => {},
  ...otherProps
}) {
  return (
    <nav className={`sx-nav-link-list ${className}`} {...otherProps}>
      {headerText && (
        <div className="sx-nav-link-list__header-section px-3 py-3 d-flex align-items-end">
          {headerText}
        </div>
      )}
      <div className="sx-nav-link-list__list-container">
        <ul className="sx-nav-link-list__list">
          {links.map((l, index) =>
            renderItem ? (
              renderItem(l)
            ) : (
              <NavLinkItem
                onClick={() => onItemClick(l, index)}
                key={index}
                {...l}
              />
            )
          )}
        </ul>
      </div>
    </nav>
  );
}

export default NavLinkList;
