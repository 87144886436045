import React from "react";
import BasePage from "../../components/layouts/BasePage";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import BaseCard from "../../components/BaseCard";
import { FaLink } from "react-icons/fa";
import VideoPlayer from "../../components/VideoPlayer";
import videos from "./content";

import "./DataScienceNotebooks.scss";
import { APP_VARS } from "../../services/appVars";

const pythonImage = "/images/python.png";
const scalaImage = "/images/scala.png";
const rImage = "/images/r.png";
const javaImage = "/images/java.png";

function DataScienceNotebooks(props) {
  return (
    <BasePage className="DataScienceNotebooks">
      <div className="p-3">
        <BaseCard>
          <div className="d-flex align-items-center py-2 px-3 gray-bottom-border-1">
            <span className="text-primary font-large">
              Advanced Analytic Tools
            </span>
            <span className="vertical-bar"></span>
            <span className="text-primary font-medium">
              Explore data mining or take advantage of advanced analytics
            </span>
          </div>

          <Container fluid>
            <Row>
              <Col sm={12} xl={6}>
                <div className="p-5">
                  <div className="font-weight-bold text-primary font-medium">
                    What are Notebooks?
                  </div>

                  <p className="font-small pt-4 text-secondary">
                    Notebooks are pretty much your old lab book from high school
                    science, but with a Harry Potter twist. It is a web
                    application that allows you to create and share documents
                    that contain live code (supporting 40+ programming
                    languages, including SQL, Python, R, Julia, and Scala),
                    equations, visualizations and narrative text.
                  </p>

                  <p className="font-small pt-2 pb-5 text-secondary">
                    Notebooks can be saved as files, checked into revision
                    control just like code, and freely shared. They run
                    anywhere, thanks to their browser-based user interface.
                  </p>

                  <div className="font-weight-bold text-primary font-medium">
                    Programming Languages
                  </div>

                  <div className="d-flex flex-wrap align-items-center pt-3 pb-5">
                    <img
                      src={APP_VARS.REACT_APP_ASSETS_URL + pythonImage}
                      className="px-4"
                      alt="programming language python"
                    />
                    <span className="vertical-bar"></span>

                    <img
                      src={APP_VARS.REACT_APP_ASSETS_URL + scalaImage}
                      className="px-4"
                      alt="programming language scala"
                    />
                    <span className="vertical-bar"></span>

                    <img
                      src={APP_VARS.REACT_APP_ASSETS_URL + rImage}
                      className="px-4"
                      alt="programming language R"
                    />
                    <span className="vertical-bar"></span>

                    <img
                      style={{ height: 96, marginBottom: 25 }}
                      src={APP_VARS.REACT_APP_ASSETS_URL + javaImage}
                      className="px-4"
                      alt="programming language R"
                    />
                  </div>

                  <div className="font-weight-bold text-primary font-medium">
                    Advanced Analytics Framework
                  </div>

                  <ul className="mb-3">
                    <li className="font-small text-secondary pt-3">
                      Offering a fully managed service that provides developers
                      and data scientist with the ability to build, train, and
                      deploy machine learning (ML) models quickly. 
                    </li>
                    <li className="font-small text-secondary pt-3">
                      Unified IDE supporting all ML development activities
                      including notebooks, experiment management, automatic
                      model creation, debugging and profiling, and model drift
                      detection
                    </li>
                    <li className="font-small text-secondary pt-3">
                      Access to Deep Learning frameworks including
                      (Spark/Python/R/MxNet/Spark/Tensorflow){" "}
                    </li>
                    <li className="font-small text-secondary pt-3">
                      Access to Open Source, and Commercially available
                      Algorithms
                    </li>
                  </ul>

                  <p className="text-secondary font-small mb-5">
                    If you are Interested in taking advantage of these new
                    capabilities
                    <Button
                      style={{ padding: "4px 3px 6px" }}
                      className="font-small font-weight-bold"
                      variant="link"
                      href="slack://channel?team=T024FQT4T&id=C011DFML9AS"
                    >
                      click here
                    </Button>
                    to find out more information
                  </p>

                  <div className="text-primary font-weight-bold font-medium pb-4">
                    Learning Resources
                  </div>

                  <Row>
                    <TableauCard
                      imgUrl="/images/reporting-icons/Data Catalog.png"
                      mainText="SageMaker Developer Guide"
                      subText="AWS SageMaker comprehensive developer guide"
                      href={true}
                      buttonText="Click here"
                      filePath="https://docs.aws.amazon.com/sagemaker/latest/dg/sagemaker-dg.pdf"
                    />

                    <TableauCard
                      imgUrl="/images/reporting-icons/Data Catalog.png"
                      mainText="Notebook Developers Guide"
                      subText="Comprehenisve Notebook developer guide"
                      href={true}
                      buttonText="Click here"
                      filePath="https://readthedocs.org/projects/jupyter-notebook/downloads/pdf/latest/"
                    />
                  </Row>
                </div>
              </Col>
              <Col sm={12} xl={6} className="right-section">
                <section className="resource-links">
                  <VideoPlayer
                    playlistTitle="Training Videos"
                    videoList={videos}
                  />
                </section>
                <section className="resource-tiles">
                  <div className="text-primary font-large pb-2">
                    Data Science Learning Resources
                  </div>
                  <HelpLink
                    text="SageMaker ML Getting Started Guide:"
                    url="https://docs.aws.amazon.com/sagemaker/latest/dg/sagemaker-dg.pdf#gs-studio"
                  />
                  <BottomCap />
                  <HelpLink
                    text="Studio Notebook Getting Started Guide:"
                    url="https://docs.aws.amazon.com/sagemaker/latest/dg/sagemaker-dg.pdf#studio-features"
                  />
                  <BottomCap />
                  <HelpLink
                    text="Amazon Sagemaker Examples:"
                    url="https://github.com/awslabs/amazon-sagemaker-examples"
                  />
                  <BottomCap />
                  <HelpLink
                    text="Explore Analyze and Process Data:"
                    url="https://docs.aws.amazon.com/sagemaker/latest/dg/sagemaker-dg.pdf#how-it-works-notebooks-instances"
                  />
                  {/* <BottomCap />
                  <HelpLink
                    text="Jupyter Notebook Tutorial:"
                    url="https://www.datacamp.com/community/tutorials/tutorial-jupyter-notebook"
                  /> */}
                </section>
              </Col>
            </Row>
          </Container>
        </BaseCard>
      </div>
    </BasePage>
  );
}

function HelpLink({ url, text }) {
  return (
    <div className="d-flex align-items-center py-4">
      <FaLink style={{ fontSize: 26 }} className="color-accent" />
      <div className="font-weight-bold text-primary pl-2">{text}</div>
      <a
        href={url}
        className="color-accent pl-2"
        target="_blank"
        rel="noopener noreferrer"
      >
        Click here
      </a>
    </div>
  );
}

function TableauCard({
  mainText,
  subText,
  filePath,
  buttonText = "Download",
  href
}) {
  const buttonProps = href ? { target: "_blank" } : { download: true };
  return (
    <BaseCard
      style={{ width: "26.2rem" }}
      className="TableauCard sx-hover-shadow grow py-5 px-2 mb-2 mr-4 d-flex align-items-center justify-content-center"
    >
      <div className="text-center">
        <div className="font-weight-bold pb-2">{mainText}</div>
        <div className="font-small pb-3">{subText}</div>
        <Button
          as="a"
          className="download-button"
          href={filePath}
          {...buttonProps}
        >
          {buttonText}
        </Button>
      </div>
    </BaseCard>
  );
}

function BottomCap(props) {
  return (
    <div
      className=""
      style={{
        height: 0,
        borderBottom: "1px solid #707070",
        marginLeft: "3rem",
        width: "40rem",
        opacity: "0.2"
      }}
    ></div>
  );
}

export default DataScienceNotebooks;
