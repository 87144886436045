import React, { useState, useEffect, useContext } from "react";
import { Route } from "react-router-dom";
import BasePage from "../../components/layouts/BasePage";
import BaseCard from "../../components/BaseCard";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { validateDuUser, trackUserAction } from "../../services/dataService";
import DataUniversity from "./DataUniversity";
import { DataUniversityContext, USER_STATUS } from "./DataUniversityContext";
import { useLocation } from "react-router-dom";

function DURegisteredRoute({ children, ...otherProps }) {
  const [registered, setRegistered] = useState(false);
  const [checkComplete, setCheckComplete] = useState(false);
  const { userContext, userStatusContext } = useContext(DataUniversityContext);
  const [, setUserStatus] = userStatusContext;
  const [, setUser] = userContext;

  const location = useLocation();
  // To get current path
  const currentPathName = `${location.pathname}${location.search || ""}`;
  useEffect(() => {
    let urlParts = currentPathName?.split("?")[1]?.split("&");

    let campaignIdFromUrl = urlParts
      ?.find(el => el.includes("campaignid"))
      ?.split("=")[1];

    let courseIdFromUrl = urlParts
      ?.find(el => el.includes("courseid"))
      ?.split("=")[1];

    let categoryIdFromUrl = urlParts
      ?.find(el => el.includes("categoryid"))
      ?.split("=")[1];

    if (!!campaignIdFromUrl) {
      !!courseIdFromUrl &&
        trackUserAction({
          action: "emailclick",
          targetType: "Campaign",
          targetName: "Course",
          targetPath: courseIdFromUrl,
          targetID: campaignIdFromUrl,
          status: "Success",
          errorDetails: ""
        });

      !!categoryIdFromUrl &&
        trackUserAction({
          action: "emailclick",
          targetType: "Campaign",
          targetName: "Category",
          targetPath: categoryIdFromUrl,
          targetID: campaignIdFromUrl,
          status: "Success",
          errorDetails: ""
        });
    }

    console.log(campaignIdFromUrl, courseIdFromUrl, categoryIdFromUrl);

    validateDuUser()
      .then(res => {
        setUser({
          studentName: res.data.StudentName,
          studentId: res.data.id,
          shirtSize: res.data.ShirtSize
        });
        setUserStatus(res.data.Status || USER_STATUS["PENDING"]); // old users are returning blank
        setRegistered(
          [USER_STATUS["APPROVED"], USER_STATUS["ONBOARDED"]].includes(
            res.data.Status
          )
        );
        setCheckComplete(true);
      })
      .catch(() => {
        setUserStatus(USER_STATUS["NEW"]);
        setCheckComplete(true);
      });
  }, [currentPathName, setUser, setUserStatus]);

  return (
    <>
      {!checkComplete ? (
        <BasePage className="p-5">
          <BaseCard>
            <Container fluid className="p-5">
              <Row>
                <Col sm={12} className="text-center">
                  LOADING
                </Col>
              </Row>
            </Container>
          </BaseCard>
        </BasePage>
      ) : (
        <>
          {registered ? (
            <Route {...otherProps}>{children}</Route>
          ) : (
            <DataUniversity />
          )}
        </>
      )}
    </>
  );
}

export default DURegisteredRoute;
