import React, { useEffect, useState, useContext } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState
} from "react-accessible-accordion";
// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import {
  AiOutlineClose,
  AiOutlineEdit,
  AiOutlinePlus,
  AiOutlineSearch
} from "react-icons/ai";
import Select from "react-select";
import AccentButton from "../../components/AccentButton";
import Grid from "../../components/Grid";
import ModalCard from "../../components/ModalCard";
import Spinner from "../../components/Spinner";
import { Tab, Tabs } from "../../components/Tabs";
import { useAlertsContext } from "../../context/alerts";
import {
  addRole,
  deleteRole,
  fetchCustomGroups,
  fetchRolePermissions,
  fetchRoles,
  fetchRoleUsers,
  fetchSecurityPermissions,
  fetchCustomUsers,
  trackUserAction,
  updateRole
} from "../../services/dataService";
import { addUniqueId2, capitalize, sortByName } from "../../services/utils";
import { AbilityContext, PermissionsContext } from "./AbilityContext";
import { Can } from "./AbilityContext";
import defineRulesFor from "./permissions";
import isEmpty from "lodash/isEmpty";
import "./Roles.scss";

function GroupManagement(props) {
  const [activeTab, setActiveTab] = useState("categories");
  const [activeGroupTab, setActiveGroupTab] = useState("admin");
  const [activeMemberTab, setActiveMemberTab] = useState("members");
  const [permissionGroups, setPermissionGroups] = useState([]);
  const [show, setShow] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [categoryGroups, setCategoryGroups] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const { register, handleSubmit, reset, errors } = useForm();
  const [itemForEdit, setItemForEdit] = useState(null);
  const { addSuccessAlert, addFailAlert } = useAlertsContext();
  const [delId, setDelId] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [fixPermissions, setFixPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [treePermissions, setTreePermissions] = useState([]);
  const [warningFlag, setWarningFlag] = useState(false);
  const [superAdmin, setSuperAdmin] = useState(false);

  const ability = useContext(AbilityContext);
  const { permissionsCtxt } = useContext(PermissionsContext);
  const [abilitiesPermissions, , isAdmin] = permissionsCtxt;

  useEffect(() => {
    const rules = defineRulesFor(abilitiesPermissions, isAdmin);
    ability.update(rules);
  }, [abilitiesPermissions.length]);

  useEffect(() => {
    fetchMainData();
    fetchFormRelData();
  }, []);

  const flatDashboards = a =>
    a.dashboards[0].dashboards
      ? { ...a, dashboards: a.dashboards.map(a => a.dashboards) }
      : { ...a, dashboards: [] };

  const fetchFormRelData = async () => {
    let res1 = await fetchCustomUsers();
    let res2 = await fetchCustomGroups();

    setUsers(
      res1.data
        .filter(user => user.status === "A" && user.type !== "E")
        .map(u => {
          return {
            ...u,
            value: u.id,
            label: `${u.name} (${u.ntid})`
          };
        })
    );

    if (Array.isArray(res2.data)) {
      const ctgGrp = res2.data.map(u => {
        return {
          description: u.desc,
          value: +u.id,
          label: u.name
        };
      });

      setCategoryGroups(
        ctgGrp.sort((a, b) => {
          if (a["label"] < b["label"]) {
            return -1;
          }
          if (a["label"] > b["label"]) {
            return 1;
          }
          return 0;
        })
      );
    }
  };

  const fetchMainData = async () => {
    try {
      let res = await fetchRoles();
      let res1 = await fetchSecurityPermissions();

      setRoles(res.data);

      let cD = res1.data
        .map(d => {
          return flatDashboards(d);
        })
        .map(e =>
          e.subcategories
            ? {
                ...e,
                subcategories: e.subcategories.map(e => flatDashboards(e, !0))
              }
            : { ...e, subcategories: [] }
        );

      let cDC = cD
        .map(e => ({
          ...e,
          children: [
            ...e.dashboards
              .filter(obj => !isEmpty(obj))
              .map(e => ({
                ...e,
                name: e ? e.assetname : "Not Available",
                id: e ? e.assetid : +Math.random(),
                typeDef: "Dashboard",
                level: 1
              })),
            ...e.subcategories
              // .map(o => ({
              //   ...o,
              //   permissions: o.permissions.map(p => {
              //     if (e.type === "admin") return { ...p };
              //     else return { ...p, action: "Read" };
              //   })
              // }))
              // .map(m => ({
              //   ...m,
              //   permissions: [
              //     ...new Map(
              //       m.permissions.map(item => [item.action, item])
              //     ).values()
              //   ]
              // }))
              .map(e => ({
                ...e,
                typeDef: "Subcategory",
                level: 1
              }))
          ],
          level: 0
        }))
        .map(elm => ({
          ...elm,
          children: elm.children.map(el => ({
            ...el,
            children: !isEmpty(el.dashboards)
              ? [
                  ...el.dashboards
                    .filter(obj => !isEmpty(obj))
                    .map(e => {
                      return {
                        ...e,
                        name: e ? e.assetname : "Not Available",
                        id: e ? e.assetid : +Math.random(),
                        typeDef: "Dashboard",
                        level: 2
                      };
                    })
                ]
              : []
          }))
        }));

      setPermissions(cD);
      setFixPermissions(cDC);
      addUniqueId2(cDC, "children");
      setTreePermissions(cDC);
      let newPG = [...new Set(cD.map(e => e.type).sort())];
      setPermissionGroups(newPG);

      setLoading(false);
      trackUserAction({
        action: "List",
        targetType: "Admin",
        targetName: "Roles",
        targetPath: "/admin/roles",
        targetID: "",
        status: "Success",
        errorDetails: ""
      });
    } catch (error) {
      addFailAlert("Something went wrong fetching roles.");
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setItemForEdit(null);
    reset({});
    setShow(false);
    setActiveTab("categories");
    setActiveGroupTab("admin");
    setSelectedGroups([]);
    setSelectedUsers([]);
    setSelectedPermissions([]);
    setWarningFlag(false);
    setSuperAdmin(false);
  };

  const handleCloseUsersModal = () => {
    setSelectedGroup(null);
    setShowUsers(false);
    setSelectedUsers([]);
    setSelectedGroups([]);
    setActiveMemberTab("members");
  };

  const handleCloseDelModal = () => {
    setDelId(null);
  };
  const handleShowDelModal = id => {
    setDelId(id);
  };

  const onSubmit = async data => {
    const itemID = itemForEdit ? itemForEdit.id : null;
    const isEdit = !!itemID;

    if (!selectedUsers?.length && !selectedGroups?.length) {
      setWarningFlag(true);
      return;
    }
    if (!superAdmin && !selectedPermissions?.length) {
      setWarningFlag(true);
      return;
    }

    const updatedSelected = [
      ...new Map(selectedPermissions.map(item => [item.id, item])).values()
    ];

    const usersSelected = !isEmpty(selectedUsers)
      ? selectedUsers.map(el => ({ id: el.value, type: "U" }))
      : [];
    const groupsSelected = !isEmpty(selectedGroups)
      ? selectedGroups.map(el => ({ id: el.value, type: "G" }))
      : [];

    try {
      const {
        // eslint-disable-next-line no-unused-vars
        data: [response]
      } = isEdit
        ? await updateRole({
            ...data,
            id: itemID,
            admin: "N",
            status: "A",
            members: [...usersSelected, ...groupsSelected],
            permissions: updatedSelected,
            isadmin: superAdmin
          })
        : await addRole({
            ...data,
            admin: "N",
            members: [...usersSelected, ...groupsSelected],
            permissions: updatedSelected,
            isadmin: superAdmin
          });
      await trackUserAction({
        action: "Edit",
        targetType: "Admin",
        targetName: "Roles",
        targetPath: "/admin/roles",
        targetID: "",
        status: "Success",
        errorDetails: ""
      });
      handleCloseModal();
      fetchMainData();
      addSuccessAlert(`Role ${isEdit ? "updated" : "created"}`);
    } catch (err) {
      console.log("error", err);
      handleCloseModal();
      addFailAlert("Something went wrong");
    }
  };

  const handleDeleteClick = id => {
    deleteRole(+id)
      .then(res => fetchMainData())
      .catch(err => addFailAlert("Something went wrong"));
  };

  const handleNewClick = group => {
    setShow(true);
  };

  const handleEditClick = group => {
    fetchRoleUsers(group.id)
      .then(res => {
        setItemForEdit(group);
        reset(group);
        setSelectedUsers(
          res.data[0].members
            ?.filter(el => el.type === "U")
            .map(u => {
              return { ...u, value: u.id, label: u.name };
            })
        );
        setSelectedGroups(
          res.data[0].members
            ?.filter(el => el.type === "G")
            .map(u => {
              return { ...u, value: u.id, label: u.name };
            })
        );
        setSuperAdmin(res.data[0]?.isadmin === "Y" ? true : false);
        setShow(true);
      })
      .catch(err => {
        addFailAlert("Something went wrong");
      });

    fetchRolePermissions(group.id).then(res => {
      setSelectedPermissions(
        res.data.map(el => ({ id: el.permissionid, checked: el.checked }))
      );
    });
  };

  const handleViewUsersClick = group => {
    setSelectedGroup(group);
    fetchRoleUsers(group.id)
      .then(res => {
        setShowUsers(true);
        setSelectedUsers(
          res.data[0].members
            ?.filter(el => el.type === "U")
            .map(u => {
              return { ...u, value: u.id, label: u.name };
            })
        );

        setSelectedGroups(
          res.data[0].members
            ?.filter(el => el.type === "G")
            .map(u => {
              return { ...u, value: u.id, label: u.name };
            })
        );
      })
      .catch(err => {
        addFailAlert("Something went wrong");
      });
  };

  const filterHandler = value => {
    setFilterText(value);

    let filteredPermissions = fixPermissions
      .map(el => {
        const a = el.children
          .filter(el => el.typeDef === "Dashboard")
          .filter(db1 =>
            db1?.assetname?.toLowerCase().includes(value.toLowerCase())
          );
        const b = el.children
          .filter(el => el.typeDef === "Subcategory")
          .map(sc => {
            const tdbs = sc?.children
              ?.filter(el => el.typeDef === "Dashboard")
              .filter(db =>
                db?.assetname?.toLowerCase().includes(value.toLowerCase())
              );
            const name = sc?.name?.toLowerCase().includes(value.toLowerCase());
            if (!!tdbs?.length || name)
              return {
                ...sc,
                children: tdbs
              };
            else return undefined;
          })
          .filter(z => !!z);
        const nm = el.name?.toLowerCase().includes(value.toLowerCase());

        if (!!a.length || !!b.length || nm)
          return {
            ...el,
            dashboards: a,
            subcategories: b,
            children: [...a, ...b]
          };
        else return undefined;
      })
      .filter(y => !!y);

    setTreePermissions(filteredPermissions);
  };

  const filterByType = (t, e) => (e ? t?.filter(t => t.action === e) : t);

  const setSelectedItemPermissions = (item, type) => {
    // console.log("item", item, type);
    let permissionsToUpdate = getItemPermissionsToSet(item, type);
    // console.log(permissionsToUpdate);
    let foundNode = { ...item };
    // lookForNode(treePermissions, item);
    let parent = null;
    const parentsArr = [];
    if (!!foundNode.UUIDParents.length) {
      for (let j = 1; j <= foundNode.UUIDParents.length; j++) {
        checkParent(
          treePermissions,
          foundNode.UUIDParents[foundNode.level - j]
        );
        if (parent) {
          parentsArr.push(parent);
          const children = parent.children
            .filter(e => {
              if (item.assetid) return e.assetid !== item.assetid;
              else return e.id !== item.id;
            })
            ?.map(e => {
              return filterByType(e.permissions, type);
            })
            ?.flat()
            ?.map(e => e?.id)
            ?.filter(elm => {
              return !permissionsToUpdate.map(el => el.id).includes(elm.id);
            });

          if (
            children.every(
              e => selectedPermissions.map(el => el.id).indexOf(e) > -1
            )
          ) {
            permissionsToUpdate = [
              ...permissionsToUpdate,
              ...filterByType(parent.permissions, type).map(p => ({
                id: p.id,
                checked: "c"
              }))
            ];
          } else {
            permissionsToUpdate = [
              ...permissionsToUpdate,
              ...filterByType(parent.permissions, type).map(p => ({
                id: p.id,
                checked: "i"
              }))
            ];
          }
        }
      }
    }
    // console.log("after", permissionsToUpdate);
    let flag = Array(foundNode?.UUIDParents?.length);

    filterByType(item.permissions, type)
      .map(e => e.id)
      .every(e => selectedPermissions.map(el => el.id).indexOf(e) > -1)
      ? setSelectedPermissions(e => {
          if (!!foundNode.UUIDParents.length) {
            for (let j = 1; j <= foundNode.UUIDParents.length; j++) {
              checkParent(
                treePermissions,
                foundNode.UUIDParents[foundNode.level - j]
              );

              if (parent) {
                const children = parent.children
                  .filter(e => {
                    if (item.assetid) return e.assetid !== item.assetid;
                    else return e.id !== item.id;
                  })
                  ?.map(e => {
                    return filterByType(e.permissions, type);
                  })
                  ?.flat()
                  ?.map(e => e?.id)
                  ?.filter(elm => {
                    return !permissionsToUpdate
                      .map(el => el.id)
                      .includes(elm.id);
                  });
                // console.log("test test:", children);
                const y = children
                  .filter(el => selectedPermissions.map(e => e.id).includes(el))
                  .filter(elm => {
                    return !filterByType(item.permissions, type)
                      .flat()
                      .map(el => el.id)
                      .includes(elm);
                  });
                // console.log("yy", y);
                if (!!y.length) {
                  const id = parent.permissions.find(el => el.action === type)
                    ?.id;
                  flag[j - 1] = {
                    flag: true,
                    isEqual: y === children.length,
                    id: id
                      ? [{ id }]
                      : parent.permissions.map(el => ({ id: el.id }))
                  };
                }
              }
            }
          }
          // console.log(
          //   "flag",
          //   flag,
          //   filterByType(item.permissions, type)[0]?.id
          // );
          let final = [...e];
          for (let j = 0; j < flag.length; j++) {
            if (!!flag.length && flag[j]?.flag) {
              const m = [
                ...final.filter(
                  el =>
                    !filterByType(item.permissions, type)
                      .map(elm => elm.id)
                      .includes(el.id)
                )
              ];
              const n = m.map(el => {
                if (el.id === flag[j].id?.find(elm => elm.id === el.id)?.id) {
                  return { ...el, checked: flag[j].isEqual ? "c" : "i" };
                } else {
                  return { ...el };
                }
              });
              final = [...n];
            } else {
              if (parentsArr.length > 1) {
                const a = parentsArr[1].children
                  .map(el => el.permissions)
                  .flat()
                  .map(elm => elm.id);

                const b = selectedPermissions.filter(el => a.includes(el.id));
                if (b.length > 1) {
                  permissionsToUpdate = permissionsToUpdate.filter(
                    el =>
                      !parentsArr[1].permissions
                        .map(elm => elm.id)
                        .includes(el.id)
                  );
                }
              }
              final = [
                ...final.filter(
                  elm => !permissionsToUpdate.map(el => el.id).includes(elm.id)
                )
              ];
            }
          }

          if (flag.length === 0 || flag.length === 1) {
            final = [
              ...final.filter(
                elm =>
                  !permissionsToUpdate
                    .filter(
                      p => !parent?.permissions?.map(e => e.id)?.includes(p.id)
                    )
                    .map(el => el.id)
                    .includes(elm.id)
              )
            ];
          }
          return [...final];
        })
      : setSelectedPermissions(e => {
          const b = permissionsToUpdate.filter(el =>
            e.map(elem => elem.id).includes(el.id)
          );
          const c = permissionsToUpdate.filter(
            el => e.map(elem => elem.id).indexOf(el.id) === -1
          );
          const d = e.filter(
            el => permissionsToUpdate.map(elem => elem.id).indexOf(el.id) === -1
          );
          return [...b, ...c, ...d];
        });

    // Dont touch it
    function checkParent(o, l) {
      o.forEach(o => {
        if (o.UUID === l) parent = o;

        o.children &&
          !!o.children.length &&
          o.children[0].level !== 2 &&
          checkParent(o.children, l);
      });
    }

    function lookForNode(list, item) {
      for (let i = 0; i < list?.length; i++) {
        if (list[i].id === item.assetid || list[i].id === item.id) {
          foundNode = list[i];
          if (list[i].level === 2) {
            break;
          }
        }
        if (foundNode) break;
        if (!foundNode) {
          lookForNode(list[i].children, item);
        }
      }
    }
  };

  const getItemPermissionsToSet = (s, e) => {
    let i = [];
    i = [...i, ...filterByType(s?.permissions, e)?.map(s => ({ id: s.id }))];
    s.dashboards &&
      [...s.dashboards].forEach(s => {
        if (s?.permissions)
          i = [
            ...i,
            ...filterByType(s?.permissions, e)?.map(s => ({ id: s?.id }))
          ];
      });
    s.subcategories &&
      [...s.subcategories].forEach(s => {
        if (s?.permissions)
          i = [
            ...i,
            ...filterByType(s?.permissions, e)?.map(s => ({ id: s.id }))
          ];
        s.dashboards &&
          [...s.dashboards].forEach(s => {
            if (s?.permissions)
              i = [
                ...i,
                ...filterByType(s?.permissions, e)?.map(s => ({ id: s.id }))
              ];
          });
      });

    return i;
  };

  const DummyColumns = ({ item }) => {
    // console.log(
    //   selectedPermissions.filter(
    //     el => el.id === item?.permissions?.find(elm => elm.id === el.id)?.id
    //   ),
    //   item?.permissions
    // );

    // const cName =
    //   selectedPermissions.find(
    //     el => el.id === item?.permissions?.find(elm => elm.id === el.id)?.id
    //   )?.checked === "i"
    //     ? "indeterminate"
    //     : "";

    // const cName = item.permissions
    //   .map(el => el.id)
    //   .every(elm => selectedPermissions.find(obj => obj.id === elm.id))
    //   ? "indeterminate"
    //   : "";

    return (
      <>
        {!item?.permissions?.map(e => e.action).includes("Read") && (
          <Col>
            <input inline type="checkbox" id={`default-1`} disabled label="" />
          </Col>
        )}

        {!item?.permissions?.map(e => e.action).includes("Write") && (
          <Col>
            <input inline type="checkbox" id={`default-1`} disabled label="" />
          </Col>
        )}

        {/* <Col className={cName}>
          <input
            inline
            type="checkbox"
            id={item?.permissions?.map(e => e.id).join("-")}
            label=""
            checked={item?.permissions
              ?.map(e => e.id)
              .every(e => selectedPermissions.map(el => el.id).indexOf(e) > -1)}
            onClick={e => {
              e.stopPropagation();
              setSelectedItemPermissions(item);
            }}
          />
        </Col> */}
      </>
    );
  };
  const RolesOverlayTrigger = ({ name }) => {
    return (
      <OverlayTrigger
        placement="bottom"
        trigger={["hover", "focus"]}
        rootClose
        overlay={
          <Popover className="db-popover">
            <Popover.Content>
              <div>{name}</div>
            </Popover.Content>
          </Popover>
        }
      >
        <span
          style={{
            fontSize: "13px"
          }}
        >
          {name.length > 48 ? name.slice(0, 47) + "..." : name}
        </span>
      </OverlayTrigger>
    );
  };

  return (
    <div className="GroupManagement">
      <Can I="Write" a="Roles Management">
        {() => (
          <Button className="mb-3" onClick={handleNewClick} disabled={loading}>
            <AiOutlinePlus /> New Role
          </Button>
        )}
      </Can>
      {loading ? (
        <Spinner>Fetching Roles</Spinner>
      ) : (
        <Grid
          width="100%"
          title="Roles"
          showExcel={true}
          suppressRowTransform={true}
          columnDefs={[
            {
              field: "id",
              headerName: "Role Id",
              sortable: true,
              resizable: true,
              width: 100
            },
            {
              field: "name",
              headerName: "Name",
              sortable: true,
              resizable: true
            },
            {
              field: "",
              headerName: "Super Admin",
              sortable: true,
              resizable: true,
              width: 100,
              cellRendererFramework: ({ data }) =>
                data.isadmin === "Y" ? "Yes" : "No"
            },
            {
              field: "description",
              headerName: "Description",
              sortable: true,
              resizable: true
            },
            {
              field: "type",
              headerName: "Type",
              sortable: true,
              resizable: true,
              width: 120
            },
            {
              field: "edit",
              headerName: "",
              resizable: true,
              cellRendererFramework: ({ data, value }) => {
                return (
                  <>
                    <Can I="Write" a="Roles Management">
                      {() => (
                        <AccentButton onClick={() => handleEditClick(data)}>
                          <AiOutlineEdit /> Edit
                        </AccentButton>
                      )}
                    </Can>
                    <AccentButton
                      className="mx-2"
                      onClick={() => handleViewUsersClick(data)}
                    >
                      <AiOutlineEdit /> Members
                    </AccentButton>
                    <Can I="Write" a="Roles Management">
                      {() => (
                        <AccentButton
                          className="mx-2"
                          onClick={() => handleShowDelModal(data.id)}
                        >
                          <AiOutlineEdit /> Delete
                        </AccentButton>
                      )}
                    </Can>
                  </>
                );
              }
            }
          ]}
          rowData={roles}
        />
      )}

      <Modal
        className="admin-modal"
        show={show}
        onHide={handleCloseModal}
        dialogClassName="role-management-modal"
        size="lg"
      >
        <Form onSubmit={handleSubmit(onSubmit)} className="pl-3 pr-3 pb-3 pt-2">
          <Modal.Header closeButton>
            <Modal.Title>{!!itemForEdit ? "Edit" : "Add New"} Role</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Tabs
              mountOnEnter
              activeKey={activeTab}
              onSelect={k => setActiveTab(k)}
              className="p-0"
            >
              <Tab eventKey="categories" title="General">
                <section className="p-1 mt-3">
                  <div className="px-3">
                    <Form.Group
                      as={Row}
                      controlId="categoryGroups-CategoryName"
                    >
                      <Form.Label column sm="4">
                        Name*
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          name="name"
                          ref={register({
                            required: true,
                            validate: val => val.trim() !== ""
                          })}
                          type="text"
                          maxLength={250}
                          placeholder=""
                          isInvalid={!!errors.name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {!!errors.name && "Required"}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      controlId="categoryGroups-SubCategoryName"
                    >
                      <Form.Label column sm="4">
                        Description
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          name="description"
                          ref={register({})}
                          type="text"
                          placeholder=""
                          maxLength={250}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      controlId="categoryGroups-SubCategoryName"
                    >
                      <Form.Label column sm="4">
                        Super Admin
                      </Form.Label>
                      <Col sm="8">
                        <Form.Check
                          type="switch"
                          id="isAdmin"
                          label=""
                          className="custom-switch-md"
                          onClick={() => setSuperAdmin(flg => !flg)}
                          checked={superAdmin}
                        />
                      </Col>
                    </Form.Group>
                  </div>

                  <div className="px-3 pb-3">
                    <Row>
                      <Col>
                        <span>Members*</span>
                        <Select
                          isMulti
                          value={selectedUsers}
                          closeMenuOnSelect={false}
                          name="users"
                          options={users}
                          onChange={(slctdUsers, { option }) => {
                            option
                              ? setSelectedUsers(prev => {
                                  if (prev) {
                                    return [
                                      ...prev,
                                      {
                                        id: option.id,
                                        label: option.label,
                                        name: option.label,
                                        type: "U",
                                        value: option.id
                                      }
                                    ];
                                  } else {
                                    return [
                                      {
                                        id: option.id,
                                        label: option.label,
                                        name: option.label,
                                        type: "U",
                                        value: option.id
                                      }
                                    ];
                                  }
                                })
                              : setSelectedUsers(slctdUsers);
                            setWarningFlag(false);
                          }}
                        />
                      </Col>
                      <Col>
                        <span>Groups*</span>
                        <Select
                          isMulti
                          value={selectedGroups}
                          closeMenuOnSelect={false}
                          name="users"
                          options={categoryGroups}
                          onChange={(slctdGroups, { option }) => {
                            option
                              ? setSelectedGroups(prev => {
                                  if (prev)
                                    return [
                                      ...prev,
                                      {
                                        id: option.value,
                                        label: option.label,
                                        name: option.label,
                                        type: "G",
                                        value: option.value
                                      }
                                    ];
                                  else
                                    return [
                                      {
                                        id: option.value,
                                        label: option.label,
                                        name: option.label,
                                        type: "G",
                                        value: option.value
                                      }
                                    ];
                                })
                              : setSelectedGroups(slctdGroups);
                            setWarningFlag(false);
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </section>
              </Tab>
              <Tab
                eventKey="content"
                title="Permissions*"
                className="cust-scroll"
              >
                <InputGroup
                  style={{
                    position: "absolute",
                    width: "37%",
                    right: "10px",
                    top: "7px"
                  }}
                >
                  <Form.Control
                    className="text-box"
                    style={{ height: "30px" }}
                    type="text"
                    placeholder="Search"
                    maxLength={25}
                    onChange={e => {
                      filterHandler(e.target.value);
                    }}
                    value={filterText}
                  />
                  {!filterText ? (
                    <AiOutlineSearch className="sx-grid__search-icon text-box-icon-rm" />
                  ) : (
                    <AiOutlineClose
                      className="sx-grid__search-icon text-box-icon-rm"
                      onClick={() => {
                        setFilterText("");
                        setTreePermissions(fixPermissions);
                      }}
                    />
                  )}
                </InputGroup>
                <Tabs
                  mountOnEnter
                  activeKey={activeGroupTab}
                  onSelect={k => setActiveGroupTab(k)}
                  className="p-0"
                >
                  {permissionGroups.map(pg => (
                    <Tab
                      eventKey={pg?.toLowerCase()}
                      title={capitalize(
                        pg === "asdashboard" ? "as dashboard" : pg
                      )}
                      key={pg}
                    >
                      <section className="p-1 mt-3">
                        <Row>
                          <Col md={4} className="offset-md-8">
                            <Row
                              style={{
                                color: "#747171",
                                fontSize: "1.4rem"
                              }}
                            >
                              <Col className="ml-5">Read</Col>
                              {pg?.toLowerCase() === "admin" && (
                                <Col className="ml-n3">Write</Col>
                              )}
                              {pg?.toLowerCase() === "admin" && (
                                <Col className="ml-n3">All</Col>
                              )}
                            </Row>
                          </Col>
                        </Row>
                        {sortByName(
                          treePermissions.filter(el => el.type === pg),
                          "name"
                        ).map(p => {
                          return (
                            <div key={p.name}>
                              {(!!p.dashboards?.length ||
                                !!p.subcategories?.length) && (
                                <Accordion
                                  allowZeroExpanded
                                  className="ml-n1"
                                  preExpanded={["asdashboard", "admin"]}
                                >
                                  <AccordionItem uuid={pg}>
                                    <AccordionItemHeading>
                                      <AccordionItemButton>
                                        <Row
                                          style={{
                                            width: "99.7%",
                                            paddingTop: "3px"
                                          }}
                                        >
                                          <Col
                                            md={8}
                                            style={{
                                              color: "#000",
                                              fontWeight: "bold",
                                              marginRight: "6px"
                                            }}
                                          >
                                            {p.name}
                                          </Col>
                                        </Row>
                                      </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                      <div
                                        style={{
                                          position: "relative"
                                        }}
                                      >
                                        <Col
                                          md={4}
                                          style={{
                                            minWidth: "220px",
                                            maxWidth: "220px",
                                            position: "absolute",
                                            top: "-24px",
                                            right: 0
                                          }}
                                        >
                                          <Row>
                                            {p.permissions?.map((pp, i) => {
                                              const cName =
                                                selectedPermissions.find(
                                                  el => el.id === pp.id
                                                )?.checked === "i"
                                                  ? "indeterminate"
                                                  : "";
                                              return (
                                                <Col
                                                  className={cName}
                                                  key={pp.id}
                                                >
                                                  <input
                                                    inline
                                                    type="checkbox"
                                                    id={pp.id}
                                                    label=""
                                                    checked={
                                                      selectedPermissions
                                                        .map(el => el.id)
                                                        .indexOf(pp.id) > -1
                                                    }
                                                    onChange={e => {
                                                      setWarningFlag(false);
                                                      e.stopPropagation();

                                                      setSelectedItemPermissions(
                                                        p,
                                                        pp.action
                                                      );
                                                    }}
                                                  />
                                                </Col>
                                              );
                                            })}
                                            {pg?.toLowerCase() === "admin" && (
                                              <DummyColumns item={p} />
                                            )}
                                            {pg?.toLowerCase() === "admin" && (
                                              <Col
                                                className={
                                                  selectedPermissions
                                                    .filter(
                                                      el =>
                                                        el.id ===
                                                        p?.permissions?.find(
                                                          elm =>
                                                            elm.id === el.id
                                                        )?.id
                                                    )
                                                    .find(
                                                      p => p?.checked === "i"
                                                    )
                                                    ? "indeterminate"
                                                    : ""
                                                }
                                              >
                                                <input
                                                  inline
                                                  type="checkbox"
                                                  id={p?.permissions
                                                    ?.map(e => e.id)
                                                    .join("-")}
                                                  label=""
                                                  checked={p?.permissions
                                                    ?.map(e => e.id)
                                                    .every(
                                                      e =>
                                                        selectedPermissions
                                                          .map(el => el.id)
                                                          .indexOf(e) > -1
                                                    )}
                                                  onChange={e => {
                                                    e.stopPropagation();
                                                    setSelectedItemPermissions(
                                                      p
                                                    );
                                                  }}
                                                />
                                              </Col>
                                            )}
                                          </Row>
                                        </Col>
                                      </div>
                                      <AccordionItemState>
                                        {state => (
                                          <div
                                            style={{
                                              display: state.expanded
                                                ? "block"
                                                : "none"
                                            }}
                                          >
                                            {sortByName(
                                              p.children?.filter(
                                                el => el.typeDef === "Dashboard"
                                              ),
                                              "assetname"
                                            ).map(pd => (
                                              <Row
                                                style={{
                                                  justifyContent: "space-around"
                                                }}
                                                key={pd.assetname}
                                              >
                                                <Col
                                                  md={8}
                                                  style={{
                                                    paddingLeft: "65px",
                                                    color: "#7e7b7b",
                                                    fontSize: "13px"
                                                  }}
                                                >
                                                  <RolesOverlayTrigger
                                                    name={
                                                      pd?.assetname
                                                        ? pd.assetname
                                                        : ""
                                                    }
                                                  />
                                                </Col>
                                                <Col
                                                  md={4}
                                                  style={{
                                                    minWidth: "220px",
                                                    maxWidth: "220px",
                                                    left: "2px"
                                                  }}
                                                >
                                                  <Row>
                                                    {pd?.permissions?.map(
                                                      (pp, i) => {
                                                        return (
                                                          <Col key={pp.id}>
                                                            <input
                                                              checked={
                                                                selectedPermissions
                                                                  .map(
                                                                    el => el.id
                                                                  )
                                                                  .indexOf(
                                                                    pp.id
                                                                  ) > -1
                                                              }
                                                              onChange={e => {
                                                                setWarningFlag(
                                                                  false
                                                                );
                                                                e.stopPropagation();

                                                                setSelectedItemPermissions(
                                                                  pd,
                                                                  pp.action
                                                                );
                                                              }}
                                                              inline
                                                              type="checkbox"
                                                              id={pp.id}
                                                              label=""
                                                            />
                                                          </Col>
                                                        );
                                                      }
                                                    )}
                                                  </Row>
                                                </Col>
                                              </Row>
                                            ))}
                                            {sortByName(
                                              p.children?.filter(el => {
                                                return (
                                                  el.typeDef === "Subcategory"
                                                );
                                              }),
                                              "name"
                                            )?.map(ps => {
                                              return (
                                                <div
                                                  className="sub-cat-btn"
                                                  key={ps.id}
                                                >
                                                  {ps?.name && (
                                                    <Accordion
                                                      allowZeroExpanded
                                                      style={{
                                                        paddingLeft: "20px"
                                                      }}
                                                    >
                                                      <AccordionItem>
                                                        <AccordionItemHeading>
                                                          <AccordionItemButton>
                                                            <Row
                                                              style={{
                                                                width: "99.7%",
                                                                paddingTop:
                                                                  "3px"
                                                              }}
                                                            >
                                                              <Col
                                                                md={8}
                                                                style={{
                                                                  paddingLeft:
                                                                    "25px"
                                                                }}
                                                              >
                                                                {ps?.name}
                                                              </Col>
                                                            </Row>
                                                          </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel>
                                                          <div
                                                            style={{
                                                              position:
                                                                "relative"
                                                            }}
                                                          >
                                                            <Col
                                                              md={4}
                                                              style={{
                                                                minWidth:
                                                                  "220px",
                                                                maxWidth:
                                                                  "220px",
                                                                position:
                                                                  "absolute",
                                                                right: "-1px",
                                                                top: "-24px"
                                                              }}
                                                            >
                                                              <Row>
                                                                {ps?.permissions?.map(
                                                                  (pp, i) => {
                                                                    const cName =
                                                                      selectedPermissions.find(
                                                                        el =>
                                                                          el.id ===
                                                                          pp.id
                                                                      )
                                                                        ?.checked ===
                                                                      "i"
                                                                        ? "indeterminate"
                                                                        : "";
                                                                    return (
                                                                      <Col
                                                                        className={
                                                                          cName
                                                                        }
                                                                        key={
                                                                          pp.id
                                                                        }
                                                                      >
                                                                        <input
                                                                          checked={
                                                                            selectedPermissions
                                                                              .map(
                                                                                el =>
                                                                                  el.id
                                                                              )
                                                                              .indexOf(
                                                                                pp.id
                                                                              ) >
                                                                            -1
                                                                          }
                                                                          onChange={e => {
                                                                            setWarningFlag(
                                                                              false
                                                                            );
                                                                            e.stopPropagation();

                                                                            setSelectedItemPermissions(
                                                                              ps,
                                                                              pp.action
                                                                            );
                                                                          }}
                                                                          inline
                                                                          type="checkbox"
                                                                          id={
                                                                            pp.id
                                                                          }
                                                                          label=""
                                                                        />
                                                                      </Col>
                                                                    );
                                                                  }
                                                                )}
                                                                {pg?.toLowerCase() ===
                                                                  "admin" && (
                                                                  <DummyColumns
                                                                    item={ps}
                                                                  />
                                                                )}
                                                                {pg?.toLowerCase() ===
                                                                  "admin" && (
                                                                  <Col
                                                                    className={
                                                                      selectedPermissions.find(
                                                                        el =>
                                                                          el.id ===
                                                                          ps?.permissions?.find(
                                                                            elm =>
                                                                              elm.id ===
                                                                              el.id
                                                                          )?.id
                                                                      )
                                                                        ?.checked ===
                                                                      "i"
                                                                        ? "indeterminate"
                                                                        : ""
                                                                    }
                                                                  >
                                                                    <input
                                                                      inline
                                                                      type="checkbox"
                                                                      id={ps?.permissions
                                                                        ?.map(
                                                                          e =>
                                                                            e.id
                                                                        )
                                                                        .join(
                                                                          "-"
                                                                        )}
                                                                      label=""
                                                                      checked={ps?.permissions
                                                                        ?.map(
                                                                          e =>
                                                                            e.id
                                                                        )
                                                                        .every(
                                                                          e =>
                                                                            selectedPermissions
                                                                              .map(
                                                                                el =>
                                                                                  el.id
                                                                              )
                                                                              .indexOf(
                                                                                e
                                                                              ) >
                                                                            -1
                                                                        )}
                                                                      onChange={e => {
                                                                        e.stopPropagation();
                                                                        setSelectedItemPermissions(
                                                                          ps
                                                                        );
                                                                      }}
                                                                    />
                                                                  </Col>
                                                                )}
                                                              </Row>
                                                            </Col>
                                                          </div>
                                                          <AccordionItemState>
                                                            {state => (
                                                              <div
                                                                style={{
                                                                  display: state.expanded
                                                                    ? "block"
                                                                    : "none"
                                                                }}
                                                              >
                                                                {sortByName(
                                                                  ps?.children,
                                                                  "assetname"
                                                                )?.map(psd => (
                                                                  <Row
                                                                    key={
                                                                      psd.assetid
                                                                    }
                                                                  >
                                                                    <Col
                                                                      md={8}
                                                                      style={{
                                                                        paddingLeft:
                                                                          "78px",
                                                                        color:
                                                                          "#7e7b7b"
                                                                      }}
                                                                    >
                                                                      <RolesOverlayTrigger
                                                                        name={
                                                                          psd?.assetname
                                                                            ? psd.assetname
                                                                            : ""
                                                                        }
                                                                      />
                                                                    </Col>
                                                                    <Col
                                                                      md={4}
                                                                      style={{
                                                                        minWidth:
                                                                          "220px",
                                                                        maxWidth:
                                                                          "220px",
                                                                        left:
                                                                          "46px"
                                                                      }}
                                                                    >
                                                                      <Row>
                                                                        {psd?.permissions?.map(
                                                                          (
                                                                            pp,
                                                                            i
                                                                          ) => (
                                                                            <Col
                                                                              key={
                                                                                pp.id
                                                                              }
                                                                            >
                                                                              <input
                                                                                checked={
                                                                                  selectedPermissions
                                                                                    .map(
                                                                                      el =>
                                                                                        el.id
                                                                                    )
                                                                                    .indexOf(
                                                                                      pp.id
                                                                                    ) >
                                                                                  -1
                                                                                }
                                                                                onChange={e => {
                                                                                  setWarningFlag(
                                                                                    false
                                                                                  );

                                                                                  e.stopPropagation();

                                                                                  setSelectedItemPermissions(
                                                                                    psd,
                                                                                    pp.action
                                                                                  );
                                                                                }}
                                                                                inline
                                                                                type="checkbox"
                                                                                id={
                                                                                  pp.id
                                                                                }
                                                                                label=""
                                                                              />
                                                                            </Col>
                                                                          )
                                                                        )}
                                                                      </Row>
                                                                    </Col>
                                                                  </Row>
                                                                ))}
                                                              </div>
                                                            )}
                                                          </AccordionItemState>
                                                        </AccordionItemPanel>
                                                      </AccordionItem>
                                                    </Accordion>
                                                  )}
                                                </div>
                                              );
                                            })}
                                          </div>
                                        )}
                                      </AccordionItemState>
                                    </AccordionItemPanel>
                                  </AccordionItem>
                                </Accordion>
                              )}
                            </div>
                          );
                        })}
                      </section>
                    </Tab>
                  ))}
                </Tabs>
              </Tab>
            </Tabs>
          </Modal.Body>

          <Modal.Footer>
            <div className="d-flex justify-content-between">
              {warningFlag && (
                <span
                  className="mt-1"
                  style={{
                    color: "#dc3545",
                    display: "inline-block",
                    fontSize: "80%",
                    left: "45px",
                    position: "absolute"
                  }}
                >
                  {!selectedUsers?.length &&
                    !selectedGroups?.length &&
                    "Select at least a Member or Group"}
                  {!superAdmin &&
                    !selectedUsers?.length &&
                    !selectedGroups?.length &&
                    !selectedPermissions?.length &&
                    ", and"}
                  {!superAdmin &&
                    !selectedPermissions?.length &&
                    " Select at least one Permission"}
                </span>
              )}

              <Button type="submit" variant="primary">
                Submit
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        className="admin-modal"
        show={showUsers}
        dialogClassName="role-management-modal"
        onHide={handleCloseUsersModal}
      >
        <Modal.Header className="py-2" closeButton>
          <Modal.Title>{selectedGroup?.name} Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            mountOnEnter
            activeKey={activeMemberTab}
            onSelect={k => setActiveMemberTab(k)}
            className="p-0"
          >
            <Tab eventKey="members" title="Users">
              <Grid
                width="100%"
                height="375px"
                animateRows
                showExcel={true}
                className="p-3"
                columnDefs={[
                  {
                    field: "userNTId",
                    headerName: "UserID",
                    sortable: true,
                    resizable: true
                  },
                  {
                    field: "fullName",
                    headerName: "Full Name",
                    sortable: true,
                    resizable: true
                  },
                  {
                    field: "email",
                    headerName: "Email",
                    sortable: true,
                    resizable: true
                  },
                  {
                    field: "department",
                    headerName: "Department",
                    sortable: true,
                    resizable: true
                  }
                ]}
                rowData={
                  !!selectedUsers?.length
                    ? users.filter(
                        u =>
                          u.userId ===
                          selectedUsers?.find(el => el.id === u.userId)?.id
                      )
                    : []
                }
              />
            </Tab>
            <Tab eventKey="groups" title="Groups">
              <Grid
                width="100%"
                height="375px"
                animateRows
                showExcel={true}
                className="p-3"
                columnDefs={[
                  {
                    field: "value",
                    headerName: "GroupId",
                    sortable: true,
                    resizable: true
                  },
                  {
                    field: "label",
                    headerName: "Group Name",
                    sortable: true,
                    resizable: true
                  },
                  {
                    field: "description",
                    headerName: "Description",
                    sortable: true,
                    resizable: true
                  }
                ]}
                rowData={
                  !!selectedGroups?.length
                    ? categoryGroups.filter(
                        u =>
                          u.value ===
                          selectedGroups?.find(el => el.id === u.value)?.id
                      )
                    : []
                }
              />
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>

      {!!delId && (
        <ModalCard
          body={`Do you still want to delete this Role?`}
          title={`Delete Role Confirmation`}
          show={!!delId}
          noText="Cancel"
          yesText="Ok"
          handleNo={handleCloseDelModal}
          handleYes={() => {
            handleDeleteClick(delId);
            handleCloseDelModal();
          }}
        />
      )}
    </div>
  );
}

export default GroupManagement;
