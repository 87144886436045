import React, { useContext, useEffect, useRef } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { FaBullseye, FaChartLine, FaDatabase } from "react-icons/fa";
import { MdSchool } from "react-icons/md";
import { LinkContainer } from "react-router-bootstrap";
import BaseCard from "../../components/BaseCard";
import Chipset from "../../components/Chips";
import Select from "../../components/Select";
import Spinner from "../../components/Spinner";
import { Tab, Tabs } from "../../components/Tabs";
import { APP_VARS } from "../../services/appVars";
import AllResultsList from "./AllResultsList";
import DashboardsList from "./DashboardsList";
import DataCatalogsList from "./DataCatalogsList";
import DataUniversityList from "./DataUniversityList";
import MetricsList from "./MetricsList";
import { SmartSearchContext } from "./SmartSearchContext";

function SmartSearchResults({
  dashboards,
  dashboardsLoading,
  globalSearchText,
  activeFilters,
  setActiveFilters
}) {
  const {
    tabContext,
    allTabContext,
    dashboardTabContext,
    metricTabContext,
    datacatalogTabContext,
    pageContext
  } = useContext(SmartSearchContext);
  const [activeTab, setActiveTab] = tabContext;
  const [allGraphData] = allTabContext;
  const [pageData, setPageData] = pageContext;
  const [dashboardGraphData] = dashboardTabContext;
  const [metricGraphData] = metricTabContext;
  const [datacatalogGraphData] = datacatalogTabContext;
  const downloadRef = useRef();

  useEffect(() => {
    return () => {
      setActiveFilters([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDownload = () => {
    let dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(allGraphData));
    downloadRef.current.setAttribute("href", dataStr);
    downloadRef.current.setAttribute("download", "smart-search-results.json");
    downloadRef.current.click();
  };
  return (
    <BaseCard>
      <div className="pt-1 pl-2 gray-bottom-border-1">
        <Breadcrumb>
          <LinkContainer to="/">
            <Breadcrumb.Item as="div">Home</Breadcrumb.Item>
          </LinkContainer>
          <Breadcrumb.Item active>Search</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      {activeFilters.length > 0 && (
        <div className="py-3 pl-4 gray-bottom-border-1">
          <Chipset
            onClose={c => setActiveFilters(f => f.filter(j => j.id !== c.id))}
            onClearAll={() => setActiveFilters([])}
            chips={activeFilters}
          />
        </div>
      )}
      <div className="d-flex align-items-center p-4 flex-wrap">
        {globalSearchText && (
          <div className="text-primary font-medium overflow-word-break">
            Search results for {globalSearchText}
            {!dashboardsLoading && (
              <span className="ml-2 text-muted font-small white-space-nowrap">
                ( Showing{" "}
                {activeTab === "all" &&
                  (activeFilters.length
                    ? pageData?.allTab?.filteredCount
                    : pageData?.allTab?.totalCount)}
                {activeTab === "dashboards" &&
                  (activeFilters.length
                    ? pageData?.dashboardTab?.filteredCount
                    : pageData?.dashboardTab?.totalCount)}
                {activeTab === "metrics" &&
                  (activeFilters.length
                    ? pageData?.metricTab?.filteredCount
                    : pageData?.metricTab?.totalCount)}
                {activeTab === "data" &&
                  (activeFilters.length
                    ? pageData?.datacatalogTab?.filteredCount
                    : pageData?.datacatalogTab?.totalCount)}
                {activeTab === "data-university" &&
                  (activeFilters.length
                    ? pageData?.dataUniversityTab?.filteredCount
                    : pageData?.dataUniversityTab?.totalCount)}{" "}
                results )
              </span>
            )}
          </div>
        )}
        <a ref={downloadRef} style={{ display: "none" }}></a>
        <Select
          label="Show:"
          className="ml-auto"
          value={pageData.pageSize}
          onChange={e => {
            e.preventDefault();
            const { value } = e.target;
            setPageData(j => ({
              ...j,
              pageSize: value
            }));
          }}
          options={[{ value: 10 }, { value: 20 }, { value: 50 }]}
        />
        {/* <AccentButton
          className="sx-accent-button--grid mx-2"
          onClick={handleDownload}
          disabled={!allGraphData.length}
        >
          {" "}
          <FiDownload />{" "}
          <span className="font-small font-weight-bold">Download</span>{" "}
        </AccentButton> */}
      </div>
      {dashboardsLoading && <Spinner>Fetching Results</Spinner>}
      {!dashboardsLoading && (
        <Container fluid>
          {!allGraphData?.length &&
          !dashboardGraphData?.length &&
          !datacatalogGraphData?.length &&
          !metricGraphData?.length ? (
            <div className="text-center mb-5">No results found.</div>
          ) : (
            <Tabs
              mountOnEnter
              activeKey={activeTab}
              onSelect={k => setActiveTab(k)}
              className="p-0 mt-n2"
            >
              <Tab
                eventKey="all"
                title={
                  <span>
                    <FaBullseye className="font-medium pointer mx-2" />
                    All
                  </span>
                }
              >
                <Row className="p-4">
                  <Col sm={12} md={6} lg={6} xl={12}>
                    <AllResultsList dashboards={dashboards} />
                  </Col>
                </Row>
              </Tab>
              <Tab
                eventKey="dashboards"
                title={
                  <span>
                    <img
                      src={
                        `${APP_VARS.REACT_APP_ASSETS_URL}/` +
                        `images/dashboard-icon${
                          activeTab === "dashboards" ? "-active" : ""
                        }.png`
                      }
                      width="18"
                      className="mx-2"
                      alt="dashboard-icon"
                    />
                    Dashboards
                  </span>
                }
              >
                <Row className="p-4">
                  <Col>
                    <DashboardsList dashboards={dashboards} />
                  </Col>
                </Row>
              </Tab>
              <Tab
                eventKey="metrics"
                title={
                  <span>
                    <FaChartLine className="font-medium pointer mx-2" />
                    Metrics
                  </span>
                }
              >
                <Row className="p-4">
                  <Col sm={12} md={6} lg={6} xl={12}>
                    <MetricsList />
                  </Col>
                </Row>
              </Tab>
              <Tab
                eventKey="data"
                title={
                  <span>
                    <FaDatabase className="font-medium pointer mx-2" />
                    Data
                  </span>
                }
              >
                <Row className="p-4">
                  <Col sm={12} md={6} lg={6} xl={12}>
                    <DataCatalogsList />
                  </Col>
                </Row>
              </Tab>
              {/* <Tab
                eventKey="data-university"
                title={
                  <span>
                    <MdSchool className="font-large  pointer mx-2" />
                    Data University
                  </span>
                }
              >
                <Row className="p-4">
                  <Col sm={12} md={6} lg={6} xl={12}>
                    <DataUniversityList globalSearchText={globalSearchText} />
                  </Col>
                </Row>
              </Tab> */}
            </Tabs>
          )}
        </Container>
      )}
    </BaseCard>
  );
}

export default SmartSearchResults;
