import React from "react";
import Slider from "../Slider";
import ReactPlayer from "react-player";
import "./VideoCarousel.scss";
import { BsDownload } from "react-icons/bs";

const DEFAULT_SLIDER_SETTINGS = {
  dots: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: false
};

function VideoCarousel({
  className = "",
  videoList = [],
  sliderSettings = DEFAULT_SLIDER_SETTINGS,
  sliderRef,
  setActiveVideo,
  setVideoList,
  ...otherProps
}) {
  return (
    <div {...otherProps} className={`sx-video-carousel ${className}`}>
      <Slider
        {...sliderSettings}
        ref={slider => (sliderRef.current = slider)}
        afterChange={index => {
          setActiveVideo(videoList.find(v => v.id === index));
        }}
      >
        {videoList.map(v => (
          <div key={v.id}>
            <div>
              <div className="video-title py-3 font-weight-bold text-primary font-large">
                {v.title}
              </div>
              <ReactPlayer
                key={v.id}
                controls
                playing={v.playing}
                url={v.src}
                width="100%"
                onPlay={() =>
                  setVideoList(vids =>
                    vids.map(video => {
                      if (video.id === v.id) {
                        return { ...video, playing: true };
                      }
                      return video;
                    })
                  )
                }
                onPause={() =>
                  setVideoList(vids =>
                    vids.map(video => {
                      if (video.id === v.id) {
                        return { ...video, playing: false };
                      }
                      return video;
                    })
                  )
                }
              />
              <div className="text-secondary font-small py-3">
                {v.description}
              </div>
            </div>
            <div className="d-flex justify-content-end">
              {v.filePath && (
                <a
                  className="text-secondary font-small py-1"
                  href={v.filePath}
                  download={true}
                >
                  <BsDownload className="mr-2" />
                  Download
                </a>
              )}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default VideoCarousel;
