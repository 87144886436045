import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import BaseCard from "../../components/BaseCard";
import DomainCard from "../../components/DomainCard";
import Spinner from "../../components/Spinner";
import { useAlertsContext } from "../../context/alerts";
import Disclaimer from "./Disclaimer";

import { APP_VARS } from "../../services/appVars";

import {
  fetchDomains,
  fetchAssets,
  trackUserAction
} from "../../services/dataService";

function Domains({
  setActiveFilters,
  setPaginationState,
  setGlobalSearchText,
  setCounts
}) {
  const [domains, setDomains] = useState([]);
  const [domainsLoading, setDomainsLoading] = useState(false);
  const history = useHistory();
  const { addFailAlert } = useAlertsContext();

  useEffect(() => {
    setDomainsLoading(true);
    Promise.all([
      fetchDomains(),
      fetchAssets({ query: "", pageSize: 1, pageNumber: 1 })
    ])

      .then(response => {
        setDomains(response[0].data["Data Domain"]);
        setCounts(response[1].data.fieldCounts || {});
        setDomainsLoading(false);
      })
      .catch(err => {
        console.log(err);
        setDomainsLoading(false);
        addFailAlert("Something went wrong fetching domains");
      });
  }, [setCounts, addFailAlert]);

  // Do a big reset every time someone lands on domain page
  useEffect(() => {
    setActiveFilters([]);
    setPaginationState({});
    setGlobalSearchText("");
  }, [setActiveFilters, setGlobalSearchText, setPaginationState]);
  return (
    <BaseCard>
      <div className="pt-1 pl-2 gray-bottom-border-1">
        <Breadcrumb>
          <LinkContainer to="/">
            <Breadcrumb.Item as="div">Home</Breadcrumb.Item>
          </LinkContainer>
          <Breadcrumb.Item active>Data Catalog</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="d-flex align-items-center pt-4 px-4 pb-2">
        <span className="text-primary font-medium">DATA CATALOG - Domains</span>
      </div>
      <Disclaimer className="pb-2 pl-4" />
      <Container fluid className="p-4">
        {domainsLoading && <Spinner>Fetching Domains</Spinner>}
        {!domainsLoading && domains.length === 0 && (
          <div className="text-center">No domains available.</div>
        )}
        {!domainsLoading && domains.length !== 0 && (
          <Row>
            {domains.map((d, index) => (
              <Col key={index} sm={12} md={6} lg={4} xl={3}>
                <DomainCard
                  onClick={() => {
                    setActiveFilters(af => [
                      ...af,
                      { type: "Data Domain", value: d["Data Domain"] }
                    ]);
                    trackUserAction({
                      action: "View",
                      targetType: "datacatalog categories",
                      targetName: d["Data Domain"],
                      targetPath: `/datacatalog/categories`,
                      targetID: d["Domain ID"],
                      status: "Success",
                      errorDetails: ""
                    });
                    history.push(`/datacatalog/categories`);
                  }}
                  popoverTitle={d["Data Domain"]}
                  popoverText={d["DomainDescription"]}
                  title={d["Data Domain"]}
                  img={
                    <img
                      src={`${APP_VARS.REACT_APP_ASSETS_URL}/${d[
                        "Image"
                      ].replace("assets/", "")}`}
                      alt={d["Data Domain"]}
                    />
                  }
                  className="mb-4 mr-2"
                />
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </BaseCard>
  );
}

export default Domains;
