import React, { useContext, useRef, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Grid from "../../components/Grid";
import { APP_VARS } from "../../services/appVars";
import { launchReportInNewWindow } from "../../services/cognosService";
import { trackUserAction } from "../../services/dataService";
import { getUrlWithNoSpace, dateformat } from "../../services/utils";
import { DashboardsContext } from "./DashboardsContext";
import "./index.scss";

function DashboardsListView() {
  const gridRef = useRef();
  const { dashboardsContext, filterContext } = useContext(DashboardsContext);
  const [, , , , filteredDashboards] = dashboardsContext;
  const [filterField] = filterContext;

  const onCellClick = ({ data }) => {
    trackUserAction({
      action: "Open",
      targetType: data.type,
      targetName: data.name,
      targetPath: `${data.id}`,
      targetID: data.id,
      status: "Success",
      errorDetails: ""
    });
    launchReportInNewWindow(getUrlWithNoSpace(data.name), data.id);
  };
  const onGridReady = params => {
    gridRef.current = params;
    params.columnApi.autoSizeAllColumns();
    // params.columnApi.autoSizeColumns(["name"]);
  };
  useEffect(() => {
    if (gridRef.current) gridRef.current.columnApi.autoSizeAllColumns();
  }, [filteredDashboards]);
  return (
    <Row className="p-1 px-5 grid-class">
      {filteredDashboards.length > 0 ? (
        <Grid
          ref={gridRef}
          width="100%"
          title="Available Students"
          autoSizeColumns={true}
          isExecutiveGrid={true}
          onGridReady={onGridReady}
          columnDefs={[
            {
              field: "name",
              headerName: "Name",
              sortable: true,
              resizable: true,
              cellClass: "cell-class",
              minWidth: "500",
              cellRendererFramework: ({ data, value }) => {
                return (
                  <>
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={() => onCellClick({ data })}
                      src={`${
                        APP_VARS.REACT_APP_ASSETS_URL
                      }/images/Icons/DashboardListView/${
                        data.source === "GoogleDrive" &&
                        data.filetype.toLowerCase() !== "na"
                          ? data.filetype
                          : "default"
                      }.svg`}
                      width="30"
                      className="mr-3"
                      alt={data.name}
                    />
                    <a
                      style={{ cursor: "pointer", fontWeight: "500" }}
                      className="search-primary"
                      href={`/reportviewer/${getUrlWithNoSpace(data.name)}/${
                        data.id
                      }`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {value}
                    </a>
                  </>
                );
              }
            },
            {
              field: "owner",
              headerName: "Business Owner",
              sortable: true,
              resizable: true,
              minWidth: 200,
              cellClass: "cell-class",
              cellRendererFramework: ({ data, value }) => (
                <>
                  {value
                    ?.replaceAll(",", "")
                    .replaceAll(";", ", ")
                    .split(",")
                    .map((e, i) => (
                      <a
                        style={{ cursor: "pointer", fontWeight: "500" }}
                        className="search-primary"
                        href={`mailto:${e.split(":")[1]}?subject=${data?.name}`}
                        rel="noopener noreferrer"
                      >
                        {!!i && ", "}
                        {e.split(":")[0]}
                      </a>
                    ))}
                </>
              )
            }
            // {
            //   field: "duedate",
            //   headerName: "Due Date",
            //   sortable: true,
            //   resizable: true,
            //   minWidth: 150,
            //   maxWidth: 150,
            //   cellClass: "cell-class",
            //   cellRendererFramework: ({ data, value }) => {
            //     return <>{dateformat(value)}</>;
            //   }
            // }
          ]}
          rowData={filteredDashboards}
        />
      ) : (
        <p className="no-db-msg my-5 py-4">
          There are no Reports available for {filterField.label}
        </p>
      )}
    </Row>
  );
}

export default DashboardsListView;
