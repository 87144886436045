import React from "react";
import { launchReportInNewWindow } from "../../services/cognosService";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DashboardCard from "../../components/DashboardCard";
import { splitDashboardTags, getUrlWithNoSpace } from "../../services/utils";
import { trackUserAction } from "../../services/dataService";
import { APP_VARS } from "../../services/appVars";

function DashboardGridView({
  dashboards = [],
  onTagClick,
  favorites,
  onFavToggle
}) {
  return (
    <Row>
      {dashboards.map((a, index) => {
        const name = getUrlWithNoSpace(a.dbName);
        return (
          <Col key={index} sm={12} md={6} lg={4} xl={3}>
            <DashboardCard
              dbId={a.dbId}
              className="sx-hover-shadow grow mb-4"
              title={a.dbName}
              tags={a.dbTags ? splitDashboardTags(a.dbTags) : []}
              onClick={() => {
                trackUserAction({
                  action: "Open",
                  targetType: a.dbType,
                  targetName: a.dbName,
                  targetPath: `/reportviewer/${name}/${a.dbId}`,
                  targetID: a.dbId,
                  status: "Success",
                  errorDetails: ""
                });
                launchReportInNewWindow(name, a.dbId);
              }}
              popoverTitle={a.dbName}
              popoverText={a.dbDesc}
              onTagClick={tag => onTagClick(tag, `${a.dbName}/${tag}`)}
              imgUrl={`${APP_VARS.REACT_APP_ASSETS_URL}/${a.dbImg.replace(
                "assets/",
                ""
              )}`}
              isFav={favorites?.some(f => f.dbId === a.dbId)}
              onFavToggle={isFav => onFavToggle(isFav, a.dbId)}
              authorized={a.authorized}
            />
          </Col>
        );
      })}
    </Row>
  );
}

export default DashboardGridView;
