import React from "react";
import { Col, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { COURSES_DATA } from "../../pages/data-university/content";
import { APP_VARS } from "../../services/appVars";
import "./InfoCard.scss";

function InfoCard({
  className = "",
  title,
  subtitle,
  image,
  theme,
  linkUrl,
  courseIds,
  selectedCategory,
  isModal,
  setModalShow,
  ...otherProps
}) {
  const variantClass = theme ? `sx-${theme}` : "sx-green"; // blue, green, purple

  const coursePaths = COURSES_DATA.filter(data => {
    return courseIds.includes(data.id);
  });

  const getTotalTimeInString = minutes => {
    return `${minutes >= 60 ? `${Math.floor(minutes / 60)}h ` : ``}${minutes %
      60}m`;
  };

  return (
    <div
      className={`sx-info-card grow position-relative d-flex justify-content-center align-items-center ${className}`}
      {...otherProps}
    >
      <div className="sx-info-card shadow ">
        <div className={`p-4 ${variantClass}`}>
          <div
            className="sx-info-card__image "
            // onClick={() => history.push(linkUrl)}
          >
            {image}
          </div>
          <div className="sx-info-card__title">{title}</div>
        </div>
        <div className="pt-4 px-4 pb-2">
          <div className="sx-info-card__subtitle">{subtitle}</div>
        </div>
        <div className="px-4 mt-auto">
          <div className="row sx-info-card__box align-items-center justify-content-between">
            {isModal ? (
              <>
                <div
                  className="sx-info-card__linkText p-2"
                  onClick={() => setModalShow(selectedCategory)}
                >
                  What's in this Course Category?
                </div>
              </>
            ) : (
              <OverlayTrigger
                placement="auto"
                trigger={["hover", "focus"]}
                rootClose
                overlay={
                  <Popover id="course-popover" className="course-popover">
                    <Popover.Content className="course-popover-overflow">
                      <Row>
                        {coursePaths.map(c => (
                          <Col sm={6} key={c.id}>
                            <Row>
                              <Col sm={3} className="p-0">
                                <div className="icon-container">
                                  <img
                                    src={
                                      APP_VARS.REACT_APP_ASSETS_URL + c.iconPath
                                    }
                                    alt="course-icon"
                                  />
                                </div>
                              </Col>
                              <Col sm={9}>
                                <h5 className="pt-3 px-3 font-weight-bold color-primary">
                                  {c.name}
                                </h5>
                                <div className="font-small pt-3 px-3">
                                  <p>
                                    <span className="font-weight-bold">
                                      {c.courses.length} Courses
                                    </span>
                                    {` (${c.courses
                                      .map(course => course.title)
                                      .join(`, `)})`}
                                  </p>
                                  <p>
                                    <span className="font-weight-bold">
                                      Total Run Time:
                                    </span>
                                    {` (${getTotalTimeInString(
                                      c.courses.reduce(
                                        (totalDuration, course) => {
                                          let m = course.duration
                                            .split(":")
                                            .reverse(); // split it at the colons
                                          let minutes =
                                            Math.ceil(+m[0] / 100) +
                                            +m[1] +
                                            (m.length === 3 ? +m[2] * 60 : 0);
                                          return minutes + totalDuration;
                                        },
                                        0
                                      )
                                    )})`}
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        ))}
                      </Row>
                    </Popover.Content>
                  </Popover>
                }
              >
                {/* e.stopPropagation() is needed to prevent double click events when clicking on the i icon */}
                <div
                  className="sx-info-card__linkText p-2"
                  onClick={e => e.stopPropagation()}
                >
                  What is in the course?
                </div>
              </OverlayTrigger>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoCard;
