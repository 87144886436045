import React, { createContext, useState } from "react";

export const DashboardsContext = createContext();
export const DashboardsProvider = props => {
  const [allDomains, setAllDomains] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [activeDomain, setActiveDomain] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);
  const [allDashboards, setAllDashboards] = useState([]);
  const [filteredDashboards, setFilteredDashboards] = useState([]);
  const [activeDashboard, setActiveDashboard] = useState(null);
  const [activeLayout, setActiveLayout] = useState("");
  const [filterText, setFilterText] = useState("");
  const [filterField, setFilterField] = useState({ label: "All", value: "" });
  const [viewAs, setViewAs] = useState("list");
  const [levels, setLevels] = useState([]);
  const [activeLevels, setActiveLevels] = useState([]);
  const [activeSubLevel, setActiveSubLevel] = useState(null);
  const [allSubLevels, setAllSubLevels] = useState([]);
  const [filteredSubLevels, setFilteredSubLevels] = useState([]);

  return (
    <DashboardsContext.Provider
      value={{
        domainsContext: [
          allDomains,
          setAllDomains,
          activeDomain,
          setActiveDomain
        ],
        categoryContext: [
          allCategories,
          setAllCategories,
          activeCategory,
          setActiveCategory,
          filteredCategories,
          setFilteredCategories
        ],
        dashboardsContext: [
          allDashboards,
          setAllDashboards,
          activeDashboard,
          setActiveDashboard,
          filteredDashboards,
          setFilteredDashboards
        ],
        layoutContext: [viewAs, setViewAs],
        filterContext: [filterField, setFilterField, filterText, setFilterText],
        levelsContext: [levels, setLevels, activeLevels, setActiveLevels],
        subLevelContext: [
          allSubLevels,
          setAllSubLevels,
          activeSubLevel,
          setActiveSubLevel,
          filteredSubLevels,
          setFilteredSubLevels
        ]
      }}
    >
      {props.children}
    </DashboardsContext.Provider>
  );
};
