import React from "react";
import { APP_VARS } from "../../services/appVars";
import { Modal, Row, Col } from "react-bootstrap";
import { AiFillQuestionCircle } from "react-icons/ai";
import "./ServiceNowInstructions.scss";

const S_NOW_INSTRUCTIONS = [
  {
    name: "Search for Insights Marketplace",
    img: "/images/S-Now_Images/serviceNowSearch.png"
  },
  {
    name: "Select Insights Marketplace in the Access Selector List",
    img: "/images/S-Now_Images/serviceNowAccessSelector.png"
  },
  {
    name: "Select Subdomain(s)",
    img: "/images/S-Now_Images/serviceNow_addDomain.png"
  }
];

export default function ServiceNowInstructions({ show, handleCloseModal }) {
  return (
    <Modal
      show={show}
      onHide={handleCloseModal}
      centered
      className="ServiceNowInstructions"
    >
      <Modal.Header closeButton className="p-2">
        <AiFillQuestionCircle size={28} className="mt-1 mr-2" />
        <Modal.Title>How to Request Access using Service Now?</Modal.Title>
      </Modal.Header>

      <Modal.Body className="bg-body-color">
        {S_NOW_INSTRUCTIONS.map((s, i) => (
          <Row
            className="d-flex align-items-center justify-content-between p-2 m-3 bg-white"
            key={i}
          >
            <Col sm={4}>
              <h5>{s.name}</h5>
            </Col>
            <Col sm={8}>
              <img
                className="border w-100 rounded p-3"
                fluid
                src={APP_VARS.REACT_APP_ASSETS_URL + s.img}
                alt="cover"
              />
            </Col>
          </Row>
        ))}
      </Modal.Body>
    </Modal>
  );
}
