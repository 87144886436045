import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Row, CloseButton } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import Slider from "react-slick";
import { DataUniversityContext } from "./DataUniversityContext";
import "./LandingCourseLibrary.scss";
const convertToHrsMins = (duration = "", small = false) => {
  let hours = duration
    ?.split(",")[0]
    ?.split("hrs")[0]
    .trim();
  // totalSeconds %= 3600;
  let minutes = duration
    ?.split(",")[1]
    ?.split("ms")[0]
    .trim();
  if (hours || minutes) {
    return small
      ? `${hours < 9 ? `0${hours}` : hours}h ${
          minutes < 9 ? `0${minutes}` : minutes
        }m`
      : `${hours < 9 ? `0${hours}` : hours}hr ${
          minutes < 9 ? `0${minutes}` : minutes
        }mins`;
  }
};
export default function LandingCourseLibrary({ selectedCategory, setShow }) {
  return (
    <div className="LandingCourseLibrary ">
      <div className="course-library-div">
        <Card.Header className="category-title-div px-5 py-4 ">
          <Row>
            <Col sm={6} className="category-title-center">
              <span className="category-title">{selectedCategory?.name} </span>
            </Col>
            <Col sm={6} className="category-title-center mt-2">
              <CloseButton
                style={{ color: "white" }}
                onClick={() => {
                  setShow(false);
                }}
              />
            </Col>
          </Row>
        </Card.Header>
        <div className="learning-paths-div">
          {selectedCategory?.learning_paths
            ?.sort((a, b) => a.SortOrder - b.SortOrder)
            .map(lp => (
              <LearningPath lp={lp} />
            ))}
        </div>
      </div>
    </div>
  );
}
function LearningPath({ lp }) {
  const [learningPath, setLearningPath] = useState(null);
  const { coursesContext, learningPathsContext } = useContext(
    DataUniversityContext
  );
  const [courses] = coursesContext;
  const [learningPaths] = learningPathsContext;
  let settings = {
    dots: false,
    centerMode: false,
    slidesToShow: 3,
    swipeToSlide: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    nextArrow: <MdKeyboardArrowRight />,
    prevArrow: <MdKeyboardArrowLeft />
  };
  useEffect(() => {
    setLearningPath(learningPaths.find(el => el.id === lp.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lp, courses, learningPaths]);
  return (
    <>
      {learningPath && (
        <div className="learning-path-div">
          <Row className=" px-5 py-4">
            <h5 className="ml-4 text-muted">{learningPath.name}</h5>
          </Row>
          <div className="mb-1 px-2 mt-n4">
            <Slider {...settings}>
              {learningPath?.courses
                ?.filter(c => !!c.MakeAvailableInDU)
                ?.sort((a, b) => a.SortOrder - b.SortOrder)
                ?.map((cc, index) => (
                  <SliderItem cc={cc} key={cc.title + index} lp={lp} />
                ))}
            </Slider>
          </div>
        </div>
      )}
    </>
  );
}

function SliderItem({ cc, lp }) {
  return (
    <div className="landing-course-div mx-3">
      <div className="landing-course-thumbnail">
        <img
          src={cc?.thumbnail?.url || cc?.ThumbnailUrl}
          alt={cc?.thumbnail?.url || cc?.ThumbnailUrl}
        />
        {/* {enrollments?.find(e => e.course.id === cc?.id) && (
  <ProgressBar
    className="mt-n2 progressbar"
    variant="success"
    now={
      enrollments?.find(e => e.course.id === cc?.id).ProgressPct
    }
  />
)} */}
      </div>
      <p className="mt-3 course-title">{cc?.title}</p>
      <p className="mt-2 course-skillpoints">
        <b>Skill Points:</b> {cc?.SkillPoint}
      </p>
      <small className="mt-2 course-description">
        <OverlayTrigger
          trigger="click"
          placement="right"
          rootClose
          overlay={
            <Card className="course-details-card">
              <Card.Img
                variant="top"
                src={cc?.thumbnail?.url || cc?.ThumbnailUrl}
                className="course-details-img"
              />
              {cc?.Duration && (
                <div className="duration-container p-2">
                  {convertToHrsMins(cc?.Duration, true)}
                </div>
              )}
              <Card.Body className="p-4">
                <Card.Title>{cc?.title}</Card.Title>
                <Card.Text>
                  {/*   eslint-disable-next-line */}
                  <p className="mt-2 learning-path-text">{lp.name}</p>
                  <p style={{ fontSize: "1.4rem" }}>
                    <div className="details-row mt-2">
                      {cc?.DifficultyLevel && (
                        <span> {cc?.DifficultyLevel}</span>
                      )}
                      {cc?.Duration && (
                        <span>{convertToHrsMins(cc?.Duration)}</span>
                      )}
                      {cc?.SkillPoint && (
                        <span> Skill Points: {cc?.SkillPoint}</span>
                      )}
                    </div>
                  </p>
                  <div className="mt-2 description-container">
                    <p className="" style={{ fontSize: "1.4rem" }}>
                      {cc?.description}
                    </p>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          }
        >
          {/*   eslint-disable-next-line */}
          <p
            className="view-details-text"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            View Details
          </p>
        </OverlayTrigger>
      </small>
    </div>
  );
}
