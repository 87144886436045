import React, { useContext, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import ViewAs from "../../components/ViewAs";
import CategoriesRow from "./CategoriesRow";
import { DashboardsContext } from "./DashboardsContext";
import DashboardsGridView from "./DashboardsGridView";
import DashboardsListView from "./DashboardsListView";
import "./index.scss";
import ZShapeLayoutWidget from "./ZShapeLayoutWidget";
import SearchBoxRow from "./SearchBoxRow";

function HeaderRow({ onTagClick, favorites, onFavToggle }) {
  const { domainsContext, categoryContext, filterContext } = useContext(
    DashboardsContext
  );
  const [, , activeDomain] = domainsContext;
  const [, , activeCategory, ,] = categoryContext;
  const [, , filterText, setFilterText] = filterContext;
  const [viewAs, setViewAs] = useState("list");

  return (
    <div className="px-4 pb-3 mx-2">
      <div className="pl-4 p-2 dashboard-header-container d-flex justify-content-between">
        <div>
          {activeDomain?.header
            ? activeDomain?.header
            : `${activeDomain?.name || ""} ${
                activeCategory?.name ? "- " + activeCategory?.name : ""
              }`}
        </div>
        <div>
          <ZShapeLayoutWidget />
        </div>
      </div>
    </div>
  );
}

export default HeaderRow;
