import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { trackUserAction } from "../../services/dataService";
import { LinkContainer } from "react-router-bootstrap";
import { useScreenSize } from "../../hooks/customHooks";
import { launchReportInNewWindow } from "../../services/cognosService";
import Button from "react-bootstrap/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import BaseCard from "../../components/BaseCard";
import Spinner from "../../components/Spinner";
import CategoryCard from "../../components/CategoryCard";
import Slider from "../../components/Slider";
import "./DashboardCategories.scss";
import DashboardCard from "../../components/DashboardCard";
import { splitDashboardTags, getUrlWithNoSpace } from "../../services/utils";
import { APP_VARS, S_NOW_LINK } from "../../services/appVars";
import { AiFillQuestionCircle } from "react-icons/ai";
import ServiceNowInstructions from "./ServiceNowInstructions";

function DashboardCategories({
  activeDomain = {},
  onTagClick,
  categories,
  dashboards,
  dashboardsLoading,
  categoriesLoading,
  setActiveFilters,
  activeCategory,
  favorites,
  onFavToggle,
  categoriesWithHelpLinks
}) {
  const history = useHistory();
  const [carouselWidth, setCarouselWidth] = useState();

  const [isSmall, isMedium, isLarge, isExtraLarge, isXXL] = useScreenSize();
  const [show, setShow] = useState(false);
  const [helpLinks, setHelpLinks] = useState([]);

  const handleCloseModal = () => {
    setShow(false);
  };
  const handleOpenModal = () => {
    setShow(true);
  };

  useEffect(() => {
    setHelpLinks(
      categoriesWithHelpLinks.find(el => el.NAME === activeDomain.value)
        ?.Help_Links
    );
    if (
      categories &&
      categories.length > 0 &&
      Object.keys(activeCategory).length === 0
    ) {
      setActiveFilters(af => [
        ...af,
        {
          type: "Subdomain",
          value: categories[0].NAME,
          id: categories[0].TypeID,
          dbKeyName: "dbCategoryID"
        }
      ]);
    }
  }, [activeCategory, categories, setActiveFilters]);

  useEffect(() => {
    if (isSmall || isMedium) {
      setCarouselWidth(1);
    }
    if (isLarge) {
      setCarouselWidth(2);
    }
    if (isExtraLarge) {
      setCarouselWidth(3);
    }
    if (isXXL) {
      setCarouselWidth(4);
    }
  }, [isSmall, isMedium, isLarge, isExtraLarge, isXXL]);
  return (
    <BaseCard className="DashboardCategories">
      <div className="pt-1 pl-2 gray-bottom-border-1">
        <Breadcrumb>
          <LinkContainer to="/">
            <Breadcrumb.Item as="div">Home</Breadcrumb.Item>
          </LinkContainer>
          <LinkContainer to="/dashboardcatalog/domains">
            <Breadcrumb.Item as="div">Dashboard Catalog</Breadcrumb.Item>
          </LinkContainer>
          <Breadcrumb.Item active>{activeDomain.value}</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="d-flex align-items-center p-4">
        <span className="text-primary font-medium">
          DASHBOARD CATALOG - {activeDomain.value}
        </span>
      </div>
      {!!helpLinks && (
        <div className="d-flex align-items-center pl-4 pb-3 pt-3 doc-links-cnt">
          <span className="text-muted" style={{ fontSize: "14px" }}>
            Documentation:
          </span>{" "}
          {helpLinks?.map((e, i) => (
            <>
              {!!i ? <span className="text-muted">|</span> : <></>}
              <a href={e.url} className="mx-2" target="_blank">
                {e.label}
              </a>
            </>
          ))}
        </div>
      )}
      <div>
        {categoriesLoading && <Spinner>Fetching Categories</Spinner>}
        {!categoriesLoading && categories.length === 0 && (
          <div className="text-center">No categories available.</div>
        )}
        {!categoriesLoading && categories.length !== 0 && (
          <div className="p-4 DashboardCategories__section-categories d-flex flex-wrap">
            {categories.map(c => (
              <div key={c.TypeID}>
                <CategoryCard
                  title={c.NAME}
                  className="pointer mr-3 mb-2"
                  isActive={c.TypeID === Number(activeCategory.TypeID)}
                  popoverText={c.Description}
                  popoverTitle={c.NAME}
                  authorized={c.authorized}
                  onClick={() => {
                    setActiveFilters(af => {
                      const filtered = af.filter(i => i.type !== "Subdomain");
                      return [
                        ...filtered,
                        {
                          type: "Subdomain",
                          value: c.NAME,
                          id: c.TypeID,
                          dbKeyName: "dbCategoryID"
                        }
                      ];
                    });
                  }}
                />
              </div>
            ))}
          </div>
        )}

        {activeCategory.TypeID && (
          <div className="DashboardCategories__dashboards-section">
            {dashboardsLoading && <Spinner>Fetching Dashboards</Spinner>}
            {!dashboardsLoading && dashboards.length > 0 && (
              <div className="py-5 px-5">
                <div className="pb-3 d-flex align-items-center">
                  <span className="text-primary font-weight-bold font-medium">
                    {activeCategory.NAME}
                  </span>
                  <span className="text-secondary font-medium pl-2">
                    ({dashboards.length})
                  </span>
                  <div className="d-flex align-items-center ml-auto pr-3">
                    {!activeCategory.authorized && (
                      <>
                        <div onClick={handleOpenModal} className="pointer mr-2">
                          <AiFillQuestionCircle size={32} color="#a7a9ab" />
                        </div>
                        <a
                          target="_blank"
                          rel="noreferrer noopener"
                          href={S_NOW_LINK}
                        >
                          <Button variant="success" className="request-button">
                            Request Access
                          </Button>
                        </a>
                      </>
                    )}
                    <Button
                      onClick={() => {
                        trackUserAction({
                          action: "Search",
                          targetType: "dashboards",
                          targetName: `${activeCategory.NAME}`,
                          targetPath: `/dashboardcatalog/search`,
                          targetID: "",
                          status: "Success",
                          errorDetails: ""
                        });
                        history.push(`/dashboardcatalog/search`);
                      }}
                      variant="link"
                    >
                      View All
                    </Button>
                  </div>
                </div>
                <Slider
                  infinite={false}
                  slidesToShow={Math.min(dashboards.length, carouselWidth)}
                  slidesToScroll={Math.min(dashboards.length, carouselWidth)}
                >
                  {dashboards.map(a => (
                    <div key={a.dbId}>
                      <DashboardCard
                        dbId={a.dbId}
                        style={{ maxWidth: "26rem", margin: "0 auto" }}
                        className="sx-hover-shadow grow"
                        title={a.dbName}
                        onClick={() => {
                          launchReportInNewWindow(
                            getUrlWithNoSpace(a.dbName),
                            a.dbId
                          );
                        }}
                        onTagClick={tag =>
                          onTagClick(
                            tag,
                            `${activeDomain.value}/${activeCategory.NAME}/${a.dbName}/${tag}`
                          )
                        }
                        tags={a.dbTags ? splitDashboardTags(a.dbTags) : []}
                        popoverTitle={a.dbName}
                        popoverText={a.dbDesc}
                        imgUrl={`${
                          APP_VARS.REACT_APP_ASSETS_URL
                        }/${a.dbImg.replace("assets/", "")}`}
                        isFav={favorites.some(f => "" + f.dbId === "" + a.dbId)}
                        onFavToggle={isFav => onFavToggle(isFav, a.dbId)}
                        authorized={a.authorized}
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            )}
            {!dashboardsLoading &&
              !categoriesLoading &&
              dashboards.length === 0 && (
                <div className="text-center py-5">
                  No dashboards available for this category.
                </div>
              )}
          </div>
        )}
        <ServiceNowInstructions
          show={show}
          handleCloseModal={handleCloseModal}
        />
      </div>
    </BaseCard>
  );
}

export default DashboardCategories;
