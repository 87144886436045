import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Accordion from "react-bootstrap/Accordion";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { Card } from "react-bootstrap";

import "./index.scss";

function AccordionCard({ title, body, idx, className = "", ...otherProps }) {
  const [expanded, setExpanded] = useState(false);
  const toggleRef = useRef(null);
  useEffect(() => {
    !idx && toggleRef.current.click();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Accordion>
      <Card.Header className="p-0 border-bottom-0">
        <Accordion.Toggle
          ref={toggleRef}
          eventKey="0"
          onClick={() => setExpanded(!expanded)}
          as="div"
        >
          <div className="d-flex align-items-center justify-content-between pointer px-2 py-2">
            <div className="sx-title-body__item-title">
              <span className="ml-2">{title}</span>
            </div>
            {body && (
              <div className="sx-title-body__toggle-button">
                {expanded ? (
                  <MdKeyboardArrowUp size="28" />
                ) : (
                  <MdKeyboardArrowDown size="28" />
                )}
              </div>
            )}
          </div>
        </Accordion.Toggle>
      </Card.Header>
      {body && (
        <Accordion.Collapse eventKey="0">
          <Card.Body className="py-0">{body}</Card.Body>
        </Accordion.Collapse>
      )}
    </Accordion>
  );
}

AccordionCard.propTypes = {
  title: PropTypes.any,
  body: PropTypes.any
};

export default AccordionCard;
