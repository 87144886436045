import React from "react";
import { InputGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { AiOutlineSearch } from "react-icons/ai";
import "./SelectSearchForm.scss";

function SelectSearchForm({
  onSubmit = () => {},
  setSearchType = () => {},
  searchType,
  ...inputProps
}) {
  return (
    <Form
      className="sx-select-search-form"
      onSubmit={e => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <InputGroup>
        <Form.Control type="text" {...inputProps}></Form.Control>
        <InputGroup.Append>
          <Button type="submit" className="search-btn">
            <AiOutlineSearch />
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </Form>
  );
}

export default SelectSearchForm;
