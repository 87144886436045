import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { AiFillInfoCircle, AiFillFileText } from "react-icons/ai";
import { MdFavorite } from "react-icons/md";
import { LinkContainer } from "react-router-bootstrap";
import { useParams } from "react-router-dom";
import BaseCard from "../../components/BaseCard";
import BasePage from "../../components/layouts/BasePage";
import ToggleDisclaimer from "../../components/ToggleDisclaimer";
import { useRecentsContext } from "../../context/recents";
import { useUserPreferencesContext } from "../../context/user-preferences";
import { S_NOW_LINK } from "../../services/appVars";
import { FaFolder } from "react-icons/fa";
import {
  fetchDashboardURL,
  fetchDashboards,
  trackUserAction,
  fetchDashboardDomainsAndCategories
} from "../../services/dataService";
import { navbarHeight } from "../../variables.scss";
import SmartAssetViewerWidget from "./SmartAssetViewerWidget";

function ReportViewer({ setActiveFilters }) {
  const { dbid } = useParams();
  const [dburl, setDBURL] = useState();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState(null);
  const [authError, setAuthError] = useState(null);
  const [hasMetrics, setHasMetrics] = useState(false);
  const { addRecentDashboard } = useRecentsContext();
  const [dashboard, setDashboard] = useState();
  const [helpLinks, setHelpLinks] = useState([]);

  const {
    favorites,
    addFavorites,
    removeFavorites
  } = useUserPreferencesContext();

  useEffect(() => {
    Promise.all([
      fetchDashboardURL(dbid),
      fetchDashboards(),
      fetchDashboardDomainsAndCategories()
    ])
      .then(response => {
        const {
          obj_URL: url,
          obj_NM: name,
          obj_thumbnail: thumbnail,
          Obj_Desc: descr,
          OpenInNewWindow: openinnewindow
        } = response[0].data[0];
        document.title = name;
        setTitle(name);
        setDescription(descr);
        setHasMetrics(!!response[0].data[0].hasmetric);
        // hasmetric
        addRecentDashboard({ name, url, dbId: dbid, thumbnail });
        let domainsArray = response[2].Domains;
        let categoriesWithHelpLinks = domainsArray.map(el => {
          let newDomain = !!el?.Help_Links
            ? {
                ...el,
                Help_Links: JSON.parse(el?.Help_Links.replaceAll(/'\'/g, "-"))
              }
            : {
                ...el,
                Help_Links: ""
              };
          return newDomain;
        });
        // console.log("Domains", categoriesWithHelpLinks);

        if (openinnewindow === "Y") {
          return window.open(url, "_self");
        }
        /* if (url.indexOf("infogix") > -1) {
        return window.open(url, "_self");
      }
      if (url.indexOf("savagebeast") > -1) {
        return window.open(url, "_self");
      } */
        let nDashboard = response[1].data.find(e => e.dbId === +dbid);
        setDashboard(nDashboard);
        let newHelpLinks = categoriesWithHelpLinks.find(
          el => el?.NAME === nDashboard?.dbDomainName
        )?.Help_Links;

        !!newHelpLinks ? setHelpLinks(newHelpLinks) : setHelpLinks([]);

        trackUserAction({
          action: "View",
          targetType: "IA",
          targetName: response[0].data[0]["obj_NM"],
          targetPath: url,
          targetID: dbid,
          status: "Success",
          errorDetails: ""
        });

        setDBURL(url);
      })
      .catch(err => {
        setError(true);
        if (err.message === "Request failed with status code 401") {
          setAuthError(true);
        }
      });
    // fetchDashboardURL(dbid)
  }, [dbid, addRecentDashboard]);

  const isFav = favorites.some(f => "" + f.dbId === "" + dbid);
  const onFavToggle = isFav => {
    isFav ? addFavorites({ dbId: dbid }) : removeFavorites({ dbId: dbid });
  };

  const popoverText = description;
  const popoverTitle = title;

  return (
    <BasePage className="ReportViewer">
      {dburl && (
        <>
          <div className="bg-white px-4">
            <Row>
              <Col sm={6}>
                <Breadcrumb>
                  <LinkContainer to="/">
                    <Breadcrumb.Item as="div">Home</Breadcrumb.Item>
                  </LinkContainer>
                  <LinkContainer to="/dashboardcatalog/domains">
                    <Breadcrumb.Item as="div">
                      Dashboard Catalog
                    </Breadcrumb.Item>
                  </LinkContainer>
                  {!!dashboard && (
                    <>
                      <LinkContainer
                        to="/dashboardcatalog/categories"
                        onClick={e => {
                          setActiveFilters([
                            {
                              type: "Business Domain",
                              value: dashboard.dbDomainName,
                              id: dashboard.dbDomainID,
                              dbKeyName: "dbDomainID"
                            }
                          ]);
                        }}
                      >
                        <Breadcrumb.Item as="div">
                          {dashboard.dbDomainName}
                        </Breadcrumb.Item>
                      </LinkContainer>
                      <LinkContainer
                        to="/dashboardcatalog/categories"
                        onClick={e => {
                          setActiveFilters([
                            {
                              type: "Business Domain",
                              value: dashboard.dbDomainName,
                              id: dashboard.dbDomainID,
                              dbKeyName: "dbDomainID"
                            },
                            {
                              type: "Subdomain",
                              value: dashboard.dbCategoryName,
                              id: dashboard.dbCategoryID,
                              dbKeyName: "dbCategoryID"
                            }
                          ]);
                        }}
                      >
                        <Breadcrumb.Item as="div">
                          {dashboard.dbCategoryName}
                        </Breadcrumb.Item>
                      </LinkContainer>
                    </>
                  )}

                  <Breadcrumb.Item active>{title}</Breadcrumb.Item>
                </Breadcrumb>
                <div className="d-flex align-items-center px-2 pb-2 pt-0">
                  {(popoverText || popoverTitle) && (
                    <OverlayTrigger
                      placement="bottom"
                      trigger="click"
                      rootClose
                      overlay={
                        <Popover>
                          <Popover.Content>
                            <div className="font-medium font-weight-bold color-primary pt-3 px-3">
                              {popoverTitle}
                            </div>
                            <div className="p-3">{popoverText}</div>
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      {/* e.stopPropagation() is needed to prevent double click events when clicking on the i icon */}
                      <AiFillInfoCircle
                        onClick={e => e.stopPropagation()}
                        className="banner-icon font-medium pointer"
                      />
                    </OverlayTrigger>
                  )}

                  <MdFavorite
                    onClick={e => {
                      e.stopPropagation();
                      onFavToggle(!isFav);
                    }}
                    className={`DashboardCard__${
                      isFav ? `fav-icon` : `grey-icon`
                    } font-medium mr-2 pointer`}
                  />
                  <h5 className="m-0 text-primary">{title}</h5>
                  {hasMetrics ? (
                    <SmartAssetViewerWidget
                      title={title}
                      listView={true}
                      bName={title}
                      dbid={dbid}
                    />
                  ) : (
                    <SmartAssetViewerWidgetDisabled />
                  )}

                  {!!helpLinks.length && (
                    <OverlayTrigger
                      placement="bottom"
                      trigger="click"
                      rootClose
                      overlay={
                        <Popover style={{ maxWidth: "900px" }}>
                          <Popover.Content>
                            <div className="p-3">
                              {helpLinks?.map((e, i) => (
                                <a
                                  style={{ display: "block" }}
                                  href={e.url}
                                  className="mx-2"
                                  target="_blank"
                                >
                                  {e.label}
                                </a>
                              ))}
                            </div>
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      {/* e.stopPropagation() is needed to prevent double click events when clicking on the i icon */}

                      <a
                        href="javascript:void(0);"
                        onClick={e => e.stopPropagation()}
                        className="pointer mt-1 mx-2"
                      >
                        {" "}
                        <AiFillFileText className="font-small banner-icon" />
                        <small className="mt-1 ml-1">Documentation</small>{" "}
                      </a>
                    </OverlayTrigger>
                  )}
                </div>
              </Col>

              <Col sm={6}>
                <ToggleDisclaimer />
              </Col>
            </Row>
            {/* {JSON.stringify(helpLinks)} */}
          </div>
          <iframe
            title="Viewer"
            id="dashbaords-if"
            src={dburl}
            style={{ width: "100%", height: `calc(100vh - ${navbarHeight})` }}
          ></iframe>
        </>
      )}
      {error && (
        <BasePage className="p-5">
          <BaseCard>
            <Container fluid className="p-5">
              <Row>
                <Col sm={12} className="text-center py-5 text-muted">
                  {authError ? (
                    <div>
                      <h5>Authorization Required</h5>
                      <p>
                        You do not have permission to view this content. Please
                        submit a Service Now ticket to request access.
                      </p>
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href={S_NOW_LINK}
                      >
                        <Button
                          variant="success"
                          className="request-button mt-3"
                        >
                          Request Access
                        </Button>
                      </a>
                    </div>
                  ) : (
                    "Failed to load the dashboard!"
                  )}
                </Col>
              </Row>
            </Container>
          </BaseCard>
        </BasePage>
      )}
    </BasePage>
  );
}

function SmartAssetViewerWidgetDisabled() {
  return (
    <>
      <span
        className="text-info mt-1 mx-2"
        style={{ opacity: 0.5, cursor: "not-allowed" }}
      >
        <FaFolder className={"font-small "} />{" "}
        <small className="mt-1">Asset Details</small>
      </span>
      <br />
    </>
  );
}

export default ReportViewer;
