import React, { useState, useRef, useEffect } from "react";
import { trackUserAction } from "../../services/dataService";
import VideoCarousel from "./VideoCarousel";
import VideoPlaylist from "./VideoPlaylist";

function VideoPlayer({ videoList: initialVideoList, playlistTitle }) {
  const [videoList, setVideoList] = useState(initialVideoList);
  const [activeVideo, setActiveVideo] = useState(videoList[0]);
  const sliderRef = useRef();

  useEffect(() => {
    setVideoList(initialVideoList);
  }, [initialVideoList]);

  useEffect(() => {
    trackUserAction({
      action: "View",
      targetType: "Video",
      targetName: activeVideo?.title || "",
      targetPath: activeVideo?.src || "",
      targetID: activeVideo?.title || "",
      status: "Success",
      errorDetails: ""
    });
  }, [activeVideo]);

  return (
    <div>
      <VideoCarousel
        sliderRef={sliderRef}
        videoList={videoList}
        setVideoList={setVideoList}
        setActiveVideo={setActiveVideo}
      />
      <VideoPlaylist
        title={playlistTitle}
        sliderRef={sliderRef}
        setActiveVideo={setActiveVideo}
        activeVideo={activeVideo}
        videoList={videoList}
        setVideoList={setVideoList}
      />
    </div>
  );
}

export default VideoPlayer;
