import React, { useEffect, useState } from "react";
import BasePage from "../../components/layouts/BasePage";
import BaseCard from "../../components/BaseCard";
import { fetchAllFilters, trackUserAction } from "../../services/dataService";
import { useAlertsContext } from "../../context/alerts";
import Button from "react-bootstrap/Button";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Spinner from "../../components/Spinner";

import Row from "react-bootstrap/Row";
import reportHelpGuides from "../dashboard-catalog/report-guides";
import "./index.scss";

export default function DataDocumentation() {
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState("domains");
  // const [filterAllData, setFilterAllData] = useState([]);
  // const [pivot, setPivot] = useState(0);
  const [domainsBtn, setDomainsBtn] = useState(true);
  const [cognosBtn, setCognosBtn] = useState(false);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const { addFailAlert } = useAlertsContext();
  // Fetch master filter data once on load.  Used for populating filter sidebar
  useEffect(() => {
    setLoading(true);
    fetchAllFilters()
      .then(result => {
        if (!Array.isArray(result.data)) {
          return;
        }
        const filterAllData = result.data
          ?.filter(e => e.type === "Data Domain")
          ?.map(e => e["Data Domain"])
          ?.flat()
          .filter(el => !!el.helplink)
          .sort((a, b) => {
            if (a["Data Domain"] < b["Data Domain"]) {
              return -1;
            }
            if (a["Data Domain"] > b["Data Domain"]) {
              return 1;
            }
            return 0;
          });

        const pivot = Math.ceil(
          result.data
            ?.find(e => e.type === "Data Domain")
            ["Data Domain"]?.map(el => el.helplink.length)
            .reduce((c, p) => c + p, 0) / 2
        );
        const tempData1 = [];
        const tempData2 = [];

        for (const p of filterAllData) {
          if (tempData1.reduce((a, b) => a + b.helplink.length, 0) < pivot)
            tempData1.push(p);
          else tempData2.push(p);
        }

        let data1Count = 0;
        let data2Count = 0;

        do {
          data1Count =
            tempData1.length +
            tempData1.map(el => el.helplink.length).reduce((c, p) => c + p, 0);
          data2Count =
            tempData2.length +
            tempData2.map(el => el.helplink.length).reduce((c, p) => c + p, 0);

          if (data1Count - 1 < data2Count) {
            tempData1.push(tempData2.shift());
          } else break;
        } while (data1Count < data2Count);

        setData1(tempData1);
        setData2(tempData2);
        // setPivot(d);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        // addFailAlert("Something went wrong fetching filters");
      });
  }, [addFailAlert]);

  const DataDomainsComponent = () => (
    <Row>
      <Col>
        {data1.map((e, index) => (
          <DomainComponent key={e["Data Domain"]} e={e} />
        ))}
      </Col>
      <Col>
        {data2.map((e, index) => (
          <DomainComponent key={e["Data Domain"]} e={e} />
        ))}
      </Col>
    </Row>
  );

  // const LegacyCognosComponent = () => (
  //   <Row>
  //     <Col>
  //       {reportHelpGuides().map((e, index) => (
  //         <>{index % 2 === 0 && <CognosComponent e={e} />}</>
  //       ))}
  //     </Col>
  //     <Col>
  //       {reportHelpGuides().map((e, index) => (
  //         <>{index % 2 === 1 && <CognosComponent e={e} />}</>
  //       ))}
  //     </Col>
  //   </Row>
  // );
  return (
    <>
      {!loading ? (
        <BasePage className="Home">
          <div className="p-4">
            <BaseCard>
              <div className="p-4 data-doc-btn mx-4 mb-4">
                <p className="doc-heading my-3">Data Documentation</p>
                {/* <Button
                  className={`font-small mb-3 ${
                    domainsBtn
                      ? "data-document-btn-active"
                      : "data-document-btn"
                  }`}
                  onClick={() => {
                    setTab("domains");
                    setDomainsBtn(true);
                    setCognosBtn(false);
                  }}
                >
                  Data Domains
                </Button> */}

                {/* <Button
                  className={`font-small ml-2 mb-3 ${
                    cognosBtn ? "data-document-btn-active" : "data-document-btn"
                  }`}
                  onClick={() => {
                    setTab("cognos");
                    setDomainsBtn(false);
                    setCognosBtn(true);
                  }}
                >
                  Legacy Cognos
                </Button> */}
                {tab === "domains" && <DataDomainsComponent />}
                {/* {tab === "cognos" && <LegacyCognosComponent />} */}
              </div>
            </BaseCard>
          </div>
        </BasePage>
      ) : (
        <BasePage className="Home p-4">
          <BaseCard className="p-5">
            <Spinner>Loading...</Spinner>
          </BaseCard>
        </BasePage>
      )}
    </>
  );
}

const DomainComponent = ({ e }) => (
  <>
    <h5 className="domain-heading">{e["Data Domain"]}</h5>
    {e.helplink.map(el => (
      <a key={el.label} href={el.url} target="_blank">
        {el.label}
      </a>
    ))}
  </>
);

// const CognosComponent = ({ e }) => (
//   <>
//     <h5 className="cognos-heading">{e.text}</h5>
//     <p>
//       <a href={e.url} target="_blank">
//         {e.text}
//       </a>
//     </p>
//   </>
// );
