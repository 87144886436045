import React, { useEffect, useState, useContext } from "react";
import { AiOutlineAim, AiOutlineEye } from "react-icons/ai";
import AccentButton from "../../components/AccentButton";
import Grid from "../../components/Grid";
import Spinner from "../../components/Spinner";
import { useAlertsContext } from "../../context/alerts";
import { APP_VARS } from "../../services/appVars";
import {
  addStagingCourse,
  fetchStagingCourses,
  trackUserAction,
  getCourses
} from "../../services/dataService";
import { AbilityContext, PermissionsContext } from "./AbilityContext";
import { Can } from "./AbilityContext";
import defineRulesFor from "./permissions";

function CourseManagement(props) {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const { addSuccessAlert, addFailAlert } = useAlertsContext();

  const ability = useContext(AbilityContext);
  const { permissionsCtxt } = useContext(PermissionsContext);
  const [permissions, , isAdmin] = permissionsCtxt;

  useEffect(() => {
    const rules = defineRulesFor(permissions, isAdmin);
    ability.update(rules);
  }, [permissions.length]);

  useEffect(() => {
    Promise.all([fetchStagingCourses(), getCourses()])
      .then(res => {
        const courseData = res[1].data
          .map(el => {
            return { ...el, cmsIdToCSV: !!el.cmsCourseID, Title: el.title };
          })
          .filter(e => e.University === "SiriusXM");
        const stagingCourseData = res[0].data.map(el => {
          return { ...el, cmsIdToCSV: !!el.cmsCourseID };
        });

        setCourses([...courseData, ...stagingCourseData]);
        setLoading(false);
        trackUserAction({
          action: "List",
          targetType: "Admin",
          targetName: "Staging Course",
          targetPath: "/admin/data-university/staging-courses",
          targetID: "",
          status: "Success",
          errorDetails: ""
        });
      })
      .catch(() => setLoading(false));
  }, []);

  const handleConfigureClick = async (CourseId, University, sxmId) => {
    let id = "";
    try {
      if (University !== "SiriusXM") {
        const { data } = await addStagingCourse(CourseId);
        id = data.id;
        await trackUserAction({
          action: "Configure",
          targetType: "Admin",
          targetName: "Staging Course",
          targetPath: "/admin/data-university/staging-courses",
          targetID: "",
          status: "Success",
          errorDetails: ""
        });

        addSuccessAlert(`Staging Course created`);
      }
      Promise.all([fetchStagingCourses(), getCourses()]).then(res => {
        const courseData = res[1].data
          .map(el => {
            return { ...el, cmsIdToCSV: !!el.cmsCourseID, Title: el.title };
          })
          .filter(e => e.University === "SiriusXM");
        const stagingCourseData = res[0].data.map(el => {
          return { ...el, cmsIdToCSV: !!el.cmsCourseID };
        });

        setCourses([...courseData, ...stagingCourseData]);
        if (University !== "SiriusXM") openCourseInStrapiCMS(id);
        else openCourseInStrapiCMS(sxmId);
      });
    } catch (err) {
      addFailAlert("Something went wrong");
    }
  };

  const openCourseInStrapiCMS = id => {
    window.open(
      `${APP_VARS.CMS_HOST}/admin/plugins/content-manager/collectionType/application::course.course/` +
        id,
      "_blank"
    );
  };

  const handlePreviewClick = data => {
    window.open(
      APP_VARS.COURSERA_LEARNING_PAGE.replace("{source}", data.Source),
      "_blank",
      "width=800,height=600"
    );
  };

  return (
    <div className="Staging Courses">
      {loading ? (
        <Spinner>Fetching Staging Courses</Spinner>
      ) : (
        <Grid
          width="100%"
          title="Available Coursesa Courses"
          showExcel={true}
          // downloadTitle="Export CSV"
          columnDefs={[
            {
              field: "Title",
              headerName: "Title",
              sortable: true,
              resizable: true
            },

            {
              field: "Domain",
              headerName: "Domain",
              sortable: true,
              resizable: true
            },
            {
              field: "SubDomain",
              headerName: "SubDomain",
              sortable: true,
              resizable: true
            },
            {
              field: "University",
              headerName: "University",
              sortable: true,
              resizable: true
            },
            {
              field: "Specialization",
              headerName: "Specialization",
              sortable: true,
              resizable: true
            },
            {
              field: "Author",
              headerName: "Author",
              sortable: true,
              resizable: true
            },
            {
              field: "Source",
              headerName: "Source",
              sortable: true,
              resizable: true
            },
            {
              field: "cmsIdToCSV",
              headerName: "In Progress?",
              sortable: true,
              resizable: true,
              cellRendererFramework: ({ value }) => (!!value ? "Yes" : "No")
            },
            {
              field: "MakeAvailableInDU",
              headerName: "In DU?",
              sortable: true,
              resizable: true,
              cellRendererFramework: ({ value }) => (value ? "Yes" : "No")
            },

            {
              field: "edit",
              headerName: "",
              resizable: true,
              width: 350,
              cellRendererFramework: ({ data, value }) => {
                return (
                  <>
                    <AccentButton onClick={() => handlePreviewClick(data)}>
                      <AiOutlineEye size="20" /> Preview
                    </AccentButton>
                    <Can I="Write" a="Data University">
                      {() => (
                        <AccentButton
                          className="sx-accent-button--success ml-2"
                          onClick={() => {
                            handleConfigureClick(
                              data.CourseId,
                              data.University,
                              data.id
                            );
                          }}
                        >
                          <AiOutlineAim size="20" /> Configure
                        </AccentButton>
                      )}
                    </Can>
                  </>
                );
              }
            }
          ]}
          rowData={courses}
        />
      )}
    </div>
  );
}

export default CourseManagement;
