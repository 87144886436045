// import { APP_VARS } from "../../../services/appVars";
const publishingVideo = "/videos/Publishing.mp4";
const publishingVideoThumb = "/images/Publishing_thumb.png";
const interfaceVideo = "/videos/Tableau-Interface.mp4";
const interfaceVideoThumb = "/images/Tableau-Interface_thumb.png";
const gettingStartedVideo = "/videos/Getting-Started.mp4";
const gettingStartedVideoThumb = "/images/Getting-Started_thumb.png";
const whatIsAskDataThumb = "/images/whatIsAskData.png";
const introAskDataThumb = "/images/introductionAskData.png";

export default () => [
  {
    title: "Getting Started with Tableau",
    src: "/assets" + gettingStartedVideo,
    description:
      "In this course, you will get an overview of Tableau desktop to start diving into your data and creating simple visualizations to find insights. You'll learn how to connect to your data sources and much more.",
    topics: "",
    duration: "25:06",
    playing: false,
    id: 0,
    thumbnail: "/assets" + gettingStartedVideoThumb
  },
  {
    title: "Tableau Interface",
    src: "/assets" + interfaceVideo,
    description:
      "This course shows you how to use the data pane, analytics pane, sheets tabs, shelves and cards, legends and more.",
    topics: "",
    duration: "4:52",
    playing: false,
    id: 1,
    thumbnail: "/assets" + interfaceVideoThumb
  },
  {
    title: "Distributing and Publishing",
    src: "/assets" + publishingVideo,
    description:
      "In this course, you'll learn ways to share, exporting to PDF or Excel, Opening Workbooks, and sharing files securely.",
    topics: "",
    duration: "4:24",
    playing: false,
    id: 2,
    thumbnail: "/assets" + publishingVideoThumb
  },
  {
    title: "What is Ask Data?",
    src: "https://www.youtube.com/watch?v=m_9Zw6LMNrw",
    description:
      "This course will show you a whole new way to interact with your data, letting you type a question and instantly get a response right in Tableau.",
    topics: "",
    duration: "1:00",
    playing: false,
    id: 3,
    thumbnail: "/assets" + whatIsAskDataThumb
  },
  {
    title: "Introduction to Ask Data",
    src: "https://www.youtube.com/watch?v=Hh3-2Nd5kMA",
    description:
      "In this course, you will dive into Ask Data and learn how to create compelling reports simply by asking questions of your data.",
    topics: "",
    duration: "44:30",
    playing: false,
    id: 4,
    thumbnail: "/assets" + introAskDataThumb
  }
];
