import React from "react";
import { APP_VARS } from "../../services/appVars";

import MenuPageTemplate from "../../components/MenuPageTemplate";

function ReportAndDashboardLanding(props) {
  const menuItems = [
    {
      title: "Browse Dashboards",
      secondaryTitle: "",
      subtitle:
        "Search, Access, Subscribe and view all reports and dashboards available throughout the Enterprise",
      imageUrl:
        APP_VARS.REACT_APP_ASSETS_URL +
        "/images/reporting-icons/Reporting and Dashboard Catalog.png",
      linkUrl: "/dashboardcatalog"
    },
    {
      title: "My Reports",
      secondaryTitle: "",
      subtitle: "Create, Run and Browse Cognos Reports",
      imageUrl:
        APP_VARS.REACT_APP_ASSETS_URL + "/images/reporting-icons/report.png",
      linkUrl: "/myreports"
    },
    {
      title: "Publish Dashboards",
      secondaryTitle: "",
      disabled: true,
      subtitle: "Make your Dashboards available throughout the enterprise",
      imageUrl:
        APP_VARS.REACT_APP_ASSETS_URL +
        "/images/reporting-icons/publish-dash.png",
      linkUrl: ""
    }
  ];
  return (
    <MenuPageTemplate
      menuItems={menuItems}
      mainText="Reporting and Dashboard Catalog"
      subText="Search, Access, Subscribe and view all reports and dashboards available throughout the Enterprise"
    />
  );
}

export default ReportAndDashboardLanding;
