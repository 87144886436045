import React from "react";
import Form from "react-bootstrap/Form";
import { AiOutlineSearch } from "react-icons/ai";
import { MdRefresh } from "react-icons/md";
import "./ReportWidgetContainer.scss";

function ReportWidgetContainer({
  children,
  headerText = "",
  className = "",
  onRefreshClick = () => {},
  moreHeaderContent,
  ...inputProps
}) {
  return (
    <div className={`sx-report-widget-container  ${className}`}>
      <div className="sx-report-widget-container__header d-flex align-items-center justify-content-between py-3 pl-3">
        {headerText}

        <div className="d-flex align-items-center pr-3">
          <div className="sx-report-widget-container__more-header-content pr-1">
            {moreHeaderContent}
          </div>
          <div className="sx-report-widget-container__refresh">
            <MdRefresh size={26} onClick={onRefreshClick} />
          </div>
        </div>
      </div>
      <div className="sx-report-widget-container__search p-3">
        <Form onSubmit={e => e.preventDefault()}>
          <Form.Group className="position-relative">
            <Form.Control {...inputProps} type="text"></Form.Control>
            <AiOutlineSearch className="sx-report-widget-container__search-icon" />
          </Form.Group>
        </Form>
      </div>
      {children}
    </div>
  );
}

export default ReportWidgetContainer;
