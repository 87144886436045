import React from "react";
import BaseCard from "../BaseCard";
import classnames from "classnames";
import { AiFillInfoCircle } from "react-icons/ai";
import { FaLock } from "react-icons/fa";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import "./CategoryCard.scss";

function CategoryCard({
  title,
  className = "",
  onClick,
  isActive,
  authorized = false,
  popoverText = "",
  popoverTitle = "",
  ...otherProps
}) {
  return (
    <BaseCard
      className={classnames(
        "sx-category-card grow d-flex align-items-center justify-content-center",
        { active: isActive },
        className
      )}
      onClick={onClick}
      {...otherProps}
    >
      {!authorized && (
        <OverlayTrigger
          placement="top"
          trigger="click"
          rootClose
          overlay={
            <Popover>
              <Popover.Content>
                <div className="p-1">Access Required</div>
              </Popover.Content>
            </Popover>
          }
        >
          <FaLock
            onClick={e => {
              e.stopPropagation();
            }}
            className="sx-category-card__auth"
          />
        </OverlayTrigger>
      )}
      <OverlayTrigger
        placement="auto"
        trigger="click"
        rootClose
        overlay={
          <Popover>
            <Popover.Content>
              <div className="font-medium font-weight-bold color-primary pt-3 px-3">
                {popoverTitle}
              </div>
              <div className="p-3">{popoverText}</div>
            </Popover.Content>
          </Popover>
        }
      >
        <AiFillInfoCircle
          onClick={e => {
            e.stopPropagation();
          }}
          className="sx-category-card__info"
        />
      </OverlayTrigger>
      <div className={classnames("sx-category-card__text text-center p-3")}>
        {title}
      </div>
    </BaseCard>
  );
}

export default CategoryCard;
