import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import BaseCard from "../../components/BaseCard";
import useAuthManager from "../../hooks/useAuthManager";
import { APP_VARS } from "../../services/appVars";
import {
  getEnrollmentBadges,
  trackUserAction
} from "../../services/dataService";

const STATSDATA = [
  {
    title: "Skill Points Earned",
    key: "totalSkillPoints",
    icon: "Skills-Icon",
    points: "100K"
  },
  {
    title: "Courses Completed",
    key: "totalCompleted",
    icon: "Course-Icon",
    points: "01"
  },
  {
    title: "Badges Earned",
    key: "totalBadges",
    icon: "Award-Icon",
    points: "01"
  }
];

const numFormatter = num =>
  Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K"
    : Math.sign(num) * Math.abs(num);

export default function StudentStats() {
  const { userName } = useAuthManager();
  const [progress, setProgress] = useState(null);
  useEffect(() => {
    getEnrollmentBadges().then(res => {
      trackUserAction({
        action: "List",
        targetType: "Student Progress",
        targetName: "Data University",
        targetPath: "/data-university",
        targetID: "",
        status: "Success",
        errorDetails: ""
      });
      setProgress(res.data);
    });
  }, []);
  return (
    <BaseCard className="student-stats shadow">
      <Card.Header as="h6" className="p-4 mt-2">
        {/* <img
          src={`${APP_VARS.REACT_APP_ASSETS_URL}/images/Student_Page/UserIcon.png`}
          width="47"
          height="47"
        /> */}
        <span>
          Welcome back, <b>{userName}</b>
        </span>
      </Card.Header>
      <Card.Header as="h6">
        <Row className="p-2">
          <img
            src={progress?.thumnbnailUrl}
            className="badge-icon mx-3"
            alt="badge"
          />
          <span className="ml-3 mt-2">
            <span className="achievement-title"> Your level</span>
            <h6 className="mt-1 achievement-text">
              {progress?.achievementName} {progress?.levelName}
            </h6>
          </span>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row className=" px-1">
          {STATSDATA.map((data, index) => {
            return (
              <>
                <Col sm={4} key={index} className="stats-div">
                  <div className="pl-1 pt-3">
                    <div className="stat-div stat-img pr-3 pl-1">
                      <img
                        src={`${APP_VARS.REACT_APP_ASSETS_URL}/images/Student_Page/${data.icon}.png`}
                        width="26"
                        alt={`${data.icon}.png`}
                      />
                    </div>
                    <div className="stat-div stat-val-text">
                      <h5 className="stats-value">
                        {!!progress &&
                          (!index
                            ? numFormatter(progress[data.key])
                            : progress[data.key])}
                      </h5>
                      <p className="stats-text">{data.title}</p>
                    </div>
                  </div>
                  {/* <Row>
                    <Col sm={4}>
                      <img
                        src={`${APP_VARS.REACT_APP_ASSETS_URL}/images/Student_Page/${data.icon}.png`}
                        width="26"
                        className="float-left"
                        alt={`${data.icon}.png`}
                      />
                    </Col>
                    <Col sm={8}>
                      <h5 className="stats-value float-left">
                        {!!progress &&
                          (!index
                            ? numFormatter(progress[data.key])
                            : progress[data.key])}
                      </h5>
                      <p className="stats-text float-left">{data.title}</p>
                    </Col>
                  </Row> */}
                </Col>
              </>
            );
          })}
        </Row>
      </Card.Body>
    </BaseCard>
  );
}
