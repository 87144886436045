import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import BasePage from "../layouts/BasePage";
import BaseCard from "../BaseCard";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { fetchUserInfo } from "../../services/dataService";

function ProtectedRoute({ children, ...otherProps }) {
  const [hasAccess, setHasAccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchRoles();
  }, []);

  async function fetchRoles() {
    try {
      let { data } = await fetchUserInfo();
      if (data) {
        const { execaccess, isadmin } = data;
        setHasAccess(isadmin || execaccess);
      }
      setIsLoading(false);
    } catch (error) {}
  }

  return (
    <>
      {isLoading ? (
        <BasePage className="p-5">
          <BaseCard>
            <Container fluid className="p-5">
              <Row>
                <Col sm={12} className="text-center">
                  Loading
                </Col>
              </Row>
            </Container>
          </BaseCard>
        </BasePage>
      ) : (
        <>
          {hasAccess ? (
            <Route {...otherProps}>{children}</Route>
          ) : (
            <BasePage className="p-5">
              <BaseCard>
                <Container fluid className="p-5">
                  <Row>
                    <Col sm={12} className="text-center">
                      NOT AUTHORIZED!
                    </Col>
                  </Row>
                </Container>
              </BaseCard>
            </BasePage>
          )}
        </>
      )}
    </>
  );
}

export default ProtectedRoute;
