import React from "react";
import ModalCard from "../../components/ModalCard";

function SessionModals({ isSessionExpired, isWarning, timeLeft, reset }) {
  return (
    <>
      {!isSessionExpired && isWarning && (
        <ModalCard
          title="Session Expiration Warning"
          body={`Your session is expiring in ${timeLeft} seconds.  Please click below to stay logged in.`}
          show={true}
          yesText="Stay Logged In"
          showCloseButton={false}
          handleYes={reset}
        />
      )}
      {isSessionExpired && (
        <ModalCard
          title="Session Expired"
          body="You have been logged out.  Please click below to log back in."
          show={true}
          yesText="Log In"
          showCloseButton={false}
          handleYes={() => window.location.reload()}
        />
      )}
    </>
  );
}

export default SessionModals;
