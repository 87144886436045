import React from "react";
import classnames from "classnames";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FooterLogo from "./footer-logo.png";
import { LinkContainer } from "react-router-bootstrap";
// import { AiFillSlackCircle, AiTwotonePhone } from "react-icons/ai";
import { AiFillSlackCircle } from "react-icons/ai";
import { MdEmail, MdComputer } from "react-icons/md";
import { FaSlack } from "react-icons/fa";

import "./Footer.scss";
import { APP_VARS, S_NOW_LINK } from "../../services/appVars";

const colProps = {
  sm: 6,
  lg: 6,
  xl: 3
};
function Footer({ children, className, ...otherProps }) {
  return (
    <div className={classnames("sx-footer", className)} {...otherProps}>
      <Container fluid>
        <Row className="pt-2 d-flex justify-content-between">
          <Col {...colProps}>
            <div className="sx-footer-section" style={{ paddingTop: 10 }}>
              <img
                src={FooterLogo}
                style={{ width: "100%", maxWidth: 170, marginTop: "-10px" }}
                className="sx-footer-logo py-3"
                alt="footer-logo"
              ></img>
              {/* <p className="font-xsmall text-secondary pl-4">Privacy Policy</p> */}
              {/* <p className="font-xsmall text-secondary pl-4">
                &copy; 2020 SiriusXM Pandora
              </p> */}
            </div>
          </Col>
          <Col {...colProps}>
            <div className="sx-footer-section"></div>
          </Col>
          <Col {...colProps}>
            <div className="sx-footer-section ml-5 pl-5">
              {/* <p className="sx-footer-text">
                There are many ways to contact us.
              </p> */}

              <div className="d-flex ml-5 pl-5">
                <p className="sx-footer-title ml-2">CONTACT US</p>
                <div
                  className="contact-us-icon-2 ml-3 mt-n4"
                  title="Reach us on Slack"
                >
                  <a href="slack://channel?team=T024FQT4T&id=C0115V44FH9">
                    <FaSlack />
                  </a>
                </div>
                <div className="contact-us-icon mt-n3" title="Email us">
                  <a href="mailto:marketplace@siriusxm.com">
                    <MdEmail />
                  </a>
                </div>
                {/* <div className="contact-us-icon" title="Call Support">
                  <a href="#">
                    <AiTwotonePhone />
                  </a>
                </div> */}
                <div className="contact-us-icon mt-n3" title="Service Now">
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href={S_NOW_LINK}
                  >
                    <MdComputer />
                  </a>
                </div>
                <span className="dot"></span>
              </div>
            </div>
          </Col>
          {/* <Col {...colProps}>
            <div className="sx-footer-section">
              <p className="sx-footer-title">NAVIGATE</p>
              <Row className="sx-footer-links">
                <Col sm={6}>
                  <LinkContainer to="/">
                    <Button variant="link" className="sx-footer-text">
                      Home
                    </Button>
                  </LinkContainer>
                </Col>

                <Col sm={6}>
                  <LinkContainer to="/datacatalog/domains">
                    <Button variant="link" className="sx-footer-text">
                      Data Catalog
                    </Button>
                  </LinkContainer>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <LinkContainer to="/dashboardcatalog/domains">
                    <Button variant="link" className="sx-footer-text">
                      Dashboard Catalog
                    </Button>
                  </LinkContainer>
                </Col>

                <Col sm={6}>
                  <LinkContainer to="/myreports">
                    <Button variant="link" className="sx-footer-text">
                      My Reports
                    </Button>
                  </LinkContainer>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <LinkContainer to="/faqs">
                    <Button variant="link" className="sx-footer-text">
                      Frequently Asked Questions
                    </Button>
                  </LinkContainer>
                </Col>

                <Col sm={6}>
                  <LinkContainer to="/about">
                    <Button variant="link" className="sx-footer-text">
                      About Marketplace
                    </Button>
                  </LinkContainer>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Button
                    className="sx-footer-text"
                    as="a"
                    variant="link"
                    target="_blank"
                    href={
                      APP_VARS.REACT_APP_ASSETS_URL +
                      "/pdfs/InsightsMarketPlace-QuickReferenceGuide-240221-1140-90.pdf"
                    }
                  >
                    Quick Reference Guide
                  </Button>
                </Col>
              </Row>
            </div>
          </Col> */}
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
