import React from "react";
import { APP_VARS } from "../../services/appVars";

import MenuPageTemplate from "../../components/MenuPageTemplate";

function SelfServiceVisualization(props) {
  const menuItems = [
    {
      title: "Tableau Analytics",
      secondaryTitle: "",
      subtitle:
        "Ask questions of your data and get the answers you’re looking for with the Tableau Self Service",
      imageUrl:
        APP_VARS.REACT_APP_ASSETS_URL +
        "/images/SelfService-Icons/Self Service Tableau.svg",
      linkUrl: "/gettingstartedwithtableau"
    },
    {
      title: "DbVisualizer",
      secondaryTitle: "",
      subtitle:
        "The universal database tool for developers, DBAs and analysts which can be used to access a wide range of databases",
      imageUrl:
        APP_VARS.REACT_APP_ASSETS_URL +
        "/images/SelfService-Icons/DbVisualizer.svg",
      linkUrl: "/dbvisualizer"
    },
    {
      title: "Jupyter Notebooks (Beta)",
      subtitle:
        "Create and share documents that contain live code, equations, visualizations and narrative text.",
      imageUrl:
        APP_VARS.REACT_APP_ASSETS_URL + "/images/reporting-icons/Notebooks.png",
      linkUrl: "/jupyter-notebooks"
    },
    {
      title: "SXM Analytics Studio",
      disabled: true,
      secondaryTitle: "",
      subtitle:
        "Next generation platform that supports the ML Lifecycle and enables data discovery and experimentation, feature engineering, model development, deployment and monitoring",
      imageUrl:
        APP_VARS.REACT_APP_ASSETS_URL +
        "/images/reporting-icons/Tableau Data Prep.png"
      // href:"https://xd.adobe.com/view/4ae42e8f-82c3-47b4-9ff2-d7e7ed235eaf-1fb7/?fullscreen"
    }
  ];
  return (
    <MenuPageTemplate
      menuItems={menuItems}
      mainText="Enterprise Analytic Tools"
      subText="Get access and training to a rich set of analytic tools. Whether you’re looking to investigate trends and interactively visualize data on your desktop, explore data mining or take advantage of advanced analytics to experiment on your hypothesis"
    />
  );
}

export default SelfServiceVisualization;
