import React from "react";
import { APP_VARS } from "../../services/appVars";

import MenuPageTemplate from "../../components/MenuPageTemplate";

function DataCatalogLanding(props) {
  const menuItems = [
    {
      title: "Browse",
      secondaryTitle: "",
      subtitle:
        "Search, access, verify and find data assets throughout the Enterprise",
      imageUrl:
        APP_VARS.REACT_APP_ASSETS_URL +
        "/images/Land-page-icons/Data Catalog.png",
      linkUrl: "/datacatalog/domains"
    },
    {
      title: "Enterprise Metrics (Beta)",
      secondaryTitle: "",
      subtitle:
        "Register your data into Enterprise registry or Temporarily to access in Playground",
      disabled: false,
      imageUrl:
        APP_VARS.REACT_APP_ASSETS_URL +
        "/images/reporting-icons/metrics_icon.png",
      linkUrl: "/datacatalog/metrics"
    },
    {
      title: "Lineage",
      secondaryTitle: "",
      disabled: true,
      subtitle:
        "Explore the lineage and knowledge graph of assets throughout the Enterprise",
      imageUrl:
        APP_VARS.REACT_APP_ASSETS_URL +
        "/images/reporting-icons/Group 1389.png",
      linkUrl: ""
    }
  ];
  return (
    <MenuPageTemplate
      menuItems={menuItems}
      mainText="Data Catalog"
      subText="Search, access, verify and find curated data assets by products thorough out the Enterprise"
    />
  );
}

export default DataCatalogLanding;
