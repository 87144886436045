export default [
  {
    title: "ML Introduction",
    src: "https://youtu.be/PU1D19Av6IQ",
    description: "Intoduction to machine learning using AWS SageMaker",
    duration: "1:03",
    playing: false,
    id: 0,
    thumbnail: "https://img.youtube.com/vi/PU1D19Av6IQ/sddefault.jpg"
  },
  {
    title: "ML Algorithms",
    src: "https://youtu.be/yGc0qePSYig",
    description: "Built-in Machine Learning Algorithms with Amazon SageMaker",
    duration: "15:37",
    playing: false,
    id: 1,
    thumbnail: "https://img.youtube.com/vi/yGc0qePSYig/sddefault.jpg"
  },
  {
    title: "Deploy ML Models",
    src: "https://youtu.be/KFuc2KWrTHs",
    description: "Move your ML models from experimentation to production",
    duration: "7:52",
    playing: false,
    id: 2,
    thumbnail: "https://img.youtube.com/vi/KFuc2KWrTHs/sddefault.jpg"
  },
  {
    title: "Getting Started with Notebooks",
    src: "https://www.youtube.com/watch?v=HW29067qVWk",
    description:
      "Jupyter Notebook Tutorial: Introduction, Setup, and Walkthrough",
    duration: "30:20",
    playing: false,
    id: 3,
    thumbnail: "https://img.youtube.com/vi/HW29067qVWk/sddefault.jpg"
  }
];
