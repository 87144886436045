import React, { useContext } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import DashboardCard from "../../components/DashboardCard";
import { APP_VARS } from "../../services/appVars";
import { launchReportInNewWindow } from "../../services/cognosService";
import { trackUserAction } from "../../services/dataService";
import { getUrlWithNoSpace, splitDashboardTags } from "../../services/utils";
import { DashboardsContext } from "./DashboardsContext";
import "./index.scss";

function DashboardsGridView({ onTagClick, favorites, onFavToggle }) {
  const { dashboardsContext, filterContext } = useContext(DashboardsContext);
  const [, , , , filteredDashboards] = dashboardsContext;
  const [filterField] = filterContext;
  return (
    <>
      <Row className="p-4 exec-db">
        {filteredDashboards.length > 0 ? (
          filteredDashboards.map(a => (
            <Col lg="2" xl="2" sm="3" md="3">
              <DashboardCard
                dbId={a.id}
                className="sx-hover-shadow grow ml-4 mt-3"
                title={a.name}
                onClick={() => {
                  trackUserAction({
                    action: "Open",
                    targetType: a.type,
                    targetName: a.name,
                    targetPath: `${a.id}`,
                    targetID: a.id,
                    status: "Success",
                    errorDetails: ""
                  });
                  launchReportInNewWindow(getUrlWithNoSpace(a.name), a.id);
                }}
                onTagClick={tag => onTagClick(tag, `${a.name}/${tag}`)}
                tags={a.tags ? splitDashboardTags(a.tags) : []}
                popoverTitle={a.name}
                popoverText={a.descr}
                imgUrl={`${
                  a.thumbnail.includes("https://")
                    ? a.thumbnail
                    : APP_VARS.REACT_APP_ASSETS_URL +
                      "/" +
                      a.thumbnail.replace("assets/", "")
                }`}
                isFav={favorites?.some(f => f.dbId === a.id)}
                onFavToggle={isFav => onFavToggle(isFav, a.id)}
                authorized={a.authorized === "True"}
              />
            </Col>
          ))
        ) : (
          <p className="no-db-msg my-5">
            There are no Reports available for {filterField.label}
          </p>
        )}
      </Row>
    </>
  );
}

export default DashboardsGridView;
