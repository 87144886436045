import React, { useState, useEffect, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import classnames from "classnames";
import Form from "react-bootstrap/Form";
import Select from "../../components/Select";
import AccentButton from "../../components/AccentButton";
import { AiOutlineSearch } from "react-icons/ai";

import { FiDownload } from "react-icons/fi";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "./Grid.scss";
import { MdDeleteForever } from "react-icons/md";
import ModalCard from "../ModalCard";

function Grid({
  height = 612, // Enough height to fit exactly 10 rows, override possible
  width = 0,
  style,
  className,
  title,
  pagination = true,
  onGridReady = () => {},
  initialPageSize = 10,
  showCustomFilter,
  showDelete,
  onDeleteInbox = () => {},
  rowData,
  showExcel,
  autoSizeColumns = true,
  isExecutiveGrid = false,
  filterText,
  downloadTitle,
  ...otherProps
}) {
  const gridApi = useRef(null);
  const [quickFilterText, setQuickFilterText] = useState();
  const [customFilter, setCustomFilter] = useState("All");
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [filteredData, setFilteredData] = useState(rowData);
  const [deleteDisabled, setDeleteDisabled] = useState(true);

  // Set page size on grid api when dropdown changes
  useEffect(() => {
    if (gridApi.current) {
      gridApi.current.paginationSetPageSize(pageSize);
    }
  }, [pageSize]);

  // filter rowData by customFilter
  useEffect(() => {
    if (customFilter !== "All") {
      setFilteredData(rowData.filter(i => i.status === customFilter));
    } else {
      setFilteredData(rowData);
    }
    setDeleteDisabled(true);
  }, [customFilter, rowData]);

  useEffect(() => {
    setQuickFilterText(filterText);
  }, [filterText]);

  const onSelectionChanged = () => {
    setDeleteDisabled(gridApi.current.getSelectedRows().length === 0);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div
        style={{
          height,
          width,
          ...style
        }}
        className={classnames(
          "ag-theme-material",
          !isExecutiveGrid ? "sx-grid" : "sx-grid2",
          className
        )}
      >
        {!isExecutiveGrid && (
          <>
            <div className="sx-grid__title-bar d-flex align-items-center justify-content-between">
              <div className="sx-grid__title pl-4">{title}</div>
              <div className="d-flex align-items-end justify-content-between">
                {showDelete && (
                  <div className="font-medium color-white pr-3">
                    <AccentButton
                      className="sx-accent-button--grid"
                      disabled={deleteDisabled}
                      title="Delete"
                      onClick={handleShow}
                    >
                      {" "}
                      <MdDeleteForever />{" "}
                      <span className="font-small font-weight-bold">
                        Delete
                      </span>{" "}
                    </AccentButton>
                  </div>
                )}
                {showCustomFilter && (
                  <Select
                    label={
                      <div className="text-white font-weight-bold font-small">
                        Status:
                      </div>
                    }
                    className={classnames("ml-auto", "pr-3")}
                    value={customFilter}
                    onChange={e => {
                      e.preventDefault();
                      setCustomFilter(e.target.value);
                    }}
                    options={[
                      { value: "All" },
                      { value: "Running" },
                      { value: "Failed" },
                      { value: "Completed" }
                    ]}
                  />
                )}
                <Select
                  label={
                    <div className="text-white font-weight-bold font-small">
                      Show:
                    </div>
                  }
                  className={classnames("ml-auto", "pr-3")}
                  value={pageSize}
                  onChange={e => {
                    e.preventDefault();
                    setPageSize(Number(e.target.value));
                  }}
                  options={[{ value: 10 }, { value: 50 }, { value: 100 }]}
                />

                <Form
                  className="sx-grid__quick-filter-form"
                  onSubmit={e => e.preventDefault()}
                >
                  <Form.Group className="position-relative">
                    <Form.Control
                      className="sx-grid__quick-filter-input"
                      type="text"
                      placeholder="Search"
                      value={quickFilterText}
                      onChange={e => {
                        e.preventDefault();
                        setQuickFilterText(e.target.value);
                      }}
                    ></Form.Control>
                    <AiOutlineSearch className="sx-grid__search-icon" />
                  </Form.Group>
                </Form>

                {showExcel && (
                  <div
                    className="font-medium color-white pr-3 pointer"
                    title="Download as CSV"
                    onClick={() =>
                      gridApi.current.exportDataAsCsv({
                        fileName: `Export-${
                          title ? title.replace(/ /g, "_") : "grid"
                        }-${Date.now()}`
                      })
                    }
                  >
                    <AccentButton className="sx-accent-button--grid">
                      {" "}
                      <FiDownload />{" "}
                      <span className="font-small font-weight-bold">
                        {downloadTitle || "Download"}
                      </span>{" "}
                    </AccentButton>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        <AgGridReact
          pagination={!isExecutiveGrid}
          animateRows
          onGridReady={params => {
            // Get access to grid api for use within this component
            gridApi.current = params.api;

            // Initialize page size for the first time
            params.api.paginationSetPageSize(pageSize);
            // params.api.sizeColumnsToFit();

            const innerGrid = document.querySelector(
              ".ag-center-cols-container"
            );
            const outerGrid = document.querySelector(".ag-root-wrapper-body");

            const innerGridWidth = innerGrid ? innerGrid.clientWidth : null;
            const outerGridWidth = outerGrid ? outerGrid.clientWidth : null;

            const shouldAutoSize =
              innerGridWidth &&
              outerGridWidth &&
              innerGridWidth < outerGridWidth;
            if (autoSizeColumns || shouldAutoSize) {
              params.api.sizeColumnsToFit();
            }
            // Also pass the grid API to callback for use outside the component
            onGridReady(params);
          }}
          rowData={filteredData}
          onSelectionChanged={onSelectionChanged}
          {...otherProps}
          quickFilterText={quickFilterText}
        />
      </div>
      {show && (
        <ModalCard
          title={`Do you want to delete reports?`}
          show={show}
          noText="No"
          yesText="Yes"
          handleNo={handleClose}
          handleYes={() => {
            onDeleteInbox(
              gridApi.current.getSelectedRows().map(r => r.id + ":" + r.Type)
            );
            handleClose();
          }}
        />
      )}
    </>
  );
}

export default Grid;
