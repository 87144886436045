import React from "react";
import RBSpinner from "react-bootstrap/Spinner";

function Spinner({ children }) {
  return (
    <div
      className="d-flex align-items-center flex-column py-3"
      style={{ height: "100%" }}
    >
      <RBSpinner
        className="justify-self-center"
        animation="border"
        variant="primary"
      />
      {children && (
        <div className="font-small pt-2 text-secondary">{children}</div>
      )}
    </div>
  );
}

export default Spinner;
