import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { AiFillCheckCircle, AiOutlineMenu } from "react-icons/ai";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import {
  FaRegPlayCircle,
  FaPlay,
  FaPause,
  FaRegPauseCircle
} from "react-icons/fa";
import ExpandableMenu from "../../components/ExpandableMenu";
import { DataUniversityContext } from "./DataUniversityContext";
import "./SxmCourse.scss";
import PlayerVideo from "../../components/PlayerVideo";
import { APP_VARS } from "../../services/appVars";
import { LinkContainer } from "react-router-bootstrap";
import { updateProgress } from "../../services/dataService";

const videoJsOptions = {
  autoPlay: false,
  controls: true,
  responsive: true,
  fluid: false,
  poster: `${APP_VARS.REACT_APP_ASSETS_URL}/images/draw.jpg`,
  playbackRates: [0.5, 1, 2],
  plugins: {
    hotkeys: { volumeStep: 0.1, seekStep: 10, alwaysCaptureHotkeys: true },
    qualityLevels: {},
    hlsQualitySelector: { displayCurrentQuality: true }
  }
};
const TitleComponent = ({ contentTitle }) => {
  const menuHandler = () => {
    document.querySelector(".vjs-fullscreen-control").click();
  };
  return (
    <>
      <Row className="left-menu">
        <Col sm={1} className="col-1-bg-courses"></Col>
        <Col sm={11} className="col-2-bg-courses"></Col>
      </Row>
      <Row>
        <Col sm={1} className="courses-icon courses-content-icon text-center">
          <AiOutlineMenu
            onClick={menuHandler}
            className="pointer"
            size={20}
            color="#707070"
          />
        </Col>
        <Col sm={11} className="courses-content-title">
          <h4 className="m-0">{contentTitle} </h4>
        </Col>
      </Row>
    </>
  );
};

const ItemComponent = ({ itemText, index, contents }) => (
  <Row>
    <Col sm={1} className="courses-icon courses-item-icon text-center">
      {index + 1}.
    </Col>
    <Col sm={11} className="courses-item-title">
      {itemText}
      <br />

      <small className="mt-n1">
        {contents?.filter(e => e.isComplete).length}/{contents?.length}{" "}
        Completed
      </small>
    </Col>
  </Row>
);

const BodyComponent = ({
  bodyText,
  playing,
  isComplete,
  progress,
  isPlaying,
  duration
}) => {
  let secs = duration % 60;
  if (secs?.toString().length === 1) secs = `0${secs}`;
  return (
    <Row>
      <Col sm={1} className="courses-icon courses-body-icon">
        {isComplete && progress == 100 ? (
          <AiFillCheckCircle size={30} color="#707070" />
        ) : (
          <div className="play-indicator" style={{ width: 30, height: 30 }}>
            <CircularProgressbar value={progress} />
          </div>
        )}
      </Col>
      <Col sm={11} className="courses-body-title">
        <span style={{ color: isPlaying ? "#105BAB" : "" }}>
          {bodyText} &nbsp;&nbsp;
        </span>
        <br />
        {!playing && <FaRegPlayCircle size={14} />}
        {playing && <FaRegPauseCircle size={14} />} &nbsp;
        <small className="mt-n1">
          {Math.floor(duration / 60)}:{secs} min
        </small>
      </Col>
    </Row>
  );
};

export default function VideoCourses({ sections }) {
  const [openAccordionKey, setOpenAccordionKey] = useState();
  const [videoList, setVideoList] = useState([]);
  const [currentVideo, setCurrentVideo] = useState();
  const [videoId, setVideoId] = useState(0);
  const [flag, setFlag] = useState(false);
  const [playingFlag, setPlayingFlag] = useState(false);
  const { sxmUniversityContext } = useContext(DataUniversityContext);
  const [
    sxmCourses,
    setSxmCourses,
    sxmCourse,
    setSxmCourse
  ] = sxmUniversityContext;

  const reactPlayer = useRef();
  useEffect(() => {
    let vList = sxmCourse?.content_sections
      ?.map(el => {
        return el.course_contents;
      })
      .flat();
    vList = vList?.map((v, i) => {
      return {
        ...v.source[0],
        sources: [
          {
            src: v.source[0].url,
            type: v.source[0].mime
          }
        ],
        poster: "",
        startTime: 0,
        progress: parseInt(
          v.progress
            ? v.progress
            : v.contentProgress
            ? v.contentProgress.progress
            : 0
        ),
        isComplete: v.isComplete
          ? v.isComplete
          : v.contentProgress
          ? v.contentProgress.completed
          : false,
        videoRef: i,
        accordionKey: v.id,
        duration: v.duration
      };
    });
    setVideoList(vList);
    if (!!vList?.length) {
      !currentVideo && setCurrentVideo(vList[0]);
    }
  }, [sxmCourse, sections]);

  useEffect(() => {
    let accKey = sections?.find(e => {
      let ids = e.contents.map(c => c.id);
      return ids.includes(currentVideo?.accordionKey);
    })?.id;
    setOpenAccordionKey(accKey);
  }, [currentVideo, sections]);

  return (
    <div className="video-courses mb-5">
      <Row>
        <Col md={4} lg={3} xl={3} sm={12} className="p-0">
          <TitleComponent contentTitle={"Contents"} />
          <div>
            {!!sections?.length && (
              <>
                <ExpandableMenu
                  activeIcon={<BiChevronDown size={30} color="#707070" />}
                  inActiveIcon={<BiChevronRight size={30} color="#707070" />}
                  activeKey={openAccordionKey}
                  onItemClick={(key, item) => {
                    setOpenAccordionKey(key);
                  }}
                  items={sections.map((s, index) => {
                    return {
                      name: (
                        <ItemComponent
                          itemText={s.name}
                          index={index}
                          contents={s.contents}
                        />
                      ),
                      id: s.id,
                      body:
                        s.contents?.length > 0 ? (
                          <>
                            {s.contents.map((c, i) => {
                              return (
                                <div
                                  key={s.name + ":" + c.name}
                                  onClick={() => {
                                    let currV = videoList.find(
                                      e => e.id === c.source[0].id
                                    );
                                    setVideoId(currV.videoRef);
                                    setFlag(true);
                                    setCurrentVideo(currV);
                                  }}
                                  className="pointer position-relative"
                                >
                                  <BodyComponent
                                    bodyText={c.name}
                                    playing={
                                      currentVideo?.id === c.source[0].id &&
                                      playingFlag
                                    }
                                    isPlaying={
                                      currentVideo?.id === c.source[0].id
                                    }
                                    isComplete={
                                      videoList.find(
                                        e => e.accordionKey === c.id
                                      ).isComplete
                                    }
                                    progress={
                                      videoList.find(
                                        e => e.accordionKey === c.id
                                      ).progress
                                    }
                                    duration={
                                      videoList.find(
                                        e => e.accordionKey === c.id
                                      ).duration
                                    }
                                  />
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <Container fluid className="p-5">
                            <Row>
                              <Col sm={12} className="text-center">
                                No Courses in this Section
                              </Col>
                            </Row>
                          </Container>
                        )
                    };
                  })}
                ></ExpandableMenu>
              </>
            )}
          </div>
        </Col>
        <Col md={8} lg={9} xl={9} sm={12} className="p-0">
          {!!videoList?.length ? (
            <div className="video-container">
              <div className="pt-5 mt-2 bg-white">
                <PlayerVideo
                  options={videoJsOptions}
                  onPreviousClick={el => {}}
                  onNextClick={el => {}}
                  onReady={el => {}}
                  playList={videoList}
                  id={currentVideo?.videoRef}
                  videoId={videoId}
                  setCurrentVideo={setCurrentVideo}
                  currentVideo={currentVideo}
                  setVideoId={setVideoId}
                  setFlag={setFlag}
                  flag={flag}
                  onVideoPlaying={() => {
                    document
                      .querySelector(".vjs-play-progress")
                      .classList.remove("vjs-play-extra");
                    setPlayingFlag(true);
                  }}
                  onVideoPaused={() => setPlayingFlag(false)}
                  trackVideoduration={el => {
                    // Perform video progress action or updation API Here.
                    let newSxmCourseContents = sxmCourse.content_sections
                      .map(el => {
                        return el.course_contents;
                      })
                      .flat();
                    let index = newSxmCourseContents
                      .map(e => e.id)
                      .indexOf(el.currentVideo.accordionKey);
                    let itemToModify = newSxmCourseContents[index];
                    const contentProgress = Math.ceil(
                      (el.currentTime / el.videoDuration) * 100
                    );
                    itemToModify.progress = contentProgress;
                    if (contentProgress == 100) itemToModify.isComplete = true;
                    else itemToModify.isComplete = false;
                    if (index !== -1) {
                      newSxmCourseContents[index] = itemToModify;
                    }
                    setSxmCourse(prev => {
                      return { ...prev, course_contents: newSxmCourseContents };
                    });

                    const payload = { ...itemToModify };

                    if (el.rem > 5 && el.videoDuration - el.currentTime > 5)
                      updateProgress(payload).then(res => {
                        for (const section of sections) {
                          for (const content of section.contents) {
                            if (content.id === res.data.course_content.id) {
                              content.contentProgressId = res.data.id;
                              break;
                            }
                          }
                        }
                      });
                  }}
                  onVideoEnd={el => {
                    document
                      .querySelector(".vjs-play-progress")
                      .classList.add("vjs-play-extra");

                    // Perform video end action or updation API Here.
                    let newSxmCourseContents = sxmCourse.content_sections
                      .map(el => {
                        return el.course_contents;
                      })
                      .flat();

                    var index = newSxmCourseContents
                      .map(e => e.id)
                      .indexOf(el.currentVideo.accordionKey);
                    let itemToModify = newSxmCourseContents[index];
                    itemToModify.progress = 100;
                    itemToModify.isComplete = true;
                    if (index !== -1) {
                      newSxmCourseContents[index] = itemToModify;
                    }
                    setSxmCourse(prev => {
                      return { ...prev, course_contents: newSxmCourseContents };
                    });
                    let nextVideo = videoList.find(
                      e => e.videoRef === el.currentVideo.videoRef + 1
                    );
                    setPlayingFlag(false);
                    if (nextVideo) {
                      setVideoId(nextVideo.videoRef);
                    }
                    setFlag(true);
                    setCurrentVideo(nextVideo);
                    const payload = { ...itemToModify };
                    updateProgress(payload).then(res => {
                      for (const section of sections) {
                        for (const content of section.contents) {
                          if (content.id === res.data.course_content.id) {
                            content.contentProgressId = res.data.id;
                            break;
                          }
                        }
                      }
                    });
                  }}
                />
              </div>
              <div className="license-sxm pt-2">
                Powered by Videojs&nbsp;
                <LinkContainer to={`/licenses`}>
                  <a href="javascript:void(0);">Licensed</a>
                </LinkContainer>
                &nbsp;under the Apache&nbsp;
                <LinkContainer to={`/licenses`}>
                  <a href="javascript:void(0);">License</a>
                </LinkContainer>
                , Version 2.0.
              </div>
              <div
                className="py-5 bg-white"
                style={{ fontFamily: "Open Sans,  sans-serif" }}
              >
                <span className="instructor">Instructor:</span>
                <span className="instructor-name">{sxmCourse?.Author}</span>
                <h3 className="course-title">{sxmCourse?.title}</h3>
                <span className="instructor"> Learning Path:</span>
                <span className="instructor-name mb-3">
                  {sxmCourse?.lpName}
                </span>
                {/* {videoList[0].description && <p>{`What you will learn:  `}</p>}
              {videoList[0].topics && <p>{`Topics include:`}</p>} */}
                {/* <p className="skill-class">All Skill Levels: 1h 30m</p> */}
                <p className="skill-class">
                  Skill Points: {sxmCourse?.SkillPoint}
                </p>
                {/* <p>Total Duration: 1h 30m</p> */}
                <p className="skill-desc mt-3">{sxmCourse?.description}</p>
                {/* <p className="skill-desc mt-3">
                  Learn about data literacy programs in the company and how you
                  can leverage data across business domains to improve outcomes.
                </p> */}
              </div>
            </div>
          ) : (
            <div className="text-center py-5 text-muted">
              <h5>No Videos Playing</h5>
              <p className="mt-3">Click on Video in Content Menu</p>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}
