import React from "react";
import BaseCard from "../BaseCard";
import classnames from "classnames";
import { AiFillInfoCircle } from "react-icons/ai";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import "./DomainCard.scss";

function DomainCard({
  children,
  className = "",
  popoverText = "",
  popoverTitle = "",
  title,
  img,
  onClick = () => {},
  ...otherProps
}) {
  return (
    <BaseCard
      onClick={onClick}
      className={classnames(
        "sx-domain-card",
        "sx-hover-shadow",
        "d-flex",
        "align-items-center",
        "justify-content-center",
        "p-2",
        "grow",
        className
      )}
      {...otherProps}
    >
      <OverlayTrigger
        placement="auto"
        trigger="click"
        rootClose
        overlay={
          <Popover>
            <Popover.Content>
              <div className="font-medium font-weight-bold color-primary pt-3 px-3">
                {popoverTitle}
              </div>
              <div className="p-3">{popoverText}</div>
            </Popover.Content>
          </Popover>
        }
      >
        <AiFillInfoCircle
          onClick={e => {
            e.stopPropagation();
          }}
          className="sx-domain-card__info"
        />
      </OverlayTrigger>
      <div className="text-center">
        <div className="sx-domain-card__image-wrapper pb-4">{img}</div>
        <div className="sx-domain-card__title">{title}</div>
      </div>
    </BaseCard>
  );
}

export default DomainCard;
