import React from "react";
import "./ModalCard.scss";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function ModalCard({
  className = "",
  title,
  body,
  show,
  noText,
  yesText,
  handleNo = () => {},
  handleYes = () => {},
  showCloseButton = true,
  ...otherProps
}) {
  return (
    <Modal show={show} {...otherProps} centered onHide={handleNo}>
      {title && (
        <Modal.Header closeButton={showCloseButton}>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}
      {body && <Modal.Body>{body}</Modal.Body>}
      <Modal.Footer>
        {noText && (
          <Button variant="secondary" onClick={handleNo}>
            {noText}
          </Button>
        )}
        <Button variant="primary" onClick={handleYes}>
          {yesText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalCard;
