import React, { useEffect } from "react";
import BasePage from "../../components/layouts/BasePage";
import { navbarHeight } from "../../variables.scss";
import { trackUserAction } from "../../services/dataService";
import { APP_VARS } from "../../services/appVars";

function JupyterHubViewer() {
  useEffect(() => {
    trackUserAction({
      action: "View JuypterHub",
      targetType: "tile",
      targetName: "JuypterHub",
      targetPath: "",
      targetID: "JuypterHub",
      status: "Success",
      errorDetails: ""
    });
  }, []);

  return (
    <BasePage>
      <iframe
        className="py-3 px-2"
        title="JuypterHub"
        id="jupyterhub-iframe"
        src={APP_VARS.REACT_APP_JUPYTER_HUB_HOST}
        style={{ width: "100%", height: `calc(100vh - ${navbarHeight})` }}
      ></iframe>
    </BasePage>
  );
}

export default JupyterHubViewer;
