import React from "react";
import classnames from "classnames";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FiFilter } from "react-icons/fi";

import "./Chip.scss";

function Chip({ onClose, type, value, className = "", ...otherProps }) {
  return (
    <div
      className={classnames(
        "sx-chip",
        "d-flex",
        "align-items-center",
        "justify-content-between",
        className
      )}
      {...otherProps}
    >
      <div
        className="text-secondary"
        style={{ paddingBottom: 2, paddingRight: 5 }}
      >
        <FiFilter />
      </div>
      <div className="sx-chip__text">
        <span className="font-weight-bold">{type}: </span>
        {value}
      </div>
      <div onClick={onClose} className="sx-chip__close">
        <AiOutlineCloseCircle />
      </div>
    </div>
  );
}

export default Chip;
