import React, { useContext, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { APP_VARS } from "../../services/appVars";
import {
  updateStudent,
  getCourseraMembershipStatus
} from "../../services/dataService";
import { DataUniversityContext, USER_STATUS } from "./DataUniversityContext";
import Select from "../../components/Select";
import "./WelcomeModal.scss";
import { SSO_Login } from "./StudentHome";

const ShirtSizes = [
  { value: "Small" },
  { value: "Medium" },
  { value: "Large" },
  { value: "XL" },
  { value: "XXL" }
];

export default function InstructionModal({ show, setShowStartModal }) {
  const { userContext, userStatusContext } = useContext(DataUniversityContext);

  const [, setUserStatus] = userStatusContext;
  const [user] = userContext;
  const [shirtSize, setShirtSize] = useState("Small");
  const continueLearning = async () => {
    setShowStartModal(false);
    getCourseraMembershipStatus().then(res => {
      res.data.status === 404 ? SSO_Login(true) : SSO_Login();
    });
  };
  const submitWelcome = async () => {
    setShowStartModal(false);
    getCourseraMembershipStatus().then(res => {
      res.data.status === 404 ? SSO_Login(true) : SSO_Login();
    });
  };
  return (
    <Modal
      size="lg"
      centered
      show={show}
      onHide={() => setShowStartModal(false)}
      dialogClassName="welcome-modal"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body>
        <Row>
          <Col md={5}>
            <img
              src={
                APP_VARS.REACT_APP_ASSETS_URL +
                "/images/data-university-landing/data-univeristy-logo-modal.png"
              }
              alt="modal background"
              height="100%"
              width="100%"
            />
          </Col>
          <Col md={7} className="p-5">
            <div className="p-4">
              {" "}
              <div className="pb-4">
                <h3 className={"font-weight-bolder mb-1 mt-n4"}>
                  <span className="congrats-blue">Let’s get started!</span>
                </h3>
              </div>
              <p>
                To start your Data University Program journey, follow the
                instructions below:
              </p>
              <>
                <ul>
                  <li>
                    Disable pop-up blocker for your browser to allow pop-ups
                    from Insights Marketplace.
                  </li>
                  <li>
                    Click Start button to begin the registration process with
                    Coursera
                  </li>
                  <li>
                    Select "I'm a new user" on the Welcome to Coursera page.
                  </li>
                  <li>Click "Continue" to complete your registration.</li>
                  <li>Close the pop-up window.</li>
                </ul>
                {/* <p>Please let us know your T-shirt size.</p> */}
                <div className="mt-4">
                  <Form>
                    <Row>
                      {/* <Col sm={1} className="mt-2">
                        <b>Size</b>
                      </Col> */}
                      {/* <Col sm={5}></Col> */}
                      <Col sm={6}>
                        <Button className="welcome-btn" onClick={submitWelcome}>
                          Start journey
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
