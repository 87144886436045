import React, { useCallback, useEffect, useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import {
  createSchedules,
  downloadSchedules,
  fetchAllSchedules,
  fetchScheduleUsers,
  pollCreateSchedules,
  pollDownloadSchedules,
  trackUserAction,
  updateSchedules
} from "../../services/dataService";
import Grid from "../../components/Grid";

import Spinner from "../../components/Spinner";
import { useAlertsContext } from "../../context/alerts";
import "./AppVariables.scss";
import {
  AiOutlineUpload,
  AiOutlineDownload,
  AiOutlinePlus
} from "react-icons/ai";
import AccentButton from "../../components/AccentButton";
import { AbilityContext, PermissionsContext } from "./AbilityContext";
import { Can } from "./AbilityContext";
import defineRulesFor from "./permissions";

function SchedulesManagement(props) {
  const [showDetails, setShowDetails] = useState(false);
  const [schedules, setSchedules] = useState([]);
  const [users, setUsers] = useState([]);
  const [idSelected, setIdSelected] = useState(null);
  const [loading, setLoading] = useState(true);
  const { addSuccessAlert, addFailAlert } = useAlertsContext();

  const ability = useContext(AbilityContext);
  const { permissionsCtxt } = useContext(PermissionsContext);
  const [permissions, , isAdmin] = permissionsCtxt;

  useEffect(() => {
    const rules = defineRulesFor(permissions, isAdmin);
    ability.update(rules);
  }, [permissions.length]);

  useEffect(() => {
    fetchAllSchedules()
      .then(res => {
        setSchedules(res.data);
        setLoading(false);
        trackUserAction({
          action: "List",
          targetType: "Admin",
          targetName: "Schedules",
          targetPath: "/admin/schedules",
          targetID: "",
          status: "Success",
          errorDetails: ""
        });
      })
      .catch(() => setLoading(false));
  }, []);

  const handleCloseModalDetails = () => {
    setShowDetails(false);
    setIdSelected(null);
  };

  const handleScheduleClick = id => {
    setIdSelected(id);
    fetchScheduleUsers({
      columnsString: id,
      action: "get"
    })
      .then(res => {
        setUsers(res.data);
        setShowDetails(true);
        trackUserAction({
          action: "Fetch",
          targetType: "Admin",
          targetName: "Schedule Users",
          targetPath: "/admin/schedules",
          targetID: "",
          status: "Success",
          errorDetails: ""
        });
      })
      .catch(err => {
        console.log(err);
        addFailAlert("Something went wrong fetching schedule users");
      });
  };

  const pollCreateFn = useCallback(
    execId =>
      pollCreateSchedules({ execId: execId })
        .then(res => addSuccessAlert("Schedule added"))
        .catch(err => {
          if (err.response.status === 303) {
            return setTimeout(() => pollCreateFn(execId));
          } else {
            console.log(err);
            addFailAlert("Something went wrong");
          }
        }),
    [addFailAlert, addSuccessAlert]
  );

  const handleNewScheduleClick = async () => {
    try {
      await trackUserAction({
        action: "Create",
        targetType: "Admin",
        targetName: "Schedules",
        targetPath: "/admin/schedules",
        targetID: "",
        status: "Success",
        errorDetails: ""
      });
      await createSchedules();
      addSuccessAlert("Schedule added");
    } catch (err) {
      if (err.response.status === 303) {
        return setTimeout(() => pollCreateFn(err.response?.data?.execId));
      } else {
        addFailAlert("Something went wrong");
      }
    }
  };

  const pollDownloadFn = useCallback(
    execId =>
      pollDownloadSchedules({ execId: execId })
        .then(res => {
          const blob = new Blob([res], { type: "octet/stream" }),
            csvURL = window.URL.createObjectURL(blob);

          let tempLink = document.createElement("a");
          tempLink.href = csvURL;
          tempLink.setAttribute("download", Date.now() + ".csv");
          tempLink.click();
          addSuccessAlert("Schedule downloaded");
        })
        .catch(err => {
          if (err.response.status === 303) {
            return setTimeout(() => pollDownloadFn(execId));
          } else {
            console.log(err);
            addFailAlert("Something went wrong");
          }
        }),
    [addFailAlert, addSuccessAlert]
  );

  const handleDownloadClick = async () => {
    try {
      await trackUserAction({
        action: "Download",
        targetType: "Admin",
        targetName: "Schedules",
        targetPath: "/admin/schedules",
        targetID: "",
        status: "Success",
        errorDetails: ""
      });
      const { data: res } = await downloadSchedules();
      const blob = new Blob([res], { type: "octet/stream" }),
        csvURL = window.URL.createObjectURL(blob);
      let tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", Date.now() + ".csv");
      tempLink.click();
      addSuccessAlert("Schedule downloaded");
    } catch (err) {
      console.log(err);
      if (err.response.status === 303) {
        return setTimeout(() => pollDownloadFn(err.response?.data?.execId));
      } else {
        addFailAlert("Something went wrong");
      }
    }
  };

  const handleUpdateClick = async itemID => {
    try {
      await updateSchedules({ scheduleids: itemID, action: "update" });
      await trackUserAction({
        action: "Update",
        targetType: "Admin",
        targetName: "Schedules",
        targetPath: "/admin/schedules",
        targetID: "",
        status: "Success",
        errorDetails: ""
      });
      addSuccessAlert("Schedule updated");
    } catch (err) {
      addFailAlert("Something went wrong");
    }
  };

  return (
    <div className="SchedulesManagement">
      <Can I="Write" a="Schedules Management">
        {() => (
          <Button className="mb-3 mx-2" onClick={handleNewScheduleClick}>
            <AiOutlinePlus /> New Schedules
          </Button>
        )}
      </Can>
      <Button className="mb-3 mx-2" onClick={handleDownloadClick}>
        <AiOutlineDownload /> Download Schedules
      </Button>

      {loading ? (
        <Spinner>Fetching Schedules</Spinner>
      ) : (
        <Grid
          width="100%"
          title="Schedules"
          columnDefs={[
            {
              field: "scheduleId",
              headerName: "Schedule Id",
              sortable: true,
              resizable: true,
              cellRendererFramework: ({ value }) => {
                return (
                  <p
                    className="color-accent pointer"
                    onClick={() => handleScheduleClick(value)}
                  >
                    {value}
                  </p>
                );
              }
            },
            {
              field: "schedulePath",
              headerName: "Schedule Path",
              sortable: true,
              resizable: true
            },
            {
              field: "scheduleObj",
              headerName: "Schedule Object",
              sortable: true,
              resizable: true
            },
            {
              field: "scheduleType",
              headerName: "Schedule Type",
              sortable: true,
              resizable: true
            },
            {
              field: "scheduleDate",
              headerName: "Schedule Date",
              sortable: true,
              resizable: true
            },
            {
              field: "edit",
              headerName: "",
              resizable: true,
              cellRendererFramework: ({ data, value }) => {
                return (
                  <Can I="Write" a="Schedules Management">
                    {() => (
                      <AccentButton
                        onClick={() => handleUpdateClick(data.scheduleId)}
                      >
                        <AiOutlineUpload /> Update
                      </AccentButton>
                    )}
                  </Can>
                );
              }
            }
          ]}
          rowData={schedules}
        />
      )}

      <Modal
        className="admin-modal SchedulesManagement"
        show={showDetails}
        onHide={handleCloseModalDetails}
      >
        <Modal.Header closeButton>
          <Modal.Title>{idSelected}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <tbody>
              <tr className="primary-tr">
                <td>Schedule Task Id</td>
                <td>User ID</td>
                <td>Email</td>
              </tr>
              {users.map(u => (
                <tr className="default-tr" key={u.id}>
                  <td>{u.taskid}</td>
                  <td>{u.userid}</td>
                  <td>{u.email}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default SchedulesManagement;
