import React, { useState, useEffect, useCallback } from "react";
import BasePage from "../../components/layouts/BasePage";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import BaseCard from "../../components/BaseCard";
import VideoPlayer from "../../components/VideoPlayer";
import getVideos from "./events-content";
import { FaUserAlt } from "react-icons/fa";
import { BsCalendar } from "react-icons/bs";

import "./Events.scss";
import { fetchEvents, fetchTestimonials } from "../../services/dataService";
import TruncateText from "../../components/TruncateText";
import IconCard from "../../components/IconCard";

import { APP_VARS, S_NOW_LINK } from "../../services/appVars";

const eventsImg = "/images/community-events.png";

function Events(props) {
  const [testimonialsData, setTestimonialsData] = useState([]);
  const [testimonialsLoaded, setTestimonialsLoaded] = useState(false);
  const [eventsData, setEventsData] = useState([]);
  const [eventsLoaded, setEventsLoaded] = useState(false);
  const [videoList] = useState(getVideos());

  const loadEvents = useCallback(
    () =>
      fetchEvents().then(res => {
        setEventsData(res.data);
        setEventsLoaded(true);
      }),
    [setEventsData]
  );

  const loadTestimonials = useCallback(
    () =>
      fetchTestimonials().then(res => {
        setTestimonialsData(res.data);
        setTestimonialsLoaded(true);
      }),
    [setTestimonialsData]
  );

  useEffect(() => {
    loadTestimonials();
    loadEvents();
  }, [loadEvents, loadTestimonials]);

  return (
    <BasePage className="GettingStartedTableau">
      <div className="p-3">
        <BaseCard>
          <div className="d-flex align-items-center py-2 px-3 gray-bottom-border-1">
            <span className="text-primary font-large">Events</span>
            <span className="vertical-bar"></span>
            <span className="text-primary font-medium">
              Your place to view Marketplace events, upcoming features and much
              more
            </span>
            <img
              className="ml-auto"
              src={APP_VARS.REACT_APP_ASSETS_URL + eventsImg}
              alt="Events"
            />
          </div>

          <Container fluid>
            <Row>
              <Col sm={12} xl={6}>
                <div className="video-section px-3 pt-3">
                  <VideoPlayer playlistTitle=" Videos" videoList={videoList} />
                </div>
              </Col>
              <Col sm={12} xl={6} className="right-section">
                <section className="resource-links">
                  <div className="text-primary font-large pb-4">
                    Upcoming Events
                  </div>
                  {eventsLoaded ? (
                    eventsData.length === 0 ? (
                      <Row>
                        <Col xl={12} lg={12} md={12} sm={12}>
                          <div>No upcoming events.</div>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        {eventsData.map(item => (
                          <Col
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            key={item.eventID}
                          >
                            <div className="pb-4">
                              <div
                                className="font-large"
                                style={{
                                  verticalAlign: "top",
                                  color: "gray",
                                  display: "inline-block"
                                }}
                              >
                                <BsCalendar />
                              </div>

                              <div
                                className="pl-3"
                                style={{
                                  width: "80%",
                                  display: "inline-block"
                                }}
                              >
                                <div
                                  style={{
                                    color: "#4d778e",
                                    fontWeight: "bold"
                                  }}
                                >
                                  {new Date(item.eventStartDate).toDateString()}
                                </div>
                                <div
                                  style={{
                                    color: "#4d778e",
                                    fontWeight: "bold"
                                  }}
                                >
                                  {item.eventTitle}
                                </div>
                                <div style={{ color: "#4d778e" }}>
                                  {item.eventDetails}
                                </div>
                                {item.registrationURL && (
                                  <a
                                    style={{
                                      color: "#2aaeeb",
                                      fontSize: "14px"
                                    }}
                                    href={item.registrationURL}
                                  >
                                    Register
                                  </a>
                                )}
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    )
                  ) : (
                    <Row>
                      <Col xl={12} lg={12} md={12} sm={12}>
                        <div>Loading events...</div>
                      </Col>
                    </Row>
                  )}
                </section>
                <section className="resource-links">
                  <div className="text-primary font-large pb-4">
                    Trendsetter Testimonials
                  </div>
                  {testimonialsLoaded ? (
                    testimonialsData.length === 0 ? (
                      <Row>
                        <Col xl={12} lg={12} md={12} sm={12}>
                          <div>No testimonials.</div>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        {testimonialsData.map(item => (
                          <Col
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            key={item.itemID}
                          >
                            <div className="pb-4">
                              <div
                                className="font-large"
                                style={{
                                  verticalAlign: "top",
                                  color: "gray",
                                  display: "inline-block"
                                }}
                              >
                                <FaUserAlt />
                              </div>

                              <div
                                className="pl-3"
                                style={{
                                  width: "80%",
                                  display: "inline-block"
                                }}
                              >
                                <div
                                  style={{
                                    color: "#4d778e",
                                    fontWeight: "bold"
                                  }}
                                >
                                  {item.firstName +
                                    " " +
                                    item.lastName +
                                    ", " +
                                    item.designation}
                                </div>
                                <div style={{ color: "#4d778e" }}>
                                  <TruncateText text={item.testimonial} />
                                </div>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    )
                  ) : (
                    <Row>
                      <Col xl={12} lg={12} md={12} sm={12}>
                        <div>Loading testimonials...</div>
                      </Col>
                    </Row>
                  )}
                </section>
                <section className="resource-tiles">
                  <div className="text-primary font-large pb-4">
                    User Survey
                  </div>
                  <Row>
                    <TableauCard
                      mainText="Marketplace Survey"
                      buttonText="Take Survey"
                      subText="We want to hear your feedback"
                      href={true}
                      filePath={S_NOW_LINK}
                      disabled={true}
                    />
                  </Row>
                </section>
              </Col>
            </Row>
          </Container>
        </BaseCard>
      </div>
    </BasePage>
  );
}

function TableauCard({
  mainText,
  subText,
  filePath,
  buttonText = "Download",
  href,
  disabled
}) {
  const width = "23.2rem";
  if (disabled) {
    return (
      <IconCard
        variant="disabled"
        style={{ width: width }}
        badgeText="Coming Soon"
        shadow
        className="py-5 px-2 mb-2 mr-4 "
        disabled={true}
        title={mainText}
        subtitle={subText}
      ></IconCard>
    );
  }
  const buttonProps = href ? { target: "_blank" } : { download: true };
  return (
    <BaseCard
      style={{ width: "23.2rem" }}
      className="TableauCard sx-hover-shadow grow py-5 px-2 mb-2 mr-4 d-flex align-items-center justify-content-center"
    >
      <div className="text-center">
        <div className="font-weight-bold pb-2">{mainText}</div>
        <div className="font-small pb-3">{subText}</div>
        <Button
          as="a"
          className="download-button"
          href={filePath}
          disabled={disabled}
          {...buttonProps}
        >
          {buttonText}
        </Button>
      </div>
    </BaseCard>
  );
}

export default Events;
