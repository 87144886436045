import React, { useEffect, useState } from "react";
import { Row, Table, Col, Modal, Container } from "react-bootstrap";
import { AiFillInfoCircle } from "react-icons/ai";
import Spinner from "../../components/Spinner";
import { APP_VARS } from "../../services/appVars";
import { dataGovernanceScoreMap } from "../../services/assetColorMap";
import { fetchDgcDefinitions } from "../../services/dataService";
import DataGovernanceInformation from "./DataGovernanceInformation";
import "./DgcStyles.scss";

export default function DgcLegendData() {
  const [dgcLegendData, setDgcLegendData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchDgcDefinitions()
      .then(res => {
        setDgcLegendData(
          res.data.map(r => {
            let tiersMap = r.tiers.reduce((acc, curr) => {
              return {
                ...acc,
                [curr.name]: curr.definition
              };
            }, {});
            return {
              metrics: r.name,
              definition: r.definition,
              ...tiersMap
            };
          })
        );
        setError(null);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setError("Error fetching DGC definitions");
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-start mb-3 pr-5">
        {Object.keys(dataGovernanceScoreMap).map(score => (
          <div key={score} className="px-2 text-center">
            <img
              src={`${APP_VARS.REACT_APP_ASSETS_URL}/images/Data-Governance-Icons/${score}.svg`}
              alt={score}
            />
            <div className="text-dark">{score}</div>
          </div>
        ))}
      </div>

      <span
        className="btn-link pointer ml-2"
        onClick={() => setShowModal(true)}
      >
        <AiFillInfoCircle style={{ verticalAlign: "text-top" }} />
        {"  "}
        What does this mean?
      </span>
      <Modal
        className="dgc-legend-modal"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Data Certification Legend</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="px-3">
            <Row className="align-items-center justify-content-center p-0">
              {loading && <Spinner>Fetching DGC definitions</Spinner>}
              {error && <span>{error}</span>}
              {!loading && !error && (
                <Table>
                  <thead>
                    <tr>
                      <th className="first-col">Metrics</th>
                      <th>Description</th>
                      <th>
                        <Container>
                          <Row>
                            <Col sm={5} className="p-0">
                              <img
                                src={`${APP_VARS.REACT_APP_ASSETS_URL}/images/Data-Governance-Icons/Gold.svg`}
                                alt="Gold"
                                className="mr-1"
                              />
                              Gold
                            </Col>
                            <Col sm={7} className="p-0">
                              <DataGovernanceInformation stars={4} />
                            </Col>
                          </Row>
                        </Container>
                      </th>
                      <th>
                        <Container>
                          <Row>
                            <Col sm={5} className="p-0">
                              <img
                                src={`${APP_VARS.REACT_APP_ASSETS_URL}/images/Data-Governance-Icons/Silver.svg`}
                                alt="Silver"
                                className="mr-1"
                              />
                              Silver
                            </Col>
                            <Col sm={7} className="p-0">
                              <DataGovernanceInformation stars={3} />
                            </Col>
                          </Row>
                        </Container>
                      </th>
                      <th>
                        <Container>
                          <Row>
                            <Col sm={5} className="p-0">
                              <img
                                src={`${APP_VARS.REACT_APP_ASSETS_URL}/images/Data-Governance-Icons/Bronze.svg`}
                                alt="Bronze"
                                className="mr-1"
                              />
                              Bronze
                            </Col>
                            <Col sm={7} className="p-0">
                              <DataGovernanceInformation stars={2} />
                            </Col>
                          </Row>
                        </Container>
                      </th>
                      <th>
                        <Container>
                          <Row>
                            <Col sm={5} className="p-0">
                              <img
                                src={`${APP_VARS.REACT_APP_ASSETS_URL}/images/Data-Governance-Icons/Ore.svg`}
                                alt="Ore"
                                className="mr-1"
                              />
                              Ore
                            </Col>
                            <Col sm={7} className="p-0">
                              <DataGovernanceInformation stars={1} />
                            </Col>
                          </Row>
                        </Container>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dgcLegendData.map(r => (
                      <tr className="default-tr">
                        <td className="first-col">{r.metrics}</td>
                        <td>{r.definition}</td>
                        <td>{r.Gold}</td>
                        <td>{r.Silver}</td>
                        <td>{r.Bronze}</td>
                        <td>{r.Ore}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
