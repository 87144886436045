import React, { useEffect, useState } from "react";
import BasePage from "../../components/layouts/BasePage";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BaseCard from "../../components/BaseCard";

import VideoPlayer from "../../components/VideoPlayer";
import { HelpLink, BottomCap, SelfServiceCard } from "./SelfServiceComponents";

import { trackUserAction } from "../../services/dataService";

import "./SelfServicePages.scss";

import getVideos from "./db-visualizer-content";
import { APP_VARS } from "../../services/appVars";

const dbvisualizer = "/images/dbvisualizer.png";

function DbVisualizer(props) {
  const [videoList] = useState(getVideos());
  useEffect(() => {
    trackUserAction({
      action: "View DbVisualizer",
      targetType: "tile",
      targetName: "DbVisualizer",
      targetPath: "/dbvisualizer",
      targetID: "DbVisualizer",
      status: "Success",
      errorDetails: ""
    });
  }, []);
  return (
    <BasePage className="SelfServicePages">
      <div className="p-3">
        <BaseCard>
          <div className="d-flex align-items-center py-2 px-3 gray-bottom-border-1">
            <span className="text-primary font-large">DbVisualizer</span>
            <span className="vertical-bar"></span>
            <span className="text-primary font-medium">
              Your place to find all the tools you need to get up and running
            </span>
            <img
              className="ml-auto py-4"
              src={APP_VARS.REACT_APP_ASSETS_URL + dbvisualizer}
              alt="DbVisualizer"
            />
          </div>

          <Container fluid>
            <Row>
              <Col sm={12} lg={8} xl={9}>
                <div className="video-section px-3 pt-3">
                  <VideoPlayer
                    playlistTitle="Training Videos"
                    videoList={videoList}
                  />
                </div>
              </Col>
              <Col sm={12} lg={4} xl={3} className="right-section">
                <section className="resource-links">
                  <div className="text-primary font-large pb-2">
                    DbVisualizer Learning Resources
                  </div>
                  <HelpLink
                    text="DbVisualizer User Guide (PDF)"
                    url="https://siriusradio.sharepoint.com/sites/EDO-Public/Shared%20Documents/Forms/AllItems.aspx?originalPath=aHR0cHM6Ly9zaXJpdXNyYWRpby5zaGFyZXBvaW50LmNvbS86Zjovcy9FRE8tUHVibGljL0VtYUNhZnlPWFBoUGp5anU5SkdoRlVVQnZwbUhCclpjZzAwSHB6a08wLXFJdnc%5FcnRpbWU9SjJoUTd3NGQyRWc&id=%2Fsites%2FEDO%2DPublic%2FShared%20Documents%2FDbVisualizer%2FDBVisualizer%5FUser%5FManual%2Epdf&parent=%2Fsites%2FEDO%2DPublic%2FShared%20Documents%2FDbVisualizer"
                  />
                  <BottomCap />
                  <HelpLink
                    text="DbVisualizer eLearning"
                    url=" http://confluence.dbvis.com/display/UG110/Users+Guide"
                  />
                  <BottomCap />
                  <HelpLink
                    text="Supported Databases"
                    url="https://www.dbvis.com/features/database-drivers/"
                  />
                  <BottomCap />
                  <HelpLink
                    text="DbVisualizer Community Forum"
                    url="https://support.dbvis.com/support/discussions"
                  />
                  <BottomCap />
                  <HelpLink
                    text="DbVisualizer Knowledge Base"
                    url="https://support.dbvis.com/support/home"
                  />
                </section>
                <section className="resource-tiles">
                  <div className="text-primary font-large pb-4">Support</div>
                  <Row>
                    <SelfServiceCard
                      mainText="Get Access"
                      buttonText="Click here"
                      subText="Steps which outline on getting access and use DbVisualizer"
                      href={true}
                      filePath="https://siriusradio.sharepoint.com/sites/EDO-Public/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FEDO%2DPublic%2FShared%20Documents%2FDbVisualizer&p=true&originalPath=aHR0cHM6Ly9zaXJpdXNyYWRpby5zaGFyZXBvaW50LmNvbS86Zjovcy9FRE8tUHVibGljL0VtYUNhZnlPWFBoUGp5anU5SkdoRlVVQnZwbUhCclpjZzAwSHB6a08wLXFJdnc_cnRpbWU9MFh0ZUlROGQyRWc"
                      image="/images/SelfService-Icons/get access.png"
                    />
                    <SelfServiceCard
                      mainText="FAQ"
                      subText="Frequently asked questions related to access, issues etc."
                      disabled={true}
                      image="/images/SelfService-Icons/FAQ.png"
                    />
                  </Row>
                </section>
              </Col>
            </Row>
          </Container>
        </BaseCard>
      </div>
    </BasePage>
  );
}

export default DbVisualizer;
