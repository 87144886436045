import { APP_VARS } from "../../../services/appVars";
const marketplaceWebinarsVideo = "/videos/Marketplace-Webinars.mp4";
const marketplaceWebinarsThumb = "/images/Marketplace-Webinars_thumb.png";

export default () => [
  {
    title: "Marketplace Webinars",
    src: APP_VARS.REACT_APP_ASSETS_URL + marketplaceWebinarsVideo,
    description: "",
    duration: "28:34",
    playing: false,
    id: 0,
    thumbnail: APP_VARS.REACT_APP_ASSETS_URL + marketplaceWebinarsThumb,
    filePath: APP_VARS.REACT_APP_ASSETS_URL + marketplaceWebinarsVideo
  }
];
