import React from "react";
import Pagination from "react-bootstrap/Pagination";

import "./PaginationTemplate.scss";

function PaginationTemplate({
  numberOfPages = 1,
  className = "",
  setCurrentPage,
  currentPage = 1,
  ...otherProps
}) {
  return (
    <div className="d-flex justify-content-center mb-3 pr-5">
      {numberOfPages <= 5 ? (
        <Pagination>
          {new Array(numberOfPages).fill(0).map((_, index) => (
            <Pagination.Item
              key={`pagination-${index}`}
              onClick={() => setCurrentPage(index + 1)}
              active={index + 1 === currentPage}
            >
              {index + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      ) : currentPage <= 3 ? (
        <Pagination>
          <Pagination.First disabled onClick={() => setCurrentPage(1)} />
          <Pagination.Prev
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          />
          {new Array(3).fill(0).map((_, index) => (
            <Pagination.Item
              key={`pagination-${index}`}
              onClick={() => setCurrentPage(index + 1)}
              active={index + 1 === currentPage}
            >
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Ellipsis disabled />
          <Pagination.Item onClick={() => setCurrentPage(numberOfPages)}>
            {numberOfPages}
          </Pagination.Item>
          <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} />
          <Pagination.Last onClick={() => setCurrentPage(numberOfPages)} />
        </Pagination>
      ) : currentPage >= numberOfPages - 2 ? (
        <Pagination>
          <Pagination.First onClick={() => setCurrentPage(1)} />
          <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} />
          <Pagination.Item onClick={() => setCurrentPage(1)}>
            {1}
          </Pagination.Item>
          <Pagination.Ellipsis disabled />
          {new Array(3).fill(0).map((_, index) => (
            <Pagination.Item
              key={`pagination-${index}`}
              onClick={() => setCurrentPage(numberOfPages - 2 + index)}
              active={numberOfPages - 2 + index === currentPage}
            >
              {numberOfPages - 2 + index}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === numberOfPages}
          />
          <Pagination.Last disabled />
        </Pagination>
      ) : (
        <Pagination>
          <Pagination.First onClick={() => setCurrentPage(1)} />
          <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} />
          <Pagination.Item onClick={() => setCurrentPage(1)}>
            {1}
          </Pagination.Item>
          <Pagination.Ellipsis disabled />
          <Pagination.Item onClick={() => setCurrentPage(currentPage - 1)}>
            {currentPage - 1}
          </Pagination.Item>
          <Pagination.Item active={true}>{currentPage}</Pagination.Item>
          <Pagination.Item onClick={() => setCurrentPage(currentPage + 1)}>
            {currentPage + 1}
          </Pagination.Item>
          <Pagination.Ellipsis disabled />
          <Pagination.Item onClick={() => setCurrentPage(numberOfPages)}>
            {numberOfPages}
          </Pagination.Item>
          <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} />
          <Pagination.Last onClick={() => setCurrentPage(numberOfPages)} />
        </Pagination>
      )}
    </div>
  );
}

export default PaginationTemplate;
