import React from "react";

import "./Navbar.scss";

function Navbar({ children, className = "", ...otherProps }) {
  return (
    <nav
      className={`sx-navbar position-relative d-flex align-items-center justify-content-between ${className}`}
      {...otherProps}
    >
      {children}
    </nav>
  );
}

export default Navbar;
