import { submitPrompts, pollSubmitPrompts } from "../../services/dataService";
import {
  getWindowDimensions,
  getCognosHost
} from "../../services/cognosService";

window.CognosPromptBuilder = {
  reportName: "",
  rptDrillable: "",
  rptNameEntered: "",
  rptNameSelected: "",
  rptDefaultOutputFormat: "HTML",
  rptSearchPath: "",
  rptStoreID: "",

  selectAll: function(listBox) {
    var objList = document.getElementById(listBox);

    for (var j = 0, n = objList.options.length; j < n; j++) {
      objList.options[j].selected = true;
    }
    //$("#"+listSelect+" option").prop("selected",true);
  },
  deselectAll: function(listBox) {
    var objList = document.getElementById(listBox);

    for (var j = 0, n = objList.options.length; j < n; j++) {
      // Loop
      objList.options[j].selected = false;
    }
  },

  populateChildLOV: function(childLOV, lovValues) {
    var validationMsg = "";
    var objChild = document.getElementById(childLOV);

    Object.keys(lovValues).forEach(function(key, value) {
      objChild.options[value] = new Option(lovValues[key], key);
    });

    // Below code check for all the cascade parameters and clears them

    var cascadeArray = document.getElementById("cascadeInfo").value.split(",");
    var obj = {};
    cascadeArray.forEach(function(cascadeArray) {
      var parentChildInfo = cascadeArray.split(":");
      obj[parentChildInfo[0]] = parentChildInfo[1];
    });
    // Loop through the entire list of cascade values and clear all
    // dependent parameters
    while (typeof obj[childLOV] !== "undefined") {
      var objCascadeChild = document.getElementById(obj[childLOV]);
      if (
        objCascadeChild.type.toLowerCase() === "select-one" &&
        objCascadeChild.find("option").length > 1
      ) {
        objCascadeChild.empty();
        objCascadeChild.options[0] = new Option("Please select a value.", "");
        objCascadeChild.options[0].selected = true;
        objCascadeChild.attr("disabled", "disabled");
      }
      if (objCascadeChild.type.toLowerCase() === "select-multiple") {
        objCascadeChild.empty();
        objCascadeChild.attr("disabled", "disabled");
      }
      childLOV = obj[childLOV];
    }

    return validationMsg;
  }, // End function populateChildLOV(childLOV,respdata)

  // This function to build string with selected values from the list of values
  getSelectedLOV: function(parentLOV) {
    var i = 0;
    var selectedValues = "";
    var arraySelectedValues = [];
    var objParent = document.getElementById(parentLOV);

    // Create selected value string for multiple list box
    if (objParent.type.toLowerCase() === "select-multiple") {
      // Loop through the selected values in parentLOV to create a string
      // of selected values  "p_"+<parent parameter name>+"="+<selected value>+"&"
      // E.g. p_MarketingDivisionName_P=New&p_MarketingDivisionName_P=Na
      while (document.getElementById(parentLOV).selectedIndex !== -1) {
        selectedValues =
          selectedValues +
          "p_" +
          objParent.name +
          "=" +
          encodeURIComponent(objParent.options[objParent.selectedIndex].value) +
          "&";
        arraySelectedValues[i++] =
          objParent.options[objParent.selectedIndex].value;
        objParent.options[objParent.selectedIndex].selected = false;
      } // End while (parentLOV.selectedIndex != -1) {
      //alert(selectedValues);
      // Reselect the selcted parent values
      for (var j = 0, n = objParent.options.length; j < n; j++) {
        // Loop parent LOV values

        for (var k = 0, y = arraySelectedValues.length; k < y; k++) {
          // Loop parent selected values
          if (objParent.options[j].value === arraySelectedValues[k]) {
            objParent.options[j].selected = true;
          }
        }
      } // End for (var j = 0, n = parentLOV.options.length; j < n; j++)
    } // End if its multiple select

    // Create selected value string for drop down
    if (
      objParent.type.toLowerCase() === "select-one" &&
      objParent.options[objParent.selectedIndex].value.trim() !== ""
    ) {
      selectedValues =
        "p_" +
        objParent.name +
        "=" +
        encodeURIComponent(objParent.options[objParent.selectedIndex].value);
      objParent.options[objParent.selectedIndex].selected = true;
    } // End if drop down

    return selectedValues;
  }, // End function populateLOV(parentLOV, childLOV)
  cognosDateFormat: function(datestring) {
    var date = new Date(datestring);
    var day = ("0" + date.getDate()).slice(-2);
    var monthIndex = ("0" + (date.getMonth() + 1)).slice(-2);
    var year = date.getFullYear();
    return year + "-" + monthIndex + "-" + day + "T00:00:00";
  },
  //validatePrompts: function() {
  // Fetch all the required parameters and loop through to check if it has
  // values
  //var inputs = $("input,textarea,select").filter("[required]:visible");
  // var inputs = document.forms["frmPrompts"].elements;
  // var isValid = true;
  // var listParam = "";
  // $inputs.each(function() {
  //   var $input = $(this);
  //   // alert("validate prompts" + $input.name + $input.val());
  //   $input.removeClass("invalid");
  //   if (
  //     this.value.indexOf("undefined") > -1 ||
  //     !$.trim($input.val()).length
  //   ) {
  //     $input.val("");
  //     isValid = false;
  //     $input.addClass("invalid");
  //   }
  // });
  // if (!isValid) {
  //   return false;
  // }
  //return true;
  //}, // End function validatePrompts

  runNow: function() {
    var params = [];
    var inputList = [];
    var paramsBoundary = -1;
    //var bFirstTime = true;
    var inputs = document.forms["frmPrompts"].elements;
    for (var i = 0; i < inputs.length; i++) {
      var item = inputs[i];
      inputList.push(item.name);
      if (
        item.name !== "" &&
        item.name !== "reportName" &&
        (item.type.toLowerCase() === "text" ||
          item.type.toLowerCase() === "textarea")
      ) {
        if (typeof item.getAttribute("data-format") !== "undefined") {
          if (item.getAttribute("ctrlType") === "startdate") {
            if (item.value !== "") {
              paramsBoundary++;
              params[paramsBoundary] =
                "p_" + item.getAttribute("rngName").replace(/__/, " ");
              paramsBoundary++;
              var todate = item.getAttribute("rngName") + "_To";
              params[paramsBoundary] =
                "<selectChoices><selectBoundRange selected='true'><start useValue='" +
                this.cognosDateFormat(item.value) +
                "' displayValue='" +
                item.value +
                "'/>" +
                "<end useValue='" +
                this.cognosDateFormat(todate.value) +
                "' displayValue='" +
                todate.value +
                "'/></selectBoundRange></selectChoices>";
            } else {
              paramsBoundary++;
              params[paramsBoundary] =
                "p_" + item.getAttribute("rngName").replace(/__/, " ");
              paramsBoundary++;
              params[paramsBoundary] = "";
            }
          } else if (item.getAttribute("ctrlType") === "datetime") {
            // Date single prompt
            paramsBoundary++;
            params[paramsBoundary] = "p_" + item.name.replace(/__/, " ");
            paramsBoundary++;
            params[paramsBoundary] = this.cognosDateFormat(item.value);
          }
        } else {
          paramsBoundary++;
          params[paramsBoundary] = "p_" + item.name.replace(/__/, " ");
          paramsBoundary++;
          params[paramsBoundary] = item.value;
        }
      } //first if
      if (item.name !== "" && item.type.toLowerCase() === "select-one") {
        if (item.selectedIndex !== 0 && item.selectedIndex !== -1) {
          paramsBoundary++;
          params[paramsBoundary] = "p_" + item.name.replace(/__/, " ");
          paramsBoundary++;
          params[paramsBoundary] =
            "<selectChoices>" +
            "<selectOption useValue='" +
            item.options[item.selectedIndex].value +
            "' displayValue='" +
            item.options[item.selectedIndex].text +
            "'/>" +
            "</selectChoices>";
        }
      } //second if

      if (item.name !== "" && item.type.toLowerCase() === "select-multiple") {
        //var selectedLOV = "";
        //var first = true;
        paramsBoundary++;
        params[paramsBoundary] = "p_" + item.name.replace(/__/, " ");
        var selectedChoices = "<selectChoices>";
        for (let j = 0, n = item.options.length; j < n; j++) {
          let opt = item.options[j];
          if (opt.selected) {
            selectedChoices =
              selectedChoices +
              "<selectOption useValue='" +
              opt.value +
              "' displayValue='" +
              opt.text +
              "'/>";
          }
        } // End for loop LOV values to create a string of
        selectedChoices = selectedChoices + "</selectChoices>";
        paramsBoundary++;
        params[paramsBoundary] = selectedChoices;
      } // End if multiple select box
    } //for loop
    return params;
  },
  LaunchCognosViewer: async function() {
    // const uiobject = "&objRef=" + this.rptStoreID + "&id=" + this.rptStoreID;
    const uiobject = `&ui.object=storeID('${this.rptStoreID}')`;
    var strWindowFeatures =
      "b_action=cognosViewer&run.outputFormat=HTML&run.prompt=false&cv.header=false&cv.toolbar=false";

    var paramsString = "";
    var params1 = this.runNow();
    for (let i = 0; i < params1.length; i += 2) {
      paramsString += "&" + params1[i];
      paramsString += "=" + params1[i + 1];
    }
    try {
      const COGNOS_HOST = await getCognosHost();
      var URL = COGNOS_HOST + strWindowFeatures + uiobject + paramsString;
      window.open(URL, "Running Report", getWindowDimensions());
    } catch (error) {}
  },
  chkSpecialCharacters: function(val) {
    var iChars = "/:*?”<>|";
    if (val.indexOf("\\") >= 0) {
      return true;
    }
    for (var i = 0; i < val.length; i++) {
      if (iChars.indexOf(val.charAt(i)) !== -1) {
        return true;
      }
    }
    return false;
  },
  createPromptString: function() {
    var inputList = [];
    var promptXML = "";
    //var inputs = document.forms["frmPrompts"].getElementsByTagName("input");
    var inputs = document.forms["frmPrompts"].elements;
    promptXML =
      promptXML +
      '<PromptAnswers><ReportName>"' +
      this.rptNameEntered +
      '"</ReportName><ReportPath>"' +
      this.rptSearchPath +
      '"</ReportPath><ReportID>"' +
      this.rptStoreID +
      '"</ReportID><Parameters>';

    for (var i = 0; i < inputs.length; i++) {
      var item = inputs[i];
      inputList.push(item.name);
      if (
        item.name !== "" &&
        item.name !== "reportName" &&
        (item.type.toLowerCase() === "text" ||
          item.type.toLowerCase() === "textarea")
      ) {
        if (typeof item.getAttribute("data-format") != "undefined") {
          if (item.getAttribute("ctrltype") === "startdate") {
            if (item.value !== "") {
              promptXML =
                promptXML +
                '<Param Type= "DateRange" name="' +
                item.getAttribute("rngName".replace(/__/, " ")) +
                '"><Value Range="StartDate" use="' +
                this.cognosDateFormat(item.value) +
                '" display = "' +
                item.value +
                '" />';
              var todate = item.getAttribute("rngName") + "_To";
              var date_to = document.getElementById(todate);
              promptXML =
                promptXML +
                '<Value Range="EndDate" use="' +
                this.cognosDateFormat(date_to.value) +
                '" display = "' +
                date_to.value +
                '" /></Param>';
            }
          } else if (item.getAttribute("ctrltype") === "datetime") {
            promptXML =
              promptXML +
              '<Param Type= "DateTime" name="' +
              item.name.replace(/__/, " ") +
              '"><Value use="' +
              this.cognosDateFormat(item.value) +
              '" display = "' +
              item.value +
              '" /> </Param>';
          }
        } else {
          promptXML =
            promptXML +
            '<Param Type= "Text" name="' +
            item.name.replace(/__/, " ") +
            '"><Value use="' +
            encodeURIComponent(item.value) +
            '" display = "' +
            encodeURIComponent(item.value) +
            '" /> </Param>';
        }
      } //end if textbox
      if (item.name !== "" && item.type.toLowerCase() === "select-one") {
        if (item.selectedIndex !== -1 && item.selectedIndex !== 0) {
          if (
            encodeURIComponent(item.options[item.selectedIndex].value) !== ""
          ) {
            promptXML =
              promptXML +
              '<Param Type= "DropDown" name="' +
              item.name.replace(/__/, " ") +
              '"><Value use="' +
              encodeURIComponent(item.options[item.selectedIndex].value) +
              '" display = "' +
              encodeURIComponent(item.options[item.selectedIndex].text) +
              '" /> </Param>';
          }
        }
      }

      if (item.name !== "" && item.type.toLowerCase() === "select-multiple") {
        var atLeastOneSelected = false;
        var ListPromptXML = "";
        for (var j = 0, n = item.options.length; j < n; j++) {
          let opt = item.options[j];

          if (opt.selected) {
            atLeastOneSelected = true;
            ListPromptXML =
              ListPromptXML +
              '<Value use="' +
              encodeURIComponent(opt.value) +
              '" display="' +
              encodeURIComponent(opt.text) +
              '"/>';
          }
        } // End for loop LOV values to create a string of

        if (atLeastOneSelected) {
          promptXML =
            promptXML +
            '<Param Type= "ListBox" name="' +
            item.name.replace(/__/, " ") +
            '">';
          promptXML = promptXML + ListPromptXML + "</Param>";
        }
      } // End if multiple select box
    } //for loop end
    promptXML = promptXML + "</Parameters></PromptAnswers>";
    return promptXML;
  },
  submitPrompts: function(buttonID, fromWhichScreen, onSuccess, onFailure) {
    this.rptNameEntered = document.getElementById("reportName").value;

    if (
      this.rptDefaultOutputFormat === "HTML" &&
      this.rptDrillable === "true"
    ) {
      this.LaunchCognosViewer();
      return;
    }
    var selectedPrompts = this.createPromptString();
    var e = document.getElementById("outputformat");

    var prmptsPayLoad = {
      runType: "run_now",
      actionType: "submitToRun",
      reportPath: this.rptSearchPath,
      reportSelectedParameters: selectedPrompts,

      defaultOutputFormat: e.options[e.selectedIndex].value,
      reportName: this.rptNameEntered,
      queryParam: "reporSearchPath=" + this.rptSearchPath
    };

    var pollSubmitPromptsFn = execId =>
      pollSubmitPrompts({ execId: execId }).catch(err => {
        if (err.response.status === 303) {
          return setTimeout(() => pollSubmitPromptsFn(execId));
        } else {
          console.log(err);
        }
      });

    submitPrompts(prmptsPayLoad).catch(err => {
      if (err.response.status === 303) {
        return pollSubmitPromptsFn(err.response.data.execId);
      } else {
        console.log(err);
      }
    });
  } // End function submitPrompts
};
