import React, { useEffect, useState, useContext } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import RBSpinner from "react-bootstrap/Spinner";
import { useForm } from "react-hook-form";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import AccentButton from "../../components/AccentButton";
import Grid from "../../components/Grid";
import Spinner from "../../components/Spinner";
import { useAlertsContext } from "../../context/alerts";
import {
  getDUSeats,
  getStudents,
  revokeCorseraLicense,
  trackUserAction,
  updateStudent,
  createCourseraMembership
} from "../../services/dataService";
import { USER_STATUS } from "../data-university/DataUniversityContext";
import { AbilityContext, PermissionsContext } from "./AbilityContext";
import { Can } from "./AbilityContext";
import defineRulesFor from "./permissions";

function StudentManagement(props) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [seats, setSeats] = useState(null);
  const [seatsLoading, setSeatsLoading] = useState(true);
  const { handleSubmit, register, errors } = useForm();
  const [filterText, setFilterText] = useState("");
  const { addSuccessAlert, addFailAlert } = useAlertsContext();
  const [studentId, setStudentId] = useState(null);
  const [studentName, setStudentName] = useState(null);
  const [emailId, setEmailId] = useState(null);
  const [studentNTId, setStudentNTId] = useState(null);
  const [isRevoke, setIsRevoke] = useState(false);
  const location = useLocation();
  const currentPathName = `${location.pathname}${location.search || ""}`;

  const ability = useContext(AbilityContext);
  const { permissionsCtxt } = useContext(PermissionsContext);
  const [permissions, , isAdmin] = permissionsCtxt;

  useEffect(() => {
    const rules = defineRulesFor(permissions, isAdmin);
    ability.update(rules);
  }, [permissions.length]);

  const refreshData = () => {
    getDUSeats().then(res => {
      setSeats(res.data.availableSeats);
      setSeatsLoading(false);
    });
    getStudents()
      .then(res => {
        setUsers(res.data);
        setLoading(false);
        trackUserAction({
          action: "List",
          targetType: "Admin",
          targetName: "Students",
          targetPath: "/admin/data-university/students",
          targetID: "",
          status: "Success",
          errorDetails: ""
        });
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    refreshData();
    setFilterText(
      currentPathName
        ?.split("?")
        .find(el => el.includes("userId"))
        ?.split("=")[1]
    );
    return () => setFilterText("");
  }, [currentPathName]);

  const updateStudentFn = async (id, data) => {
    await updateStudent(id, data);
    refreshData();
    addSuccessAlert(`${data.status} Successfully`);
  };

  const revokeLicense = (studentName, emailId, studentId, status, comment) => {
    revokeCorseraLicense(studentNTId)
      .then(res => {
        const studentDetails = {
          studentName,
          emailId,
          status,
          comment,
          shirtSize: ""
        };
        updateStudentFn(studentId, studentDetails);
        trackUserAction({
          action: "Revoke",
          targetType: "Admin",
          targetName: "Students",
          targetPath: "/admin/data-university/students",
          targetID: "",
          status: "Success",
          errorDetails: ""
        });
        setIsRevoke(false);
        setStudentId(null);
        setStudentNTId(null);
      })
      .catch(err => {
        addFailAlert("Something went wrong from Coursera");
      });
  };
  const onSubmit = async data => {
    try {
      if (isRevoke) {
        revokeLicense(
          studentName,
          emailId,
          studentId,
          USER_STATUS["REVOKED"],
          data.comment
        );
      } else {
        await updateStudentFn(studentId, {
          studentName,
          emailId,
          status: USER_STATUS["REJECTED"],
          comment: data.comment,
          shirtSize: ""
        });
        trackUserAction({
          action: "Reject",
          targetType: "Admin",
          targetName: "Students",
          targetPath: "/admin/data-university/students",
          targetID: "",
          status: "Success",
          errorDetails: ""
        });
      }
      setIsRevoke(false);
      setStudentId(null);
      setStudentNTId(null);
    } catch (e) {
      setIsRevoke(false);
      setStudentId(null);
      setStudentNTId(null);
      addFailAlert("Something went wrong");
    }
  };
  return (
    <div className="Students">
      {loading ? (
        <Spinner>Fetching Students</Spinner>
      ) : (
        <div>
          <div className="text-center p-2 text-info font-weight-bold">
            {seatsLoading ? (
              <>
                <RBSpinner
                  className="justify-self-center"
                  animation="border"
                  variant="primary"
                >
                  <div className="font-small pt-2 text-secondary">
                    Fetching Seats
                  </div>
                </RBSpinner>
              </>
            ) : (
              `Available Seats: ${seats}`
            )}
          </div>
          <Grid
            width="100%"
            title="Available Students"
            filterText={filterText}
            columnDefs={[
              {
                field: "studentNTId",
                headerName: "Student ID",
                sortable: true,
                resizable: true
              },
              {
                field: "userName",
                headerName: "Student Name",
                sortable: true,
                resizable: true
              },
              {
                field: "userTitle",
                headerName: "Title",
                sortable: true,
                resizable: true
              },
              {
                field: "userDept",
                headerName: "Department",
                sortable: true,
                resizable: true
              },
              {
                field: "userEmail",
                headerName: "Email Address",
                sortable: true,
                resizable: true
              },
              {
                field: "studentStatus",
                headerName: "Status",
                sortable: true,
                resizable: true,
                width: 100,
                cellRendererFramework: ({ value }) => {
                  return <span>{value || "Open"}</span>;
                }
              },

              {
                field: "action",
                headerName: "Actions",
                resizable: true,
                width: 200,

                cellRendererFramework: ({ data, value }) => {
                  return (
                    <>
                      {data.studentStatus === USER_STATUS["PENDING"] && (
                        <>
                          <Can I="Write" a="Data University">
                            {() => (
                              <AccentButton
                                className="sx-accent-button--success"
                                onClick={() => {
                                  // inviteStudentToCorsera({
                                  //   studentId: data.studentNTId,
                                  //   userName: data.userName,
                                  //   emailId: data.userEmail
                                  // })
                                  //   .then(() => {
                                  const studentDetails = {
                                    studentName: data.userName,
                                    emailId: data.userEmail,
                                    status: USER_STATUS["APPROVED"],
                                    comment: "",
                                    shirtSize: ""
                                  };
                                  updateStudentFn(
                                    data.studentId,
                                    studentDetails
                                  );
                                  createCourseraMembership();
                                  trackUserAction({
                                    action: "Approve",
                                    targetType: "Admin",
                                    targetName: "Students",
                                    targetPath:
                                      "/admin/data-university/students",
                                    targetID: "",
                                    status: "Success",
                                    errorDetails: ""
                                  });
                                  // })
                                  // .catch(err => {});
                                }}
                              >
                                <AiFillCheckCircle size="20" /> Approve
                              </AccentButton>
                            )}
                          </Can>
                          <Can I="Write" a="Data University">
                            {() => (
                              <AccentButton
                                className="sx-accent-button--danger ml-2"
                                onClick={() => {
                                  setStudentName(data.userName);
                                  setEmailId(data.userEmail);
                                  setStudentId(data.studentId);
                                  setIsRevoke(false);
                                }}
                              >
                                <AiFillCloseCircle size="20" /> Reject
                              </AccentButton>
                            )}
                          </Can>
                        </>
                      )}

                      {(data.studentStatus === USER_STATUS["APPROVED"] ||
                        data.studentStatus === USER_STATUS["ONBOARDED"]) && (
                        <Can I="Write" a="Data University">
                          {() => (
                            <AccentButton
                              className="sx-accent-button--warning"
                              onClick={() => {
                                setStudentName(data.userName);
                                setEmailId(data.userEmail);
                                setStudentId(data.studentId);
                                setStudentNTId(data.studentNTId);
                                setIsRevoke(true);
                              }}
                              style={{ width: "100%" }}
                            >
                              <AiFillCloseCircle size="20" /> Revoke License
                            </AccentButton>
                          )}
                        </Can>
                      )}
                    </>
                  );
                }
              }
            ]}
            rowData={users}
          />
        </div>
      )}
      <Modal
        className="reject-student-modal"
        show={!!studentId}
        onHide={() => setStudentId(null)}
        size="lg"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {isRevoke
                ? "Are you sure you want to revoke this license?"
                : "Are you sure you want to reject this application?"}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form.Group controlId="reject-comment">
              <Form.Label>Reason*</Form.Label>
              <Form.Control
                name="comment"
                as="textarea"
                type="text"
                ref={register({
                  required: true,
                  validate: val => val.trim() !== ""
                })}
                maxLength={250}
                isInvalid={!!errors.comment}
              />
            </Form.Group>
            <Form.Control.Feedback type="invalid">
              Required
            </Form.Control.Feedback>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setIsRevoke(false);
                setStudentId(null);
              }}
            >
              Cancel
            </Button>
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}

export default StudentManagement;
