import React, { useState, useEffect, useCallback, useRef } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import BaseCard from "../../components/BaseCard";
import { Tabs, Tab } from "../../components/Tabs";
import Grid from "../../components/Grid";
import Spinner from "../../components/Spinner";
import { addUniqueId } from "../../services/utils";

import BasePage from "../../components/layouts/BasePage";
import {
  fetchPackages,
  fetchReports,
  fetchInbox,
  deleteInboxReports,
  downloadReport,
  pollPackages,
  pollReports
  // API_URL,
  // trackUserAction
} from "../../services/dataService";
import { useAlertsContext } from "../../context/alerts";

import PackageTreeView from "./PackageTreeView";
import ReportTreeView from "./ReportTreeView";
import {
  AiOutlineFileExcel,
  AiOutlineFilePdf,
  AiOutlineHtml5
} from "react-icons/ai";

const reportIconStyle = { fontSize: 15, paddingBottom: 2 };
const reportTypeIconMap = {
  "/images/icon_html.gif": <AiOutlineHtml5 style={reportIconStyle} />,
  "/images/icon_pdf.gif": <AiOutlineFilePdf style={reportIconStyle} />,
  "/images/icon_excel.gif": <AiOutlineFileExcel style={reportIconStyle} />
};

function MyReports(props) {
  const location = useLocation();
  const { addFailAlert } = useAlertsContext();

  const searchParams = new URLSearchParams(location.search);
  const initActiveTab = searchParams.get("activetab");
  const activeId = searchParams.get("id");

  const [activeTab, setActiveTab] = useState(initActiveTab || "reports");
  const [packagesLoading, setPackagesLoading] = useState(false);
  const [reportsLoading, setReportsLoading] = useState(false);
  const [inboxLoading, setInboxLoading] = useState(false);

  const [packages, setPackages] = useState([]);
  const [folders, setFolders] = useState([]);
  const [inboxList, setInboxList] = useState([]);
  const gridApi = useRef(null);

  const downloadReports = (rptID, rptType) => {
    downloadReport(rptID, rptType)
      /* .then(res => {
        let type = "";
        if (rptType === "Interactive") {
          type = "Reports";
        } else if (rptType === "Scheduled") {
          type = "Schedules";
        }
        const size = res.data.split(".").length;
        const slashsize = res.data.split("\\").length;
        const outputFormat = res.data.split(".")[size - 1];
        const rptName = res.data.split("\\")[slashsize - 1];

        let url = `${window.location.protocol}//${window.location.host}/${API_URL}/${type}${res.data}`;

        //let url = `http://localhost:8080/${API_URL}/${type}${res.data}`;
        trackUserAction({
          action: "Download",
          targetType: rptType,
          targetName: rptName,
          targetPath: `/${type}${res.data}`,
          targetID: rptID,
          status: "Success",
          errorDetails: ""
        });
        if (outputFormat === "html") {
          window.open(url, "_blank");
        } else {
          let a = document.createElement("a");
          a.href = url;
          a.download = `${rptName}.${outputFormat}`;
          a.click();
        }
      }) */
      .catch(err => {
        console.log(err);
        addFailAlert("Download failed");
      });
  };

  const pollPackagesFn = useCallback(() => {
    pollPackages()
      .then(response => {
        if (Array.isArray(response.data)) {
          addUniqueId(response.data, "Package");
          setPackages(response.data);
        }
        setPackagesLoading(false);
      })
      .catch(err => {
        if (err.response.status === 303) {
          return setTimeout(() => pollPackagesFn());
        } else {
          console.log(err);
          addFailAlert("Something went wrong fetching packages");
          setPackagesLoading(false);
        }
      });
  }, [addFailAlert]);

  const refreshPackages = useCallback(
    data => {
      setPackagesLoading(true);
      if (data === undefined) {
        data = false;
      }
      fetchPackages(data)
        .then(response => {
          if (Array.isArray(response.data)) {
            addUniqueId(response.data, "Package");
            setPackages(response.data);
          }
          setPackagesLoading(false);
        })
        .catch(err => {
          if (err.response.status === 303) {
            return pollPackagesFn();
          } else {
            console.log(err);
            addFailAlert("Something went wrong fetching packages");
            setPackagesLoading(false);
          }
        });
    },
    [addFailAlert, pollPackagesFn]
  );

  const pollReportsFn = useCallback(() => {
    pollReports()
      .then(response => {
        if (Array.isArray(response.data)) {
          addUniqueId(response.data, "Folder");
          setFolders(response.data);
        }
        setReportsLoading(false);
      })
      .catch(err => {
        if (err.response.status === 303) {
          return setTimeout(() => pollReportsFn());
        } else {
          console.log(err);
          addFailAlert("Something went wrong fetching reports");
          setReportsLoading(false);
        }
      });
  }, [addFailAlert]);

  const refreshReports = useCallback(
    data => {
      setReportsLoading(true);
      if (data === undefined) {
        data = false;
      }
      fetchReports(data)
        .then(response => {
          if (Array.isArray(response.data)) {
            addUniqueId(response.data, "Folder");
            setFolders(response.data);
          }
          setReportsLoading(false);
        })
        .catch(err => {
          if (err.response.status === 303) {
            return pollReportsFn();
          } else {
            console.log(err);
            addFailAlert("Something went wrong fetching reports");
            setReportsLoading(false);
          }
        });
    },
    [addFailAlert, pollReportsFn]
  );

  useEffect(() => {
    refreshPackages();
    refreshReports();
  }, [refreshPackages, refreshReports]);

  useEffect(() => {
    setInboxLoading(true);
    fetchInbox()
      .then(response => {
        setInboxList(response.data);
        setInboxLoading(false);
      })
      .catch(err => {
        console.log(err);
        addFailAlert("Something went wrong fetching inbox");
        setInboxLoading(false);
      });
  }, [addFailAlert]);

  const onDeleteInbox = useCallback(
    data => {
      deleteInboxReports({
        actionType: "delete",
        runType: "completed",
        reportId: data.join(",")
      })
        .then(response => {
          fetchInbox()
            .then(response => {
              setInboxList(response.data);
            })
            .catch(err => {
              console.log(err);
              addFailAlert("Something went wrong fetching inbox");
            });
        })
        .catch(err => {
          console.log(err);
          addFailAlert("Something went wrong deleting inbox reports");
        });
    },
    [addFailAlert]
  );

  return (
    <BasePage className="DashboardCatalog py-3">
      <Container fluid>
        <Row>
          <Col md={12} lg={12} sm={12}>
            <BaseCard>
              <div className="pt-1 pl-2 gray-bottom-border-1">
                <Breadcrumb>
                  <LinkContainer to="/">
                    <Breadcrumb.Item as="div">Home</Breadcrumb.Item>
                  </LinkContainer>
                  <Breadcrumb.Item active>My Report</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div>
                <Tabs
                  mountOnEnter
                  activeKey={activeTab}
                  onSelect={k => setActiveTab(k)}
                >
                  <Tab eventKey="reports" title="Reports">
                    <Container fluid className="pb-4">
                      <Row className="pt-3">
                        <Col sm={12} lg={6}>
                          <ReportTreeView
                            loading={reportsLoading}
                            refreshReports={refreshReports}
                            folders={folders}
                            setFolders={setFolders}
                          />
                        </Col>
                        <Col sm={12} lg={6}>
                          <PackageTreeView
                            loading={packagesLoading}
                            refreshPackages={refreshPackages}
                            packages={packages}
                            setPackages={setPackages}
                          />
                        </Col>
                      </Row>
                    </Container>
                  </Tab>
                  <Tab eventKey="inbox" title="Inbox">
                    <Container fluid className="py-4 px-5">
                      {inboxLoading && <Spinner>Fetching Inbox</Spinner>}

                      {!inboxLoading && (
                        <Grid
                          autoSizeColumns
                          width="100%"
                          title="Inbox"
                          suppressRowClickSelection={true}
                          rowSelection="multiple"
                          rowClassRules={{
                            // apply highlight to active ID
                            "rag-blue-highlight": params =>
                              "" + params.data.id === activeId
                          }}
                          onGridReady={params => {
                            // Get access to grid api for use within this component
                            gridApi.current = params.api;
                            gridApi.current.paginationGoToPage(
                              Math.floor(
                                inboxList
                                  .map(i => "" + i.id)
                                  .indexOf(activeId) /
                                  gridApi.current.paginationGetPageSize()
                              )
                            );
                          }}
                          showCustomFilter={true}
                          isExternalFilterPresent={true}
                          showDelete={true}
                          onDeleteInbox={onDeleteInbox}
                          columnDefs={[
                            {
                              field: "name",
                              headerCheckboxSelection: true,
                              headerCheckboxSelectionFilteredOnly: true,
                              checkboxSelection: true,
                              headerName: "Report Name",
                              sortable: true,
                              resizable: true,
                              cellRendererFramework: ({ data, value }) => {
                                return (
                                  <div>
                                    <Button
                                      className="font-xsmall"
                                      variant="link"
                                      //href={`/insightsmarketplace/download.jsp?docID=${data.id}`}
                                      onClick={() =>
                                        downloadReports(data.id, data.Type)
                                      }
                                      target="_blank"
                                      disabled={data.status !== "Completed"}
                                    >
                                      {reportTypeIconMap[data.formatImage]}{" "}
                                      {value}
                                    </Button>
                                  </div>
                                );
                              }
                            },
                            {
                              field: "Type",
                              headerName: "Type",
                              sortable: true,
                              resizable: true
                            },
                            {
                              field: "CreatedDate",
                              headerName: "Created Date",
                              sortable: true,
                              resizable: true
                            },
                            {
                              field: "status",
                              headerName: "Status",
                              sortable: true,
                              resizable: true
                            }
                          ]}
                          rowData={inboxList}
                        />
                      )}
                    </Container>
                  </Tab>
                </Tabs>
              </div>
            </BaseCard>
          </Col>
        </Row>
      </Container>
    </BasePage>
  );
}

export default MyReports;
