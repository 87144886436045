import React, { useContext, useEffect, useState } from "react";
import { Card, Col, ProgressBar, Row, Tab, Tabs } from "react-bootstrap";
import { AiFillPlayCircle } from "react-icons/ai";
import Accordion from "../../components/Accordion";
import BaseCard from "../../components/BaseCard";
import Spinner from "../../components/Spinner";
import {
  trackUserAction,
  getCourseraMembershipStatus
} from "../../services/dataService";
import { DataUniversityContext } from "./DataUniversityContext";
import { APP_VARS } from "../../services/appVars";
import { SSO_Login } from "./StudentHome";

const groupByLearningPath = (r, e, m = false) => {
  let enrolledLearningPaths = [];
  e.forEach(e => {
    let n = !m
      ? r?.find(r =>
          r?.courses.map(r => r?.CourseId).includes(e.course.CourseId)
        )
      : r?.filter(r =>
          r?.courses.map(r => r?.CourseId).includes(e.course.CourseId)
        );
    !m ? enrolledLearningPaths.push(n) : enrolledLearningPaths.push(...n);
  });

  let objToReturn = [...new Set(enrolledLearningPaths.map(r => r?.id))]
    .map(r => enrolledLearningPaths.find(e => e?.id === r))
    .map(r => ({
      learningPath: r,
      enrollments: e.filter(e =>
        r?.courses.map(r => r?.CourseId).includes(e.course.CourseId)
      )
    }));
  return objToReturn;
};
export default function StudentProgress() {
  const [completedCourses, setCompletedCourses] = useState(null);
  const [inProgressCourses, setInProgressCourses] = useState(null);
  const {
    enrollmentsContext,
    coursesContext,
    learningPathsContext,
    spinnerContext
  } = useContext(DataUniversityContext);
  const [enrollments] = enrollmentsContext;
  const [courses] = coursesContext;
  const [learningPaths] = learningPathsContext;
  const [showSpinner] = spinnerContext;
  useEffect(() => {
    if (!!enrollments.length && !!learningPaths.length) {
      setInProgressCourses(
        groupByLearningPath(
          learningPaths,
          enrollments.filter(el => !el.Completed)
        )
      );
      setCompletedCourses(
        groupByLearningPath(
          learningPaths,
          enrollments.filter(el => el.Completed)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enrollments, courses, learningPaths]);

  return (
    <>
      <BaseCard className="student-progress shadow mt-3">
        <Card.Header as="h4" className="p-4 mt-2">
          My Learning
        </Card.Header>
        <Card.Body>
          <Tabs
            defaultActiveKey="In progress"
            id="courses-tab"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <Tab
              eventKey="In progress"
              title={`In Progress (${
                inProgressCourses
                  ? enrollments.filter(e => !e.Completed).length
                  : "0"
              })`}
            >
              {showSpinner ? (
                <Spinner />
              ) : (
                <>
                  {!!inProgressCourses &&
                  enrollments.filter(e => !e.Completed)?.length ? (
                    <div className="w-100 m-auto p-2">
                      {inProgressCourses.map((item, index) => (
                        <Accordion
                          title={`${item?.learningPath?.name} (${item?.enrollments?.length})`}
                          body={<CourseList courseList={item?.enrollments} />}
                          idx={index}
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="w-100 m-auto p-5 text-center text-muted ">
                      No courses in progress.
                      <br /> Please select a course to begin.
                    </div>
                  )}
                </>
              )}
            </Tab>
            <Tab
              eventKey="Completed"
              title={`Completed (${
                completedCourses
                  ? enrollments.filter(e => e.Completed).length
                  : "0"
              })`}
            >
              {!!completedCourses &&
              enrollments.filter(e => e.Completed)?.length ? (
                <div className="w-100 m-auto p-2">
                  {completedCourses.map((item, index) => (
                    <Accordion
                      title={`${item?.learningPath?.name} (${item?.enrollments?.length})`}
                      body={<CourseList courseList={item?.enrollments} />}
                      idx={index}
                    />
                  ))}
                </div>
              ) : (
                <div className="w-100 m-auto p-5 text-center text-muted ">
                  No courses completed.
                  <br /> Please select a course to begin.
                </div>
              )}
            </Tab>
          </Tabs>
        </Card.Body>
      </BaseCard>
    </>
  );
}

const CourseList = ({ courseList }) => {
  return (
    <div>
      {courseList.map(l => (
        <>
          <Row className="course-container">
            <Col>
              {/* {JSON.stringify(l.course)} */}
              <div
                className="course-video-container"
                onClick={() => {
                  {
                    l.course.University === "SiriusXM"
                      ? window.open(
                          `/data-university/sxm-course/${l.course.id}`,
                          "_blank"
                        )
                      : window.open(
                          APP_VARS.COURSERA_LEARNING_PAGE.replace(
                            "{source}",
                            l.course?.src
                          ),
                          "_blank"
                        );
                  }
                  trackUserAction({
                    action: "View",
                    targetType: "Course",
                    targetName: "Data University",
                    targetPath: "/data-university",
                    targetID: "",
                    status: "Success",
                    errorDetails: ""
                  });
                }}
              >
                <img
                  className="course-thumbnail"
                  src={l.course?.ThumbnailUrl}
                  alt={`${l.course?.title}`}
                />
                <div className="play-icon-container">
                  <AiFillPlayCircle
                    color="white"
                    className="play-icon mt-n3 ml-n2"
                    size={30}
                  />
                </div>
                <ProgressBar variant="success" now={l.ProgressPct} />
              </div>
              <p>{l.ProgressPct || 0}% Completed</p>
            </Col>
            <Col>
              <p className="course-title mb-2">{l.course?.title}</p>

              <Row>
                <Col sm={12}>
                  <span className="skill-points-text ">Skill Points:</span>{" "}
                  <span className="skill-points mt-1">
                    {!l.Completed && `${l.ProgressPoint || "0"} / `}
                    {l.course?.SkillPoint}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      ))}
    </div>
  );
};
