import forEach from "lodash/forEach";
import groupBy from "lodash/groupBy";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { AiFillQuestionCircle } from "react-icons/ai";
import { LinkContainer } from "react-router-bootstrap";
import BaseCard from "../../components/BaseCard";
import Chipset from "../../components/Chips";
import Spinner from "../../components/Spinner";
import { S_NOW_LINK } from "../../services/appVars";
import DashboardGridView from "./DashboardGridView";
import ServiceNowInstructions from "./ServiceNowInstructions";

function DashboardSearchResults({
  dashboards,
  dashboardsLoading,
  activeFilters,
  setActiveFilters,
  globalSearchText,
  onTagClick,
  favorites,
  onFavToggle,
  fetchAllDashboardData
}) {
  const [show, setShow] = useState(false);
  const [visibleDashboards, setVisibleDashboards] = useState({});
  const handleCloseModal = () => {
    setShow(false);
  };
  const handleOpenModal = () => {
    setShow(true);
  };
  useEffect(() => {
    const groupedDashboardsByDomain = groupBy(dashboards, "dbDomainName");
    const groupedDashboardsByCategory = forEach(
      groupedDashboardsByDomain,
      function(value, key) {
        groupedDashboardsByDomain[key] = groupBy(
          groupedDashboardsByDomain[key],
          "dbCategoryName"
        );
      }
    );
    setVisibleDashboards(groupedDashboardsByCategory);
    // eslint-disable-next-line
  }, [globalSearchText, dashboards]);

  return (
    <BaseCard>
      <div className="pt-1 pl-2 gray-bottom-border-1 pr-5">
        <Row>
          <Breadcrumb>
            <LinkContainer to="/">
              <Breadcrumb.Item as="div">Home</Breadcrumb.Item>
            </LinkContainer>
            <LinkContainer to="/dashboardcatalog">
              <Breadcrumb.Item as="div">Dashboard Catalog</Breadcrumb.Item>
            </LinkContainer>
            <Breadcrumb.Item active>Search Results</Breadcrumb.Item>
          </Breadcrumb>
        </Row>
      </div>

      {activeFilters.length > 0 && (
        <div className="py-3 pl-4 gray-bottom-border-1">
          <Chipset
            onClose={c => setActiveFilters(f => f.filter(j => j.id !== c.id))}
            onClearAll={() => {
              setActiveFilters([]);
              fetchAllDashboardData();
            }}
            chips={activeFilters}
          />
        </div>
      )}

      <div className="d-flex align-items-center p-4 flex-wrap">
        {globalSearchText && (
          <div className="text-primary font-medium overflow-word-break">
            Search results for {globalSearchText}
          </div>
        )}

        {!globalSearchText && (
          <div className="text-primary font-medium">DASHBOARDS</div>
        )}

        {visibleDashboards.length !== 0 && (
          <div className="text-muted font-xsmall pl-2 white-space-nowrap">
            ( Showing {dashboards.length} results )
          </div>
        )}
      </div>

      <Container fluid>
        {dashboardsLoading && <Spinner>Fetching Dashboards</Spinner>}
        {!dashboardsLoading && Object.keys(visibleDashboards).length === 0 && (
          <div className="text-center">No dashboards available.</div>
        )}

        <>
          {!dashboardsLoading &&
            Object.keys(visibleDashboards).length > 0 &&
            Object.keys(visibleDashboards).map((v, index) =>
              Object.keys(visibleDashboards[v]).map(d => (
                <div key={d}>
                  <div className="row p-4 d-flex align-items-center bg-lighter-blue">
                    <span className="text-primary font-weight-bold">{v}</span>
                    <span className="text-secondary">
                      {`: ${d} (${visibleDashboards[v][d].length})`}
                    </span>
                    <div className="d-flex align-items-center ml-auto pr-3">
                      {!visibleDashboards[v][d][0].authorized && (
                        <>
                          <div
                            onClick={handleOpenModal}
                            className="pointer mr-2"
                          >
                            <AiFillQuestionCircle size={32} color="#a7a9ab" />
                          </div>
                          <a
                            target="_blank"
                            rel="noreferrer noopener"
                            href={S_NOW_LINK}
                          >
                            <Button
                              variant="success"
                              className="request-button"
                            >
                              Request Access
                            </Button>
                          </a>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="p-4">
                    <DashboardGridView
                      onTagClick={onTagClick}
                      dashboards={visibleDashboards[v][d]}
                      favorites={favorites}
                      onFavToggle={onFavToggle}
                    />
                  </div>
                </div>
              ))
            )}
        </>

        <ServiceNowInstructions
          show={show}
          handleCloseModal={handleCloseModal}
        />
      </Container>
    </BaseCard>
  );
}
export default DashboardSearchResults;
