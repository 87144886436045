import React, { useState, useEffect, useCallback } from "react";
import classnames from "classnames";
import Spinner from "../Spinner";
import Slider from "../Slider";
import DashboardCard from "../DashboardCard";
import { launchReportInNewWindow } from "../../services/cognosService";
import { useScreenSize } from "../../hooks/customHooks";

import "./RecentsWidget.scss";
import { useUserPreferencesContext } from "../../context/user-preferences";
import { APP_VARS } from "../../services/appVars";
import { getUrlWithNoSpace } from "../../services/utils";

function RecentsWidget({
  className,
  recents = [],
  recentsLoading,
  ...otherProps
}) {
  const [carouselWidth, setCarouselWidth] = useState();

  const [isSmall, isMedium, isLarge, isExtraLarge, isXXL] = useScreenSize();

  useEffect(() => {
    if (isSmall || isMedium) {
      setCarouselWidth(2);
    }
    if (isLarge) {
      setCarouselWidth(3);
    }
    if (isExtraLarge) {
      setCarouselWidth(4);
    }
    if (isXXL) {
      setCarouselWidth(5);
    }
  }, [isSmall, isMedium, isLarge, isExtraLarge, isXXL]);

  const {
    favorites,
    addFavorites,
    removeFavorites
  } = useUserPreferencesContext();

  const onFavToggle = useCallback(
    (isFav, dbId) => {
      isFav ? addFavorites({ dbId: dbId }) : removeFavorites({ dbId: dbId });
    },
    [addFavorites, removeFavorites]
  );

  return (
    <div {...otherProps} className={classnames("RecentsWidget", className)}>
      <div>
        {recentsLoading && <Spinner>Fetching Recents</Spinner>}
        {!recentsLoading && (
          <div className="px-4 pt-4 pb-5">
            <Slider
              infinite={false}
              slidesToShow={carouselWidth}
              slidesToScroll={carouselWidth}
            >
              {recents.map(a => (
                <div key={a.dbId}>
                  <DashboardCard
                    style={{ maxWidth: "26rem", margin: "0 auto" }}
                    className="sx-hover-shadow grow"
                    title={a.name}
                    onClick={() => {
                      launchReportInNewWindow(
                        getUrlWithNoSpace(a.name),
                        a.dbId
                      );
                    }}
                    imgUrl={`${APP_VARS.REACT_APP_ASSETS_URL}/${(
                      a.thumbnail || ""
                    ).replace("assets/", "")}`}
                    isFav={favorites.some(f => "" + f.dbId === "" + a.dbId)}
                    onFavToggle={isFav => onFavToggle(isFav, a.dbId)}
                  />
                </div>
              ))}
            </Slider>
          </div>
        )}
      </div>
    </div>
  );
}

export default RecentsWidget;
