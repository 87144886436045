import getTableauVideos from "../../self-service-visualization/tableau-content";
import getJupyterHubVideos from "../../self-service-visualization/jupyter-hub-content";
import getDataScientistVideos from "../../self-service-visualization/data-scientist-content";
import getDbVisualiserVideos from "../../self-service-visualization/db-visualizer-content";
import getExcelVideos from "../../self-service-visualization/excel-content";

export const COURSE_MENU_ITEMS = [
  {
    title: "Business Analyst",
    subtitle:
      "In this learning path, you'll learn how to use Excel and Tableau to get more out of your data.",
    imageUrl: "/images/data-university-landing/pie-icon.svg",
    linkUrl: "/data-university/courses/business-analyst",
    variant: "green",
    courseIds: [1, 2]
  },
  {
    title: "Data Analyst",
    subtitle:
      "In this learning path, you'll learn how to use Excel, Tableau and DB Visualizer to help you access and manage your data effectively.",
    imageUrl: "/images/data-university-landing/database-icon.svg",
    linkUrl: "/data-university/courses/data-analyst",
    variant: "blue",
    courseIds: [1, 2, 3]
  },
  {
    title: "Data Scientist",
    subtitle:
      "In this learning path, you'll start building your advanced analytics expertise with Jupyter Notebooks, where you can create and share documents that have live code, visualizations and narrative text.",
    imageUrl: "/images/data-university-landing/react-database-icon.svg",
    linkUrl: "/data-university/courses/data-scientist",
    variant: "purple",
    courseIds: [4, 5]
  }
];

export const COURSES_DATA = [
  {
    name: "Microsoft Excel",
    id: 1,
    iconPath: "/images/data-university-landing/excel-logo.png",
    courses: getExcelVideos()
  },
  {
    name: "Tableau",
    id: 2,
    iconPath: "/images/data-university-landing/tableau-logo.png",
    courses: getTableauVideos()
  },
  {
    name: "Db Visualizer",
    id: 3,
    iconPath: "/images/data-university-landing/dbvisualizer-logo.png",
    courses: getDbVisualiserVideos()
  },
  {
    name: "Data Science for Beginners",
    id: 4,
    iconPath: "/images/data-university-landing/data-science-logo.svg",
    courses: getDataScientistVideos()
  },
  {
    name: "Jupyter Notebooks",
    id: 5,
    iconPath: "/images/data-university-landing/jupyterhub-logo.png",
    courses: getJupyterHubVideos()
  }
];
