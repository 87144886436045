import React, { createContext, useState } from "react";

export const SmartSearchContext = createContext();

export const SmartSearchProvider = props => {
  const [dataDocumentation, setDataDocumentation] = useState({});
  const [legend, setLegend] = useState([]);
  const [allGraphData, setAllGraphData] = useState([]);
  const [allTabData, setAllTabData] = useState([]);
  const [pageData, setPageData] = useState({
    pageSize: 20,
    allTab: {
      currentPage: 1,
      totalCount: 0,
      filteredCount: 0,
      numberOfPages: 0,
      responseTime: 0
    },
    dashboardTab: {
      currentPage: 1,
      totalCount: 0,
      filteredCount: 0,
      numberOfPages: 0,
      responseTime: 0
    },
    metricTab: {
      currentPage: 1,
      totalCount: 0,
      filteredCount: 0,
      numberOfPages: 0,
      responseTime: 0
    },
    datacatalogTab: {
      currentPage: 1,
      totalCount: 0,
      filteredCount: 0,
      numberOfPages: 0,
      responseTime: 0
    },
    dataUniversityTab: {
      currentPage: 1,
      totalCount: 0,
      filteredCount: 0,
      numberOfPages: 0,
      responseTime: 0
    }
  });
  const [dashboardGraphData, setDashboardGraphData] = useState([]);
  const [dashboardTabData, setDashboardTabData] = useState([]);
  const [metricGraphData, setMetricGraphData] = useState([]);
  const [metricTabData, setMetricTabData] = useState([]);
  const [datacatalogGraphData, setDatacatalogGraphData] = useState([]);
  const [datacatalogTabData, setDatacatalogTabData] = useState([]);
  const [dataUniversityGraphData, setDataUniversityGraphData] = useState([]);
  const [dataUniversityTabData, setDataUniversityTabData] = useState([]);
  const [userStatus, setUserStatus] = useState(USER_STATUS["NEW"]);
  const [user, setUser] = useState({ studentName: "", studentId: "" });
  const [activeTab, setActiveTab] = useState("all");

  return (
    <SmartSearchContext.Provider
      value={{
        allTabContext: [
          allGraphData,
          setAllGraphData,
          allTabData,
          setAllTabData
        ],
        dashboardTabContext: [
          dashboardGraphData,
          setDashboardGraphData,
          dashboardTabData,
          setDashboardTabData
        ],
        metricTabContext: [
          metricGraphData,
          setMetricGraphData,
          metricTabData,
          setMetricTabData
        ],
        datacatalogTabContext: [
          datacatalogGraphData,
          setDatacatalogGraphData,
          datacatalogTabData,
          setDatacatalogTabData
        ],
        dataUniversityContext: [
          dataUniversityGraphData,
          setDataUniversityGraphData,
          dataUniversityTabData,
          setDataUniversityTabData
        ],
        userContext: [user, setUser, userStatus, setUserStatus],
        legendContext: [legend, setLegend],
        tabContext: [activeTab, setActiveTab],
        pageContext: [pageData, setPageData],
        dataDocumentationContext: [dataDocumentation, setDataDocumentation]
      }}
    >
      {props.children}
    </SmartSearchContext.Provider>
  );
};

export const USER_STATUS = {
  NEW: "New",
  PENDING: "Pending",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  ONBOARDED: "Onboarded",
  REVOKED: "Revoked"
};

export const calculateResultsCount = (t, c, l, s) =>
  1 === t ? s : t === c ? (c - 1) * l + (s % l) : t * l + (s % l);
