import React from "react";
import classnames from "classnames";
import { FaPlayCircle, FaRegPauseCircle } from "react-icons/fa";

import "./VideoPlaylist.scss";

function VideoPlaylist({
  children,
  sliderRef,
  activeVideo,
  videoList,
  className = "",
  title,
  setActiveVideo,
  setVideoList,
  ...otherProps
}) {
  return (
    <div {...otherProps} className={`sx-video-playlist ${className}`}>
      <div className="text-primary font-base pl-3 pb-3">
        <span className="font-weight-bold pr-1">{title}</span>
        <span className="font-small">({videoList.length})</span>
      </div>

      <div className="sx-video-playlist__playlist-wrapper d-flex">
        {videoList.map(v => (
          <PlaylistItem
            {...v}
            key={v.id}
            onClick={() => {
              setActiveVideo(v);
              setVideoList(vidItems =>
                vidItems.map(i => {
                  if (v.id === i.id && v.playing) {
                    return { ...i, playing: false };
                  }
                  return {
                    ...i,
                    playing: i.id === v.id
                  };
                })
              );
              sliderRef.current.slickGoTo(v.id);
            }}
            active={v.title === activeVideo.title}
          />
        ))}
      </div>
    </div>
  );
}

function PlaylistItem({
  title,
  duration,
  active,
  thumbnail,
  onClick,
  playing
}) {
  return (
    <div onClick={onClick} className="playlist-item mr-3">
      <div className={classnames("image-container", { active })}>
        <div className="image-container__play-button">
          {!playing && <FaPlayCircle className="sx-play" />}
          {playing && <FaRegPauseCircle className="sx-pause" />}
        </div>
        <div className="image-container__duration">{duration}</div>
        <img src={thumbnail} alt={title} />
      </div>
      <div className="font-small text-secondary pt-1 pl-1">{title}</div>
    </div>
  );
}

export default VideoPlaylist;
