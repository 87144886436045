import React from "react";
import "./Select.scss";

import FormControl from "react-bootstrap/FormControl";

function Select({
  label,
  className = "",
  controlId,
  options = [],
  ...inputProps
}) {
  return (
    <div className={`sx-select d-flex align-items-center ${className}`}>
      <label className="font-small text-secondary text-align-right pr-2">
        {label}
      </label>
      <FormControl as="select" {...inputProps} className={className}>
        {options.map(o => (
          <option key={o.value} value={o.value}>
            {o.display || o.value}
          </option>
        ))}
      </FormControl>
    </div>
  );
}

export default Select;
