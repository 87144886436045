import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import BaseCard from "../../components/BaseCard";
import Spinner from "../../components/Spinner";
import Select from "../../components/Select";
import { useScreenSize } from "../../hooks/customHooks";

import AssetCard from "../../components/AssetCard";
import { getAssetColor } from "../../services/assetColorMap";
import Chipset from "../../components/Chips";
import { useAlertsContext } from "../../context/alerts";
import { trackUserAction, fetchAssets } from "../../services/dataService";
import PaginationTemplate from "../../components/PaginationTemplate";
import DgcLegendData from "./DgcLegendData";

const convertFilterListToWhereClause = activeFilters => {
  const where = activeFilters.reduce((acc, curr) => {
    const { type, value } = curr;
    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(value);
    return acc;
  }, {});

  const whereList = Object.keys(where).map(i => ({
    key: i,
    value: where[i]
  }));

  return whereList;
};

function SearchResults({
  globalSearchText,
  activeFilters,
  setActiveFilters,
  setCounts,
  paginationState,
  setPaginationState
}) {
  const history = useHistory();
  const { addFailAlert } = useAlertsContext();
  const [assets, setAssets] = useState([]);
  const [assetsLoading, setAssetsLoading] = useState(false);
  const [assetRowWidth, setAssetRowWidth] = useState(3);

  const [isSmall, isMedium, isLarge, isExtraLarge, isXXL] = useScreenSize();

  useEffect(() => {
    if (isSmall || isMedium) {
      setAssetRowWidth(12);
    }
    if (isLarge) {
      setAssetRowWidth(6);
    }
    if (isExtraLarge) {
      setAssetRowWidth(4);
    }
    if (isXXL) {
      setAssetRowWidth(3);
    }
  }, [isSmall, isMedium, isLarge, isExtraLarge, isXXL]);

  const refreshAssets = useCallback(
    (query, activeFilters, pageSize, currentPage) => {
      const whereList = convertFilterListToWhereClause(activeFilters);

      const requestData = { query, pageNumber: currentPage, pageSize };
      if (whereList.length > 0) {
        requestData.where = whereList;
      }

      setAssetsLoading(true);
      fetchAssets(requestData)
        .then(response => {
          const { pageInfo, fieldList, fieldCounts: counts } = response.data;
          setCounts(counts || []);
          setAssets(fieldList || []);
          setPaginationState(ps => ({
            ...ps,
            totalCount: (pageInfo || {}).totalCount,
            numberOfPages: (pageInfo || {}).maxPageNumber
          }));
          setAssetsLoading(false);
        })
        .catch(err => {
          console.log(err);
          addFailAlert("Something went wrong fetching assets");
          setAssetsLoading(false);
        });
    },
    [setCounts, setPaginationState, addFailAlert]
  );

  const {
    currentPage = 1,
    numberOfPages = 1,
    pageSize = 20,
    totalCount = ""
  } = paginationState;

  useEffect(() => {
    refreshAssets(globalSearchText, activeFilters, pageSize, currentPage);
  }, [activeFilters, globalSearchText, refreshAssets, pageSize, currentPage]);

  return (
    <BaseCard>
      <div className="pt-1 pl-2 gray-bottom-border-1">
        <Breadcrumb>
          <LinkContainer to="/">
            <Breadcrumb.Item as="div">Home</Breadcrumb.Item>
          </LinkContainer>
          <LinkContainer to="/datacatalog/domains">
            <Breadcrumb.Item as="div">Data Catalog</Breadcrumb.Item>
          </LinkContainer>
          <Breadcrumb.Item active>Search</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      {activeFilters.length > 0 && (
        <div className="py-3 pl-4 gray-bottom-border-1">
          <Chipset
            onClose={c =>
              setActiveFilters(f =>
                f.filter(j => !(j.type === c.type && j.value === c.value))
              )
            }
            onClearAll={() => setActiveFilters([])}
            chips={activeFilters.map(({ type, value }, index) => ({
              id: index,
              type,
              value
            }))}
          />
        </div>
      )}

      <div className="d-flex align-items-center p-4 flex-wrap">
        {globalSearchText && (
          <div className="text-primary font-medium overflow-word-break">
            Search results for {globalSearchText}
          </div>
        )}

        {!globalSearchText && (
          <div className="text-primary font-medium">ASSETS</div>
        )}

        {assets.length > 0 && (
          <div className="searchresults-body text-muted font-xsmall pl-2 white-space-nowrap">
            ( Showing {pageSize * (currentPage - 1) + 1} to{" "}
            {Math.min(pageSize * currentPage, totalCount)} of {totalCount}{" "}
            results )
          </div>
        )}

        <Select
          label="Show:"
          className="ml-auto"
          value={pageSize}
          onChange={e => {
            e.preventDefault();
            const { value } = e.target;
            setPaginationState(i => ({
              ...i,
              pageSize: Number(value),
              currentPage: 1
            }));
          }}
          options={[{ value: 10 }, { value: 20 }, { value: 50 }]}
        />
      </div>

      <Container fluid className="p-4">
        {assetsLoading && <Spinner>Fetching Assets</Spinner>}
        {!assetsLoading && assets.length === 0 && (
          <div className="text-center">No assets available.</div>
        )}
        {!assetsLoading && assets.length !== 0 && (
          <Row>
            {assets.map((a, index) => (
              <Col key={index} sm={assetRowWidth}>
                <AssetCard
                  onClick={() => {
                    trackUserAction({
                      action: "View",
                      targetType: "asset",
                      targetName: a["Name"],
                      targetPath: `/assetdescription/${a["Asset ID"]}`,
                      targetID: a["Asset ID"],
                      status: "Success",
                      errorDetails: ""
                    });
                    history.push(`/assetdescription/${a["Asset ID"]}`);
                  }}
                  onButtonClick={({ name, value }) => {
                    const filterAlreadyExists = activeFilters.find(
                      i => i.type === name && i.value === value
                    );
                    if (!filterAlreadyExists) {
                      setActiveFilters(i => [...i, { type: name, value }]);
                    }
                  }}
                  onTagClick={tag => {
                    const filterAlreadyExists = activeFilters.find(
                      i => i.type === "Tags" && i.value === tag
                    );
                    if (!filterAlreadyExists) {
                      setActiveFilters(i => [
                        ...i,
                        { type: "Tags", value: tag }
                      ]);
                    }
                  }}
                  description={a["Business Description"]}
                  tags={a["Tags"] || []}
                  className="mr-2 mb-5 pointer grow sx-hover-shadow"
                  title={a["Business Name"]}
                  brandColor={getAssetColor(a["Brand"])}
                  brandText={a["Brand"]}
                  dataGovernanceScore={
                    a["Data Governance Certification"] || "Pending"
                  }
                  dgcValues={a["DGC Metrics"]}
                  tableValues={[
                    {
                      name: "Refresh Frequency",
                      value: a["Refresh Frequency"]
                    },
                    {
                      name: "Security Classification",
                      value: a["Security Classification"]
                    },
                    {
                      name: "Data Category",
                      value: a["Data Category"]
                    },
                    {
                      name: "Asset Platform",
                      value: a["Asset Platform"]
                    },
                    {
                      name: "Data Domain",
                      value: a["Data Domain"]
                    }
                  ]}
                />
              </Col>
            ))}
          </Row>
        )}
        {assets.length > 0 && (
          <Row>
            <Col sm={6}>
              <DgcLegendData />
            </Col>
            <Col sm={6}>
              <PaginationTemplate
                setCurrentPage={val =>
                  setPaginationState(j => ({
                    ...j,
                    currentPage: val
                  }))
                }
                numberOfPages={numberOfPages}
                currentPage={currentPage}
              />
            </Col>
          </Row>
        )}
      </Container>
    </BaseCard>
  );
}

export default SearchResults;
