import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { trackUserAction } from "../../services/dataService";
import { DashboardsContext } from "./DashboardsContext";
import "./index.scss";

function DomainsRow() {
  const { domainsContext, categoryContext, dashboardsContext } = useContext(
    DashboardsContext
  );
  const [allDomains, , activeDomain, setActiveDomain] = domainsContext;
  const [, , , setActiveCategory, ,] = categoryContext;
  return (
    <div className="pt-3 ml-4 px-2 dmn-btn">
      {allDomains.map((e, i) => (
        <Button
          key={e.name}
          className={`mb-3  ${
            activeDomain?.id === e.id ? "domain-btn-active" : "domain-btn"
          }`}
          onClick={() => {
            setActiveCategory(null);
            setActiveDomain(e);
            sessionStorage.setItem("activeDomain", JSON.stringify(e));
            sessionStorage.setItem("activeCategory", null);
            sessionStorage.setItem("activeSublevel", null);
            trackUserAction({
              action: "View",
              targetType: "executive dashboard categories",
              targetName: e.name,
              targetPath: `/executive-dashboards`,
              targetID: e.id,
              status: "Success",
              errorDetails: ""
            });
          }}
        >
          {e.name}
        </Button>
      ))}
    </div>
  );
}

export default DomainsRow;
