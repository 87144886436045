import React from "react";
import BasePage from "../../components/layouts/BasePage";
import "./index.scss";

export default function Licenses() {
  return (
    <BasePage className="pb-5 bg-white">
      <h1 className="legal-header p-5">Legal</h1>
      <div className="legal-cont">
        <h2>Licenses</h2>
        <ul>
          <li>
            <span
              onClick={() => {
                window.open(
                  `https://github.com/videojs/video.js/blob/main/LICENSE`
                );
              }}
            >
              Video Js
            </span>
          </li>
          <li>
            <span
              onClick={() => {
                window.open(`http://www.apache.org/licenses/LICENSE-2.0`);
              }}
            >
              Apache License, Version 2.0
            </span>
          </li>
        </ul>
      </div>
    </BasePage>
  );
}
