import React from "react";
import { LinkContainer } from "react-router-bootstrap";

import "./NavLinkItem.scss";

function NavLinkItem({
  title,
  imageUrl,
  linkUrl,
  disabled,
  onClick = () => {}
}) {
  const disabledClass = disabled ? "disabled" : "";
  const Component = linkUrl ? LinkContainer : "div";
  return (
    <Component to={linkUrl}>
      <li
        onClick={onClick}
        className={`sx-nav-link-item px-3 py-3 d-flex align-items-center pointer ${disabledClass}`}
      >
        <img className="pr-2" src={imageUrl} alt={title} /> {title}
      </li>
    </Component>
  );
}

export default NavLinkItem;
