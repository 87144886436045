import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { DashboardsContext } from "./DashboardsContext";
import "./index.scss";

function BasicLayout() {
  const { categoryContext, dashboardsContext, filterContext } = useContext(
    DashboardsContext
  );
  const [, , activeCategory, , ,] = categoryContext;
  const [
    ,
    ,
    activeDashboard,
    setActiveDashboard,
    filteredDashboards
  ] = dashboardsContext;
  const [filterField] = filterContext;
  return (
    <>
      {activeDashboard?.url ? (
        <div className="mx-4 px-2 py-4">
          <p style={{ fontSize: "2.4rem", color: "#707070" }}>
            {activeDashboard?.name}
          </p>
          {<hr />}
          <iframe
            title="basic"
            src={activeDashboard?.url}
            width="100%"
            height="700px"
            frameBorder="0"
          />
        </div>
      ) : (
        <div className="text-center basic-layout-text mt-3">
          There are no Reports available for {filterField.label}
        </div>
      )}
    </>
  );
}

export default BasicLayout;
