import React, { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  AiOutlinePlus,
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineUpload
} from "react-icons/ai";
import { MdSync, MdSyncDisabled } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import {
  fetchContents,
  createContent,
  deleteContent,
  updateContent,
  getAllImageFolders,
  uploadContent,
  trackUserAction,
  fetchAppVariables,
  fetchBrands,
  syncCatalogContent,
  fetchThumbnailList,
  fetchCustomUsers
} from "../../services/dataService";
import Grid from "../../components/Grid";
import AccentButton from "../../components/AccentButton";
import { useAlertsContext } from "../../context/alerts";
import { getValuesByKey, getValueOnlyByKey } from "../../services/utils";
import Spinner from "../../components/Spinner";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import ModalCard from "../../components/ModalCard";
import {
  SortableContainer,
  SortableElement,
  sortableHandle
} from "react-sortable-hoc";
import { AbilityContext, PermissionsContext } from "./AbilityContext";
import { Can } from "./AbilityContext";
import defineRulesFor from "./permissions";

const DEFAULT_SELECT_VALUES = [{ value: "", label: "" }];

let objectTypesValues = DEFAULT_SELECT_VALUES;
let sourcesValues = DEFAULT_SELECT_VALUES;
let belongstoValues = DEFAULT_SELECT_VALUES;
let brandValues = DEFAULT_SELECT_VALUES;
let filterValues = DEFAULT_SELECT_VALUES;
let fetchedUsers = DEFAULT_SELECT_VALUES;
let thumbnailValues = DEFAULT_SELECT_VALUES;

let DB_SOURCES_TO_SYNC = ["QlikView", "Tableau-SXM", "Cognos", "Excel"].map(e =>
  e.toLowerCase()
);
const SHOW_SYNC_BUTTON = S =>
    !S.CatalogSync && DB_SOURCES_TO_SYNC.includes(S.src.toLowerCase()),
  SHOW_SYNCED_BUTTON = S =>
    S.CatalogSync && DB_SOURCES_TO_SYNC.includes(S.src.toLowerCase());

const NEW_WINDOW = [
  { value: "N", label: "No" },
  { value: "Y", label: "Yes" }
];

const IMG_PARENT_FOLDER = "assets/images/IAThumbnails/";
function arrayMove(array, from, to) {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
}

const SortableMultiValue = SortableElement(props => {
  // this prevents the menu from being opened/closed when the user clicks
  // on a value to begin dragging it. ideally, detecting a click (instead of
  // a drag) would still focus the control and toggle the menu, but that
  // requires some magic with refs that are out of scope for this example
  const onMouseDown = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  return (
    <components.MultiValue
      className="sortable-element"
      {...props}
      innerProps={innerProps}
    />
  );
});

const SortableMultiValueLabel = sortableHandle(props => (
  <components.MultiValueLabel {...props} />
));

const SortableSelect = SortableContainer(Select);

function MultiSelectSort({ value, options, onOptionsChange }) {
  const [selected, setSelected] = React.useState(value);

  const onChange = selectedOptions => setSelected(selectedOptions);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(selected, oldIndex, newIndex);
    setSelected(newValue);
  };

  useEffect(() => {
    onOptionsChange(selected);
  }, [onOptionsChange, selected]);

  return (
    <SortableSelect
      useDragHandle
      helperClass="sortable-z-index"
      axis="xy"
      onSortEnd={onSortEnd}
      distance={4}
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
      isMulti
      options={options}
      value={selected}
      onChange={onChange}
      components={{
        MultiValue: SortableMultiValue,
        MultiValueLabel: SortableMultiValueLabel
      }}
      closeMenuOnSelect={false}
    />
  );
}

function ContentManagement(props) {
  const [show, setShow] = useState(false);
  const [uploadShow, setUploadShow] = useState(false);
  const [uploadShowV2, setUploadShowV2] = useState(false);
  const [thumbnails, setThumbnails] = useState([]);
  const [contents, setContents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [description, setDescription] = useState("");
  const [srchtgs, setSrchtgs] = useState("");
  const { register, handleSubmit, errors, reset, setValue } = useForm();
  const [itemForEdit, setItemForEdit] = useState(null);
  const { addSuccessAlert, addFailAlert } = useAlertsContext();
  const [selectedObjType, setSelectedObjType] = useState([]);
  const [selectedSource, setSelectedSource] = useState([]);
  const [selectedBelongsTo, setSelectedBelongsTo] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(brandValues[0]);
  const [selectedNewWindow, setSelectedNewWindow] = useState(NEW_WINDOW[0]);
  const [selectedThumbnail, setSelectedThumbnail] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [imgFolders, setImgFolders] = useState([]);
  const [delId, setDelId] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedOwners, setSelectedOwners] = useState([]);

  const ability = useContext(AbilityContext);
  const { permissionsCtxt } = useContext(PermissionsContext);
  const [permissions, , isAdmin] = permissionsCtxt;

  useEffect(() => {
    const rules = defineRulesFor(permissions, isAdmin);
    ability.update(rules);
  }, [permissions.length]);

  const handleCloseDelModal = () => {
    setDelId(null);
  };
  const handleShowDelModal = id => {
    setDelId(id);
  };

  const refreshContent = () => {
    fetchContents()
      .then(res => {
        setContents(res.data);
        setLoading(false);
        trackUserAction({
          action: "List",
          targetType: "Admin",
          targetName: "Content",
          targetPath: "/admin/content",
          targetID: "",
          status: "Success",
          errorDetails: ""
        });
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    fetchAppVariables().then(res => {
      objectTypesValues = getValuesByKey(
        res.data,
        "REACT_APP_CONTENT_CONTENT_TYPES"
      );
      setSelectedObjType(objectTypesValues[0]);
      sourcesValues = getValuesByKey(res.data, "REACT_APP_CONTENT_SOURCES");
      setSelectedSource(sourcesValues[0]);
      belongstoValues = getValuesByKey(
        res.data,
        "REACT_APP_CONTENT_CONTENT_BELONGSTO"
      );
      const filterVals = JSON.parse(
        getValueOnlyByKey(res.data, "ui.dashboards.filter")
      );
      const data = [
        ...filterVals.map(d => {
          const option = {
            label: d.quarter,
            value: d.quarter,
            type: "quarter"
          };
          let qVal = d.quarter.split(" ")[0];

          const options = d.months.map(s => {
            let values = s.month.split(" ");
            return {
              label: s.month,
              value: `${values[1]} ${qVal} ${values[0]}`,
              type: "month"
            };
          });

          return [option, ...options];
        })
      ];
      filterValues = data.flat();
      setSelectedBelongsTo(belongstoValues[0]);
    });
    fetchCustomUsers().then(res => {
      fetchedUsers = res.data
        .filter(el => el.status === "A" && el.type !== "E")
        .map(u => ({ label: u.name, value: u.email }));
      setUsers(fetchedUsers);
    });
    fetchBrands().then(res => {
      brandValues = res.data.map(d => {
        return { value: d.id, label: d.name };
      });
      setSelectedBrand(brandValues[0]);
    });
    fetchThumbnailList()
      .then(res => {
        setThumbnails(
          res.data?.map(el => {
            const path = el.path ? ` (${el.path})` : "";
            return {
              value: el.rootPath,
              label: `${el.name}${path}`
            };
          })
        );
        thumbnailValues = res.data?.map(el => {
          const path = el.path ? ` (${el.path})` : "";
          return {
            value: el.rootPath,
            label: `${el.name}${path}`
          };
        });
      })
      .catch();
    refreshContent();
  }, []);

  const clearData = () => {
    setSelectedOwners(prev => prev?.splice(0, prev.length));
    setItemForEdit(null);
    reset({});
    setSelectedObjType(objectTypesValues[0]);
    setSelectedSource(sourcesValues[0]);
    setSelectedBelongsTo(belongstoValues[0]);
    setSelectedBrand(brandValues[0]);
    setSelectedNewWindow(NEW_WINDOW[0]);
    setSelectedThumbnail([]);
    setSelectedFilter([]);
    setName("");
    setUrl("");
    setDescription("");
    setSrchtgs("");
  };

  const handleCloseModal = () => {
    setShow(false);
    clearData();
  };

  const handleCloseUploadModal = () => {
    setUploadShowV2(false);
    setUploadShow(false);
    uploadShowV2 && setShow(true);
    setPreviewImage(null);
  };

  const handleNewContentClick = () => {
    reset({});
    clearData();
    fetchBrands().then(res => {
      brandValues = res.data.map(d => {
        return { value: d.id, label: d.name };
      });
      setSelectedBrand(brandValues[0]);
    });
    setShow(true);
    setSelectedThumbnail([]);
  };

  const handleUploadClick = () => {
    setUploadShow(true);
    getAllImageFolders({})
      .then(res => {
        setImgFolders(
          res.data.map(f => {
            const folderName = f.foldername
              .replace(IMG_PARENT_FOLDER, ``)
              .slice(0, -1);
            return {
              value: f.foldername,
              label: folderName || "/"
            };
          })
        );
      })
      .catch(err => {
        setUploadShow(false);
        addFailAlert("Failed to fetch image folders");
      });
  };

  const handleUploadClickV2 = () => {
    setUploadShowV2(true);
    setShow(false);
    handleUploadClick();
  };

  const handleSelectObjType = selectedObjType => {
    setValue("type", selectedObjType);
    setSelectedObjType(selectedObjType);
  };
  const handleSelectSource = selectedSource => {
    setValue("src", selectedSource);
    setSelectedSource(selectedSource);
  };

  const handleSelectBelongsTo = selectedBelongsTo => {
    setValue("belongsto", selectedBelongsTo);
    setSelectedBelongsTo(selectedBelongsTo);
  };
  const handleSelectBrand = selectedBrand => {
    setValue("brandid", selectedBrand);
    setSelectedBrand(selectedBrand);
  };
  const handleSelectNewWindow = selectedNewWindow => {
    setValue("newWindow", selectedNewWindow);
    setSelectedNewWindow(selectedNewWindow);
  };

  const handleSelectFilter = option => {
    option?.__isNew__
      ? setSelectedFilter({ label: option.value, value: option.value })
      : setSelectedFilter(option);
  };

  const handleSelectOwners = selectedOwners => {
    setValue("owner", selectedOwners);
    setSelectedOwners(selectedOwners);
  };

  const onSubmit = async data => {
    const contentID = itemForEdit ? itemForEdit.id : null;
    const isEdit = !!contentID;

    if (isEdit) {
      try {
        const {
          data: [response]
        } = await updateContent({
          ...data,
          id: contentID,
          thumbnail: selectedThumbnail ? selectedThumbnail.value : "",
          type: selectedObjType.value,
          src: selectedSource.value,
          owner: !!selectedOwners.length
            ? selectedOwners.map(s => `${s.label}:${s.value}`).join(";")
            : "",
          filters: selectedFilter ? selectedFilter.value : "",
          belongsto: selectedBelongsTo.value,
          brandId: selectedBrand.value,
          newWindow: selectedNewWindow.value
        });
        await trackUserAction({
          action: "Edit",
          targetType: "Admin",
          targetName: "Content",
          targetPath: "/admin/content",
          targetID: "",
          status: "Success",
          errorDetails: ""
        });
        handleCloseModal();
        addSuccessAlert(`Content updated`);

        const index = contents.findIndex(i => i.id === response.id);
        if (index !== undefined) {
          // Insert new updated row into the grid at the correct index
          setContents(items =>
            items.map((i, idx) => {
              if (idx === index) {
                return response;
              }
              return i;
            })
          );
        }
      } catch (err) {
        handleCloseModal();
        addFailAlert("Something went wrong");
      }
    } else {
      try {
        const {
          data: [response]
        } = await createContent({
          ...data,
          thumbnail: selectedThumbnail ? selectedThumbnail.value : "",
          type: selectedObjType.value,
          owner: !!selectedOwners.length
            ? selectedOwners.map(s => `${s.label}:${s.value}`).join(";")
            : "",
          filters: selectedFilter ? selectedFilter.value : "",
          src: selectedSource.value,
          belongsto: selectedBelongsTo.value,
          brandId: selectedBrand.value,
          newWindow: selectedNewWindow.value
        });
        await trackUserAction({
          action: "Create",
          targetType: "Admin",
          targetName: "Content",
          targetPath: "/admin/content",
          targetID: "",
          status: "Success",
          errorDetails: ""
        });
        handleCloseModal();
        addSuccessAlert(`Content created`);
        // Add new row to the end
        setContents([...contents, response]);
      } catch (err) {
        handleCloseModal();
        addFailAlert("Something went wrong");
      }
    }
  };

  const handleDeleteClick = async id => {
    try {
      await deleteContent({ id: id });
      await trackUserAction({
        action: "Delete",
        targetType: "Admin",
        targetName: "Content",
        targetPath: "/admin/content",
        targetID: "",
        status: "Success",
        errorDetails: ""
      });
      addSuccessAlert("Content deleted");
      setContents(items => items.filter(i => i.id !== id));
    } catch (err) {
      addFailAlert("Something went wrong");
    }
  };

  const handleEditClick = content => {
    fetchBrands().then(res => {
      brandValues = res.data.map(d => {
        return { value: d.id, label: d.name };
      });
    });
    setItemForEdit(content);
    setName(content.name);
    setUrl(content.url);
    setDescription(content.description);
    setSrchtgs(content.srchtgs);
    setSelectedObjType({ value: content.type, label: content.type });
    setSelectedSource({ value: content.src, label: content.src });

    setSelectedThumbnail(
      !!content.thumbnail
        ? {
            value: content.thumbnail,
            label: thumbnailValues.find(b => b.value === content.thumbnail)
              ?.label
          }
        : []
    );
    setSelectedBelongsTo({
      value: content.belongsto,
      label: content.belongsto
    });
    setSelectedBrand({
      value: content.brandid,
      label: brandValues.find(b => b.value === content.brandid)?.label
    });
    setSelectedNewWindow(
      NEW_WINDOW.filter(v => v.value === content.newWindow)[0] || NEW_WINDOW[0]
    );

    setSelectedOwners(
      !!content.owner
        ? content.owner
            .split(";")
            .map(g =>
              fetchedUsers.find(
                u => `${u.label}:${u.value}`.toLowerCase() === g.toLowerCase()
              )
            )
        : []
    );

    !content.filters
      ? setSelectedFilter([])
      : setSelectedFilter(filterValues.find(e => e.value === content.filters));
    reset(content);
    setShow(true);
  };

  const handleSelectImgFolder = option => {
    setValue("imgFolder", option.label);
  };

  const handleSelectThumbnail = option => {
    setSelectedThumbnail(option);
  };

  const handleNewImgFolder = e => {
    setValue("imgFolder", e.target.value);
  };

  const onUploadSubmit = async data => {
    console.log("files", data);
    // uploadShowV2 && setShow(true);
    if (!data.file) {
      addFailAlert("Attach a file");
      return;
    }
    let fileData = new FormData();
    fileData.append("file", data.file[0]);
    try {
      const uploadedImg = await uploadContent(fileData, data.imgFolder || "/");

      if (uploadShowV2) {
        const fileName = uploadedImg.data
          .replace(IMG_PARENT_FOLDER, ``)
          .replace(data.imgFolder, "");

        let selectedThumbnailOption = {
          value: uploadedImg.data,
          label: `${fileName?.replaceAll("/", "")} (${data.imgFolder})`
        };
        thumbnailValues.push(selectedThumbnailOption);
        setSelectedThumbnail(selectedThumbnailOption);
      }

      await trackUserAction({
        action: "Upload",
        targetType: "Admin",
        targetName: "Content Image",
        targetPath: "/admin/content",
        targetID: "",
        status: "Success",
        errorDetails: ""
      });
      handleCloseUploadModal();
      !uploadShowV2 && addSuccessAlert(`Content uploaded`);
    } catch (err) {
      handleCloseUploadModal();
      addFailAlert("Something went wrong");
    }
  };

  const handleSyncClick = id => {
    syncCatalogContent(id)
      .then(() => {
        refreshContent();
        addSuccessAlert(`Content synced`);
      })
      .catch(() => {
        addFailAlert("Content sync failed");
      });
  };

  const formatOptionLabel = ({ label, type }) => (
    <div style={type === "month" ? { marginLeft: "15px" } : {}}>{label}</div>
  );

  return (
    <div className="Contents">
      <Can I="Write" a="Content Management">
        {() => (
          <>
            <Button className="mb-3 mx-2" onClick={handleNewContentClick}>
              <AiOutlinePlus /> New Content
            </Button>
            <Button className="mb-3 mx-2" onClick={handleUploadClick}>
              <AiOutlineUpload /> Upload
            </Button>
          </>
        )}
      </Can>

      {loading ? (
        <Spinner>Fetching Contents</Spinner>
      ) : (
        <Grid
          width="100%"
          title="Contents"
          columnDefs={[
            {
              field: "id",
              headerName: "Id",
              sortable: true,
              resizable: true
            },
            {
              field: "name",
              headerName: "Name",
              sortable: true,
              resizable: true
            },
            {
              field: "src",
              headerName: "Source",
              sortable: true,
              resizable: true
            },
            {
              field: "url",
              headerName: "URL",
              sortable: true,
              resizable: true
            },
            {
              field: "description",
              headerName: "Description",
              sortable: true,
              resizable: true
            },
            {
              field: "srchtgs",
              headerName: "Tags",
              sortable: true,
              resizable: true
            },
            {
              field: "belongsto",
              headerName: "Belongs To",
              sortable: true,
              resizable: true
            },
            {
              field: "edit",
              headerName: "",
              resizable: true,
              cellRendererFramework: ({ data, value }) => {
                return (
                  <Can I="Write" a="Content Management">
                    {() => (
                      <>
                        {SHOW_SYNC_BUTTON(data) && (
                          <AccentButton
                            className="mr-2"
                            onClick={() => handleSyncClick(data.id)}
                          >
                            <MdSync /> Sync
                          </AccentButton>
                        )}

                        {SHOW_SYNCED_BUTTON(data) && (
                          <AccentButton className="disabled ml-n2 mr-2">
                            <MdSyncDisabled /> Synced
                          </AccentButton>
                        )}

                        <AccentButton onClick={() => handleEditClick(data)}>
                          <AiOutlineEdit /> Edit
                        </AccentButton>
                        <AccentButton
                          className="sx-accent-button--danger ml-2"
                          onClick={() => handleShowDelModal(data.id)}
                        >
                          <AiOutlineDelete /> Delete
                        </AccentButton>
                      </>
                    )}
                  </Can>
                );
              }
            }
          ]}
          rowData={contents}
        />
      )}

      <Modal
        className="contents-modal"
        show={show}
        onHide={handleCloseModal}
        style={{ visibility: uploadShowV2 ? "hidden" : "" }}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {!!itemForEdit ? "Edit" : "Add New"} Content
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form.Group as={Row} controlId="contents-type">
              <Form.Label column sm="4">
                Type{!itemForEdit && <>*</>}
              </Form.Label>
              <Col sm="8">
                <Select
                  value={selectedObjType}
                  defaultValue={objectTypesValues[0]}
                  isDisabled={!!itemForEdit}
                  name="type"
                  options={objectTypesValues}
                  onChange={handleSelectObjType}
                  ref={register({ name: "type" })}
                />
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="contents-name">
              <Form.Label column sm="4">
                Name*
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  name="name"
                  ref={register({
                    required: true,
                    validate: val => val.trim() !== ""
                  })}
                  type="text"
                  placeholder=""
                  value={name}
                  onChange={e => setName(e.target.value)}
                  isInvalid={!!errors.name}
                  maxLength={250}
                />
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="business-owner">
              <Form.Label column sm="4">
                Business Owner
              </Form.Label>
              <Col sm="8">
                <MultiSelectSort
                  value={selectedOwners}
                  options={users}
                  onOptionsChange={handleSelectOwners}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="contents-src">
              <Form.Label column sm="4">
                Source*
              </Form.Label>
              <Col sm="8">
                <Select
                  value={selectedSource}
                  defaultValue={sourcesValues[0]}
                  name="src"
                  options={sourcesValues}
                  onChange={handleSelectSource}
                  ref={register({ name: "src" })}
                />
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="contents-thumbnail">
              <Form.Label column sm="4">
                Thumbnail
              </Form.Label>
              <Col
                sm="8"
                style={{ display: "flex" }}
                className="justify-content-between"
              >
                <div style={{ flex: "4 0 100px" }}>
                  <Select
                    isClearable
                    name="thumbnail"
                    value={selectedThumbnail}
                    options={thumbnailValues}
                    defaultValue={thumbnails[0]}
                    onChange={handleSelectThumbnail}
                    ref={register({ name: "thumbnail" })}
                  />
                </div>

                <Button
                  className="ml-2"
                  style={{ flexgrow: 1 }}
                  onClick={handleUploadClickV2}
                >
                  <AiOutlineUpload />
                </Button>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="contents-filters">
              <Form.Label column sm="4">
                Filters
              </Form.Label>
              <Col sm="8">
                <CreatableSelect
                  formatOptionLabel={formatOptionLabel}
                  isClearable
                  value={selectedFilter}
                  name="filters"
                  options={filterValues}
                  onChange={handleSelectFilter}
                  ref={register({ name: "filters" })}
                  defaultValue={filterValues[0]}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="contents-url">
              <Form.Label column sm="4">
                URL*
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  as="textarea"
                  name="url"
                  ref={register({
                    required: true,
                    validate: val => val.trim() !== ""
                  })}
                  type="text"
                  placeholder=""
                  value={url}
                  onChange={e => setUrl(e.target.value)}
                  isInvalid={!!errors.url}
                  maxLength={2000}
                />
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="contents-description">
              <Form.Label column sm="4">
                Description*
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  as="textarea"
                  name="description"
                  ref={register({
                    required: true,
                    validate: val => val.trim() !== ""
                  })}
                  type="text"
                  placeholder=""
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  isInvalid={!!errors.description}
                  maxLength={4000}
                />
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="contents-srchtgs">
              <Form.Label column sm="4">
                Tags
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  as="textarea"
                  name="srchtgs"
                  ref={register({
                    setValueAs: val => val.trim()
                  })}
                  type="text"
                  value={srchtgs}
                  onChange={e => setSrchtgs(e.target.value)}
                  placeholder=""
                  maxLength={250}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="contents-belongsto">
              <Form.Label column sm="4">
                Belongs To*
              </Form.Label>
              <Col sm="8">
                <Select
                  value={selectedBelongsTo}
                  name="belongsto"
                  options={belongstoValues}
                  onChange={handleSelectBelongsTo}
                  ref={register({ name: "belongsto" })}
                  defaultValue={belongstoValues[0]}
                />
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="contents-brandid">
              <Form.Label column sm="4">
                Brand*
              </Form.Label>
              <Col sm="8">
                <Select
                  value={selectedBrand}
                  name="brandid"
                  options={brandValues}
                  onChange={handleSelectBrand}
                  ref={register({ name: "brandid" })}
                  defaultValue={brandValues[0]}
                />
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="contents-newWindow">
              <Form.Label column sm="4">
                Opens in New Window*
              </Form.Label>
              <Col sm="8">
                <Select
                  value={selectedNewWindow}
                  name="newWindow"
                  options={NEW_WINDOW}
                  onChange={handleSelectNewWindow}
                  ref={register({ name: "newWindow" })}
                  defaultValue={NEW_WINDOW[0]}
                />
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="due-date">
              <Form.Label column sm="4">
                Due Date
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  name="duedate"
                  ref={register({
                    // required: true,
                    // validate: val => val.trim() !== ""
                  })}
                  type="date"
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                />
              </Col>
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        className="contents-modal"
        show={uploadShow}
        onHide={handleCloseUploadModal}
      >
        <Form onSubmit={handleSubmit(onUploadSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Upload Content</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form.Group as={Row} controlId="contents-file">
              <Form.Label column sm="4">
                Image File*
              </Form.Label>
              <Col sm="8" style={{ display: "flex" }}>
                <Form.Control
                  name="file"
                  ref={register({
                    required: true,
                    name: "file"
                  })}
                  onChange={e => {
                    setValue("file", e.target.files); // you get all the files object here
                    setPreviewImage(URL.createObjectURL(e.target.files[0]));
                  }}
                  type="file"
                  isInvalid={!!errors.file}
                />
                {previewImage && (
                  <img
                    className="preview"
                    src={previewImage}
                    alt=""
                    style={{ width: "50px", display: "inline" }}
                  />
                )}
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="contents-imgFolder">
              <Form.Label column sm="4">
                Image Folder
              </Form.Label>
              <Col sm="8">
                <Select
                  name="imgFolder"
                  options={imgFolders}
                  defaultValue={imgFolders[0]}
                  onChange={handleSelectImgFolder}
                  ref={register({ name: "imgFolder" })}
                />
              </Col>
            </Form.Group>
            OR
            <Form.Group as={Row} controlId="contents-imgNewFolder">
              <Form.Label column sm="4">
                New Folder
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  name="imgNewFolder"
                  type="text"
                  placeholder=""
                  onChange={handleNewImgFolder}
                  maxLength={250}
                />
              </Col>
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button type="submit" variant="primary">
              Upload
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {!!delId && (
        <ModalCard
          body={`Do you still want to delete this ${`Content`}?`}
          title={`Delete ${`Content`} Confirmation`}
          show={!!delId}
          noText="Cancel"
          yesText="Ok"
          handleNo={handleCloseDelModal}
          handleYes={() => {
            handleDeleteClick(delId);
            handleCloseDelModal();
          }}
        />
      )}
    </div>
  );
}

export default ContentManagement;
