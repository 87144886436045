import { useLocalStorage } from "beautiful-react-hooks";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { FiFilter } from "react-icons/fi";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch
} from "react-router-dom";
import CheckWithCount from "../../components/CheckWithCount";
import ExpandableMenu from "../../components/ExpandableMenu";
import BasePage from "../../components/layouts/BasePage";
import { useAlertsContext } from "../../context/alerts";
import { fetchAllFilters, trackUserAction } from "../../services/dataService";
import Categories from "./Categories";
import Domains from "./Domains";
import MetricsWrapper from "./MetricsWrapper";
import SearchResults from "./SearchResults";
import { groupBy } from "lodash";

function DataCatalogShell({
  globalSearchText,
  setGlobalSearchText,
  activeFilters,
  setActiveFilters
}) {
  const history = useHistory();
  const { path } = useRouteMatch();
  const [openAccordionKey, setOpenAccordionKey] = useState("Business Domain");
  const [filterMasterData, setFilterMasterData] = useState([]);
  const [filterAllData, setFilterAllData] = useState([]);
  const [counts, setCounts] = useState([]);
  const { addFailAlert } = useAlertsContext();

  // Pagination state
  const [paginationState, setPaginationState] = useLocalStorage(
    "DATA_CATALOG_PAGINATION",
    {}
  );

  // Fetch master filter data once on load.  Used for populating filter sidebar
  useEffect(() => {
    fetchAllFilters()
      .then(result => {
        if (!Array.isArray(result.data)) {
          return setFilterMasterData({});
        }
        setFilterMasterData(
          result.data.reduce((acc, curr) => {
            acc[curr.type] = curr[curr.type].map(i => ({
              type: curr.type,
              value: i[curr.type]
            }));
            return acc;
          }, {})
        );
        setFilterAllData(
          result.data
            ?.filter(e => e.type === "Data Domain")
            ?.map(e => e["Data Domain"])
            ?.flat()
        );
      })
      .catch(err => {
        console.log(err);
        addFailAlert("Something went wrong fetching filters");
      });
  }, [addFailAlert]);

  function CommonFilters() {
    return (
      <Col md={4} lg={3} xl={2} sm={12}>
        <ExpandableMenu
          title={
            <div className="d-flex align-items-center">
              <FiFilter />
              <span className="ml-2">Filters</span>
            </div>
          }
          onItemClick={(key, item) => {
            if (key === openAccordionKey) {
              return setOpenAccordionKey(null);
            }
            if (item.body) {
              return setOpenAccordionKey(key);
            }
          }}
          activeKey={openAccordionKey}
          items={Object.keys(filterMasterData).map(k => {
            return {
              name: k,
              id: k,
              body: filterMasterData[k].map(({ type, value }) => (
                <CheckWithCount
                  key={`${type}|${value}`}
                  className="mb-2"
                  name={`${type}|${value}`}
                  label={value}
                  checked={
                    !!activeFilters.find(
                      af => af.type === type && af.value === value
                    )
                  }
                  count={counts[type] ? counts[type][value] || 0 : null}
                  onChange={e => {
                    trackUserAction({
                      action: "Search Data Catalog",
                      targetType: "filters",
                      targetName: `${type}|${value}`,
                      targetPath: "/datacatalog/search",
                      targetID: `${type}|${value}`,
                      status: "Success",
                      errorDetails: ""
                    });
                    history.push("/datacatalog/search");
                    setPaginationState({});
                    const filterExists = !!activeFilters.find(
                      j => j.type === type && j.value === value
                    );
                    if (filterExists) {
                      return setActiveFilters(af =>
                        af.filter(j => !(j.type === type && j.value === value))
                      );
                    }
                    return setActiveFilters(af => [...af, { type, value }]);
                  }}
                />
              ))
            };
          })}
        ></ExpandableMenu>
      </Col>
    );
  }

  return (
    <BasePage>
      <Container fluid className="p-4">
        <Switch>
          {/* If landing on "/dashboardcatalog" then redirect to "/dashboardcatalog/domains" */}
          <Redirect exact path={path} to={`${path}/domains`} />
          {/* Sub-Route for Domains */}
          <Route exact path={`${path}/domains`}>
            <Row>
              <CommonFilters />
              <Col md={8} lg={9} xl={10} sm={12}>
                <Domains
                  setCounts={setCounts}
                  setGlobalSearchText={setGlobalSearchText}
                  setActiveFilters={setActiveFilters}
                  setPaginationState={setPaginationState}
                />
              </Col>
            </Row>
          </Route>

          {/* Sub-Route for Categories */}
          <Route exact path={`${path}/categories`}>
            <Row>
              <CommonFilters />
              <Col md={8} lg={9} xl={10} sm={12}>
                <Categories
                  setCounts={setCounts}
                  activeFilters={activeFilters}
                  setActiveFilters={setActiveFilters}
                  filterAllData={filterAllData}
                />
              </Col>
            </Row>
          </Route>
          {/* Sub-Route for Metrics */}
          <Route exact path={`${path}/metrics`}>
            <MetricsWrapper />
          </Route>
          {/* Sub-Route for Search Results */}
          <Route exact path={`${path}/search`}>
            <Row>
              <CommonFilters />
              <Col md={8} lg={9} xl={10} sm={12}>
                <SearchResults
                  setCounts={setCounts}
                  activeFilters={activeFilters}
                  setActiveFilters={setActiveFilters}
                  globalSearchText={globalSearchText}
                  setPaginationState={setPaginationState}
                  paginationState={paginationState}
                />
              </Col>
            </Row>
          </Route>
          <Redirect exact path={`${path}/*`} to="/" />
        </Switch>
      </Container>
    </BasePage>
  );
}

export default DataCatalogShell;
