import { APP_VARS } from "../../../services/appVars";

const FILE_selfPayCampaignTouchAnalytics =
  "/pdfs/SelfPay Campaign Touch Analytics.pdf";
const FILE_selfPayAttritionAnalytics = "/pdfs/SelfPay Attrition Analytics.pdf";
const FILE_nonPayAttritionAnalytics = "/pdfs/NonPay Attrition Analytics.pdf";
const FILE_streamingAnalytics = "/pdfs/Streaming Analytics.pdf";
const FILE_streamingSummaryAnalytics = "/pdfs/Streaming Summary Analytics.pdf";
const FILE_streamingPerformanceAnalytics =
  "/pdfs/Streaming Performance Analytics.pdf";
const FILE_trialStreamingAnalytics = "/pdfs/Trial Streaming Analytics.pdf";
const FILE_subscriberTrialLifeCycle = "/pdfs/Trial Life Cycle Analytics.pdf";
const FILE_marketingCampaign = "/pdfs/Campaign_Performance_Analytics.pdf";
const FILE_marketingCampaignTouch =
  "/pdfs/Campaign Touch Performance Analytics.pdf";
const FILE_subscriptionStreaming = "/pdfs/Subscription Streaming Analytics.pdf";

export default () => [
  {
    text: "SelfPay Campaign Touch Analytics",
    url: APP_VARS.REACT_APP_ASSETS_URL + FILE_selfPayCampaignTouchAnalytics
  },
  {
    text: "SelfPay Attrition Analytics",
    url: APP_VARS.REACT_APP_ASSETS_URL + FILE_selfPayAttritionAnalytics
  },
  {
    text: "NonPay Attrition Analytics",
    url: APP_VARS.REACT_APP_ASSETS_URL + FILE_nonPayAttritionAnalytics
  },
  {
    text: "Streaming Analytics",
    url: APP_VARS.REACT_APP_ASSETS_URL + FILE_streamingAnalytics
  },
  {
    text: "Streaming Summary Analytics",
    url: APP_VARS.REACT_APP_ASSETS_URL + FILE_streamingSummaryAnalytics
  },
  {
    text: "Streaming Performance Analytics (New)",
    url: APP_VARS.REACT_APP_ASSETS_URL + FILE_streamingPerformanceAnalytics
  },
  {
    text: "Trial Streaming Analytics",
    url: APP_VARS.REACT_APP_ASSETS_URL + FILE_trialStreamingAnalytics
  },
  {
    text: "Subscriber Trial Life Cycle Analytics",
    url: APP_VARS.REACT_APP_ASSETS_URL + FILE_subscriberTrialLifeCycle
  },
  {
    text: "Marketing Campaign Analytics",
    url: APP_VARS.REACT_APP_ASSETS_URL + FILE_marketingCampaign
  },
  {
    text: "Marketing Campaign Touch Analytics",
    url: APP_VARS.REACT_APP_ASSETS_URL + FILE_marketingCampaignTouch
  },
  {
    text: "Subscription Streaming Analytics",
    url: APP_VARS.REACT_APP_ASSETS_URL + FILE_subscriptionStreaming
  }
];
