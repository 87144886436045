import React from "react";
import { APP_VARS } from "../../services/appVars";
import { Modal, Row, Col } from "react-bootstrap";
import { AiFillQuestionCircle } from "react-icons/ai";
import "../dashboard-catalog/ServiceNowInstructions.scss";

const S_NOW_INSTRUCTIONS = [
  {
    name: "<h5>Search for Jupyter in the search field</h5>",
    img: "/images/JupyterServiceNow/snow_search_jupyter.png"
  },
  {
    name:
      "<h5>Select Jupyter in the Access Selector List and add the role</h5>",
    img: "/images/JupyterServiceNow/access_selector.png"
  },
  {
    name:
      "<h5>Select the Environment(s) and click Add to Selected Accesses.</h5><br/> <h5>Click Order Now when finished</h5>",
    img: "/images/JupyterServiceNow/environments_popup.png"
  }
];

export default function ServiceNowInstructions({ show, handleCloseModal }) {
  return (
    <Modal
      show={show}
      onHide={handleCloseModal}
      centered
      className="ServiceNowInstructions"
    >
      <Modal.Header closeButton className="p-2">
        <AiFillQuestionCircle size={28} className="mt-1 mr-2" />
        <Modal.Title>How to Request Access using Service Now?</Modal.Title>
      </Modal.Header>

      <Modal.Body className="bg-body-color">
        {S_NOW_INSTRUCTIONS.map((s, i) => (
          <Row
            className="d-flex align-items-center justify-content-between p-2 m-3 bg-white"
            key={i}
          >
            <Col sm={4}>
              <div dangerouslySetInnerHTML={{ __html: s.name }} />
            </Col>
            <Col sm={8}>
              <img
                className="border w-100 rounded p-3"
                fluid
                src={APP_VARS.REACT_APP_ASSETS_URL + s.img}
                alt="cover"
              />
            </Col>
          </Row>
        ))}
      </Modal.Body>
    </Modal>
  );
}
