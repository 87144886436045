import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";

import "./TruncateText.scss";

function TruncateText({ text, className = "", limit = 200, ...otherProps }) {
  const truncatingRequired = text.length >= 200;
  const shortText = truncatingRequired ? text.slice(0, limit) + "..." : text;
  const [showMore, setShowMore] = useState(false);
  const [value, setValue] = useState(shortText);
  const onButtonClick = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {
    if (showMore) {
      setValue(text);
    } else {
      setValue(shortText);
    }
    return () => {
      setValue(shortText);
    };
  }, [shortText, showMore, text]);

  return (
    <div {...otherProps} className={`sx-truncate-text ${className}`}>
      <em>{value}</em>
      {truncatingRequired && (
        <Button
          className="show-more ml-1 p-0"
          variant="link"
          size="sm"
          onClick={e => {
            e.stopPropagation();
            onButtonClick();
          }}
          title={"Show Text"}
        >
          {`Show ${showMore ? `Less` : `More`}`}
        </Button>
      )}
    </div>
  );
}

export default TruncateText;
