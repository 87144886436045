import React, { useState } from "react";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { FiGrid } from "react-icons/fi";
import { secondary, accent2 } from "../../variables.scss";
import classnames from "classnames";
import "./index.scss";

const style = { transition: "all 0.2s ease" };

function ViewAs({ activeKey, onClick, className = "" }) {
  const [isListView, setIsListView] = useState(false);
  const isGrid = activeKey === "grid";

  return (
    <div className={classnames("d-flex align-items-center", className)}>
      {isListView && (
        <AiOutlineUnorderedList
          title="List View"
          onClick={() => {
            setIsListView(flag => !flag);
            onClick("list");
          }}
          className="pointer pl-2 grid-list-icon"
          style={{
            ...style,
            fontSize: "3.5rem"
          }}
        />
      )}

      {!isListView && (
        <FiGrid
          title="Grid View"
          onClick={() => {
            setIsListView(flag => !flag);
            onClick("grid");
          }}
          className="pointer pl-2 grid-list-icon"
          style={{
            ...style,
            fontSize: "3.5rem"
          }}
        />
      )}
    </div>
  );
}

export default ViewAs;
