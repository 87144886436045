import React, { useState, useCallback, useContext, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useLocalStorage } from "beautiful-react-hooks";

const AlertsContext = React.createContext(null);

const AlertsProvider = props => {
  const [alerts, setAlerts] = useState([]);
  const [activeAlerts, setActiveAlerts] = useLocalStorage("ALERTS", []);

  const markAsInactive = useCallback(
    removeFilter => {
      setActiveAlerts(activeAlerts => {
        let changedAlert = activeAlerts.find(
          i => i.id + i.Menu === removeFilter
        );
        changedAlert.show = false;
        let newList = [
          ...activeAlerts.filter(i => i.id + i.Menu !== removeFilter)
        ];
        return [...newList, changedAlert];
      });
    },
    [setActiveAlerts]
  );

  const syncActiveAlerts = useCallback(
    responseList => {
      const allActiveAlerts = responseList.reduce(
        (a, item) => [
          ...a,
          ...item.categories.map(c => ({ ...item, Menu: c.categoryName }))
        ],
        []
      );

      setActiveAlerts(currentAlerts =>
        allActiveAlerts.map(a => {
          return {
            ...a,
            show: currentAlerts.filter(
              aa => aa.id + aa.Menu === a.id + a.Menu
            )[0]
              ? currentAlerts.filter(aa => aa.id + aa.Menu === a.id + a.Menu)[0]
                  .show
              : true
          };
        })
      );
    },
    [setActiveAlerts]
  );

  const listenForChanges = useCallback(() => {
    const storedAlerts = localStorage.getItem("ALERTS");
    if (storedAlerts && storedAlerts !== JSON.stringify(activeAlerts)) {
      setActiveAlerts(JSON.parse(storedAlerts));
    }
  }, [activeAlerts, setActiveAlerts]);

  useEffect(() => {
    // Listen across tabs for a change to local storage
    window.addEventListener("storage", listenForChanges);

    // Clean up fuction to make sure we don't get multiple instances of the same
    // event listener each time the component re-render
    return () => window.removeEventListener("storage", listenForChanges);
  }, [listenForChanges]);

  const removeAlert = useCallback(id => {
    setAlerts(currentAlerts => currentAlerts.filter(a => a.id !== id));
  }, []);

  const addAlert = useCallback(
    alert => {
      const id = uuidv4();
      setAlerts(currentAlerts => [
        ...currentAlerts,
        { ...alert, id, show: true }
      ]);
      setTimeout(() => removeAlert(id), alert.delayClose || 5000);
    },
    [removeAlert]
  );

  const addSuccessAlert = useCallback(
    (message, delayClose) => addAlert({ type: "success", message, delayClose }),
    [addAlert]
  );
  const addFailAlert = useCallback(
    (message, delayClose) => addAlert({ type: "fail", message, delayClose }),
    [addAlert]
  );

  const addInfoAlert = useCallback(
    (message, delayClose) => addAlert({ type: "info", message, delayClose }),
    [addAlert]
  );

  const addCustomAlert = useCallback(
    (header, message, delayClose) =>
      addAlert({
        type: "custom",
        header,
        message,
        delayClose: delayClose || 15000
      }),
    [addAlert]
  );

  return (
    <AlertsContext.Provider
      value={{
        alerts,
        activeAlerts,
        addAlert,
        removeAlert,
        addSuccessAlert,
        addFailAlert,
        addInfoAlert,
        addCustomAlert,
        syncActiveAlerts,
        markAsInactive
      }}
    >
      {props.children}
    </AlertsContext.Provider>
  );
};

export const useAlertsContext = () => useContext(AlertsContext);

export default AlertsProvider;
