import React, { useEffect } from "react";
import videojs from "video.js";
import "videojs-playlist";
import qualityLevels from "videojs-contrib-quality-levels";
import hlsQualitySelector from "videojs-hls-quality-selector";
import "videojs-hotkeys";
import seekButtons from "videojs-seek-buttons";
import "./index.scss";
import "../../../node_modules/video.js/dist/video-js.css";
import "../../../node_modules/videojs-seek-buttons/dist/videojs-seek-buttons.css";

videojs.registerPlugin("hotkeys", () => ({
  volumeStep: 0.1,
  seekStep: 10,
  alwaysCaptureHotkeys: true
}));
videojs.registerPlugin("seekButtons", seekButtons);
videojs.registerPlugin("qualityLevels", qualityLevels);
videojs.registerPlugin("hlsQualitySelector", hlsQualitySelector);

const VideoJSPlayer = ({
  options,
  onReady = () => {},
  onNextClick = () => {},
  onPreviousClick = () => {},
  playList = [],
  seekValue = 10,
  onVideoEnd = () => {},
  onVideoPlaying = () => {},
  interval = 5.6,
  trackVideoduration = () => {},
  id,
  setFlag,
  flag,
  videoId,
  setVideoId,
  setCurrentVideo,
  currentVideo,
  onVideoPaused
}) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);

  const Button = videojs.getComponent("Button");
  const NextButton = videojs.extend(Button, {
    constructor: function() {
      Button.apply(this, arguments);
      this.addClass("vjs-icon-next-item");
      this.controlText("Next");
    },

    handleClick: function() {
      const videoElement = videoRef.current;
      const player = (playerRef.current = videojs(videoElement, options, () => {
        // onReady && onReady(player);
      }));
      onNextClick(player);

      //   const index = playList.findIndex(
      //     el => el.sources[0].src === player.state.src
      //   );
      //   player.setState({ src: playList[index + 1].sources[0].src });
      //   setCurrentVideo(playList[player.state.va + 1].sources[0].src);
      setFlag(false);
      setVideoId(player.state.val + 1);
      player.playlist.currentItem(player.state.val + 1);
      let currV = playList[player.state.val + 1];
      !!currV && setCurrentVideo(currV);
      // player.playlist.next();
    }
  });
  videojs.registerComponent("NextButton", NextButton);

  const PreviousButton = videojs.extend(Button, {
    constructor: function() {
      Button.apply(this, arguments);
      this.addClass("vjs-icon-previous-item");
      this.controlText("Previous");
    },

    // handleClick: function() {
    //   const videoElement = videoRef.current;
    //   const player = (playerRef.current = videojs(videoElement, options, () => {
    //     // onReady && onReady(player);
    //   }));
    //   const index = playList.findIndex(
    //     el => el.sources[0].src === player.state.src
    //   );
    //   player.setState({ src: playList[index - 1].sources[0].src });
    //   setCurrentVideo(playList[index - 1].sources[0].src);
    //   onPreviousClick(player);
    //   player.playlist.previous();
    // }

    handleClick: function() {
      const videoElement = videoRef.current;
      const player = (playerRef.current = videojs(videoElement, options, () => {
        // onReady && onReady(player);
      }));
      onPreviousClick(player);

      //   const index = playList.findIndex(
      //     el => el.sources[0].src === player.state.src
      //   );
      //   player.setState({ src: playList[index + 1].sources[0].src });
      //   setCurrentVideo(playList[player.state.va + 1].sources[0].src);
      setFlag(false);
      setVideoId(player.state.val - 1);
      player.playlist.currentItem(player.state.val - 1);
      let currV = playList[player.state.val - 1];
      !!currV && setCurrentVideo(currV);
      // setCurrentVideo(playList[player.state.val - 1]);
      // player.playlist.next();
    }
  });
  videojs.registerComponent("PreviousButton", PreviousButton);

  const rewindButton = videojs.extend(Button, {
    constructor: function() {
      Button.apply(this, arguments);
      this.addClass("icon-rotate-left");
      this.controlText(`Rewind ${seekValue}`);
    },

    handleClick: function() {
      const videoElement = videoRef.current;
      const player = (playerRef.current = videojs(videoElement, options, () => {
        // onReady && onReady(player);
      }));
      player.currentTime(player.currentTime() - seekValue);
    }
  });
  videojs.registerComponent("rewindButton", rewindButton);

  const forwardButton = videojs.extend(Button, {
    constructor: function() {
      Button.apply(this, arguments);
      this.addClass("icon-rotate-right");
      this.controlText(`Forward ${seekValue}`);
    },

    handleClick: function() {
      const videoElement = videoRef.current;
      const player = (playerRef.current = videojs(videoElement, options, () => {
        // onReady && onReady(player);
      }));
      player.currentTime(player.currentTime() + seekValue);
    }
  });
  videojs.registerComponent("forwardButton", forwardButton);

  //   const PlayButton = videojs.extend(Button, {
  //     constructor: function() {
  //       Button.apply(this, arguments);
  //       this.removeClass("vjs-icon-play");
  //       this.removeClass("vjs-icon-pause");
  //       this.removeClass("vjs-icon-replay");
  //       this.addClass("vjs-icon-play");
  //       this.controlText("Play 1");
  //     },

  //     handleClick: function() {
  //       const videoElement = videoRef.current;
  //       const player = (playerRef.current = videojs(videoElement, options, () => {
  //         onReady && onReady(player);
  //       }));
  //       player.playlist.play();
  //     }
  //   });
  //   videojs.registerComponent("PlayButton", PlayButton);

  useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);
      }));

      player.getChild("controlBar").addChild("PreviousButton", {}, 0);
      //   player.getChild("controlBar").addChild("rewindButton", {}, 1);
      //   player.getChild("controlBar").addChild("forwardButton", {}, 3);
      player.getChild("controlBar").addChild("NextButton", {}, 2);
      // player.getChild("controlBar").addChild("PlayButton", {}, 2);
      player.setState({ src: playList[0]?.sources[0]?.src });
      player.seekButtons({
        forward: seekValue,
        back: seekValue,
        forwardIndex: 2,
        backIndex: 1
      });
      player.qualityLevels();
      player.hlsQualitySelector();
      player.fluid(true);
      player.playlist(playList);
    } else {
      // you can update player here
      //   const player = playerRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  useEffect(() => {
    const player = playerRef.current;
    if (player) {
      const curVid = playList.find(el => el?.url === currentVideo?.url);

      if (curVid && curVid.progress !== 100 && !!curVid.progress) {
        const startTime = Math.floor((curVid.duration * curVid.progress) / 100);
        const playPromise = player.play();

        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              player.currentTime(startTime);
              player.pause();
            })
            .catch(() => {
              player.currentTime(startTime);
              player.pause();
            });
        }
      }
      //   else if (
      //     !!playList.length &&
      //     currentVideo?.name !== playList[0]?.name
      //   ) {
      //     const playPromise = player.play();

      //     if (playPromise !== undefined) {
      //       playPromise
      //         .then(() => {
      //           player.play();
      //         })
      //         .catch(() => {
      //           player.play();
      //         });
      //     }
      //   }
    }
  }, [currentVideo]);

  useEffect(() => {
    const player = playerRef.current;
    let intervalX;
    player.on("ended", () => {
      !!intervalX && clearInterval(intervalX);
      let currV = !!player.state.val ? playList[player.state.val] : playList[0];
      onVideoEnd({ currentVideo: currV });
    });
    player.on("pause", () => {
      onVideoPaused();
      !!intervalX && clearInterval(intervalX);
    });
    player.on("durationchange", () => {
      if (
        !!playList.length &&
        (playList[player.state.val].name !== playList[0]?.name ||
          player.state.firstVFlag)
      ) {
        player.play();
        player.setState({ firstVFlag: true });
      }
    });
    player.on("playing", () => {
      !!intervalX && clearInterval(intervalX);
      onVideoPlaying();
      let currV = playList[player.state.val];

      intervalX = setInterval(() => {
        trackVideoduration({
          videoDuration: player.duration(),
          currentTime: player.currentTime(),
          currentVideo: currV,
          rem: player.remainingTime()
        });
      }, interval * 1000);
    });
  }, [interval]);

  useEffect(() => {
    const player = playerRef.current;
    player.setState({ val: videoId });
    if (flag) {
      player.playlist.currentItem(id);
    }
  }, [id, videoId]);

  return (
    <div data-vjs-player>
      <video
        data-setup='{ "inactivityTimeout": 0 }'
        ref={videoRef}
        className="video-js vjs-big-play-centered vjs-show-big-play-button-on-pause"
      />
    </div>
  );
};

export default VideoJSPlayer;
