import React from "react";
import { AbilityProvider } from "./AbilityContext";
import ShellAdmin from "./ShellAdmin";

export default function AdminShell({ isAdmin, permissionsUpdated }) {
  return (
    <AbilityProvider permissionsUpdated={permissionsUpdated} isAdmin={isAdmin}>
      <ShellAdmin />
    </AbilityProvider>
  );
}
