import isEmpty from "lodash/isEmpty";
import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import {
  AiOutlineEdit,
  AiOutlineEllipsis,
  AiOutlinePlus
} from "react-icons/ai";
import Select from "react-select";
import AccentButton from "../../components/AccentButton";
import Grid from "../../components/Grid";
import Spinner from "../../components/Spinner";
import { useAlertsContext } from "../../context/alerts";
import {
  activateUser,
  addCustomUser,
  deactivateUser,
  fetchCustomGroups,
  fetchCustomUsers,
  fetchRoles,
  searchInternalUser,
  trackUserAction,
  updateCustomUser
} from "../../services/dataService";
import { AbilityContext, Can, PermissionsContext } from "./AbilityContext";
import defineRulesFor from "./permissions";
import "./UserManagement.scss";

const invalidStyle = {
  placeholder: base => ({
    ...base,
    fontSize: "1em",
    color: "#dc3545",
    fontWeight: 400
  }),
  container: base => ({
    ...base,
    backgroundColor: "#dc3545",
    padding: 0.5
  })
};

const BtnCellRenderer = value => {
  return (
    !isEmpty(value) && (
      <div>
        <span>{value[0]?.name}</span>
        <OverlayTrigger
          placement="right"
          trigger="click"
          rootClose
          overlay={
            <Popover>
              <Popover.Content style={{ zIndex: 3 }}>
                {value.map(v => {
                  return <div key={v.id}>{v.name}</div>;
                })}
              </Popover.Content>
            </Popover>
          }
        >
          {/* e.stopPropagation() is needed to prevent double click events when clicking on the i icon */}

          <Button
            onClick={e => e.stopPropagation()}
            style={{ border: "none", background: "inherit" }}
          >
            {value.length > 1 && (
              <AiOutlineEllipsis
                style={{ fontSize: "25px", color: "rgba(0, 0, 0, 0.87)" }}
              />
            )}
          </Button>
        </OverlayTrigger>
      </div>
    )
  );
};

function UserManagementCust(props) {
  const [show, setShow] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [customGroups, setCustomGroups] = useState([]);
  const [intFirstName, setIntFirstName] = useState("");
  const [intLastName, setIntLastName] = useState("");
  const [intEmail, setIntEmail] = useState("");
  const [searchingInternalUsers, setSearchingInternalUsers] = useState(false);
  const [internalUsersList, setInternalUsersList] = useState([]);
  const [selectedUserList, setSelectedUserList] = useState([]);
  const [selectedCustomGroups, setSelectedCustomGroups] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const { register, handleSubmit, reset } = useForm();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [itemForEdit, setItemForEdit] = useState(null);
  const { addSuccessAlert, addFailAlert } = useAlertsContext();
  const [invalidGroups, setInvalidGroups] = useState(false);
  const [roles, setRoles] = useState([]);
  const gridApi = useRef(null);

  const ability = useContext(AbilityContext);
  const { permissionsCtxt } = useContext(PermissionsContext);
  const [permissions, , isAdmin] = permissionsCtxt;

  useEffect(() => {
    const rules = defineRulesFor(permissions, isAdmin);
    ability.update(rules);
  }, [permissions.length]);

  useEffect(() => {
    fetchCustomUsers()
      .then(res => {
        setUsers(res.data);
        setLoading(false);
        trackUserAction({
          action: "List",
          targetType: "Admin",
          targetName: "Users",
          targetPath: "/security/users",
          targetID: "",
          status: "Success",
          errorDetails: ""
        });
      })
      .catch(() => {
        setLoading(false);
      });

    fetchCustomGroups().then(result => {
      if (Array.isArray(result.data)) {
        const ctgGrp = result.data.map(u => {
          return {
            description: u.desc,
            value: +u.id,
            label: u.name
          };
        });

        setCustomGroups(
          ctgGrp.sort((a, b) => {
            if (a["label"] < b["label"]) {
              return -1;
            }
            if (a["label"] > b["label"]) {
              return 1;
            }
            return 0;
          })
        );
      }
    });
    fetchRoles()
      .then(result => {
        if (Array.isArray(result.data)) {
          const ctgGrp = result.data.map(u => {
            return {
              value: +u.id,
              label: u.name
            };
          });

          setRoles(
            ctgGrp.sort((a, b) => {
              if (a["label"] < b["label"]) {
                return -1;
              }
              if (a["label"] > b["label"]) {
                return 1;
              }
              return 0;
            })
          );
        }
      })
      .catch();
  }, []);

  const handleCloseModal = () => {
    setItemForEdit(null);
    reset({});
    setInternalUsersList([]);
    setSelectedUserList([]);
    setSelectedCustomGroups([]);
    setSelectedRoles([]);
    setIntFirstName("");
    setIntLastName("");
    setIntEmail("");
    setShow(false);
  };

  const handleNewUserClick = () => {
    setShow(true);
  };

  const onSubmit = async data => {
    if (!selectedCustomGroups?.length && !selectedRoles?.length) {
      setInvalidGroups(true);
      return;
    }

    const itemID = itemForEdit ? itemForEdit.itemID : null;
    const groupsSelected = selectedCustomGroups?.map(el => el.value).join(",");
    const rolesSelected = selectedRoles?.map(el => el.value).join(",");
    try {
      if (!!itemID) {
        const { data } = await updateCustomUser({
          id: itemForEdit.id,
          groups: groupsSelected ? groupsSelected : "",
          roles: rolesSelected ? rolesSelected : ""
        });
        await trackUserAction({
          action: "Edit",
          targetType: "Admin",
          targetName: "Users",
          targetPath: "/security/user",
          targetID: "",
          status: "Success",
          errorDetails: ""
        });
        const index = users?.findIndex(el => el.id === itemForEdit?.id);
        if (data?.length > 0 && index !== -1) {
          const updatedUsers = [...users];
          setSelectedRoles(
            data[0].roles?.map(el => ({ label: el.name, value: el.id }))
          );
          setSelectedCustomGroups(
            data[0].group?.map(el => ({ label: el.name, value: el.id }))
          );
          updatedUsers[index] = { ...data[0] };
          setUsers(updatedUsers);
        }
      } else {
        const { data } = await addCustomUser({
          ntid: selectedUserList.userNTId,
          groups: groupsSelected ? groupsSelected : "",
          roles: rolesSelected ? rolesSelected : ""
        });

        await trackUserAction({
          action: "Create",
          targetType: "Admin",
          targetName: "Users",
          targetPath: "/security/user",
          targetID: "",
          status: "Success",
          errorDetails: ""
        });
        if (data === "User already exists") {
          throw data;
        }

        setUsers(prev => [...data, ...prev]);
      }

      handleCloseModal();
      addSuccessAlert(`User ${!!itemID ? "updated" : "created"}`);
    } catch (err) {
      console.log("error:", err);
      handleCloseModal();
      if (err === "User already exists") {
        addFailAlert("User already exists");
      } else {
        addFailAlert("Something went wrong");
      }
    }
  };

  const userStatusHandler = async data => {
    let item;
    try {
      data.status === "A"
        ? (item = await deactivateUser({
            id: +data.id
          }))
        : (item = await activateUser({
            id: +data.id
          }));

      const index = users?.findIndex(el => el.id === data?.id);

      if (item?.data?.length > 0 && index !== -1) {
        const updatedUsers = [...users];
        updatedUsers[index] = { ...item.data[0] };
        setUsers(updatedUsers);
      }
      await trackUserAction({
        action: data.status === "A" ? "Deactivate" : "Activate",
        targetType: "Admin",
        targetName: "Users",
        targetPath:
          data.status === "A"
            ? "/security/user/deactivate"
            : "/security/user/activate",
        targetID: "",
        status: "Success",
        errorDetails: ""
      });
      addSuccessAlert("User Status Updated");
    } catch (err) {
      addFailAlert("Something went wrong");
    }
  };

  const handleEditClick = user => {
    const name = user.name.split(" ");
    const formattedUser = {
      ...user,
      firstName: name[0],
      lastName: name.length > 1 ? name[1] : "",
      itemID: user.id
    };
    setItemForEdit(formattedUser);
    reset(formattedUser);
    setSelectedCustomGroups(
      user.group?.map(el => ({ label: el.name, value: el.id }))
    );
    setSelectedRoles(user.roles?.map(el => ({ label: el.name, value: el.id })));
    setShow(true);
  };

  const onClickSearchInternal = async data => {
    setSearchingInternalUsers(true);
    try {
      const { data: response } = await searchInternalUser(data);
      await trackUserAction({
        action: "Search",
        targetType: "Admin",
        targetName: "Users",
        targetPath: "/security/users/search",
        targetID: "",
        status: "Success",
        errorDetails: ""
      });
      setInternalUsersList([...response]);
      setSearchingInternalUsers(false);
    } catch (err) {
      //handleCloseModal();
      setSearchingInternalUsers(false);
      addFailAlert("Something went wrong");
    }
  };

  const onSelectionChanged = () => {
    let selectedRows = gridApi.current.getSelectedRows();
    setSubmitDisabled(selectedRows.length === 0);
    setSelectedUserList(selectedRows.length > 0 ? selectedRows[0] : []);
  };

  const formatOptionLabel = ({ label }) => (
    <div style={{ marginLeft: "10px" }}>{label}</div>
  );

  return (
    <div className="UserManagement">
      <Can I="Write" a="User Management">
        {() => (
          <Button
            className="mb-3"
            onClick={handleNewUserClick}
            disabled={loading}
          >
            <AiOutlinePlus /> New User
          </Button>
        )}
      </Can>
      {loading ? (
        <Spinner>Fetching Users</Spinner>
      ) : (
        <Grid
          width="100%"
          title="Users"
          showExcel={true}
          suppressRowTransform={true}
          autoSizeColumns={false}
          groupDefaultExpanded={0}
          getRowHeight={params => {
            return params.api.getSizesForCurrentTheme().rowHeight;
          }}
          columnDefs={[
            {
              field: "id",
              headerName: "User Id",
              sortable: true,
              resizable: true,
              width: 100
            },
            {
              field: "status",
              headerName: "Status",
              sortable: true,
              resizable: true,
              width: 110,
              cellRendererFramework: ({ data, value }) => {
                return (
                  <>
                    <Can I="Write" a="User Management" passThrough>
                      {allowed => (
                        <AccentButton
                          className={`sx-accent-button--${
                            data.status === "A" ? "success" : "danger"
                          } mx-auto`}
                          disabled={!allowed}
                          onClick={() => userStatusHandler(data)}
                        >
                          {data.status === "A" ? "Active" : "Inactive"}
                        </AccentButton>
                      )}
                    </Can>
                  </>
                );
              }
            },
            {
              field: "name",
              headerName: "Name",
              sortable: true,
              resizable: true
            },
            {
              field: "ntid",
              headerName: "NTID",
              sortable: true,
              resizable: true,
              width: 130
            },
            {
              field: "department",
              headerName: "Department",
              sortable: true,
              resizable: true
            },
            {
              field: "phone",
              headerName: "Phone No",
              sortable: true,
              resizable: true
            },
            {
              field: "email",
              headerName: "Email",
              sortable: true,
              resizable: true
            },
            {
              field: "group",
              headerName: "User Groups",
              sortable: true,
              resizable: true,
              cellRendererFramework: ({ value }) => BtnCellRenderer(value)
            },
            {
              field: "roles",
              headerName: "Roles",
              sortable: true,
              resizable: true,
              cellRendererFramework: ({ value }) => BtnCellRenderer(value)
            },
            {
              field: "last udpated",
              headerName: "Last Updated",
              sortable: true,
              width: 150,
              cellRendererFramework: ({ data, value }) => {
                return value ? value.split(" ")[0] : "";
              }
            },
            {
              field: "edit",
              headerName: "",
              resizable: true,
              width: 130,
              cellRendererFramework: ({ data, value }) => {
                return (
                  <>
                    <Can I="Write" a="User Management">
                      {() => (
                        <AccentButton onClick={() => handleEditClick(data)}>
                          <AiOutlineEdit /> Edit
                        </AccentButton>
                      )}
                    </Can>
                  </>
                );
              }
            }
          ]}
          rowData={users}
        />
      )}

      <Modal className="users-modal" show={show} onHide={handleCloseModal}>
        <Form onSubmit={handleSubmit(onSubmit)} className="p-3">
          <Modal.Header closeButton>
            <Modal.Title>{!!itemForEdit ? "Edit" : "Add New"} User</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {!!itemForEdit ? (
              <div className="px-3">
                <span>User Details:</span>
                <Form.Group as={Row} controlId="users-first-name">
                  <Form.Label column sm="2">
                    First Name
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      name="firstName"
                      readOnly
                      ref={register({})}
                      type="text"
                      placeholder=""
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="users-last-name">
                  <Form.Label column sm="2">
                    Last Name
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      name="lastName"
                      readOnly
                      ref={register({})}
                      type="text"
                      placeholder=""
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="users-email">
                  <Form.Label column sm="2">
                    Email
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      as="textarea"
                      name="email"
                      readOnly
                      ref={register({})}
                      type="text"
                      placeholder=""
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="users-phone">
                  <Form.Label column sm="2">
                    Phone
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      as="textarea"
                      name="phone"
                      readOnly
                      ref={register({})}
                      type="text"
                      placeholder=""
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="users-department">
                  <Form.Label column sm="2">
                    Department
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      as="textarea"
                      name="department"
                      readOnly
                      ref={register({})}
                      type="text"
                      placeholder=""
                    />
                  </Col>
                </Form.Group>
              </div>
            ) : (
              <>
                <div>
                  {/* <span className="px-3">Enter user search criteria:</span> */}
                  <Form.Group
                    as={Row}
                    className="d-flex align-items-center justify-content-between my-0 mx-1 p-0"
                  >
                    <Col sm="3">
                      <input
                        name="intFirstName"
                        type="text"
                        placeholder="First Name"
                        onChange={e => setIntFirstName(e.target.value)}
                      />
                    </Col>
                    <Col sm="3">
                      <input
                        name="intLastName"
                        type="text"
                        placeholder="Last Name"
                        onChange={e => setIntLastName(e.target.value)}
                      />
                    </Col>
                    <Col sm="3">
                      <input
                        name="intEmail"
                        type="text"
                        placeholder="E-mail Id"
                        onChange={e => setIntEmail(e.target.value)}
                      />
                    </Col>
                    <Col sm="2" className="text-center">
                      <Button
                        disabled={
                          intFirstName.length < 3 &&
                          intLastName.length < 3 &&
                          intEmail.length < 3
                        }
                        onClick={() =>
                          onClickSearchInternal({
                            firstName: intFirstName,
                            lastName: intLastName,
                            email: intEmail
                          })
                        }
                      >
                        Search User
                      </Button>
                    </Col>
                    <Col sm="1" className="text-center">
                      {searchingInternalUsers && <Spinner />}
                    </Col>
                  </Form.Group>
                </div>
                <Grid
                  width="100%"
                  height="375px"
                  animateRows
                  onGridReady={params => {
                    // Get access to grid api for use within this component
                    gridApi.current = params.api;
                  }}
                  className="p-3"
                  onSelectionChanged={onSelectionChanged}
                  columnDefs={[
                    {
                      headerName: "Name",
                      checkboxSelection: true,
                      sortable: true,
                      resizable: true,
                      cellRendererFramework: ({ data }) =>
                        `${data.firstName} ${data.lastName} (${data.userNTId})`
                    },
                    {
                      field: "department",
                      headerName: "Department",
                      sortable: true,
                      resizable: true
                    },
                    {
                      field: "title",
                      headerName: "Title",
                      sortable: true,
                      resizable: true
                    },
                    {
                      field: "email",
                      headerName: "Email",
                      sortable: true,
                      resizable: true
                    }
                  ]}
                  rowData={internalUsersList}
                />
              </>
            )}
            {/* <div className="px-3 pb-3">
                <span>User Groups:</span>
                <Select
                  isMulti
                  value={selectedGroups}
                  closeMenuOnSelect={false}
                  name="groups"
                  options={groups}
                  onChange={selectedGroups => setSelectedGroups(selectedGroups)}
                />
              </div> */}
            <div className="px-3 pb-3">
              <span>Custom Groups*:</span>
              <Select
                isMulti
                value={selectedCustomGroups}
                closeMenuOnSelect={false}
                name="customGroups"
                options={customGroups}
                onChange={selectedCustomGroups => {
                  setInvalidGroups(false);
                  setSelectedCustomGroups(selectedCustomGroups);
                }}
                formatOptionLabel={formatOptionLabel}
                isInvalid={invalidGroups}
                placeholder={invalidGroups ? `Required` : `Select Type`}
                styles={invalidGroups ? invalidStyle : {}}
              />
            </div>
            <div className="px-3 pb-3">
              <span>Roles*:</span>
              <Select
                isMulti
                value={selectedRoles}
                closeMenuOnSelect={false}
                name="roles"
                options={roles}
                onChange={selectedRoles => {
                  setInvalidGroups(false);
                  setSelectedRoles(selectedRoles);
                }}
                formatOptionLabel={formatOptionLabel}
                isInvalid={invalidGroups}
                placeholder={invalidGroups ? `Required` : `Select Type`}
                styles={invalidGroups ? invalidStyle : {}}
              />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              type="submit"
              variant="primary"
              disabled={!itemForEdit && submitDisabled}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}

export default UserManagementCust;
