import React from "react";
import BaseCard from "../BaseCard";
import classnames from "classnames";
import AccentButton from "../AccentButton";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { APP_VARS } from "../../services/appVars";

import "./AssetCard.scss";
import DataGovernanceInformation from "../../pages/data-catalog/DataGovernanceInformation";

const stringMaxLength = 75;

function getTruncatedString(s) {
  if (s.length <= stringMaxLength) {
    return s;
  }
  return `${s.substring(0, stringMaxLength)}...`;
}

function getButtonClassName(brand) {
  switch (brand) {
    case "Connected Vehicle":
      return "connected-vehicle";
    case "SXM Canada Audio":
      return "canada";
    case "Pandora":
      return "pandora";
    default:
      return "";
  }
}

function AssetCard({
  children,
  className = "",
  popoverText = "",
  popoverTitle = "",
  title,
  img,
  onClick = () => {},
  brandText,
  onHeaderClick = () => {},
  onButtonClick = () => {},
  brandColor = "",
  dataGovernanceScore = "Pending",
  dgcValues = [],
  tableValues = [],
  tags = [],
  onTagClick = () => {},
  description = "",
  ...otherProps
}) {
  return (
    <BaseCard
      onClick={e => {
        if (
          (e.target && e.target.tagName === "IMG") ||
          e.target.className.indexOf("badge") > -1
        ) {
          return;
        }
        onClick();
      }}
      className={classnames(
        "sx-asset-card",
        "align-items-center",
        "justify-content-center",
        "sx-hover-shadow",
        className
      )}
      {...otherProps}
    >
      <div className="sx-asset-card__hover-wrapper">
        {brandText && (
          <div
            className="sx-asset-card__brand-section"
            style={{ backgroundColor: brandColor }}
          >
            <div className="sx-asset-card__brand-text">{brandText}</div>
            <div className="sx-asset-card__brand-button">
              <AccentButton
                className={`sx-accent-button--${getButtonClassName(brandText)}`}
                onClick={e => {
                  e.stopPropagation();
                  onButtonClick({ name: "Brand", value: brandText });
                }}
              >
                {brandText}
              </AccentButton>
            </div>
            <div className="sx-asset-card__hidden-text">
              {getTruncatedString(description)}
            </div>
          </div>
        )}
        <div
          className={classnames(
            "sx-asset-card__header-section d-flex align-items-center justify-content-between pl-3 py-4",
            { "sx-asset-card__inherit-border": !brandText }
          )}
        >
          <div className="sx-asset-card__header-title text-primary font-base font-weight-bold">
            {title}
          </div>
          <div className="d-flex px-3">
            <DGCMetricsPopover
              dataGovernanceScore={dataGovernanceScore}
              dgcValues={dgcValues}
            />
            {tags.length > 0 && (
              <div className="sx-asset-card__tags pl-3">
                <OverlayTrigger
                  placement="auto"
                  trigger="click"
                  rootClose
                  overlay={
                    <Popover>
                      <Popover.Content>
                        <div className="px-3 py-2">
                          {tags.map(t => (
                            <AccentButton
                              key={t}
                              onClick={e => {
                                e.stopPropagation();
                                onTagClick(t);
                              }}
                              className="mr-2 mb-2"
                            >
                              {t}
                            </AccentButton>
                          ))}
                        </div>
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <div className="position-relative sx-asset-card__badge-wrapper">
                    <div className="sx-asset-card__badge">{tags.length}</div>
                    <img
                      src={
                        APP_VARS.REACT_APP_ASSETS_URL + "/images/tag-icon.png"
                      }
                      alt="Tag"
                    />
                  </div>
                </OverlayTrigger>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="sx-asset-card__table-section py-3 pl-3 pr-2">
        <Container>
          {tableValues.map((t, index) => (
            <Row key={index} className="align-items-center mb-2">
              <Col
                style={{ paddingLeft: 0, paddingRight: 0 }}
                sm={7}
                className="text-primary font-small font-weight-bold white-space-nowrap"
              >
                {t.name}
              </Col>
              <Col
                style={{ paddingLeft: 0, paddingRight: 0 }}
                sm={5}
                className="text-center"
              >
                <AccentButton
                  className="dots"
                  style={{ width: "100%" }}
                  onClick={e => {
                    e.stopPropagation();
                    onButtonClick(t);
                  }}
                  title={t.value}
                >
                  {t.value}
                </AccentButton>
              </Col>
            </Row>
          ))}
        </Container>
      </div>
    </BaseCard>
  );
}

export function DGCMetricsPopover({ dataGovernanceScore, dgcValues }) {
  return (
    <OverlayTrigger
      placement="auto"
      trigger="hover"
      rootClose
      overlay={
        <Popover className="dgc-values-popover">
          <Popover.Title as="h3" className="p-2">
            <img
              src={`${APP_VARS.REACT_APP_ASSETS_URL}/images/Data-Governance-Icons/${dataGovernanceScore}.svg`}
              alt={dataGovernanceScore}
              onClick={e => e.stopPropagation()}
            />
            <span className="ml-2">
              {`${dataGovernanceScore} ${
                dataGovernanceScore === "Pending"
                  ? `Certification`
                  : `Certified`
              }`}
            </span>
          </Popover.Title>
          <Popover.Content>
            <div className="p-3">
              {dgcValues?.map(data => {
                let levelValue =
                  !!data && parseInt(data["value"].replace("Level ", ""));
                let stars = !isNaN(levelValue) ? 5 - levelValue : 0;
                return (
                  <>
                    {!!data && (
                      <Row>
                        <Col sm={6}>{data["name"]}</Col>
                        <Col sm={6} className="text-right">
                          <DataGovernanceInformation stars={stars} />
                        </Col>
                      </Row>
                    )}
                  </>
                );
              })}
            </div>
          </Popover.Content>
        </Popover>
      }
    >
      {/* e.stopPropagation() is needed to prevent double click events when clicking on the i icon */}
      <img
        src={`${APP_VARS.REACT_APP_ASSETS_URL}/images/Data-Governance-Icons/${dataGovernanceScore}.svg`}
        alt={dataGovernanceScore}
        width="22px"
        onClick={e => e.stopPropagation()}
      />
    </OverlayTrigger>
  );
}

export default AssetCard;
