import React, { useState } from "react";
import {
  AiOutlineFile,
  AiOutlinePlayCircle,
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineFileAdd,
  AiOutlineFilePdf
} from "react-icons/ai";
import {
  getCognosHost,
  getWindowDimensions,
  getParams
} from "../../services/cognosService";
import Modal from "react-bootstrap/Modal";
import Spinner from "../../components/Spinner";
import { deleteMyContent, trackUserAction } from "../../services/dataService";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import { MdDashboard } from "react-icons/md";
import { GoPackage } from "react-icons/go";
import { FiHelpCircle } from "react-icons/fi";

import Button from "react-bootstrap/Button";

import TreeView from "../../components/TreeView";
import ReportWidgetContainer from "../../components/ReportWidgetContainer";

import reportHelpGuides from "./report-guides";

//run, edit, delete, new dashboard, new report
const iconTypeMap = {
  authoring: <GoPackage />,
  dashboard: <AiOutlineFile />,
  report: <AiOutlineFile />
};

const actionIconMap = {
  clsRun: <AiOutlinePlayCircle />,
  clsEdit: <AiOutlineEdit />,
  deleteInd: <AiOutlineDelete />,
  clsNewDB: <MdDashboard />,
  clsNewPkg: <AiOutlineFileAdd />
};

function PackageTreeView({
  activeMenuItem,
  packages,
  setPackages,
  refreshPackages,
  loading
}) {
  const [packageFilterText, setPackageFilterText] = useState("");

  const [show, setShow] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [reportToDelete, setReportToDelete] = useState({});

  const handleClose = () => {
    setShow(false);
    setReportToDelete({});
  };

  function lookForPackage(list, item) {
    list.forEach(node => {
      if (node.UUID === item.UUID) {
        node.expanded = !node.expanded;
      }
      if (node.Package) {
        lookForPackage(node.Package, item);
      }
    });
  }

  const handleExpandTreeClick = item => {
    lookForPackage(packages, item);
    setPackages(p => [...p]);
  };

  const handlePackageTreeChildClick = async (item, key) => {
    trackUserAction({
      action: key,
      targetType: item.type,
      targetName: item.packageName,
      targetPath: "",
      targetID: item.storeID,
      status: "Success",
      errorDetails: ""
    });

    if (key === "deleteInd") {
      setReportToDelete(item);
      setShow(true);
    } else {
      if (item.openFor === "edit" && key === "clsNewPkg") {
        key = "clsEdit";
      }
      trackUserAction({
        action: "Open workSpace",
        targetType: key,
        targetName: item.packageName,
        targetPath: "",
        targetID: item.storeID,
        status: "Success",
        errorDetails: ""
      });

      try {
        const COGNOS_HOST = await getCognosHost();
        window.open(
          COGNOS_HOST + getParams(key, item.storeID),
          "_blank",
          getWindowDimensions()
        );
      } catch (error) {}

      return;
    }
  };

  const handleDeleteReport = () => {
    setDeleting(true);
    deleteMyContent({
      reportPath: reportToDelete.storeID,
      actionType: "deleteObject"
    })
      .then(() => {
        setDeleting(false);
        refreshPackages(true);
        handleClose();
        trackUserAction({
          action: "Delete workSpace",
          targetType: "MyFolder Package",
          targetName: reportToDelete.packageName,
          targetPath: "",
          targetID: reportToDelete.storeID,
          status: "Delete Success",
          errorDetails: ""
        });
      })
      .catch(err => {
        setDeleting(false);
        handleClose();
        console.log(err);
      });
  };

  return (
    <ReportWidgetContainer
      headerText="Ad Hoc Analysis"
      value={packageFilterText}
      placeholder="Search packages"
      refresh="package"
      onRefreshClick={e => {
        e.preventDefault();
        refreshPackages(true);
      }}
      onSubmit={e => e.preventDefault()}
      onChange={e => {
        e.preventDefault();
        setPackageFilterText(e.target.value);
      }}
      moreHeaderContent={
        <OverlayTrigger
          placement="auto"
          trigger="click"
          rootClose
          overlay={
            <Popover style={{ width: "35rem", maxWidth: "35rem" }}>
              <Popover.Content>
                <div className="font-base font-weight-bold color-primary pt-3 px-3">
                  Self Service Report Training
                </div>
                <div className="p-3">
                  {reportHelpGuides().map(({ text, url }) => (
                    <div key={text}>
                      <Button
                        className="font-small"
                        as="a"
                        variant="link"
                        target="_blank"
                        href={url}
                      >
                        <AiOutlineFilePdf
                          style={{ paddingBottom: 1, color: "#e77681" }}
                        />{" "}
                        {text}
                      </Button>
                    </div>
                  ))}
                </div>
              </Popover.Content>
            </Popover>
          }
        >
          <FiHelpCircle
            onClick={e => {
              e.stopPropagation();
            }}
            size={26}
            className="pointer"
          />
        </OverlayTrigger>
      }
    >
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <span className="font-medium text-primary font-weight-bold">
            Confirmation
          </span>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the{" "}
          <span className="font-weight-bold">{reportToDelete.packageName}</span>{" "}
          report?
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={deleting}
            variant="outline-secondary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteReport}
            disabled={deleting}
            variant="danger"
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <div style={{ maxHeight: "50rem", overflow: "auto" }}>
        {loading && <Spinner>Fetching Packages</Spinner>}
        {!loading && (
          <TreeView
            className="mt-4 ml-4"
            onNodeClick={handleExpandTreeClick}
            renderLabel={item => item.packageName}
            childrenKeyName="Package"
            renderChild={item => (
              <div className="d-flex align-items-center justify-content-between">
                <Button
                  onClick={() => handlePackageTreeChildClick(item, "clsNewPkg")}
                  variant="link"
                >
                  <span className="pr-2 text-primary">
                    {iconTypeMap[item.type]}
                  </span>
                  {item.packageName}
                </Button>
                <span className="pr-3">
                  {Object.keys(actionIconMap)
                    .filter(i => !!item[i])
                    .map(key => (
                      <span
                        key={key}
                        onClick={() => handlePackageTreeChildClick(item, key)}
                        className="text-primary"
                      >
                        {actionIconMap[key]}
                      </span>
                    ))}
                </span>
              </div>
            )}
            nameField="packageName"
            searchTerm={packageFilterText}
            data={packages}
          />
        )}
      </div>
    </ReportWidgetContainer>
  );
}

export default PackageTreeView;
