import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import BasePage from "../../components/layouts/BasePage";
import {
  getCourses,
  getEnrollments,
  getLearningPaths,
  getEnrollmentProgress,
  getCourseraMembershipStatus,
  trackUserAction
} from "../../services/dataService";
import { DataUniversityContext, USER_STATUS } from "./DataUniversityContext";
import WelcomeModal from "./WelcomeModal";
import CourseLibrary from "./CourseLibrary";
import "./StudentHome.scss";
import StudentProgress from "./StudentProgress";
import StudentStats from "./StudentStats";
import { APP_VARS } from "../../services/appVars";
import { useAlertsContext } from "../../context/alerts";
import InstructionModal from "./InstructionModal";

export function SSO_Login(isBigWindow = false) {
  const { innerWidth: width, innerHeight: height } = window;
  const winoptions =
    "resizable=no,toolbar=no,menubar=no,titlebar=no,directories=no,status=no,location=no,status=no,dom.disable_window_open_feature.location=false,position=center,scrollbars=no,";
  if (isBigWindow) {
    window.open(APP_VARS.COURSERA_SSO_LOGIN_PAGE, "_blank");
  } else {
    let i = window.open(
      APP_VARS.COURSERA_SSO_LOGIN_PAGE,
      "_blank",
      `left=${width}, top=${height},${winoptions} height=, width=`
    );
    i.resizeTo(0, 0);
    i.moveTo(0, height);
    setTimeout(() => {
      i.close();
    }, 1e4);
  }
}
export default function StudentHome() {
  const {
    userStatusContext,
    enrollmentsContext,
    coursesContext,
    learningPathsContext,
    spinnerContext
  } = useContext(DataUniversityContext);
  const [progress, setProgress] = useState([]);
  const [userStatus] = userStatusContext;
  const [enrollments, setEnrollments] = enrollmentsContext;
  const [, setCourses] = coursesContext;
  const [, setLearningPaths] = learningPathsContext;
  const [, setShowSpinner] = spinnerContext;
  const [showStartModal, setShowStartModal] = useState(false);

  const { addFailAlert } = useAlertsContext();
  useEffect(() => {
    getCourseraMembershipStatus().then(res => {
      if (res.data.status === 404) {
        userStatus === USER_STATUS["ONBOARDED"] && SSO_Login(true); // Add true After testing
      } else {
        userStatus === USER_STATUS["ONBOARDED"] && SSO_Login();
      }
    });
    getEnrollments().then(res => {
      trackUserAction({
        action: "List",
        targetType: "Enrollments",
        targetName: "Data University",
        targetPath: "/data-university",
        targetID: "",
        status: "Success",
        errorDetails: ""
      });
      setEnrollments(res.data.filter(l => l.course !== null));
      getEnrollmentProgress()
        .then(res => {
          setProgress(res.data);
          trackUserAction({
            action: "List",
            targetType: "Student Progress",
            targetName: "Data University",
            targetPath: "/data-university",
            targetID: "",
            status: "Success",
            errorDetails: ""
          });
        })
        .catch(err => addFailAlert("Something went wrong fetching progress"));
    });
    getCourses().then(res => {
      trackUserAction({
        action: "List",
        targetType: "Courses",
        targetName: "Data University",
        targetPath: "/data-university",
        targetID: "",
        status: "Success",
        errorDetails: ""
      });
      setCourses(res.data.filter(c => !!c.MakeAvailableInDU));
    });
    Promise.all([getLearningPaths(), getLearningPaths("?university=SiriusXM")])
      .then(res => {
        trackUserAction({
          action: "List",
          targetType: "Learning Paths",
          targetName: "Data University",
          targetPath: "/data-university",
          targetID: "",
          status: "Success",
          errorDetails: ""
        });
        setLearningPaths([...res[0].data, ...res[1].data]);

        setShowSpinner(false);
      })
      .catch(err => {
        setShowSpinner(false);
        console.log(err);
      });
    // getLearningPaths("").then(res => {
    //   trackUserAction({
    //     action: "List",
    //     targetType: "Learning Paths",
    //     targetName: "Data University",
    //     targetPath: "/data-university",
    //     targetID: "",
    //     status: "Success",
    //     errorDetails: ""
    //   });
    //   setLearningPaths(res.data);
    //   setShowSpinner(false);
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let modEnrollments = enrollments.map(element => {
      let fProgress = progress.find(
        p => element.course.CourseId === p.contentId
      );
      if (!!fProgress) {
        element.ProgressPct = fProgress.overallProgress;
        element.ProgressPoint =
          (fProgress.overallProgress * element.course.SkillPoint) / 100;
      }
      return element;
    });
    setEnrollments(modEnrollments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  return (
    <>
      <BasePage className="shadow">
        <Container fluid className="px-5 py-3">
          <Row className="d-flex align-items-stretch justify-content-between my-3">
            <Col sm={3}>
              <StudentStats />
              <StudentProgress />
            </Col>
            <Col sm={9}>
              <CourseLibrary />
            </Col>
          </Row>
        </Container>
        <WelcomeModal
          show={userStatus === USER_STATUS["APPROVED"]}
          setShowStartModal={setShowStartModal}
        />
        <InstructionModal
          show={showStartModal}
          setShowStartModal={setShowStartModal}
        />
      </BasePage>
    </>
  );
}
