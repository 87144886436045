import { APP_VARS } from "./appVars";
import { fetchDashboardURL } from "../../src/services/dataService";

export const getCognosHost = function() {
  return fetchDashboardURL(-1)
    .then(response => {
      const namespace = response.data[0]["Namespace"];
      const run_host = APP_VARS.REACT_APP_COGNOS_HOST.replace("?", "v1/disp?");
      return `${run_host}CAMNamespace=${namespace}&`;
    })
    .catch(err => {
      console.log(err);
    });
};

export const windowOptions =
  ",resizable=yes,toolbar=no,menubar=no,titlebar=no, location=no,status=no,dom.disable_window_open_feature.location=false,position=center,scrollbars=yes,";

export const launchReportInNewWindow = function(name, dbId) {
  window.open(`/reportviewer/${name}/${dbId}`);
};

export const getParams = (type, storeID) => {
  if (type === "clsNewPkg") {
    return `perspective=authoring&id=${storeID}&isNewFromPackage=true&cmProperties[id]=${storeID}&UIProfile=Titan`;
  } else if (type === "clsNewDB") {
    return `perspective=createBoard&id=createBoardPage_dashboard&isAuthoringMode=true&objRef=createBoard&mode=createBoard&action=edit`;
  } else if (type === "clsRun") {
    return `perspective=authoring&objRef=${storeID}&mode=run&action=view`;
  } else if (type === "clsEdit") {
    return `perspective=authoring&id=${storeID}&cmProperties[id]=${storeID}&UIProfile=Titan`;
  }
};

// export const deleteReport = storeID => {
//   var dataObj = {
//     reportPath: storeID,
//     actionType: "deleteObject"
//   };
//   deleteMyContent(dataObj)
//     .then(response => {})
//     .catch(err => {
//       console.log(err);
//     });

//   return ``;
// };

//(",resizable=yes,toolbar=no,menubar=no,titlebar=no, location=no,status=no,dom.disable_window_open_feature.location=false,position=center,scrollbars=yes,");

function calcWindowDimensions(formatType) {
  const { innerWidth: width, innerHeight: height } = window;
  const x = width * 0.1;
  const y = height * 0.1;
  const winoptions =
    ",resizable=yes,toolbar=no,menubar=no,titlebar=no, location=no,status=no,dom.disable_window_open_feature.location=false,position=center,scrollbars=yes,";
  var winref = "";
  var ht = 500;
  var wd = 500;
  if (formatType === "HTML") {
    ht = (height * 70) / 100;
    wd = (width * 75) / 100;
    winref = `left=${x}, top = ${y}${winoptions} height=${ht}, width ${wd}`;
  } else {
    ht = (height * 40) / 100;
    wd = (width * 65) / 100;

    winref = `left=${x}, top = ${y}${winoptions} height=${ht}, width ${wd}`;
  }

  return { winref };
}
export const getWindowDimensions = formatType => {
  return calcWindowDimensions(formatType);
};
