import React, { useState } from "react";
import {
  FaNewspaper,
  FaDatabase,
  FaCode,
  FaNetworkWired,
  FaFolder
} from "react-icons/fa";
import { AiFillFileText } from "react-icons/ai";
import { Col, Row, Table } from "react-bootstrap";
import { Tab, Tabs } from "../../components/Tabs";
import SimpleTreeView from "../../components/SimpleTreeView";
import Spinner from "../../components/Spinner";
import {
  fetchSmartAssetViewerData,
  fetchDashboardDomainsAndCategories,
  fetchDashboards
} from "../../services/dataService";
import { isEmpty } from "lodash";
// import { addUniqueId } from "../../services/utils";
// import { APP_VARS } from "../../services/appVars";
import Modal from "react-bootstrap/Modal";

function SmartAssetViewerWidget({ title, bName, listView, dbid }) {
  const [activeTab, setActiveTab] = useState("summary");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const [helpLinks, setHelpLinks] = useState([]);

  const fetchWidgetData = () => {
    setShow(true);
    Promise.all([
      fetchSmartAssetViewerData(bName?.replaceAll(" ", "%20")),
      fetchDashboards(),
      fetchDashboardDomainsAndCategories()
    ])
      .then(res => {
        setData(res[0].data);
        setError(null);
        setLoading(false);
        let domainsArray = res[2].Domains;
        let categoriesWithHelpLinks = domainsArray.map(el => {
          return {
            ...el,
            Help_Links: !!el.Help_Links
              ? JSON.parse(el.Help_Links.replaceAll(/'\'/g, "-"))
              : ""
          };
        });
        let nDashboard = res[1].data.find(e => e.dbId === +dbid);
        setHelpLinks(
          categoriesWithHelpLinks.find(
            el => el.NAME === nDashboard.dbDomainName
          )?.Help_Links
        );
      })
      .catch(err => {
        // setError("Unable to fetch Smart Asset Viewer Data");
        setLoading(false);
      });
  };
  return (
    <>
      <a
        href="javascript:void(0);"
        className="pointer mt-1 mx-2"
        onClick={e => fetchWidgetData()}
      >
        <FaFolder className={"font-small pointer"} />{" "}
        <small className="mt-1">Asset Details</small>
      </a>
      <br />
      <br />
      <Modal
        className="contents-modal"
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="asset-viewer-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Smart Asset Viewer : {title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading && <Spinner>Fetching asset details</Spinner>}
          {error && <p>{error}</p>}
          {!loading && (
            <Tabs
              mountOnEnter
              activeKey={activeTab}
              onSelect={k => setActiveTab(k)}
              className="p-0"
            >
              <Tab
                eventKey="summary"
                title={<FaNewspaper className="font-medium pointer mx-2" />}
              >
                <section className="p-1 fixed-dimensions">
                  {data?.metrics?.length ? (
                    <div>
                      <h5 className="mt-1">Metrics</h5>
                      <Table>
                        <thead>
                          <tr className="primary-tr">
                            <th width="250">Name</th>
                            <th width="500">Description</th>
                            <th>Tables</th>
                            <th>Type</th>
                            <th>Logic</th>
                            <th>Filters</th>
                            <th>Query</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.metrics?.map((v, i) => (
                            <tr className="default-tr" key={i}>
                              <td>{v.metric}</td>
                              <td>{v.description}</td>
                              <td>{v.tables}</td>
                              <td>{v.type}</td>
                              <td>{v.logic}</td>
                              <td>{v.filter}</td>
                              <td>{v.query}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <p className="text-center m-3">No data available.</p>
                  )}
                </section>
              </Tab>
              <Tab
                disabled
                eventKey="data-model"
                title={
                  <FaDatabase className="disabled-icon font-medium pointer mx-2" />
                }
              >
                <section className="p-1 fixed-dimensions">
                  <SimpleTreeView
                    className="m-1"
                    // onNodeClick={handleExpandTreeClick}
                    onNodeClick={data => console.log(data)}
                    renderLabel={item => item.name}
                    childrenKeyName="nextLineageNodes"
                    nameField="name"
                    renderChild={item => (
                      <div className="d-flex align-items-center justify-content-between">
                        {item.name}
                      </div>
                    )}
                    searchTerm={""}
                    data={data.dataLineage}
                  />
                </section>
              </Tab>
              <Tab
                disabled
                eventKey="data-lineage"
                title={
                  <FaNetworkWired className="disabled-icon font-medium pointer mx-2" />
                }
              >
                <section className="p-1 fixed-dimensions"></section>
              </Tab>
              <Tab
                disabled
                eventKey="query"
                title={
                  <FaCode className="disabled-icon font-medium pointer mx-2" />
                }
              >
                <section className="p-1 fixed-dimensions"></section>
              </Tab>
              {!isEmpty(helpLinks) && (
                <Tab
                  eventKey="documentation"
                  title={
                    <AiFillFileText className="font-medium pointer mx-2" />
                  }
                >
                  <section className="p-1 py-4 fixed-dimensions">
                    {helpLinks.map((e, i) => (
                      <p>
                        <a href={e.url} className="mx-2" target="_blank">
                          {e.label}
                        </a>
                      </p>
                    ))}
                  </section>
                </Tab>
              )}
            </Tabs>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
export default SmartAssetViewerWidget;
