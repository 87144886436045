import React, { useEffect } from "react";
// import { NeoVis } from "neovis.js";
import { APP_VARS } from "../../services/appVars";
let viz;
let NeoVis = window.NeoVis;
function draw(id) {
  let config = {
    container_id: "viz",
    server_url: "bolt://graph.dev.edo.siriusxm.com:7687",
    server_user: "edo_seng_mktplc_app",
    server_password: "ldax@dev",
    server_database: "edo-ldax-dev",
    encrypted: "ENCRYPTION_ON",
    trust: "TRUST_SYSTEM_CA_SIGNED_CERTIFICATES",
    labels: {
      LDAX_Source: {
        caption: "brand",
        title_properties: ["brand", "brand_id"]
      },
      LDAX_Phone: {
        caption: "phone",
        title_properties: ["phone"]
      },
      LDAX_Account: {
        caption: "account_id",
        title_properties: ["account_id"]
      },
      LDAX: {
        caption: "ldax_id"
      }
    },
    relationships: {
      INTERACTS: {
        thickness: "count",
        caption: true
      }
    },
    initial_cypher: `MATCH p=(n:LDAX_Source{brand_id:'${id}'})-[r]-(x) RETURN p LIMIT 25`
    //"MATCH p=()-[r:HAS_PHONE_NUM]->() RETURN p LIMIT 25"
    //MATCH (n:LDAX_Source{brand:"SIRIUSXM",brand_id:"5000087074132"})-[r]-(p) RETURN n,r,p LIMIT 25
  };

  if (APP_VARS.REACT_APP_ENV === "prod") {
    config = {
      ...config,
      server_url: "bolt://graph.edo.siriusxm.com:7687",
      server_user: "edo_seng_mktplc_app",
      server_password: "Optimize!!",
      server_database: "edo-ldax-prod"
    };
  }
  viz = new NeoVis.default(config);
  viz.render();
}

export default function NeoGraph({ id }) {
  useEffect(() => {
    if (!!id) draw(id);
  }, [id]);
  return <div id="viz"></div>;
}
