import { primary, secondary } from "../variables.scss";

export const colorMap = {
  "SXM US Audio": primary,
  "Connected Vehicle": "#90a5b3",
  "SXM Canada Audio": "#9092b3",
  Pandora: "#7196c7"
};

export const getAssetColor = brand => colorMap[brand] || primary;

export const dataGovernanceScoreMap = {
  Gold: "#CCAD4D",
  Silver: "#A0B7B7",
  Bronze: "#D49B6F",
  Ore: "#948485",
  Pending: secondary
};

export const getDataGovernanceScoreColor = score =>
  dataGovernanceScoreMap[score] || secondary;
