import React, { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { AiOutlinePlus, AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import Modal from "react-bootstrap/Modal";
import {
  fetchBrands,
  createBrand,
  deleteBrand,
  updateBrand,
  trackUserAction
} from "../../services/dataService";
import Grid from "../../components/Grid";
import AccentButton from "../../components/AccentButton";
import { useAlertsContext } from "../../context/alerts";

import Spinner from "../../components/Spinner";
import ModalCard from "../../components/ModalCard";
import { AbilityContext, PermissionsContext } from "./AbilityContext";
import { Can } from "./AbilityContext";
import defineRulesFor from "./permissions";

function BrandManagement(props) {
  const [show, setShow] = useState(false);
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  const { register, handleSubmit, errors, reset } = useForm();
  const [itemForEdit, setItemForEdit] = useState(null);
  const { addSuccessAlert, addFailAlert } = useAlertsContext();

  const [delId, setDelId] = useState(null);

  const ability = useContext(AbilityContext);
  const { permissionsCtxt } = useContext(PermissionsContext);
  const [permissions, , isAdmin] = permissionsCtxt;

  useEffect(() => {
    const rules = defineRulesFor(permissions, isAdmin);
    ability.update(rules);
  }, [permissions.length]);

  const handleCloseDelModal = () => {
    setDelId(null);
  };
  const handleShowDelModal = id => {
    setDelId(id);
  };

  useEffect(() => {
    fetchBrands()
      .then(res => {
        setBrands(res.data);
        setLoading(false);
        trackUserAction({
          action: "List",
          targetType: "Admin",
          targetName: "Brand",
          targetPath: "/admin/brand",
          targetID: "",
          status: "Success",
          errorDetails: ""
        });
      })
      .catch(() => setLoading(false));
  }, []);

  const handleCloseModal = () => {
    setItemForEdit(null);
    reset({});
    setShow(false);
  };
  const handleNewBrandClick = () => {
    setShow(true);
  };

  const onSubmit = async data => {
    const brandID = itemForEdit ? itemForEdit.id : null;
    const isEdit = !!brandID;

    if (isEdit) {
      try {
        const {
          data: [response]
        } = await updateBrand({
          ...data,
          id: brandID,
          type: "DB"
        });
        await trackUserAction({
          action: "Edit",
          targetType: "Admin",
          targetName: "Brand",
          targetPath: "/admin/brand",
          targetID: "",
          status: "Success",
          errorDetails: ""
        });
        handleCloseModal();
        addSuccessAlert(`Brand updated`);

        const index = brands.findIndex(i => i.id === response.id);
        if (index !== undefined) {
          // Insert new updated row into the grid at the correct index
          setBrands(items =>
            items.map((i, idx) => {
              if (idx === index) {
                return response;
              }
              return i;
            })
          );
        }
      } catch (err) {
        handleCloseModal();
        addFailAlert("Something went wrong");
      }
    } else {
      try {
        const {
          data: [response]
        } = await createBrand({
          ...data,
          type: "DB"
        });
        await trackUserAction({
          action: "Create",
          targetType: "Admin",
          targetName: "Brand",
          targetPath: "/admin/brand",
          targetID: "",
          status: "Success",
          errorDetails: ""
        });
        handleCloseModal();
        addSuccessAlert(`Brand created`);

        // Add new row to the end
        setBrands([...brands, response]);
      } catch (err) {
        handleCloseModal();
        addFailAlert("Something went wrong");
      }
    }
  };

  const handleDeleteClick = async id => {
    try {
      await deleteBrand({ id: id });
      await trackUserAction({
        action: "Delete",
        targetType: "Admin",
        targetName: "Brand",
        targetPath: "/admin/brand",
        targetID: "",
        status: "Success",
        errorDetails: ""
      });
      addSuccessAlert("Brand deleted");
      setBrands(items => items.filter(i => i.id !== id));
    } catch (err) {
      addFailAlert("Something went wrong");
    }
  };

  const handleEditClick = brand => {
    setItemForEdit(brand);
    reset(brand);
    setShow(true);
  };

  return (
    <div className="Brands">
      <Can I="Write" a="Brand Management">
        {() => (
          <Button className="mb-3 mx-2" onClick={handleNewBrandClick}>
            <AiOutlinePlus /> New Brand
          </Button>
        )}
      </Can>

      {loading ? (
        <Spinner>Fetching Brands</Spinner>
      ) : (
        <Grid
          width="100%"
          title="Brands"
          columnDefs={[
            {
              field: "name",
              headerName: "Name",
              sortable: true,
              resizable: true
            },
            {
              field: "edit",
              headerName: "",
              resizable: true,
              cellRendererFramework: ({ data, value }) => {
                return (
                  <Can I="Write" a="Brand Management">
                    {() => (
                      <>
                        <AccentButton onClick={() => handleEditClick(data)}>
                          <AiOutlineEdit /> Edit
                        </AccentButton>
                        <AccentButton
                          className="sx-accent-button--danger ml-2"
                          onClick={() => handleShowDelModal(data.id)}
                        >
                          <AiOutlineDelete /> Delete
                        </AccentButton>
                      </>
                    )}
                  </Can>
                );
              }
            }
          ]}
          rowData={brands}
        />
      )}

      <Modal className="brands-modal" show={show} onHide={handleCloseModal}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {!!itemForEdit ? "Edit" : "Add New"} Brand
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form.Group as={Row} controlId="brands-name">
              <Form.Label column sm="4">
                Name*
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  name="name"
                  ref={register({
                    required: true,
                    validate: val => val.trim() !== ""
                  })}
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.name}
                  maxLength={250}
                />
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button type="submit" variant="primary">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {!!delId && (
        <ModalCard
          body={`Do you still want to delete this ${`Brand`}?`}
          title={`Delete ${`Brand`} Confirmation`}
          show={!!delId}
          noText="Cancel"
          yesText="Ok"
          handleNo={handleCloseDelModal}
          handleYes={() => {
            handleDeleteClick(delId);
            handleCloseDelModal();
          }}
        />
      )}
    </div>
  );
}

export default BrandManagement;
