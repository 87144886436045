import groupBy from "lodash/groupBy";
import React, { useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { FiFilter } from "react-icons/fi";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch
} from "react-router-dom";
import CheckWithCount from "../../components/CheckWithCount";
import ExpandableMenu from "../../components/ExpandableMenu";
import BasePage from "../../components/layouts/BasePage";
import { useAlertsContext } from "../../context/alerts";
import {
  fetchLegend,
  fetchAllFilters,
  fetchAllGraphData,
  fetchDashboardDomainsAndCategories,
  trackUserAction,
  validateDuUser,
  fetchDCHelplinks
} from "../../services/dataService";
import { SmartSearchContext } from "./SmartSearchContext";
import SmartSearchResults from "./SmartSearchResults";

function SmartSearchShell({
  globalSearchText,
  activeFilters,
  setActiveFilters
}) {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { addFailAlert } = useAlertsContext();
  const [openAccordionKey, setOpenAccordionKey] = useState(1);
  const [domainsLoading, setDomainsLoading] = useState(false);

  const [domainCounts, setDomainCounts] = useState([]);
  const [categoryCounts, setCategoryCounts] = useState([]);
  const [contactCounts, setContactCounts] = useState([]);
  const [platformCounts, setPlatformCounts] = useState([]);
  const [certificationCounts, setCertificationCounts] = useState([]);
  const {
    tabContext,
    allTabContext,
    dashboardTabContext,
    metricTabContext,
    datacatalogTabContext,
    userContext,
    pageContext,
    dataUniversityContext,
    legendContext,
    dataDocumentationContext,
    USER_STATUS
  } = useContext(SmartSearchContext);
  const [legend, setLegend] = legendContext;
  const [activeTab, setActiveTab] = tabContext;
  const [pageData, setPageData] = pageContext;
  const [allGraphData, setAllGraphData, , setAllTabData] = allTabContext;
  const [
    dashboardGraphData,
    setDashboardGraphData,
    ,
    setDashboardTabData
  ] = dashboardTabContext;
  const [
    metricGraphData,
    setMetricGraphData,
    ,
    setMetricTabData
  ] = metricTabContext;
  const [
    datacatalogGraphData,
    setDatacatalogGraphData,
    ,
    setDatacatalogTabData
  ] = datacatalogTabContext;
  const [, setUser, , setUserStatus] = userContext;
  const [
    dataUniversityGraphData,
    setDataUniversityGraphData,
    ,
    setDataUniversityTabData
  ] = dataUniversityContext;
  const [dataDocumentation, setDataDocumentation] = dataDocumentationContext;

  const clearAll = async () => {
    await setActiveFilters([]);
    await setActiveTab("all");
    await setPageData(j => ({
      ...j,
      pageSize: 20,
      allTab: {
        ...j.allTab,
        currentPage: 1
      },
      dashboardTab: {
        ...j.dashboardTab,
        currentPage: 1
      },
      metricTab: {
        ...j.metricTab,
        currentPage: 1
      },
      datacatalogTab: {
        ...j.datacatalogTab,
        currentPage: 1
      },
      dataUniversityTab: {
        ...j.dataUniversityTab,
        currentPage: 1
      }
    }));
  };

  const handleSetPageData = (TAB, DATA) => {
    setPageData(j => ({
      ...j,
      [TAB]: {
        ...j[TAB],
        totalCount: DATA.length,
        numberOfPages: Math.ceil(DATA.length / pageData.pageSize)
      }
    }));
  };

  const handleSetDomainsData = (TAB, DATA, SETTER) => {
    let slicedDashboards = DATA.slice(
      pageData.pageSize * (pageData[TAB].currentPage - 1),
      pageData.pageSize * pageData[TAB].currentPage - 1
    );
    let numberOfPages = Math.ceil(DATA.length / pageData.pageSize),
      currentPage = pageData[TAB].currentPage;

    numberOfPages !== pageData[TAB].numberOfPages && (currentPage = 1);
    SETTER(slicedDashboards);
    setPageData(j => ({
      ...j,
      [TAB]: {
        ...j[TAB],
        filteredCount: DATA.length,
        currentPage: currentPage,
        numberOfPages: Math.ceil(DATA.length / pageData.pageSize)
      }
    }));
  };

  useEffect(() => {
    clearAll();
    !allGraphData.length && setDomainsLoading(true);
    fetchSmartSearchData(globalSearchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addFailAlert, globalSearchText]);

  const fetchSmartSearchData = async searchText => {
    let response1 = await fetchDashboardDomainsAndCategories();
    let response2 = await fetchAllGraphData(searchText);
    let response3 = await fetchLegend();
    let response4 = await fetchDCHelplinks();

    setDomainsLoading(false);

    setLegend(response3.data);
    setDataDocumentation(response4.data);

    try {
      let AllData = response2.data.results.sort((a, b) => b.score - a.score);
      let DomainsToAdd = [
        ...new Set([
          ...AllData.filter(
            el => el.domain && el.type !== "Data University"
          ).map(el => el.domain),
          ...response1.Domains.map(d => d.NAME.replace(/\s+/g, " "))
        ])
      ].map((i, index) => ({
        id: i + "-",
        count: 0,
        label: i,
        dbKeyName: "dbDomainID"
      }));
      let CategoriesToAdd = [
        ...new Set([
          ...AllData.filter(
            el => el.category && el.type !== "Data University"
          ).map(el => el.category),
          ...response1.Categories.map(c => c.NAME.replace(/\s+/g, " "))
        ])
      ].map((i, index) => ({
        id: i + "-",
        count: 0,
        label: i,
        dbKeyName: "dbCategoryID"
      }));
      let ContactsToAdd = [
        ...new Set([
          ...AllData.filter(
            el => el.assetOwner && el.type !== "Data University"
          ).map(el => el.assetOwner)
        ])
      ].map((i, index) => ({
        id: i + "-",
        count: 0,
        label: i,
        dbKeyName: "edoContact"
      }));

      let PlatformsToAdd = [
        ...new Set([
          ...AllData.filter(
            el => el.platform && el.type !== "Data University"
          ).map(el => el.platform)
        ])
      ].map((i, index) => ({
        id: i + "-",
        count: 0,
        label: i,
        dbKeyName: "platform"
      }));

      let CertificationsToAdd = [
        ...new Set([
          ...AllData.filter(
            el => el.certification && el.type !== "Data University"
          ).map(el => el.certification)
        ])
      ].map((i, index) => ({
        id: i + "-",
        count: 0,
        label: i,
        dbKeyName: "certification"
      }));

      setDomainCounts(DomainsToAdd);
      setCategoryCounts(CategoriesToAdd);
      setContactCounts(ContactsToAdd);
      setPlatformCounts(PlatformsToAdd);
      setCertificationCounts(CertificationsToAdd);
      // Setting Tabs Data
      setAllGraphData(AllData.filter(e => e.type !== "Data University"));
      setDashboardGraphData(AllData.filter(e => e.type === "dashboard"));
      setMetricGraphData(AllData.filter(e => e.type === "metric"));
      setDatacatalogGraphData(AllData.filter(e => e.type === "dataset"));
      setDataUniversityGraphData(
        AllData.filter(e => e.type === "Data University")
      );
      // Setting User Data

      let response5 = await validateDuUser();

      if (!!response5) {
        setUser({
          studentName: response4?.data.StudentName,
          studentId: response4?.data.id
        });
        setUserStatus(response4?.data.Status || USER_STATUS["NEW"]);
      }
    } catch (error) {
      setDomainsLoading(false);
    }

    // Promise.all([
    //   fetchDashboardDomainsAndCategories(),
    //   fetchAllGraphData(globalSearchText),
    //   fetchLegend()
    // ])
    //   .then(response => {

    //     setDomainsLoading(false);
    //   })
    //   .catch(err => {
    //     // addFailAlert("Something went wrong fetching dashboards");
    //     setDomainsLoading(false);
    //   });
  };
  useEffect(() => {
    let dataGetter = [];
    let dataSetter = () => {};
    if (activeTab === "all") {
      handleSetPageData("allTab", allGraphData);
      dataGetter = allGraphData;
      dataSetter = setAllTabData;
    }
    if (activeTab === "dashboards") {
      handleSetPageData("dashboardTab", dashboardGraphData);
      dataGetter = dashboardGraphData;
      dataSetter = setDashboardTabData;
    }
    if (activeTab === "metrics") {
      handleSetPageData("metricTab", metricGraphData);
      dataGetter = metricGraphData;
      dataSetter = setMetricTabData;
    }
    if (activeTab === "data") {
      handleSetPageData("datacatalogTab", datacatalogGraphData);
      dataGetter = datacatalogGraphData;
      dataSetter = setDatacatalogTabData;
    }
    if (activeTab === "data-university") {
      handleSetPageData("dataUniversityTab", dataUniversityGraphData);
      dataGetter = dataUniversityGraphData;
      dataSetter = setDataUniversityTabData;
    }
    // First, filter the superset down by text search query
    // Break active filters out by type
    // i.e. {"Business Domain": [1234, 5678], "Subdomain": [9548, 3728]}
    const grouped = groupBy(activeFilters, "type");
    console.log("grouped Data", grouped);
    // Second, filter the above result by domainId
    const appliedBusinessDomains = grouped["Business Domain"];

    const dashboardsByDomain = dataGetter.filter(i => {
      if (!appliedBusinessDomains) {
        return true;
      }
      return appliedBusinessDomains.map(j => j.value).indexOf(i.domain) > -1;
    });

    // Third, filter the above result by category (aka subdomain)
    const appliedSubdomains = grouped["Subdomain"];
    const dashboardsByCategory = dashboardsByDomain.filter(i => {
      if (!appliedSubdomains) {
        return true;
      }
      return appliedSubdomains.map(j => j.value).indexOf(i.category) > -1;
    });

    // Forth, filter the above result by EDO Contact (aka assetOwner)

    const appliedContacts = grouped["EDO Contact"];
    const dashboardsByContacts = dashboardsByCategory.filter(i => {
      if (!appliedContacts) {
        return true;
      }
      return appliedContacts.map(j => j.value).indexOf(i.assetOwner) > -1;
    });

    // fifth, filter the above result by Platform

    const appliedPlatforms = grouped["Platform"];
    const dashboardsByPlatforms = dashboardsByContacts.filter(i => {
      if (!appliedPlatforms) {
        return true;
      }
      return appliedPlatforms.map(j => j.value).indexOf(i.platform) > -1;
    });

    // sixth, filter the above result by Certification

    const appliedCertifications = grouped["Data Certification"];
    console.log("certifications", appliedCertifications);
    const dashboardsByCertifications = dashboardsByPlatforms.filter(i => {
      if (!appliedCertifications) {
        return true;
      }
      return (
        appliedCertifications.map(j => j.value).indexOf(i.certification) > -1
      );
    });

    // Use the db's filtered by search text to get counts by domainId
    const countsByDomain = dataGetter.reduce((acc, curr) => {
      if (!acc[curr.domain]) {
        acc[curr.domain] = 0;
      }
      acc[curr.domain]++;
      return acc;
    }, {});

    // Use the db's filtered by domain to get counts by category (aka subdomain)
    const countsByCategory = dashboardsByDomain.reduce((acc, curr) => {
      if (!acc[curr.category]) {
        acc[curr.category] = 0;
      }
      acc[curr.category]++;
      return acc;
    }, {});

    // Use the db's filtered by category to get counts by EDO contacts (aka assetOwner)
    const countsByContacts = dashboardsByContacts.reduce((acc, curr) => {
      if (!acc[curr.assetOwner]) {
        acc[curr.assetOwner] = 0;
      }
      acc[curr.assetOwner]++;
      return acc;
    }, {});

    // Use the db's filtered by EDO Contacts to get counts by Platforms (aka platform)
    const countsByPlatforms = dashboardsByPlatforms.reduce((acc, curr) => {
      if (!acc[curr.platform]) {
        acc[curr.platform] = 0;
      }
      acc[curr.platform]++;
      return acc;
    }, {});
    // Use the db's filtered by Platforms to get counts by Data Certification (aka platform)
    const countsByCertifications = dashboardsByCertifications.reduce(
      (acc, curr) => {
        if (!acc[curr.certification]) {
          acc[curr.certification] = 0;
        }
        acc[curr.certification]++;
        return acc;
      },
      {}
    );
    if (activeTab === "all") {
      handleSetDomainsData("allTab", dashboardsByCertifications, dataSetter);
    }
    if (activeTab === "dashboards") {
      handleSetDomainsData(
        "dashboardTab",
        dashboardsByCertifications,
        dataSetter
      );
    }
    if (activeTab === "metrics") {
      handleSetDomainsData("metricTab", dashboardsByCertifications, dataSetter);
    }

    if (activeTab === "data") {
      handleSetDomainsData(
        "datacatalogTab",
        dashboardsByCertifications,
        dataSetter
      );
    }
    if (activeTab === "data-university") {
      handleSetDomainsData(
        "dataUniversityTab",
        dashboardsByCategory,
        dataSetter
      );
    }
    setDomainCounts(domCounts => {
      return domCounts.map(i => ({
        ...i,
        count: countsByDomain[i.label] || 0
      }));
    });

    setCategoryCounts(catCounts =>
      catCounts.map(i => ({
        ...i,
        count: countsByCategory[i.label] || 0
      }))
    );

    setContactCounts(conCounts =>
      conCounts.map(i => ({
        ...i,
        count: countsByContacts[i.label] || 0
      }))
    );
    console.log("Contacts", countsByContacts);

    setPlatformCounts(platCounts =>
      platCounts.map(i => ({
        ...i,
        count: countsByPlatforms[i.label] || 0
      }))
    );

    console.log("Plat", countsByPlatforms);
    setCertificationCounts(certCounts =>
      certCounts.map(i => ({
        ...i,
        count: countsByCertifications[i.label] || 0
      }))
    );
    console.log("Cert", countsByCertifications);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allGraphData,
    dashboardGraphData,
    metricGraphData,
    datacatalogGraphData,
    dataUniversityGraphData,
    activeTab,
    activeFilters,
    globalSearchText,
    pageData.pageSize,
    pageData.allTab.currentPage,
    pageData.dashboardTab.currentPage,
    pageData.metricTab.currentPage,
    pageData.datacatalogTab.currentPage,
    pageData.dataUniversityTab.currentPage
  ]);

  const sideFilterData = [
    {
      name: "Business Domain",
      id: 1,
      counts: domainCounts
    },
    {
      name: "Subdomain",
      id: 2,
      counts: categoryCounts
    },
    { name: "EDO Contact", id: 3, counts: contactCounts },
    { name: "Platform", id: 4, counts: platformCounts },
    { name: "Data Certification", id: 5, counts: certificationCounts }
  ];

  return (
    <BasePage>
      <Container fluid className="p-4">
        <Row>
          <Col md={4} lg={3} xl={2} sm={12}>
            <ExpandableMenu
              title={
                <div className="d-flex align-items-center">
                  <FiFilter />
                  <span className="ml-2">Filters</span>
                </div>
              }
              onItemClick={(key, item) => {
                if (key === openAccordionKey) {
                  return setOpenAccordionKey(null);
                }
                if (item.body) {
                  return setOpenAccordionKey(key);
                }
              }}
              activeKey={openAccordionKey}
              items={sideFilterData.map(i => ({
                ...i,
                body: (
                  <div>
                    {i.counts.map(({ label, count, id, dbKeyName }) => (
                      <CheckWithCount
                        key={id + label}
                        className="mb-2"
                        name={`${i.name}|${id}|${label}`}
                        label={label}
                        checked={!!activeFilters.find(k => k.id === id)}
                        count={count}
                        onChange={e => {
                          trackUserAction({
                            action: "Search",
                            targetType: "filter",
                            targetName: `${i.name}|${id}|${label}`,
                            targetPath: "/smartsearch/results",
                            targetID: id,
                            status: "Success",
                            errorDetails: ""
                          });
                          history.push("/smartsearch/results");
                          const filterExists = activeFilters.find(
                            j => j.id === id
                          );

                          if (filterExists) {
                            return setActiveFilters(af =>
                              af.filter(j => j.id !== id)
                            );
                          }
                          return setActiveFilters(af => [
                            ...af,
                            {
                              type: i.name,
                              value: label,
                              id: id,
                              dbKeyName
                            }
                          ]);
                        }}
                      />
                    ))}
                  </div>
                )
              }))}
            ></ExpandableMenu>
          </Col>
          <Col md={8} lg={9} xl={10} sm={12}>
            <Switch>
              {/* If landing on "/smartsearch" then redirect to "/smartsearch/results" */}
              <Redirect exact path={path} to={`${path}/results`} />
              {/* Sub-Route for Search Results */}
              <Route exact path={`${path}/results`}>
                <SmartSearchResults
                  dashboardsLoading={domainsLoading}
                  activeFilters={activeFilters}
                  setActiveFilters={setActiveFilters}
                  globalSearchText={globalSearchText}
                />
              </Route>
            </Switch>
          </Col>
        </Row>
      </Container>
    </BasePage>
  );
}

export default SmartSearchShell;
