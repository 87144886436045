import React, { useEffect } from "react";
import BasePage from "../../components/layouts/BasePage";
import { navbarHeight } from "../../variables.scss";
import { trackUserAction } from "../../services/dataService";
import { APP_VARS } from "../../services/appVars";

function TableauAskData() {
  useEffect(() => {
    trackUserAction({
      action: "View Tableau Ask Data",
      targetType: "tile",
      targetName: "Tableau Ask Data",
      targetPath: "/tableauaskdata",
      targetID: "Tableau Ask Data",
      status: "Success",
      errorDetails: ""
    });
  }, []);
  return (
    <BasePage>
      <iframe
        className="py-3 px-2"
        title="TableauAskData"
        id="tableau-ask-data"
        src={APP_VARS.REACT_APP_TABLEAU_ASKDATA_HOST}
        style={{ width: "100%", height: `calc(100vh - ${navbarHeight})` }}
      ></iframe>
    </BasePage>
  );
}

export default TableauAskData;
