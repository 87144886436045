import React from "react";
import Navbar from "../../components/Navigation";
import { APP_VARS } from "../../services/appVars";

function SlimNavbar() {
  return (
    <Navbar className="sx-navbar-body">
      <div className="pl-4">
        <img
          className="pl-4"
          style={{ height: "auto", maxWidth: "383px" }}
          src={APP_VARS.REACT_APP_ASSETS_URL + "/images/logo.png"}
          alt="SXM Brand Logo"
        />
      </div>
    </Navbar>
  );
}

export default SlimNavbar;
