import React from "react";
import ReactSlickSlider from "react-slick";
const Slider = React.forwardRef(
  ({ slidesToShow = 1, slidesToScroll = 1, children, ...otherProps }, ref) => (
    <ReactSlickSlider
      dots={Math.ceil(children.length / slidesToShow) <= 7}
      slidesToShow={slidesToShow}
      slidesToScroll={slidesToScroll}
      children={children}
      ref={ref}
      {...otherProps}
    />
  )
);
export default Slider;
