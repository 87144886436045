import React, { useContext, useEffect } from "react";
import SmartSearchShell from "./DashboardCatalogShell";
import { DashboardsProvider, DashboardsContext } from "./DashboardsContext";

export default function SmartSearchWrapper({
  globalSearchText,
  setGlobalSearchText,
  activeFilters,
  setActiveFilters,
  isAdmin
}) {
  return (
    <DashboardsProvider>
      <SmartSearchShell
        globalSearchText={globalSearchText}
        setGlobalSearchText={setGlobalSearchText}
        activeFilters={activeFilters}
        setActiveFilters={setActiveFilters}
        isAdmin={isAdmin}
      />
    </DashboardsProvider>
  );
}
