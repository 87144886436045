import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import Slider from "react-slick";
import BaseCard from "../../components/BaseCard";
import InfoCard from "../../components/InfoCard";
import BasePage from "../../components/layouts/BasePage";
import Spinner from "../../components/Spinner";
import { APP_VARS } from "../../services/appVars";
import {
  getDUSeats,
  getLearningPaths,
  getNewCourseCategories,
  trackUserAction
} from "../../services/dataService";
import FooterSupportCard from "../landing/FooterSupportCard";
import DUBanner from "./Banner";
import { COURSE_MENU_ITEMS } from "./content";
import "./DataUniversity.scss";
import { DataUniversityContext, USER_STATUS } from "./DataUniversityContext";
import LandingCourseLibrary from "./LandingCourseLibrary";
import Register from "./Register";
import AccentButton from "../../components/AccentButton";

function DataUniversity() {
  const menuItems = COURSE_MENU_ITEMS;
  const [seats, setSeats] = useState(0);
  const [show, setShow] = useState(false);
  const [visiblity, setVisibility] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const {
    categoriesContext,
    learningPathsContext,
    userStatusContext
  } = useContext(DataUniversityContext);
  const [userStatus] = userStatusContext;

  const [, setLearningPaths] = learningPathsContext;
  const [categories, setCategories] = categoriesContext;

  useEffect(() => {
    getDUSeats().then(res => {
      setSeats(res.data.availableSeats);
    });
    getNewCourseCategories().then(cc => {
      setCategories(cc.data?.sort((a, b) => a.SortOrder - b.SortOrder));
    });

    Promise.all([getLearningPaths(), getLearningPaths("?university=SiriusXM")])
      .then(res => {
        setLearningPaths([...res[0].data, ...res[1].data]);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
    trackUserAction({
      action: "View",
      targetType: "Page",
      targetName: "Data University",
      targetPath: "/data-university",
      targetID: "",
      status: "Success",
      errorDetails: ""
    });
  }, [setCategories, setLearningPaths]);
  let settings = {
    dots: false,
    centerMode: false,
    slidesToShow: 3,
    swipeToSlide: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    nextArrow: <MdKeyboardArrowRight />,
    prevArrow: <MdKeyboardArrowLeft />
  };
  const setModalShow = category => {
    setSelectedCategory(category);
    trackUserAction({
      action: "View",
      targetType: "Category",
      targetName: "Data University",
      targetPath: "/data-university",
      targetID: "",
      status: "Success",
      errorDetails: ""
    });
    setShow(true);
  };

  return (
    <>
      {loading ? (
        <BasePage className="p-5">
          <BaseCard>
            <Container fluid className="p-5">
              <Row>
                <Col sm={12} className="text-center">
                  <Spinner>Loading Seats</Spinner>
                </Col>
              </Row>
            </Container>
          </BaseCard>
        </BasePage>
      ) : (
        <BasePage className="Data-University">
          <DUBanner seats={seats} />
          <section className="section-features">
            <Container fluid className="pb-5 huge-container">
              <Row className="p-5">
                <Col sm={12}>
                  <div className="pb-3">
                    <h2 className="font-weight-bold section-title">
                      What’s your interest?
                    </h2>
                  </div>
                </Col>
                <Col sm={12}>
                  <Slider {...settings}>
                    {categories.map(c => (
                      // <Col md={4} sm={12} className="px-4" key={c.name}>
                      <InfoCard
                        title={c.name}
                        image={<img src={c?.Thumbnail[1]?.url} alt={c.name} />}
                        subtitle={c.description}
                        theme={menuItems[2].variant}
                        linkUrl={menuItems[0].linkUrl}
                        courseIds={menuItems[0].courseIds}
                        isModal
                        setModalShow={setModalShow}
                        selectedCategory={c}
                        className="px-4"
                      />
                      // </Col>
                    ))}
                  </Slider>
                  <Modal
                    size="xl"
                    centered
                    show={show}
                    dialogClassName="category-modal"
                    onHide={() => {
                      setShow(false);
                    }}
                    style={{ display: !visiblity ? "none" : "" }}
                  >
                    <Modal.Body>
                      <LandingCourseLibrary
                        selectedCategory={selectedCategory}
                        setShow={setShow}
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="mx-auto p-2">
                        {userStatus === USER_STATUS["PENDING"] ? (
                          <Button className="pending-btn p-2">
                            Pending Approval
                          </Button>
                        ) : (
                          <>
                            {APP_VARS.ENABLE_DU ? (
                              <Register
                                DUClass="start-btn p-2"
                                setModalShow={setShow}
                                setVisibility={setVisibility}
                              />
                            ) : (
                              <AccentButton
                                disabled
                                className="disabled-btn p-2"
                              >
                                Coming Soon
                              </AccentButton>
                            )}
                          </>
                        )}
                      </div>
                    </Modal.Footer>
                  </Modal>
                  {/* <Row className="pb-4">
                    {menuItems.map(item => {
                      return (
                        <Col md={4} sm={12} className="px-4" key={item.title}>
                          <InfoCard
                            title={item.title}
                            image={
                              <img
                                src={
                                  APP_VARS.REACT_APP_ASSETS_URL + item.imageUrl
                                }
                                alt={item.title}
                              />
                            }
                            subtitle={item.subtitle}
                            variant={item.variant}
                            linkUrl={item.linkUrl}
                            courseIds={item.courseIds}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                 */}
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section-footer">
            <Container fluid className="pb-5 huge-container">
              <Row className="p-5">
                <Col sm={12}>
                  <div className="pb-3">
                    <h2 className="font-weight-bold section-title">
                      Getting Started
                    </h2>
                  </div>
                </Col>
                <Col sm={8}>
                  <div className="p-4 footer-cards footer-blue position-relative">
                    <div className="badge">Coming Soon</div>
                    <Col sm={9}>
                      <h3 className="font-weight-bold">
                        See what you can do with SiriusXM Data
                      </h3>
                      <p>
                        Learn about SiriusXM and Pandora data assets, data
                        architecture and how we are using data to drive business
                        outcomes.
                      </p>
                    </Col>
                  </div>
                </Col>
                <Col sm={4}>
                  <FooterSupportCard />
                </Col>
              </Row>
            </Container>
          </section>
        </BasePage>
      )}
    </>
  );
}

export default DataUniversity;
