import React from "react";
import classnames from "classnames";
import Chip from "./Chip";
import Button from "react-bootstrap/Button";
import { AiOutlineDelete } from "react-icons/ai";

import "./Chipset.scss";

function Chipset({
  onClose = () => {},
  onClearAll = () => {},
  className = "",
  chips = [],
  ...otherProps
}) {
  return (
    <div
      className={classnames(
        "sx-chipset",
        "d-flex",
        "flex-wrap",
        "align-items-center",
        className
      )}
      {...otherProps}
    >
      {chips.map(c => (
        <Chip
          key={c.id}
          type={c.type}
          value={c.value}
          onClose={() => onClose(c)}
        />
      ))}
      <Button onClick={onClearAll} variant="link">
        <AiOutlineDelete /> Clear All
      </Button>
    </div>
  );
}

export default Chipset;
